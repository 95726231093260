import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { Tabs, Tab, Grid, Typography } from "@material-ui/core";
import useStyles from "../../../modules/UserProfile/profileStyles/myProfileStyles";
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import GettingStarted from "./GettingStarted/GettingStarted";
//import FAQs from "./FAQs";
import { SelectedCustomerAlias } from "../CompoundFeedWizard/types";
import AccessSampleData from "../Benchmark/AccessSampleData";
import useAdobeDataLayer from "../../analytics/adobeDataLayer";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const KnowledgeBase : React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [activeView, setActiveView] = useState(0);
  const selectedCustomer = useSelector(
    (state: SelectedCustomerAlias) => state.selectedCustomer?.value
  );
  const { formStartEvent } = useAdobeDataLayer();

  const gettingStartedTitle = intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.GETTING_STARTED' });
  //const faqsTitle = intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.FAQS' });
  const requestDataTitle = intl.formatMessage({ id: 'SUSTELL.HELP_CENTER.TAB.KNOWLEDGE_BASE.REQUEST_SAMPLE_DATA' });

  const tabsContent = [
    gettingStartedTitle,
    //faqsTitle,
    requestDataTitle,
  ];
  const handleTabChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: number
  ) => {
    setActiveView(newValue);
    if (newValue === 1) {
      formStartEvent('Request sample data', 'add');
    }
  };
  
  return (
    <div>
      <Grid container>
        <Grid item xs={3}>
          <Tabs
            orientation="vertical"
            className={classes.knowledgeBaseVerticalTabs}
            value={activeView}
            onChange={handleTabChange}
          >
            {tabsContent.map((tab, index) => (
              <Tab
                key={`${tab}-key`}
                className={classes.knowledgeBaseVerticalTab}
                label={tab}
                value={index}
              />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={9}>
          {activeView === 0 && <div className={classes.profileSubContainer}>
            <Typography className={classes.profileTitle}>{gettingStartedTitle}</Typography>
            <GettingStarted/>
          </div>}
          {/*activeView === 1 && <div className={classes.profileSubContainer}>
            <Typography className={classes.profileTitle}>{faqsTitle}</Typography>
            <FAQs />
            </div>*/}
          {activeView === 1 && <div className={classes.profileSubContainer}>
              <Typography className={classes.profileTitle}>{requestDataTitle}</Typography>
              <AccessSampleData
                onClose={() => {}}
                isShowPostLogin={false}
                customerID ={selectedCustomer?.id}
              ></AccessSampleData>
            </div>}
        </Grid>
      </Grid>
    </div>
  )};

export default KnowledgeBase;
