import { FC } from 'react';
import { DsmButton, DsmIcon } from '@dsm-dcs/design-system-react';
import { Box } from '@material-ui/core';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';

// placeholder types
export type AnyEvent = any;

interface StepperButtonsProps {
  cancelHandler?: (e: AnyEvent | undefined) => void | undefined;
  localSaveHandler?: (e: AnyEvent | undefined) => void | undefined;
  skipHandler?: (e: AnyEvent | undefined) => void | undefined;
  continueHandler?: (e: AnyEvent | undefined) => void | undefined;
  backButtonHandler?: (e: AnyEvent | undefined) => void | undefined;
  cancelLabel?: string | undefined;
  skipLabel?: string | undefined;
  continueLabel?: string | undefined;
  backButtonLabel?: string | undefined;
  localSaveLabel?: string | undefined;
  hideStandardContinue?: boolean;
  hideCancelButton?: boolean;
}

export const StepperButtons: FC<StepperButtonsProps> = ({
  cancelHandler,
  localSaveHandler,
  skipHandler,
  continueHandler,
  backButtonHandler,
  cancelLabel,
  localSaveLabel,
  skipLabel,
  continueLabel,
  hideStandardContinue = false,
  hideCancelButton = false,
  backButtonLabel,
}) => {
  const intl = useIntl();
  const cancelBtnLabel =
    cancelLabel || intl.formatMessage({ id: 'GENERAL.CANCEL' });
  const skipBtnLabel = skipLabel || intl.formatMessage({ id: 'SUSTELL.SKIP' });
  const localSaveLbl =
    localSaveLabel || intl.formatMessage({ id: 'GENERAL.SAVE' });
  const continueBtnLabel =
    continueLabel || intl.formatMessage({ id: 'SUSTELL.SAVE_CONTINUE' });
  const backBtnLabel =
    backButtonLabel || intl.formatMessage({ id: 'GENERAL.BACK' });

  return (
    <>
      {/* Buttons for stepping forward */}
      <Box
        style={{ display: 'flex', marginTop: 'var(--dsm-spacing-px-8)' }}
        flexDirection="row"
        justifyContent="space-between"
      >
        {!hideCancelButton && (
          <DsmButton
            variant="primary"
            destructive={true}
            disabled={!cancelHandler}
            onClick={cancelHandler}
          >
            <DsmIcon slot="before" name="general/trash-01" />
            {cancelBtnLabel}
          </DsmButton>
        )}
        {hideCancelButton && <div />}
        <Box
          style={{ display: 'flex', width: '30%' }}
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          {skipHandler && (
            <DsmButton variant="text" onClick={skipHandler}>
              {skipBtnLabel}
            </DsmButton>
          )}
          {localSaveHandler && (
            <DsmButton
              style={{ marginLeft: 'var(--dsm-spacing-px-4)' }}
              variant="primary"
              onClick={localSaveHandler}
            >
              {localSaveLbl}
            </DsmButton>
          )}
          {backButtonHandler && (
            <DsmButton
              style={{ marginLeft: 'var(--dsm-spacing-px-4)' }}
              variant="secondary"
              onClick={backButtonHandler}
            >
              <DsmIcon slot="before" name="arrows/arrow-left" />
              {backBtnLabel}
            </DsmButton>
          )}
          {!hideStandardContinue && (
            <DsmButton
              style={{ marginLeft: 'var(--dsm-spacing-px-4)' }}
              variant="primary"
              disabled={!continueHandler}
              onClick={continueHandler}
            >
              {continueBtnLabel}
              <DsmIcon slot="after" name="arrows/arrow-right" />
            </DsmButton>
          )}
        </Box>
      </Box>
    </>
  );
};

export default StepperButtons;
