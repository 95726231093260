import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { defaultUnitsV2 as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import { TransportMode } from '../../../../../graphql/types';

import { emissionFieldsMapOutV2 } from '../../Intervention/mapper/emissionFields';
import { floatValueInDefaultUnit, setFloatValue } from './floatHelperFunctions';
import { v4 as uuidv4 } from 'uuid';
import { StageType } from '../../../../../graphql/types';
import { mapOutProcessingStage } from './outProcessingMapperSustell';
import { AnimalProductivity } from '../../../../../graphql/generated/blonk/pigs';

const defaultTimeUnit = 'ROUND';
const productivity = AnimalProductivity.High;

export const createCorePayloadSustell = (formData, isDraft = false) => {
  const defaultCycles = !isDraft ? 2 : undefined;

  const payloadObj = {
    name: formData.info.name,
    year: parseInt(formData.info.year, 10),
    description: formData.info.description,
    validFrom: formData.info.validFrom,
    validTo: formData.info.validTo,
    reportingPeriod: defaultTimeUnit,
    roundsPerYear: defaultCycles,
    databaseFoundation: formData.info.databaseFoundation,
    // additional property initialV2Copy - needed for pig conversion from v1.5 to 2.0, but probably also for all other species later
    // set only by copying Lambda to true, to avoid calling Blonk when dataset is known to be incomplete
    // if missing (for backward compatibility) OR false the backend call of Blonk will work normally, only true will stop it
    initialV2Copy: false,  
    resourceUse: {},
    stages: [],
    age: formData.info.age
  };

  if (formData.info.oldName) payloadObj.oldName = formData.info.oldName;

  return payloadObj;
};

export const getUserTransportUnit = (selectedTransportMode) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  switch (selectedTransportMode) {
    case TransportMode.InlandShip:
      return userUOM.unitTransportDistanceInlandWaterways;      
    case TransportMode.SeaShip:
      return userUOM.unitTransportDistanceSea;    
    case TransportMode.Train:
    case TransportMode.Truck:
    default:
      return userUOM.unitTransportDistanceTerrestrial;
  }
}

export const getDefaultTransportUnit = (selectedTransportMode) => {
  switch (selectedTransportMode) {
    case TransportMode.InlandShip:
      return defaultMetric.unitTransportDistanceInlandWaterways;      
    case TransportMode.SeaShip:
      return defaultMetric.unitTransportDistanceSea;    
    case TransportMode.Train:
    case TransportMode.Truck:
    default:
      return defaultMetric.unitTransportDistanceTerrestrial;
  }
}

export const mapResourceUseSustell = (
  formData,
  payloadObj,
  resources = [
    'electricityUse',
    'gasUse',
    'waterUse',
    'dieselUse',
    'gasolineUse',
    'propaneOrLpgUse',
    'coalUse',
    'electricityPhotovoltaicUse',
    'electricityWindUse',
  ]
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  // console.log(formData.resourceUse, payloadObj)
  if (resources?.includes('electricityUse'))
    setFloatValue(
      payloadObj.resourceUse,
      'electricityUse',
      formData.resourceUse,
      'electricityUse',
      userUOM.unitElectricityUse,
      defaultMetric.unitElectricityUse
    );

  if (resources?.includes('gasUse'))
    setFloatValue(
      payloadObj.resourceUse,
      'gasUse',
      formData.resourceUse,
      'gasUse',
      userUOM.unitNaturalGasUse,
      defaultMetric.unitNaturalGasUse
    );

  if (resources?.includes('waterUse'))
    setFloatValue(
      payloadObj.resourceUse,
      'waterUse',
      formData.resourceUse,
      'waterUse',
      userUOM.unitWaterUse,
      defaultMetric.unitWaterUse
    );

  if (resources?.includes('dieselUse'))
      setFloatValue(
        payloadObj.resourceUse,
        'dieselUse',
        formData.resourceUse,
        'dieselUse',
        userUOM.unitDieselUse,
        defaultMetric.unitDieselUse
      );

  if (resources?.includes('propaneOrLpgUse'))
    setFloatValue(
      payloadObj.resourceUse,
      'propaneOrLpgUse',
      formData.resourceUse,
      'propaneOrLpgUse',
      userUOM.unitNaturalGasUse,
      defaultMetric.unitNaturalGasUse
    );

  if (resources?.includes('coalUse'))
    setFloatValue(
      payloadObj.resourceUse,
      'coalUse',
      formData.resourceUse,
      'coalUse',
      userUOM.unitHeatUse,
      defaultMetric.unitHeatUse
    );

  if (resources?.includes('gasolineUse'))
    setFloatValue(
      payloadObj.resourceUse,
      'gasolineUse',
      formData.resourceUse,
      'gasolineUse',
      userUOM.unitDieselUse,
      defaultMetric.unitDieselUse
    );

  if (resources?.includes('electricityPhotovoltaicUse'))
    setFloatValue(
      payloadObj.resourceUse,
      'electricityPhotovoltaicUse',
      formData.resourceUse,
      'electricityPhotovoltaicUse',
      userUOM.unitElectricityUse,
      defaultMetric.unitElectricityUse
    );

  if (resources?.includes('electricityWindUse'))
    setFloatValue(
      payloadObj.resourceUse,
      'electricityWindUse',
      formData.resourceUse,
      'electricityWindUse',
      userUOM.unitElectricityUse,
      defaultMetric.unitElectricityUse
    );

  formData?.resourceUse?.energyTypes?.forEach((energyType) => {
    if (
      resources?.includes(energyType.type) &&
      energyType.type === 'dieselUse'
    )
      payloadObj.resourceUse.dieselUse =
        userUOM.unitDieselUse && defaultMetric.unitDieselUse && energyType.value
          ? floatValueInDefaultUnit(
              energyType.value,
              userUOM.unitDieselUse,
              defaultMetric.unitDieselUse
            )
          : energyType.value;
    if (resources?.includes(energyType.type) && energyType.type === 'coalUse')
      payloadObj.resourceUse.coalUse =
        userUOM.unitHeatUse && defaultMetric.unitHeatUse && energyType.value
          ? floatValueInDefaultUnit(
              energyType.value,
              userUOM.unitHeatUse,
              defaultMetric.unitHeatUse
            )
          : energyType.value;
    if (
      resources?.includes(energyType.type) &&
      energyType.type === 'propaneOrLpgUse'
    )
      payloadObj.resourceUse.propaneOrLpgUse =
        userUOM.unitNaturalGasUse &&
        defaultMetric.unitNaturalGasUse &&
        energyType.value
          ? floatValueInDefaultUnit(
              energyType.value,
              userUOM.unitNaturalGasUse,
              defaultMetric.unitNaturalGasUse
            )
          : energyType.value;
    if (
      resources?.includes(energyType.type) &&
      energyType.type === 'gasolineUse'
    )
      payloadObj.resourceUse.gasolineUse =
        userUOM.unitDieselUse && defaultMetric.unitDieselUse && energyType.value
          ? floatValueInDefaultUnit(
              energyType.value,
              userUOM.unitDieselUse,
              defaultMetric.unitDieselUse
            )
          : energyType.value;
  });

  formData?.resourceUse?.selfGeneratedRenewables?.forEach(
    (selfGeneratedRenewable) => {
      if (resources?.includes(selfGeneratedRenewable.type))
        payloadObj.resourceUse[selfGeneratedRenewable.type] =
          userUOM.unitElectricityUse &&
          defaultMetric.unitElectricityUse &&
          selfGeneratedRenewable.value
            ? floatValueInDefaultUnit(
                selfGeneratedRenewable.value,
                userUOM.unitElectricityUse,
                defaultMetric.unitElectricityUse
              )
            : selfGeneratedRenewable.value;
    }
  );
  // console.log(payloadObj.resourceUse)
  return payloadObj;
};

const mapCompoundFeedForOneStage = (feedFormData, stageFeedDataObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const stageFeedData = stageFeedDataObj; // pass reference to avoid error on assigning to input parameter
  if (feedFormData.compoundFeeds && Array.isArray(feedFormData.compoundFeeds)) {
    feedFormData.compoundFeeds.forEach((oneFeedItem) => {
      if (oneFeedItem.feedType && 'kgPerAnimal' in oneFeedItem) {
        const value = floatValueInDefaultUnit(
          oneFeedItem.kgPerAnimal,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass
        );
        const oneCompoundInput = {
          compoundFeedId: oneFeedItem.feedType,
          quantity: value,
        };

        stageFeedData.compoundFeedUses.push(oneCompoundInput);
      }
    });
  }
  return stageFeedData;
};

const mapSingleIngridientsForOneStage = (feedFormData, stageFeedDataObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const stageFeedData = stageFeedDataObj; // pass reference to avoid error on assigning to input parameter

  if (
    feedFormData.singleIngredients &&
    Array.isArray(feedFormData.singleIngredients)
  ) {
    feedFormData.singleIngredients.forEach((oneFeedItem) => {
      if (oneFeedItem.feedType && 'kgPerAnimal' in oneFeedItem) {
        const value = floatValueInDefaultUnit(
          oneFeedItem.kgPerAnimal,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass
        );
        const oneSingleIngredientInput = {
          ingredient: {
             // if ID not set, generate it
            id: oneFeedItem.id || uuidv4(),
            name:oneFeedItem.feedType,
            origin:oneFeedItem.origin 
          },
          quantity: value,
        };
        stageFeedData.singleIngredientUses.push(oneSingleIngredientInput);
      }
    });
  }

  return stageFeedData;
};

const mapNaturalFeedForOneStage = (feedFormData, stageFeedDataObj) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const stageFeedData = stageFeedDataObj; // pass reference to avoid error on assigning to input parameter

  if (
    feedFormData.naturalFeed &&
    Array.isArray(feedFormData.naturalFeed)
  ) {
    feedFormData.naturalFeed.forEach((oneFeedItem) => {
      if (oneFeedItem.type && 'quantity' in oneFeedItem) {
        const value = floatValueInDefaultUnit(
          oneFeedItem.quantity,
          userUOM.unitBarnOutputMass,
          defaultMetric.unitBarnOutputMass
        );
        const oneNaturalFeedInput = {
          id: oneFeedItem.id || uuidv4(),
          type: oneFeedItem.type,
          quantity: value,
          crudeProteinContent: oneFeedItem.crudeProteinContent,
          origin: oneFeedItem.origin,
        };

        if(oneFeedItem.distanceTraveled || oneFeedItem.distanceTraveled === 0)
          oneNaturalFeedInput.transport = [
            {
              distance: floatValueInDefaultUnit(
                oneFeedItem.distanceTraveled,
                getUserTransportUnit(oneFeedItem.transportMode),
                getDefaultTransportUnit(oneFeedItem.transportMode)
              ),
              mode: oneFeedItem.transportMode,
            },
          ];

        stageFeedData.naturalFeed.push(oneNaturalFeedInput);
      }
    });
  }

  return stageFeedData;
};

export const mapRationForOneStage = (feedFormData, stageFeedData) => {
  mapCompoundFeedForOneStage(feedFormData, stageFeedData);
  mapSingleIngridientsForOneStage(feedFormData, stageFeedData);
  mapNaturalFeedForOneStage(feedFormData, stageFeedData);
};

const mapEmissionForOneStage = (emissionFormData, emissionDataForOneStage) => {
  // now check the emission fields
  emissionFieldsMapOutV2.forEach((value, key) => {
    if (key in emissionFormData) {
      const valueNum = parseFloat(emissionFormData[key]);
      // console.log("key, value",key, value);
      emissionDataForOneStage.push({
        emissionType: value,
        change: valueNum,
      });
    }
  });
};

const mapCommonStageData = (formData, payloadObj) => {
  if (formData.stages && Array.isArray(formData.stages)) {
    for (let i = 0; i < formData.stages.length; i++) {
      const generalDataForStage = formData.stages[i];

      // for now one common mapper for processing stages
      // split per type if needed later
      if (generalDataForStage.type === StageType.Processing) {
        const oneStageData = mapOutProcessingStage(generalDataForStage);
        payloadObj.stages.push(oneStageData);
      } else {
        const formDataForStage = generalDataForStage.stageData || {};
        const oneStageData = {
          name: generalDataForStage.name,
          id: generalDataForStage.id || uuidv4(),
          type: generalDataForStage.type,
          pondName: generalDataForStage?.pondName,
          stageData: {
            animal_group: {},
            animalProductivity: productivity,
            feedingProgram: {
              compoundFeedUses: [],
              singleIngredientUses: [],
              naturalFeed: [],
            },
          },
        };
        if (formDataForStage.feed)
          mapRationForOneStage(
            formDataForStage.feed,
            oneStageData.stageData.feedingProgram
          );
        if ('emissions' in formDataForStage) {
          oneStageData.stageData.emissionMitigations = [];
          if (formDataForStage.emissions)
            mapEmissionForOneStage(
              formDataForStage.emissions,
              oneStageData.stageData.emissionMitigations
            );
        }
        payloadObj.stages.push(oneStageData);
      }
    }
  }
};

export const mapCommonProductionDataOutSustell = (
  formData,
  isDraft = false
) => {
  const payloadObj = createCorePayloadSustell(formData, isDraft);
  mapCommonStageData(formData, payloadObj);
  return payloadObj;
};
