import {
  ProcessingStageDBModel,
  ProcessingStageDataDBModel,
} from './TempProcessingDB';

type AnimalSourceOrigin = 'BACKGROUND_DATA' | 'PRODUCTION_SYSTEM';

export type Water = {
  amount: Scalars['Float'];
  type: WaterType;
};

export enum WaterType {
  Water = 'WATER',
}
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: Date;
  AWSDateTime: Date;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export enum ReportingPeriod {
  Round = 'ROUND',
  Year = 'YEAR',
}
export interface StageDBModel {
  name: string;
  id: string;
  stageData: any;
}
export interface BaselineDBModel {
  createdAt: string;
  updatedAt?: Maybe<string>;
  farmId: string;
  name: string;
  oldName: string;
  averageAnnualTemperature?: Maybe<number>;
  description: string;
  lastUpdatedBy: string;
  productionProcessId: string;
  reference: string;
  reportingPeriod?: Maybe<ReportingPeriod>;
  resourceUse?: Maybe<ResourceUse>;
  roundsPerYear?: Maybe<number>;
  validFrom: string;
  validTo: string;
  year: number;
  calculationInProgress?: Maybe<boolean>;
  stages: StageDBModel[];
}

export enum CompoundFeedDatabase {
  Afp_5 = 'AFP_5',
  Afp_6_3 = 'AFP_6_3',
  Gfli = 'GFLI',
}

export type Maybe<T> = T | null;

export enum StageType {
  Breeding = 'BREEDING',
  Fattening = 'FATTENING',
  Growing = 'GROWING',
  Hatching = 'HATCHING',
  Laying = 'LAYING',
  Processing = 'PROCESSING',
}

export enum WaterSourceType {
  GroundWater = 'GROUND_WATER',
  SurfaceWater = 'SURFACE_WATER',
}

export type ResourceUse = {
  __typename?: 'ResourceUse';
  coalUse?: Maybe<Scalars['Float']>;
  dieselUse?: Maybe<Scalars['Float']>;
  electricityPhotovoltaicUse?: Maybe<Scalars['Float']>;
  electricityUse?: Maybe<Scalars['Float']>;
  electricityWindUse?: Maybe<Scalars['Float']>;
  gasUse?: Maybe<Scalars['Float']>;
  gasolineUse?: Maybe<Scalars['Float']>;
  propaneOrLpgUse?: Maybe<Scalars['Float']>;
  waterSource?: Maybe<WaterSourceType>;
  waterUse?: Maybe<Scalars['Float']>;
};

export interface AnimalsIncomingDBModel {
  id: string;
  origin: AnimalSourceOrigin;
  originStageId?: Maybe<string>;
  baselineRef?: Maybe<string>; // Needed internally (When it's internal source)
  originStageName?: Maybe<string>; // Needed internally (When it's internal source)
  originFarmId?: Maybe<string>; // Needed internally (When it's internal source)
  originFarmName?: Maybe<string>; // Needed internally (When it's internal source)
  animalType?: 'CALF' | 'COW';
  stageType?: Maybe<string>;
  numberOfAnimals: number;
  transportDistance?: Maybe<number>;
  shrinkingRate?: number;
}
export interface CommonStageInputModel {
  animals: Array<AnimalsIncomingDBModel>;
  averageWeight: number;
}

export type AnimalProductivityDB = 'HIGH' | 'LOW';

export interface IngredientUseDBModel {
  id: string;
  name: string;
  origin: string;
}

export interface SingleIngredientUseDBModel {
  ingredient: IngredientUseDBModel;
  ashContent?: number;
  crudeProteinContent?: number;
  digestibleEnergyContent?: number;
  distanceTransport?: number;
  dryMatterContent?: number;
  grossEnergyContent?: number;
  milk?: boolean;
  milkFromMother?: boolean;
  naturalDetergentFiberContent?: number;
  phosphorusContent?: number;
  quantity?: number;
  silage?: boolean;
  urinaryEnergyContent?: number;
}

export interface FeedDBModel {
  quantity: number;
}

export interface CompundFeedInfoDBModel extends FeedDBModel {
  compoundFeedId: string;
  feedmillLocation?: string;
  ingredientUses: Array<IngredientUseDBModel>;
  name: string;
  outboundTransport?: {
    distance: number;
    mode: string;
  };
  water?: {
    amount: number;
    type: string;
  };
}

export interface FeedingProgramDBModel {
  databaseFoundation: CompoundFeedDatabase;
  dietCharacterisation:
    | 'FEEDLOT_CORN'
    | 'FEEDLOT_OTHER_GRAIN'
    | 'HIGH_FORAGE_HIGH_QUALITY'
    | 'HIGH_FORAGE_LOW_QUALITY'
    | 'MIXED_RATIONS';
  compoundFeeds?: Array<CompundFeedInfoDBModel>;
  singleIngredients?: Array<SingleIngredientUseDBModel>;
  freshGrass: Array<FreshGrassDBModel>;
}

export interface MothersMilkDBModel {
  amount: Maybe<number>;
  crudeProteinContent: number;
  digestibility: number;
  dryMatterContent: number;
}

export interface FreshGrassDBModel extends MothersMilkDBModel {
  type: string;
  grossEnergyContent?: number;
  urinaryEnergy?: number;
  ashContent?: number;
}
export interface FeedingProgramCalfDBModel extends FeedingProgramDBModel {
  averageMilkFat?: Maybe<number>;
  mothersMilk?: Maybe<MothersMilkDBModel>;
}

export interface BeefBeddingDBModel {
  amount: number;
  type: string;
}

export interface EmissionMitigationDBModel {
  change: number;
  emissionType: string;
}

export interface ManureManagementSystemDBModel {
  id: string;
  share: number;
  mmsType: string;
  mmsViewModelType: string;
  storagePeriod?: string;
  manurePhase?: string;
}

export interface TimeSpentDBModel {
  timeInFeedlot: number;
  timeInHousing: number;
  timeInHousingTied?: Maybe<number>;
  timeOnLargeGrazingAreas: number;
  timeOnSmallGrazingAreas: number;
}

export interface BreedingOutputDBModel {
  animalsOutgoing: number;
  averageWeight: number;
  calculateLca?: Maybe<boolean>;
  mortalities: number;
  priceSoldAnimals?: Maybe<number>;
  soldExternally: number;
  weightAtMortality: number;
}

export interface GrowingOutputDBModel extends BreedingOutputDBModel {
  averageAge: number;
}

export interface StockChangeDBModel {
  averageWeightCowsStart: number;
  cowsPresentAtEnd: number;
  cowsPresentAtStart: number;
}

export interface EnergyCarrierDBModel {
  amount: number;
  type: string;
}

export interface MilkProductionDBModel {
  amount?: Maybe<number>;
  fatContent?: Maybe<number>;
  proteinContent?: Maybe<number>;
}

export interface BeefCommonStageDataDBModel {
  animalProductivity?: AnimalProductivityDB;
  beddingCows?: Array<BeefBeddingDBModel>;
  emissionMitigationsCows?: Array<EmissionMitigationDBModel>;
  energyCarriers?: Array<EnergyCarrierDBModel>;
  feedingProgramCows: FeedingProgramDBModel;
  mmsCows: Array<ManureManagementSystemDBModel>;
  timeSpentCows: TimeSpentDBModel;
  water?: Array<Water>;
}
export interface BeefBreedingStageDataDBModel
  extends BeefCommonStageDataDBModel {
  beddingCalves?: Array<BeefBeddingDBModel>;
  calvesBirthWeight?: number;
  emissionMitigationsCalves?: Array<EmissionMitigationDBModel>;
  feedingProgramCalves: FeedingProgramCalfDBModel;
  input: CommonStageInputModel;
  lengthStageCalves: number;
  milkProduction?: MilkProductionDBModel;
  mmsCalves: Array<ManureManagementSystemDBModel>;
  outputCalves: BreedingOutputDBModel;
  outputCows: BreedingOutputDBModel;
  stockChange: StockChangeDBModel;
  timeSpentCalves: TimeSpentDBModel;
}

export interface GrowingStageInputModel extends CommonStageInputModel {
  averageAge: number;
}

export interface BeefGrowingStageDataDBModel
  extends BeefCommonStageDataDBModel {
  input: GrowingStageInputModel;
  outputCows: GrowingOutputDBModel;
  isStageRepeated: string;
  numberOfRepetitions: number;
  growingPurpose: 'BEEF_PRODUCTION' | 'REPLACEMENT';
}

export interface BeefStageDBModel extends StageDBModel {
  startDate: string;
  endDate?: Maybe<string>;
  type: StageType;
  stageData:
    | BeefBreedingStageDataDBModel
    | BeefGrowingStageDataDBModel
    | ProcessingStageDataDBModel;
}
export interface BeefBaselineDBModel extends BaselineDBModel {
  databaseFoundation: CompoundFeedDatabase;
  resourceUse?: Maybe<ResourceUse>;
  stages: Array<BeefStageDBModel | ProcessingStageDBModel>;
}
