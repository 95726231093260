import { cloneDeep } from 'lodash';
import { Box, Tab, Tabs } from '@material-ui/core';
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { BaselineDialogProps } from '../../common';
import {
  DairyV2Baseline,
  DairyV2DairyCowStageData,
  DairyV2Emission,
  DairyV2ContinuousGrowingStageData,
  TargetAnimal,
} from '../../../../models/Baseline/DairyV2Baseline';
import DairyV2EmissionInputsComponent from './DairyV2EmissionInputsComponent';
import { beefTabsStyles } from '../../../../../../_metronic/layout';
import { StageType } from '../../../../../../graphql/types';

interface TabPanelProps {
  children: ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`beef-breeding-emissions-${index}`}
      aria-labelledby={`beef-breeding-emissions-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const DairyV2EmissionsDialog: FC<
  BaselineDialogProps & { stageType: StageType }
> = ({ formType, itemIndex, handleCancel, formVisible = false, stageType }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const intl = useIntl();
  const formContext = useFormContext();
  const currResetValue = useRef<DairyV2Emission>();
  const classes = beefTabsStyles();

  const fieldItemPrefix = `stages.${itemIndex}.stageData.emissions`;
  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE',
  });

  useEffect(() => {
    if (formVisible) {
      currResetValue.current =
        (cloneDeep(formContext.getValues(fieldItemPrefix)) as DairyV2Emission) ||
        {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = useCallback(() => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() } as DairyV2Baseline;
      const dairyV2Stage = resetObject.stages[itemIndex].stageData as DairyV2ContinuousGrowingStageData | DairyV2DairyCowStageData;
      if (dairyV2Stage?.emissions) {
        dairyV2Stage.emissions = {
          ...currResetValue.current,
        };
        formContext.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) handleCancel('reset');
  }, [formContext, handleCancel, itemIndex]);

  const handleTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <DialogContainer
      variant="wide"
      iconCode="maps-travel/globe-04"
      formVisible={formVisible}
      formTitle={formTitle}
      handleClose={handleResetClick}
      introText={intl.formatMessage({ id: 'EMISSIONS.INTRO' })}
    >
      {stageType === StageType.DairyCow ? (
        <>
          <Box className={classes.tabsBox}>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="beef-breeding-emissions"
              className={classes.tabs}
            >
              <Tab
                label={intl.formatMessage({
                  id: `SUSTELL.STAGE.BEEF.INPUT.COWS`,
                })}
              />
              <Tab
                label={intl.formatMessage({
                  id: `SUSTELL.STAGE.BEEF.INPUT.CALVES`,
                })}
              />
            </Tabs>
          </Box>
          <TabPanel value={currentTab} index={0}>
            <DairyV2EmissionInputsComponent
              formType={formType}
              fieldItemPrefix={`${fieldItemPrefix}.${TargetAnimal.COWS}`}
              handleReset={handleResetClick}
              handleCancel={handleCancel}
              stageIndex={itemIndex}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <DairyV2EmissionInputsComponent
              formType={formType}
              fieldItemPrefix={`${fieldItemPrefix}.${TargetAnimal.CALVES}`}
              handleReset={handleResetClick}
              handleCancel={handleCancel}
              stageIndex={itemIndex}
            />
          </TabPanel>
        </>
      ) : (
        <DairyV2EmissionInputsComponent
          formType={formType}
          fieldItemPrefix={fieldItemPrefix}
          handleReset={handleResetClick}
          handleCancel={handleCancel}
          stageIndex={itemIndex}
        />
      )}
    </DialogContainer>
  );
};

export default DairyV2EmissionsDialog;
