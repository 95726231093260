/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  DsmButton,
  DsmGrid,
  DsmIcon,
  DsmModal,
} from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import {
  AnimalType,
  CompoundFeedDatabase,
  Maybe,
} from '../../../../../../graphql/types';
import SustellRationFeeds from '../../../../../modules/Farms/Baseline/SustellRationFeeds_v2';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { BaselineFeed } from '../../../../models/Baseline';
import {
  SalmonBaseline,
  SalmonFeed,
} from '../../../../models/Baseline/SalmonBaseline';
import { unitLong } from '../../../../utils/unit-utils';
import { getDatabaseOptions } from '../../../CompoundFeedWizard/utils';
import { DsmButtonV2, useButtonStyles } from '../../../helpers/DsmButton';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType } from '../../common';
import { CompoundFeedNameDatabase } from '../types';
import SustellDynamicInput from './SustellDynamicInput';
import getTranslatedEnumOrDefaultLookupValues from '../../../../utils/translation-utils';
import lookupValues from '../../../../helpers/lookupValues';
import { MasterDataEnumType } from '../../Intervention/CommonDataParts/InterventionTypes';
import { Tooltip } from '@material-ui/core';

interface SFeedsFormDialogProps extends BaselineDialogProps {
  compoundFeeds: Maybe<Array<CompoundFeedNameDatabase>>;
  // eslint-disable-next-line react/require-default-props
  animalType?: AnimalType;
  masterDataEnums: MasterDataEnumType | null;
  baseline: SalmonBaseline;
}

interface FeedValues {
  [key: string]: string;
}

const SFeedsFormDialog: FC<SFeedsFormDialogProps> = ({
  formVisible,
  itemIndex = 0,
  handleCancel,
  formType = 'add',
  compoundFeeds,
  masterDataEnums,
  baseline,
  animalType = AnimalType.SalmonV2,
}) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as any;
  const buttonClasses = useButtonStyles();
  const currResetValue = useRef('');
  const feedItem = { feedType: '', kgPerAnimal: '' };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();

  const calculateInitialDatabaseValue = () => {
    let initValue = getValues(`info.databaseFoundation`);
    if (!initValue) initValue = userProfile.getUserDatabasePrefs().databaseType;
    return initValue;
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const precision = userProfile.getUnitBarnOutputMassPrecision() as number;
  const fieldItemPrefix = `stages.${itemIndex}.stageData.feed`;

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getValues, setValue, clearErrors, reset, trigger } =
    useFormContext<SalmonBaseline>();

  const dynamicItem = { type: '', amount: '' };

  const baselineFeeds = baseline?.stages?.[itemIndex]?.stageData?.feed;

  const baselineCompoundFeeds = getValues(
    `stages.${itemIndex}.stageData.feed.compoundFeeds`
  );

  const baselineDatabaseFoundation: string =
    calculateInitialDatabaseValue() as string;
  const defaultDatabaseFoundation = baselineDatabaseFoundation;

  const getCompoundFeedsList = (databaseOption: string) =>
    compoundFeeds?.filter((feed) => feed.databaseFoundation === databaseOption);

  const prepopulateEmptyField = () =>
    formType === FormType.Add ? [{ ...dynamicItem }] : [];

  const [antibioticsUse, setAntibioticsUse] = useState(
    baselineFeeds?.antibioticsUse && baselineFeeds?.antibioticsUse?.length > 0
      ? baselineFeeds.antibioticsUse
      : [prepopulateEmptyField()]
  );

  const [
    openDatabaseSelectionWarningDialog,
    setOpenDatabaseSelectionWarningDialog,
  ] = useState({
    open: false,
    defaultValue: baselineDatabaseFoundation,
    selectedValue: '',
  });

  const closeDialog = () => {
    setOpenDatabaseSelectionWarningDialog({
      open: false,
      selectedValue: '',
      defaultValue: baselineDatabaseFoundation,
    });
  };

  const handleDiscard = () => {
    getCompoundFeedsList(openDatabaseSelectionWarningDialog.defaultValue);
    setValue(
      `info.databaseFoundation`,
      openDatabaseSelectionWarningDialog.defaultValue
    );
    const stages = getValues('stages');
    stages.forEach((stage, i) => {
      const updatedFeeds = stage?.stageData?.feed?.compoundFeeds?.map(
        (item) => ({
          feedType: item.feedType,
          kgPerAnimal: item.kgPerAnimal,
        })
      );
      setValue(`stages.${i}.stageData.feed.compoundFeeds`, updatedFeeds);
    });
    closeDialog();
  };

  const handleConfirm = () => {
    closeDialog();
    getCompoundFeedsList(baselineDatabaseFoundation);
    const stages = getValues('stages');
    stages.forEach((stage, i) => {
      if (i === itemIndex) {
        const updatedFeeds = stage?.stageData?.feed?.compoundFeeds?.map(() => ({
          feedType: '',
          kgPerAnimal: 0,
        }));
        setValue(`stages.${i}.stageData.feed.compoundFeeds`, updatedFeeds);
      }
    });
  };

  const [compoundFeedData, setCompoundFeedData] = useState<BaselineFeed[]>(
    baselineCompoundFeeds &&
      baselineCompoundFeeds !== null &&
      (baselineCompoundFeeds as BaselineFeed[])?.length > 0
      ? (baselineCompoundFeeds as BaselineFeed[])
      : [{ ...feedItem }]
  );

  const checkSum = (): void => {
    // Wrapped them in a promise.all to fix linter issue an handle the promise.
    Promise.all([
      trigger(`${fieldItemPrefix}.nutrientComposition.crudeProtein`),
      trigger(`${fieldItemPrefix}.nutrientComposition.crudeLipid`),
      trigger(`${fieldItemPrefix}.nutrientComposition.crudeFibre`),
      trigger(`${fieldItemPrefix}.nutrientComposition.starch`),
      trigger(`${fieldItemPrefix}.nutrientComposition.sugar`),
      trigger(`${fieldItemPrefix}.nutrientComposition.phosphorous`),
    ])
      .then()
      .catch((err: Error) =>
        console.log(`Error triggering validation ${err.message}`)
      );
  };

  useEffect(() => {
    setCompoundFeedData((baselineCompoundFeeds || []) as BaselineFeed[]);
  }, [baselineCompoundFeeds]);

  useEffect(() => {
    setAntibioticsUse(
      baseline?.stages?.[itemIndex]?.stageData?.feed?.antibioticsUse ||
        prepopulateEmptyField()
    );
  }, [baseline]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...getValues() } as SalmonBaseline;
      if (resetObject.stages[itemIndex]?.stageData?.feed) {
        const val = JSON.parse(currResetValue.current) as {
          feed: SalmonFeed;
          databaseFoundation: string;
        };
        resetObject.stages[itemIndex].stageData.feed = val.feed;
        resetObject.info.databaseFoundation =
          val.databaseFoundation as CompoundFeedDatabase;
        reset(resetObject, {
          errors: true,
        });
        // reset compound feeds and single ingredients data
        // to previously valid (confirmed)
        setCompoundFeedData(val?.feed.compoundFeeds || []);
      }
    }
    if (handleCancel) handleCancel('reset');
  };

  const updateTotalFeedIntake = useCallback(() => {
    const currFormValues = getValues();
    const compoundFeeds1 =
      currFormValues.stages?.[itemIndex]?.stageData?.feed?.compoundFeeds;

    let totalIntake = 0;
    if (compoundFeeds1) {
      totalIntake += compoundFeeds1.reduce(
        (acc, item) =>
          acc +
          (item.feedType && !Number.isNaN(item.kgPerAnimal)
            ? Number(item.kgPerAnimal)
            : 0),
        0
      );
    }

    const totalIntakeValue =
      totalIntake !== 0 ? totalIntake.toFixed(precision) : '';
    setValue(`${fieldItemPrefix}.totalFeedIntake`, totalIntakeValue);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemIndex, fieldItemPrefix, setValue, getValues, precision, formVisible]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      unitLong(userUOM.unitBarnOutputMass)
    : 'kg';
  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE',
  });

  const handleChangeDatabaseFoundation = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }

    const option: string = event.target.value as string;
    // show dialog when changing database, and if there is previously selected DB
    if (baselineDatabaseFoundation && baselineDatabaseFoundation !== option) {
      setOpenDatabaseSelectionWarningDialog({
        open: true,
        selectedValue: option,
        defaultValue: baselineDatabaseFoundation,
      });
    }
  };

  const totalFeedIntake = getValues(`${fieldItemPrefix}.totalFeedIntake`);

  if (typeof totalFeedIntake === 'number') {
    const totalIntakeValue =
      totalFeedIntake !== 0 ? totalFeedIntake.toFixed(precision) : '';
    setValue(`${fieldItemPrefix}.totalFeedIntake`, totalIntakeValue);
  }

  const resetToDefaultValues = () => {
    setValue(
      `${fieldItemPrefix}.nutrientComposition.feedSpill`,
      lookupValues.marineFishFeedDefaultValues.feedSpill
    );
    setValue(
      `${fieldItemPrefix}.nutrientComposition.solubleNitrogen`,
      lookupValues.marineFishFeedDefaultValues.solubleNitrogen
    );
    setValue(
      `${fieldItemPrefix}.nutrientComposition.solublePhosphorous`,
      lookupValues.marineFishFeedDefaultValues.solublePhosphorous
    );
    setValue(
      `${fieldItemPrefix}.nutrientComposition.solubleCarbon`,
      lookupValues.marineFishFeedDefaultValues.solubleCarbon
    );
    setValue(
      `${fieldItemPrefix}.nutrientComposition.digestibleNitrogen`,
      lookupValues.marineFishFeedDefaultValues.digestibleNitrogen
    );
    setValue(
      `${fieldItemPrefix}.nutrientComposition.digestibleCarbon`,
      lookupValues.marineFishFeedDefaultValues.digestibleCarbon
    );
    setValue(
      `${fieldItemPrefix}.nutrientComposition.digestiblePhosphorous`,
      lookupValues.marineFishFeedDefaultValues.digestiblePhosphorous
    );
    clearErrors(`${fieldItemPrefix}.nutrientComposition.feedSpill`);
    clearErrors(`${fieldItemPrefix}.nutrientComposition.solubleNitrogen`);
    clearErrors(`${fieldItemPrefix}.nutrientComposition.solublePhosphorous`);
    clearErrors(`${fieldItemPrefix}.nutrientComposition.solubleCarbon`);
    clearErrors(`${fieldItemPrefix}.nutrientComposition.digestibleNitrogen`);
    clearErrors(`${fieldItemPrefix}.nutrientComposition.digestibleCarbon`);
    clearErrors(`${fieldItemPrefix}.nutrientComposition.digestiblePhosphorous`);
  };

  useEffect(() => {
    if (formVisible) {
      // to faster deep copy all potential arrays and subobjects
      // need to reset feed and databaseFoundation
      currResetValue.current = JSON.stringify({
        // feed: serializedData,
        feed: getValues(fieldItemPrefix),
        databaseFoundation: baselineDatabaseFoundation,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="shapes/cube-02"
      formTitle={formTitle}
      variant="demiWide"
    >
      {openDatabaseSelectionWarningDialog.open && (
        <DsmModal
          open={openDatabaseSelectionWarningDialog.open}
          icon="general/info-square"
          header={intl.formatMessage({
            id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELETION_WARNING_HEADER',
          })}
          onDsmClosed={() => closeDialog()}
        >
          <p slot="content">
            {intl.formatMessage({
              id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELETION_WARNING_BODY',
            })}
          </p>
          <>
            <DsmButtonV2
              slot="actions"
              onClick={handleDiscard}
              className={buttonClasses.secondaryButton}
            >
              {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
            </DsmButtonV2>
            <DsmButtonV2
              variant="secondary"
              slot="actions"
              onClick={handleConfirm}
            >
              {intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
            </DsmButtonV2>
          </>
        </DsmModal>
      )}

      <DsmGrid
        className={classes.dsmGridOneColumnNoRowgap}
        style={{ width: '40%', marginBottom: 'var(--dsm-spacing-px-4)' }}
      >
        <ReactHookDsmSelect
          name="info.databaseFoundation"
          label={`${intl.formatMessage({
            id: 'BASELINE.FORM.SALMON.DATABASE_SELECTION',
          })}`}
          disabled={formType === FormType.View}
          options={getDatabaseOptions()}
          defaultValue={defaultDatabaseFoundation}
          changeHandler={(
            e: React.ChangeEvent<{
              name?: string | undefined;
              value: unknown;
            }>
          ) => handleChangeDatabaseFoundation(e)}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED.DATABASE_SELECTION.TOOLTIP',
          })}
        />
      </DsmGrid>

      <DsmGrid
        className={classes.dsmGridOneColumnNoRowgap}
        style={{ width: '70%', marginBottom: 'var(--dsm-spacing-px-6)' }}
      >
        <SustellRationFeeds
          key="compoundFeeds"
          label={intl.formatMessage({ id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED' })}
          availableFeedItems={getCompoundFeedsList(baselineDatabaseFoundation)}
          fieldItemPrefix={fieldItemPrefix}
          subFieldName="compoundFeeds"
          updateTotalFeedIntake={updateTotalFeedIntake}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.BARN.RATION.COMPOUND_FEEDS.TOOLTIP',
          })}
          formState={formType}
          compoundFeed
          feedData={compoundFeedData}
          setFeedData={setCompoundFeedData}
          animalType={animalType}
        />
      </DsmGrid>

      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <DsmGrid className={classes.dsmGridOneColumnNoRowgap}>
          <ReactHookDsmInput
            name={`${fieldItemPrefix}.totalFeedIntake`}
            label={`${intl.formatMessage({
              id: 'BASELINE.FORM.BARN.RATION.TOTAL_INTAKE',
            })}`}
            margin="none"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.BARN.RATION.TOTAL_INTAKE.TOOLTIP',
            })}
            adornment={barnOutputMassUnit}
            disabled
            defaultValue={getValues(`${fieldItemPrefix}.totalFeedIntake`)}
          />

          <ReactHookDsmInput
            key={`${fieldItemPrefix}.nutrientComposition.crudeProtein`}
            name={`${fieldItemPrefix}.nutrientComposition.crudeProtein`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.CRUDE_PROTEIN',
            })}
            required
            margin="none"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.CRUDE_PROTEIN.TOOLTIP',
            })}
            adornment="%"
            disabled={formType === 'view'}
            type="number"
            defaultValue={getValues(
              `${fieldItemPrefix}.nutrientComposition.crudeProtein`
            )}
            focusLostHandler={() => checkSum()}
          />

          <ReactHookDsmInput
            key={`${fieldItemPrefix}.nutrientComposition.crudeLipid`}
            name={`${fieldItemPrefix}.nutrientComposition.crudeLipid`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.CRUDE_LIPID',
            })}
            required
            margin="none"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.CRUDE_LIPID.TOOLTIP',
            })}
            adornment="%"
            disabled={formType === 'view'}
            type="number"
            defaultValue={getValues(
              `${fieldItemPrefix}.nutrientComposition.crudeLipid`
            )}
            focusLostHandler={() => checkSum()}
          />

          <ReactHookDsmInput
            key={`${fieldItemPrefix}.nutrientComposition.crudeFibre`}
            name={`${fieldItemPrefix}.nutrientComposition.crudeFibre`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.CRUDE_FIBRE',
            })}
            required
            margin="none"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.CRUDE_FIBRE.TOOLTIP',
            })}
            adornment="%"
            disabled={formType === 'view'}
            type="number"
            defaultValue={getValues(
              `${fieldItemPrefix}.nutrientComposition.crudeFibre`
            )}
            focusLostHandler={() => checkSum()}
          />

          <ReactHookDsmInput
            key={`${fieldItemPrefix}.nutrientComposition.starch`}
            name={`${fieldItemPrefix}.nutrientComposition.starch`}
            label={intl.formatMessage({ id: 'BASELINE.FORM.SALMON.STARCH' })}
            required
            margin="none"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.STARCH.TOOLTIP',
            })}
            adornment="%"
            disabled={formType === 'view'}
            type="number"
            defaultValue={getValues(
              `${fieldItemPrefix}.nutrientComposition.starch`
            )}
            focusLostHandler={() => checkSum()}
          />

          <ReactHookDsmInput
            key={`${fieldItemPrefix}.nutrientComposition.sugar`}
            name={`${fieldItemPrefix}.nutrientComposition.sugar`}
            label={intl.formatMessage({ id: 'BASELINE.FORM.SALMON.SUGAR' })}
            required
            margin="none"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.SUGAR.TOOLTIP',
            })}
            adornment="%"
            disabled={formType === 'view'}
            type="number"
            defaultValue={getValues(
              `${fieldItemPrefix}.nutrientComposition.sugar`
            )}
            focusLostHandler={() => checkSum()}
          />

          <ReactHookDsmInput
            key={`${fieldItemPrefix}.nutrientComposition.phosphorous`}
            name={`${fieldItemPrefix}.nutrientComposition.phosphorous`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.PHOSPHOROUS',
            })}
            required
            margin="none"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.PHOSPHOROUS.TOOLTIP',
            })}
            adornment="%"
            disabled={formType === 'view'}
            type="number"
            defaultValue={getValues(
              `${fieldItemPrefix}.nutrientComposition.phosphorous`
            )}
            focusLostHandler={() => checkSum()}
          />

          <ReactHookDsmInput
            key={`${fieldItemPrefix}.nutrientComposition.dryMatter`}
            name={`${fieldItemPrefix}.nutrientComposition.dryMatter`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.DRY_MATTER',
            })}
            required
            margin="none"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.DRY_MATTER.TOOLTIP',
            })}
            adornment="%"
            disabled={formType === 'view'}
            type="number"
            defaultValue={getValues(
              `${fieldItemPrefix}.nutrientComposition.dryMatter`
            )}
          />
        </DsmGrid>

        <DsmGrid className={classes.dsmGridOneColumnNoRowgap}>
          <ReactHookDsmInput
            key={`${fieldItemPrefix}.nutrientComposition.feedSpill`}
            name={`${fieldItemPrefix}.nutrientComposition.feedSpill`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.FEED_SPILL',
            })}
            margin="none"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.FEED_SPILL.TOOLTIP',
            })}
            adornment="%"
            disabled={formType === 'view'}
            type="number"
            defaultValue={getValues(
              `${fieldItemPrefix}.nutrientComposition.feedSpill`
            )}
          />

          <ReactHookDsmInput
            key={`${fieldItemPrefix}.nutrientComposition.solubleNitrogen`}
            name={`${fieldItemPrefix}.nutrientComposition.solubleNitrogen`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.SOLUBLE_NITROGEN',
            })}
            margin="none"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.SOLUBLE_NITROGEN.TOOLTIP',
            })}
            adornment="%"
            disabled={formType === 'view'}
            type="number"
            defaultValue={getValues(
              `${fieldItemPrefix}.nutrientComposition.solubleNitrogen`
            )}
          />

          <ReactHookDsmInput
            key={`${fieldItemPrefix}.nutrientComposition.solublePhosphorous`}
            name={`${fieldItemPrefix}.nutrientComposition.solublePhosphorous`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.SOLUBLE_PHOSPHORUS',
            })}
            margin="none"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.SOLUBLE_PHOSPHORUS.TOOLTIP',
            })}
            adornment="%"
            disabled={formType === 'view'}
            type="number"
            defaultValue={getValues(
              `${fieldItemPrefix}.nutrientComposition.solublePhosphorous`
            )}
          />

          <ReactHookDsmInput
            key={`${fieldItemPrefix}.nutrientComposition.solubleCarbon`}
            name={`${fieldItemPrefix}.nutrientComposition.solubleCarbon`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.SOLUBLE_CARBON',
            })}
            margin="none"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.SOLUBLE_CARBON.TOOLTIP',
            })}
            adornment="%"
            disabled={formType === 'view'}
            type="number"
            defaultValue={getValues(
              `${fieldItemPrefix}.nutrientComposition.solubleCarbon`
            )}
          />

          <ReactHookDsmInput
            key={`${fieldItemPrefix}.nutrientComposition.digestibleNitrogen`}
            name={`${fieldItemPrefix}.nutrientComposition.digestibleNitrogen`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.DIGESTIBLE_NITROGEN',
            })}
            margin="none"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.DIGESTIBLE_NITROGEN.TOOLTIP',
            })}
            adornment="%"
            disabled={formType === 'view'}
            type="number"
            defaultValue={getValues(
              `${fieldItemPrefix}.nutrientComposition.digestibleNitrogen`
            )}
          />

          <ReactHookDsmInput
            key={`${fieldItemPrefix}.nutrientComposition.digestibleCarbon`}
            name={`${fieldItemPrefix}.nutrientComposition.digestibleCarbon`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.DIGESTIBLE_CARBON',
            })}
            margin="none"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.DIGESTIBLE_CARBON.TOOLTIP',
            })}
            adornment="%"
            disabled={formType === 'view'}
            type="number"
            defaultValue={getValues(
              `${fieldItemPrefix}.nutrientComposition.digestibleCarbon`
            )}
          />

          <ReactHookDsmInput
            key={`${fieldItemPrefix}.nutrientComposition.digestiblePhosphorous`}
            name={`${fieldItemPrefix}.nutrientComposition.digestiblePhosphorous`}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.DIGESTIBLE_PHOSPHORUS',
            })}
            margin="none"
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.SALMON.DIGESTIBLE_PHOSPHORUS.TOOLTIP',
            })}
            adornment="%"
            disabled={formType === 'view'}
            type="number"
            defaultValue={getValues(
              `${fieldItemPrefix}.nutrientComposition.digestiblePhosphorous`
            )}
          />
          <div className={classes.resetButtonContainer}>
            <DsmButton
              style={{ marginTop: '15px' }}
              widthFull
              variant="text"
              disabled={formType === FormType.View}
              onClick={() => resetToDefaultValues()}
              className="mt-3"
            >
              <DsmIcon slot="before" name="arrows/refresh-cw-01" />
              <u>
                {intl.formatMessage({
                  id: 'BASELINE.FORM.SALMON.ANTIBIOTICS_USE.RESET_DEFAULT_VALUES',
                })}
              </u>
            </DsmButton>
            <Tooltip
              title={intl.formatMessage({
                id: 'SUSTELL.RESET_DEFAULT.TOOLTIP',
              })}
              placement="left"
              className='ml-3'
              style={{transform: 'translateY(85%)'}}
            >
              <DsmIcon
                name="general/help-circle"
                className={classes.inlineTooltipClass}
                title=""
              />
            </Tooltip>
          </div>
        </DsmGrid>
      </DsmGrid>

      <SustellDynamicInput
        key={`${fieldItemPrefix}.antibioticsUse`}
        label={intl.formatMessage({
          id: 'BASELINE.FORM.SALMON.ANTIBIOTICS_USE',
        })}
        availableOptions={getTranslatedEnumOrDefaultLookupValues(
          masterDataEnums?.AntibioticType || [],
          lookupValues.antibiotics,
          intl,
          'MASTERDATA.ENUM.ANITIBIOTIC_USE'
        )}
        fieldItemPrefix={fieldItemPrefix}
        subFieldName="antibioticsUse"
        tooltip={intl.formatMessage({
          id: 'BASELINE.FORM.SALMON.ANTIBIOTICS_USE.TOOLTIP',
        })}
        formState={formType}
        data={antibioticsUse}
        setData={setAntibioticsUse}
        disableAddButtonWhenAllOptionSelected
      />

      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {
          await trigger(`stages.${itemIndex}.stageData.feed`);
          handleCancel('confirm');
        }}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default SFeedsFormDialog;
