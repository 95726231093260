import {
  CompoundFeedV2,
  NutritionalAnalysisType,
} from '../../../../../graphql/types';
import { floatValueInUserUnits } from '../../../../modules/Farms/Baseline/mapper/inCommonMapperSustellV2';
import lookupValues from '../../../helpers/lookupValues';
import { getUIDatabaseFoundation } from '../../../utils/compound-feed-utils';
import { defaultUnitsV2 as defaultMetric } from '../../../utils/unit-utils';
import { CompoundFeedFormExtended, IngredientsUsesExtended } from '../types';
import {
  ADDON_INGREDIENT_SEPARATOR,
  CUSTOM_INGREDIENT_ID_SEPARATOR,
} from '../utils';
import {
  convertDistanceUnitsV2,
  convertIngredientInputMassV2,
  getConvertedOnSiteAcitivityInputV2,
} from './UnitsConverterV2';

const getPhosphorusContentValue = (
  compoundFeedV2: CompoundFeedV2
): number | undefined => {
  let phosphorusContentValue: number | undefined;
  compoundFeedV2.nutritionalAnalysisData?.forEach((n) => {
    if(n?.type === NutritionalAnalysisType.PhosphorusContent) {
      phosphorusContentValue = n?.value;
    }
    // PContent got deprecated, the following maps previously created values by users only if no PhosphorusContent is created
    if(!phosphorusContentValue && n?.type === NutritionalAnalysisType.PContent) {
        phosphorusContentValue = n?.value;
    }
  });
  return phosphorusContentValue
}

const mapCompoundFeedDataV2In = (
  compoundFeedV2: CompoundFeedV2,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userUOM: any
  // originUnit=null: any
): CompoundFeedFormExtended | undefined => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access
  const userEnergyUOM = `${userUOM.unitNutritionalGrossEnergy}/${userUOM.unitIngredientQuantityInputMass}`;
  const defaultEnergyUOM = `${defaultMetric.unitNutritionalGrossEnergy}/${defaultMetric.unitIngredientQuantityInputMass}`;
  const nutritionalAnalysisData = compoundFeedV2.nutritionalAnalysisData;
  const targetSpecies = compoundFeedV2.targetSpecies[0];

  const feedFormFields: CompoundFeedFormExtended = {
    feedmill_location: {
      iso_code: compoundFeedV2.feedmillLocation,
    },
    name: compoundFeedV2.name,
    target_species: compoundFeedV2.targetSpecies,
    year: {
      value: compoundFeedV2.year,
    },
    created_date: compoundFeedV2.createdAt,
    is_reference: false,
    databaseFoundation: getUIDatabaseFoundation(
      compoundFeedV2.databaseFoundation
    ),
    id: compoundFeedV2.id,
    modified_date: compoundFeedV2.updatedAt,
    on_site_activities_input: getConvertedOnSiteAcitivityInputV2(
      compoundFeedV2.onSiteActivities,
      userUOM
    ),
    outbound_transport_input: convertDistanceUnitsV2(
      compoundFeedV2.outboundTransport,
      userUOM,
      true
    ),
    nutritional_analysis_data: {
      // This is in %, no need for conversion
      crudeProteinContent: nutritionalAnalysisData?.find(
        (n) => n?.type === NutritionalAnalysisType.CrudeProteinContent
      )?.value,
      dryMatterContent: nutritionalAnalysisData?.find(
        (n) => n?.type === NutritionalAnalysisType.DryMatterContent
      )?.value,
      ashContent: nutritionalAnalysisData?.find(
        (n) => n?.type === NutritionalAnalysisType.AshContent
      )?.value,
      phosphorusContent: getPhosphorusContentValue(compoundFeedV2),
      pContent: nutritionalAnalysisData?.find(
        (n) => n?.type === NutritionalAnalysisType.PContent
      )?.value,
      naturalDetergentFiber: nutritionalAnalysisData?.find(
        (n) => n?.type === NutritionalAnalysisType.NaturalDetergentFiber
      )?.value,
      containsMilk:
        nutritionalAnalysisData?.find(
          (n) => n?.type === NutritionalAnalysisType.ContainsMilk
        )?.value === 1
          ? 'Yes'
          : 'No',
      milk: nutritionalAnalysisData?.find(
        (n) => n?.type === NutritionalAnalysisType.Milk
      )?.value,
      // conversion needed
      digestibleEnergyContent:
        (targetSpecies !== lookupValues.animalTypeFeedAnimalSystem.BEEF 
          && targetSpecies !== lookupValues.animalTypeFeedAnimalSystem.DAIRY_V2)
          ? (floatValueInUserUnits(
              nutritionalAnalysisData?.find(
                (n) =>
                  n?.type === NutritionalAnalysisType.DigestibleEnergyContent
              )?.value,
              userEnergyUOM,
              defaultEnergyUOM
            ) as number)
          : nutritionalAnalysisData?.find(
              (n) => n?.type === NutritionalAnalysisType.DigestibleEnergyContent
            )?.value,
      grossEnergyContent: floatValueInUserUnits(
        nutritionalAnalysisData?.find(
          (n) => n?.type === NutritionalAnalysisType.GrossEnergyContent
        )?.value,
        userEnergyUOM,
        defaultEnergyUOM
      ) as number,
      urinaryEnergyContent:
        (targetSpecies !== lookupValues.animalTypeFeedAnimalSystem.BEEF 
          && targetSpecies !== lookupValues.animalTypeFeedAnimalSystem.DAIRY_V2)
          ? (floatValueInUserUnits(
              nutritionalAnalysisData?.find(
                (n) => n?.type === NutritionalAnalysisType.UrinaryEnergyContent
              )?.value,
              userEnergyUOM,
              defaultEnergyUOM
            ) as number)
          : nutritionalAnalysisData?.find(
              (n) => n?.type === NutritionalAnalysisType.UrinaryEnergyContent
            )?.value,
    },
    ingredient_uses: compoundFeedV2.ingredientUses?.map((ing) => {
      let { name } = ing.ingredient;
      if (ing.customIngredient) {
        name = `${ing.ingredient.name}${CUSTOM_INGREDIENT_ID_SEPARATOR}${
          ing.customName || 'unknown'
        }`;
      } else if (
        ing.ingredient.addonType &&
        ing.ingredient.addonType !== null
      ) {
        name = `${ing.ingredient.name}${ADDON_INGREDIENT_SEPARATOR}${
          ing.ingredient.addonType || 'unknown'
        }`;
      }
      const retVal: IngredientsUsesExtended = {
        ingredient_id: ing.ingredient.origin,
        id: ing.ingredient.name,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        quantity: convertIngredientInputMassV2(ing.quantity, userUOM),
        addonType: ing.ingredient.addonType || undefined,
        origin:
          ing.isProxy && ing.originalOrigin
            ? ing.originalOrigin
            : ing.ingredient.origin,
        proxyOrigin: !!ing.isProxy,
        customIngredient: !!ing.customIngredient,
        name,
        requested_ingredient: {
          custom_name: ing.customName,
          ingredient_name: {
            id:
              ing.isProxy && ing.originalOrigin
                ? ing.originalOrigin
                : ing.ingredient.name,
            name: ing.ingredient.name,
          },
          origin: ing.originalOrigin
            ? ing.originalOrigin
            : ing.ingredient.origin,
        },
        ingredient: {
          ingredient_name: {
            id: ing.ingredient.name,
            name: ing.ingredient.name,
          },
          origin: ing.ingredient.origin,
        },
        inbound_transport_input: convertDistanceUnitsV2(
          ing.inboundTransport,
          userUOM
        ),
      };

      if (ing.isProxy && ing.ingredient.origin !== ing.originalOrigin) {
        retVal.proxyOriginCode = ing.ingredient.origin;
      }

      return retVal;
    }),
  };

  return feedFormFields;
};

export default mapCompoundFeedDataV2In;
