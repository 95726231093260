import { DeepMap, FieldError } from 'react-hook-form';
import {
  CompoundFeed,
  CompoundFeedIngredientName,
  Maybe,
  Origin,
  RequestedIngredient,
  Ingredient,
  CompoundFeedAddonsV2,
  TypeValueFloatInput,
} from '../../../../graphql/types';
import { CFDatabaseFoundation } from '../../models/CompoundFeed';

export enum FormType {
  View = 'view',
  Edit = 'edit',
  New = 'new',
}

export interface IngredientOriginIso {
  code: string;
}

export interface IngredientOrigin {
  iso_code: IngredientOriginIso;
  name?: string;
}

export interface IngredientOriginCombinationCustom {
  id: string;
  name: string;
  origin?: IngredientOrigin;
}

export const enum TransportType {
  Truck = 'truck',
  InlandShip = 'inland ship',
  SeaShip = 'sea ship',
  Train = 'train',
}

export interface CommonCFEntry {
  type: TransportType;
  value: Maybe<number>;
  unit: Maybe<string>;
  distance: Maybe<number>;
}

export interface IngredientField {
  id?: string;
  inbound_transport?: [CommonCFEntry?];
  custom_name?: string;
  origin?: string;
  key?: string;
  localId?: string;
  name?: string;
  proxyOrigin?: boolean;
  proxyOriginCode?: string | null | undefined;
  customIngredient?: boolean;
  quantity?: number | string | undefined;
  quantityPercentage?: number | string | undefined;
  addonType?: string | undefined;
  inbound_transport_input?: InboundTransportInput;
}

export interface FeedMillLocation {
  iso_code: string;
  name: string;
}

export interface TargetSpeciesEntry {
  display_name: string;
  value: string;
}
// Using this type because it matches
export interface FeedFormFields {
  id: string | '';
  name: string | '';
  year: {
    value: number;
  };
  feedmill_location: {
    iso_code: string | '';
  };
  target_species: TargetSpeciesEntry[] | [];
  outbound_transport?: CommonCFEntry[] | [];
  on_site_activities?: OnSiteActivitiesInput;
  ingredient_uses?: IngredientUses[] | [];
  quantitySum?: number;
  databaseFoundation?: Maybe<CFDatabaseFoundation> | undefined;
  nutritional_analysis_data?: {
    crudeProteinContent?: Maybe<string | number>;
    grossEnergyContent?: Maybe<string | number>;
    dryMatterContent?: Maybe<string | number>;
    metabolizableEnergyContent?: Maybe<string | number>;
    ashContent?: Maybe<string | number>;
    pContent?: Maybe<string | number>;
  };
}

export interface IngredientUses {
  id?: string;
  ingredient_id?: string;
  name?: string;
  quantity?: number | string;
  quantityPercentage?: number | string;
  quantityTotal?: number | string;
  exclusive_origins?: [IngredientOrigin];
  inbound_transport?: [CommonCFEntry?];
  inbound_transport_input?: InboundTransportInput;
  origin?: string | null;
  proxyOrigin?: boolean;
  customIngredient?: boolean;
  proxyOriginCode?: string | null;
  addonType?: string;
}

export interface CompoundFeedFormExtended
  extends Omit<
    CompoundFeed,
    | '__typename'
    | 'outbound_transport'
    | 'on_site_activities'
    | 'ingredient_uses'
  > {
  outbound_transport_input?: OutboundTransportInput;
  outbound_transport?: Maybe<CommonCFEntry[]> | [];
  on_site_activities?: CommonCFEntry[];
  on_site_activities_input?: OnSiteActivitiesInput;
  ingredient_uses?: IngredientsUsesExtended[] | [];
  databaseFoundation?: CFDatabaseFoundation;
  nutritional_analysis_data?: {
    crudeProteinContent?: Maybe<string | number>;
    grossEnergyContent?: Maybe<string | number>;
    dryMatterContent?: Maybe<string | number>;
    metabolizableEnergyContent?: Maybe<string | number>;
    ashContent?: Maybe<string | number>;
    pContent?: Maybe<string | number>;
    digestibleEnergyContent?: Maybe<string | number>;
    phosphorusContent?: Maybe<string | number>;
    urinaryEnergyContent?: Maybe<string | number>;
    milk?: Maybe<string | number>;
    containsMilk?: Maybe<string | number>;
    naturalDetergentFiber?: Maybe<string | number>;
  };
  quantitySum?: number;
}

export interface CustomIngredient {
  categoryId: string | null;
  originalIngredientName?: string | null;
  name?: string;
  id?: string | null;
  isProxyCategory?: boolean;
}

export interface CustomIngredientError
  extends DeepMap<CustomIngredient, FieldError> {
  categoryId?: FieldError;
  name?: FieldError;
}

// export interface IngredientsUsesExtended extends Omit<IngredientUsesInput, 'quantity' | 'inbound_transport'> {
export interface IngredientsUsesExtended
  extends Omit<IngredientUses, 'quantity' | 'inbound_transport'> {
  // name?: Maybe<string>;
  inbound_transport_input?: InboundTransportInput;
  inbound_transport?: Maybe<CommonCFEntry[]> | [];
  quantity?: number;
  requested_ingredient: RequestedIngredient;
  ingredient: Ingredient;
}

export interface ErrorResponseFeeds
  extends DeepMap<FeedFormFields, FieldError> {
  outbound_transport_input?: FieldError;
  on_site_activities_input?: FieldError;
}

export type IngredientNamesAndOriginsV2 =
  | {
      ingredientNames: CompoundFeedIngredientName[];
      origins: Origin[];
      addons: CompoundFeedAddonsV2[];
    }
  | undefined;

export interface ResourceItem {
  type: string;
  value: string | number;
}

// Needed to make Record utility type keys optional
type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;

// TODO: remove surplus types
export type InboundTransportKey =
  | 'truck'
  | 'sea_ship'
  | 'train'
  | 'inland_ship';
export type OutboundTransportKey = 'truck' | 'inland_ship';
export type OnSiteActivitiesKey =
  | 'electricity_use'
  | 'gas_use'
  | 'heat_use'
  | 'water_use'
  | 'diesel_use'
  | 'gasoline_use'
  | 'propaneOrLpg_use'
  | 'coal_use'
  | 'electricityPhotovoltaic_use'
  | 'electricityWind_use';
export interface InboundTransportInput
  extends PartialRecord<InboundTransportKey, number> {}
export interface OutboundTransportInput
  extends PartialRecord<OutboundTransportKey, number> {}
export interface OnSiteActivitiesInput
  extends PartialRecord<OnSiteActivitiesKey, number> {
  selfGeneratedRenewables: ResourceItem[];
  energyTypes: ResourceItem[];
}

// TODO: until converted to TS
export type UserProfileAlias = any;
export type SelectedCustomerAlias = any;

export enum IngredientInputType {
  ABSOLUTE = 'ABSOLUTE',
  PERCENTAGE = 'PERCENTAGE',
}
