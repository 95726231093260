import React, { FC } from 'react';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormType } from '../FarmFlow/common';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../_metronic/layout';
import ReactHookDsmSelect from '../../../modules/Helpers/ReactHookDsmSelect2';
import ReactHookDsmInput from '../../../modules/Helpers/ReactHookDsmInput2';
import { enumToOptionsArrayWithTranslatedStrings } from '../../utils/obj-utils';
import {
  EnergyCarrierType,
  RenewableType,
} from '../../models/Facility/FacilityTypes';
import { UserProfilePrefs } from '../../../modules/Helpers/UserProfilePrefs';
import SustellTypeAmountFields from '../../../modules/Helpers/SustellTypeAmountFIelds';
import {
  EnergyTypeValidation,
  isAmountAndTypeFilled,
} from './slaughterhouseFacilityValidation';

interface AdditionalResourcesItem {
  keyId: string;
  type: string;
  amount: string | number;
}

interface FacilityResourcesProps {
  formType: FormType;
}

const FacilityResources: FC<FacilityResourcesProps> = ({ formType }) => {
  const intl = useIntl();
  const formContext = useFormContext();
  const classes: any = processAndStageStylesV2();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();

  const {
    fields: additionalEnergyTypes,
    append: appendAdditionalEnergyTypes,
    remove: removeAdditionalEnergyTypes,
  } = useFieldArray({
    name: `additionalEnergyTypes`,
    control: formContext.control,
    keyName: 'keyId',
  });

  const {
    fields: selfGeneratedRenewables,
    append: appendSelfGeneratedRenewables,
    remove: removeSelfGeneratedRenewables,
  } = useFieldArray({
    name: `selfGeneratedRenewables`,
    control: formContext.control,
    keyName: 'keyId',
  });

  const triggerEnergyFieldsValidation = () => {
    const fieldsToTrigger: Array<keyof EnergyTypeValidation> = [
      'gasUse',
      'electricity',
    ];
    // Had to handle the promise this way because the DsmButton component won't accept promises as onClick callbacks
    formContext
      .trigger(fieldsToTrigger)
      .then()
      .catch((err: Error) =>
        console.log(`Error triggering form validation ${err.message}`)
      );
  };

  const getEnergyFieldsFormValue = () => {
    type EnergyCasting = Array<{
      amount: unknown;
      type: unknown;
    }>;

    // Had to watch specifically for the fields because watch all is inconsistent when removing values.
    const selfGeneratedRenewables = formContext.watch(
      'selfGeneratedRenewables'
    ) as EnergyCasting;
    const additionalEnergyTypes = formContext.watch(
      'additionalEnergyTypes'
    ) as EnergyCasting;
    const electricity = formContext.watch('electricity') as string;
    const gasUse = formContext.watch('gasUse') as string;

    return {
      selfGeneratedRenewables: selfGeneratedRenewables || [],
      additionalEnergyTypes: additionalEnergyTypes || [],
      electricity: electricity || '',
      gasUse: gasUse || '',
    };
  };

  const checkEnergyFields = () => {
    const {
      selfGeneratedRenewables,
      additionalEnergyTypes,
      electricity,
      gasUse,
    } = getEnergyFieldsFormValue();

    return {
      isAdditionalEnergyFilled: isAmountAndTypeFilled(additionalEnergyTypes),
      isRenewablesFilled: isAmountAndTypeFilled(selfGeneratedRenewables),
      isEletricityFilled: Boolean(electricity),
      isGasUseFilled: Boolean(gasUse),
    };
  };

  const triggerValidationOnRemove = (removeFieldCallback: () => void) => {
    removeFieldCallback();
    const { isAdditionalEnergyFilled, isRenewablesFilled } =
      checkEnergyFields();
    if (!isAdditionalEnergyFilled && !isRenewablesFilled) {
      triggerEnergyFieldsValidation();
    }
  };

  const triggerValidationOnLostFocus = () => {
    triggerEnergyFieldsValidation();
  };

  const triggerValidationOnChange = () => {
    const {
      isAdditionalEnergyFilled,
      isRenewablesFilled,
      isEletricityFilled,
      isGasUseFilled,
    } = checkEnergyFields();

    if (
      !isAdditionalEnergyFilled &&
      !isRenewablesFilled &&
      !isEletricityFilled &&
      !isGasUseFilled
    )
      return;

    triggerEnergyFieldsValidation();
  };

  return (
    <>
      <h3>
        {intl.formatMessage({ id: 'SUSTELL.FACILITIES.RESOURCES.TITLE' })}
      </h3>
      <p>
        {intl.formatMessage({ id: 'SUSTELL.FACILITIES.RESOURCES.DESCRIPTION' })}
      </p>
      <DsmGrid
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        className={classes.dsmGridOneColumn}
        style={{ marginTop: `var(--dsm-spacing-px-6)`, width: '32.5%' }}
      >
        <ReactHookDsmInput
          focusLostHandler={() => triggerValidationOnLostFocus()}
          changeHandler={() => triggerValidationOnChange()}
          name="electricity"
          label={intl.formatMessage({
            id: 'SUSTELL.FACILITIES.RESOURCES.GRID_ELECTRICITY_USE',
          })}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          type="number"
          tooltip={intl.formatMessage({
            id: 'SUSTELL.FACILITIES.RESOURCES.GRID_ELECTRICITY_USE.TT',
          })}
          disabled={formType === FormType.View}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          adornment={intl.formatMessage(
            { id: 'SUSTELL.FACILITIES.RESOURCES.UNIT_PER_YEAR_PLACEHOLDER' },
            { unit: userUOM.unitElectricityUse }
          )}
        />
      </DsmGrid>
      <>
        {selfGeneratedRenewables &&
          selfGeneratedRenewables?.map(
            (field: AdditionalResourcesItem, index: number) => (
              <React.Fragment key={field.keyId}>
                <DsmGrid
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                  className={classes.additionalEntriesBlockHolder}
                  style={{ width: '66%' }}
                >
                  {formType !== FormType.View && (
                    <DsmButton
                      variant="text"
                      style={{ position: 'relative', width: '100%' }}
                      onClick={() =>
                        triggerValidationOnRemove(() =>
                          removeSelfGeneratedRenewables(index)
                        )
                      }
                    >
                      <DsmIcon
                        name="general/x-close"
                        style={{
                          position: 'absolute',
                          color: 'var(--dsm-color-neutral-darker',
                          right: '0',
                        }}
                      />
                    </DsmButton>
                  )}
                  <DsmGrid
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    className={classes.dsmGridTwoColumn}
                  >
                    <ReactHookDsmSelect
                      changeHandler={() => triggerValidationOnChange()}
                      label={intl.formatMessage({
                        id: 'SUSTELL.FACILITIES.RESOURCES.SELF_GENERATED_RENEWABLE_TYPE',
                      })}
                      placeholder={intl.formatMessage({
                        id: 'SUSTELL.FACILITIES.RESOURCES.ENERGY_TYPE.PLACEHOLDER',
                      })}
                      name={`selfGeneratedRenewables.${index}.type`}
                      options={enumToOptionsArrayWithTranslatedStrings(
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        RenewableType,
                        intl,
                        'SUSTELL.RENEWABLES'
                      )}
                      required
                      disabled={formType === FormType.View}
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      defaultValue={field.type}
                      tooltip={intl.formatMessage({
                        id: 'SUSTELL.FACILITIES.RESOURCES.SELF_GENERATED_RENEWABLE_TYPE.TT',
                      })}
                      adornment=" "
                      key={`self-generated-renewable-type-${field.keyId || ''}`}
                    />
                    <ReactHookDsmInput
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      defaultValue={field.amount}
                      key={`aself-generated-renewable-amount-${
                        field.keyId || ''
                      }`}
                      changeHandler={() => triggerValidationOnChange()}
                      name={`selfGeneratedRenewables.${index}.amount`}
                      label={intl.formatMessage({
                        id: 'SUSTELL.FACILITIES.RESOURCES.SELF_GENERATED_RENEWABLE_USE',
                      })}
                      type="number"
                      required
                      tooltip={intl.formatMessage({
                        id: 'SUSTELL.FACILITIES.RESOURCES.SELF_GENERATED_RENEWABLE_USE.TT',
                      })}
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                      adornment={intl.formatMessage(
                        {
                          id: 'SUSTELL.FACILITIES.RESOURCES.UNIT_PER_YEAR_PLACEHOLDER',
                        },
                        { unit: userUOM.unitElectricityUse }
                      )}
                      disabled={formType === FormType.View}
                    />
                  </DsmGrid>
                </DsmGrid>
              </React.Fragment>
            )
          )}
        <DsmButton
          variant="text"
          disabled={formType === FormType.View}
          onClick={() => {
            appendSelfGeneratedRenewables({ type: '', amount: '' });
          }}
        >
          <DsmIcon slot="before" name="general/plus-circle" />
          {intl.formatMessage({
            id: 'SUSTELL.FACILITIES.RESOURCES.ADD_SELF_GENERATED_RENEWABLE',
          })}
        </DsmButton>
        <br />
      </>

      <DsmGrid
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        className={classes.dsmGridOneColumn}
        style={{ marginTop: `var(--dsm-spacing-px-6)`, width: '32.5%' }}
      >
        <ReactHookDsmInput
          name="gasUse"
          focusLostHandler={() => triggerValidationOnLostFocus()}
          changeHandler={() => triggerValidationOnChange()}
          label={intl.formatMessage({
            id: 'SUSTELL.FACILITIES.RESOURCES.GAS_USE',
          })}
          type="number"
          tooltip={intl.formatMessage({
            id: 'SUSTELL.FACILITIES.RESOURCES.GAS_USE.TT',
          })}
          disabled={formType === FormType.View}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          adornment={intl.formatMessage(
            { id: 'SUSTELL.FACILITIES.RESOURCES.UNIT_PER_YEAR_PLACEHOLDER' },
            { unit: userUOM.unitNaturalGasUse }
          )}
        />
      </DsmGrid>

      <>
        {additionalEnergyTypes &&
          additionalEnergyTypes?.map(
            (field: AdditionalResourcesItem, index: number) => (
              <React.Fragment key={field.keyId}>
                <DsmGrid
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                  className={classes.additionalEntriesBlockHolder}
                  style={{ width: '66%' }}
                >
                  {formType !== FormType.View && (
                    <DsmButton
                      variant="text"
                      style={{ position: 'relative', width: '100%' }}
                      onClick={() =>
                        triggerValidationOnRemove(() =>
                          removeAdditionalEnergyTypes(index)
                        )
                      }
                    >
                      <DsmIcon
                        name="general/x-close"
                        style={{
                          position: 'absolute',
                          color: 'var(--dsm-color-neutral-darker',
                          right: '0',
                        }}
                      />
                    </DsmButton>
                  )}
                  <DsmGrid
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                    className={classes.dsmGridTwoColumn}
                  >
                    <SustellTypeAmountFields
                      labelInput={intl.formatMessage({
                        id: 'SUSTELL.FACILITIES.RESOURCES.ENERGY_USE',
                      })}
                      labelSelect={intl.formatMessage({
                        id: 'SUSTELL.FACILITIES.RESOURCES.ENERGY_TYPE',
                      })}
                      placeholderSelect={intl.formatMessage({
                        id: 'SUSTELL.FACILITIES.RESOURCES.ENERGY_TYPE.PLACEHOLDER',
                      })}
                      tooltipType={intl.formatMessage({
                        id: 'SUSTELL.FACILITIES.RESOURCES.ENERGY_TYPE.TT',
                      })}
                      // tooltipUse={intl.formatMessage({id: "SUSTELL.FACILITIES.RESOURCES.ENERGY_USE.TT" })}
                      options={enumToOptionsArrayWithTranslatedStrings(
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        EnergyCarrierType,
                        intl,
                        undefined
                      ).filter(
                        (el) =>
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                          el.value !== EnergyCarrierType.NaturalGas &&
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                          el.value !== EnergyCarrierType.Electricity
                      )}
                      index={index}
                      formType={formType}
                      keyName={field.keyId}
                      defaultValueAmount={field.amount}
                      deafultValueType={field.type}
                      fieldItemPrefix="additionalEnergyTypes"
                      changeHandler={() => triggerValidationOnChange()}
                    />
                  </DsmGrid>
                </DsmGrid>
              </React.Fragment>
            )
          )}
        <DsmButton
          variant="text"
          disabled={formType === FormType.View}
          onClick={() => {
            appendAdditionalEnergyTypes({ type: '', amount: '' });
          }}
        >
          <DsmIcon slot="before" name="general/plus-circle" />
          {intl.formatMessage({ id: 'SUSTELL.FACILITIES.ADD_ANOTHER_ENERGY' })}
        </DsmButton>
        <br />
      </>

      <DsmGrid
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        className={classes.dsmGridThreeColumn}
        style={{ marginTop: `var(--dsm-spacing-px-6)` }}
      >
        <ReactHookDsmInput
          name="waterUse"
          label={intl.formatMessage({
            id: 'SUSTELL.FACILITIES.RESOURCES.WATER_USE',
          })}
          type="number"
          required
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.FACILITIES.RESOURCES.WATER_USE.TT',
          })}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          adornment={intl.formatMessage(
            { id: 'SUSTELL.FACILITIES.RESOURCES.UNIT_PER_YEAR_PLACEHOLDER' },
            { unit: userUOM.unitWaterUse }
          )}
        />
        <ReactHookDsmInput
          name="wasteWaterAmount"
          label={intl.formatMessage({
            id: 'SUSTELL.FACILITIES.RESOURCES.WASTEWATER',
          })}
          required
          type="number"
          disabled={formType === FormType.View}
          tooltip={intl.formatMessage({
            id: 'SUSTELL.FACILITIES.RESOURCES.WASTEWATER.TT',
          })}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          adornment={intl.formatMessage(
            { id: 'SUSTELL.FACILITIES.RESOURCES.UNIT_PER_YEAR_PLACEHOLDER' },
            { unit: userUOM.unitWaterUse }
          )}
        />
      </DsmGrid>
    </>
  );
};

export default FacilityResources;
