import { cloneDeep } from 'lodash';
import { useEffect, useRef, FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { DsmButton, DsmIcon } from '@dsm-dcs/design-system-react';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { BaselineDialogProps, FormType } from '../../common';
import { DatabaseFoundation } from '../../../../../../graphql/generated/blonk/pigs';
import { CompoundFeedNameDatabase, Origin, SingleIngredient } from '../types';
import { Maybe, StageType } from '../../../../../../graphql/types';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { BaselineFeed } from '../../../../models/Baseline';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import BeefFeedComponent from './BeefFeedComponent';
import {
  BeefBaseline,
  BeefBreedingStageData,
  BeefFeed,
  BeefGrowingStageData,
  BeefStage,
  TargetAnimal,
} from '../../../../models/Baseline/BeefBaseline';
import { defaultValues } from '../../baselineDefaultValues';
import { getInitialDatabaseValue } from '../../../../helpers/initialValues';
import { Tooltip } from '@material-ui/core';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { CSSClassesList } from '../../../../helpers/helperTypes';

interface BeefFeedGrowingDialogProps extends BaselineDialogProps {
  compoundFeeds: Maybe<Array<CompoundFeedNameDatabase>>;
  singleIngredients: Maybe<Array<SingleIngredient>>;
  origins: Maybe<Array<Origin>>;
}

const beefFeedStageDefaultValues = defaultValues.BEEF.stages[0].stageData.feed;

const BeefFeedGrowingDialog: FC<BeefFeedGrowingDialogProps> = ({
  formVisible,
  formType,
  itemIndex,
  handleCancel,
  handleSave = handleCancel,
  compoundFeeds,
  singleIngredients,
  origins,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  const formContext = useFormContext();
  const currResetValue = useRef<{
    feed: BeefFeed;
    databaseFoundation: DatabaseFoundation;
  }>();
  const fieldItemPrefix = `stages.${itemIndex}.stageData.feed`;
  const classes = processAndStageStylesV2() as CSSClassesList;

  const handleResetClick = useCallback(() => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() } as BeefBaseline;
      const growingStageData = resetObject.stages[itemIndex]?.stageData as BeefGrowingStageData
      if (growingStageData?.feed) {
        growingStageData.feed = {
          ...currResetValue.current.feed,
        };
        formContext.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) handleCancel('reset');
  }, [formContext, handleCancel, itemIndex]);

  const intl = useIntl();
  const baselineDatabaseFoundation =
    getInitialDatabaseValue<DatabaseFoundation>(
      formContext,
      fieldItemPrefix,
      userProfile
    );
  const initialDatabase = useRef<DatabaseFoundation>(
    getInitialDatabaseValue<DatabaseFoundation>(
      formContext,
      fieldItemPrefix,
      userProfile
    )
  );

  const updateBreedingFeeds = (stage: BeefStage, index: number) => {
    const cowsFeeds = (stage.stageData as BeefGrowingStageData).feed
      .compoundFeeds;
    const updatedCowsFeeds: BaselineFeed[] | undefined = cowsFeeds?.map(() => ({
      feedType: '',
      kgPerAnimal: 0,
    }));
    formContext.setValue(
      `stages.${index}.stageData.feed.compoundFeeds`,
      updatedCowsFeeds
    );
    const calvesFeeds = (stage.stageData as BeefBreedingStageData).feed.calves
      .compoundFeeds;
    const updatedCalvesFeeds: BaselineFeed[] | undefined = calvesFeeds?.map(
      () => ({ feedType: '', kgPerAnimal: 0 })
    );
    formContext.setValue(
      `stages.${index}.stageData.feed.compoundFeeds`,
      updatedCalvesFeeds
    );
  };

  const updateFeeds = (stage: BeefStage, index: number) => {
    const feeds = (stage.stageData as BeefGrowingStageData).feed.compoundFeeds;
    const updatedFeeds: BaselineFeed[] | undefined = feeds?.map(() => ({
      feedType: '',
      kgPerAnimal: 0,
    }));
    formContext.setValue(
      `stages.${index}.stageData.feed.compoundFeeds`,
      updatedFeeds
    );
  };

  const handleSaveClick = async () => {
    const currFormValues = formContext.getValues();
    formContext.reset(currFormValues, { errors: true });
    const stages = currFormValues?.stages as Array<BeefStage> || [];
    stages.forEach((stage: BeefStage, i: number) => {
      if (
        i !== itemIndex &&
        initialDatabase.current !== baselineDatabaseFoundation
      ) {
        if (stage.type === StageType.Breeding) updateBreedingFeeds(stage, i);
        else updateFeeds(stage, i);
      }
    });
    if (handleSave) {
      await formContext.trigger(`stages.${itemIndex}.stageData.feed`);
      await formContext.trigger(`stages.${itemIndex}.stageData.feed`);
      handleSave('confirm');
    }
  };

  useEffect(() => {
    if (formVisible) {
      const serializedData = formContext.getValues(
        `stages.${itemIndex}.stageData.feed`
      ) as BeefFeed;
      currResetValue.current = cloneDeep({
        feed: serializedData,
        databaseFoundation: baselineDatabaseFoundation,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const resetToDefaultValues = () => {
    // reset mother's milk amount
    formContext.setValue(
      `${fieldItemPrefix}.${TargetAnimal.CALVES}.averageMilkFat`,
      beefFeedStageDefaultValues.calves.averageMilkFat
    );
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="shapes/cube-02"
      formTitle={intl.formatMessage({
        id: 'SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE',
      })}
      variant="ultrawide"
    >
      <BeefFeedComponent
        formType={formType}
        fieldItemPrefix={fieldItemPrefix}
        targetAnimal={TargetAnimal.COWS}
        stageIndex={itemIndex}
        compoundFeeds={compoundFeeds}
        singleIngredients={singleIngredients}
        formVisible={formVisible}
        origins={origins}
        stageType={StageType.Growing}
      />
      <div className={classes.resetButtonContainer}>
        <DsmButton
          widthFull
          variant="text"
          onClick={() => resetToDefaultValues()}
          disabled={formType === FormType.View}
          className="mt-3"
        >
          <DsmIcon slot="before" name="arrows/refresh-cw-01" />
          <u style={{ textDecoration: 'none' }}>
            {intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.RESET_DEFAULT_VALUES',
            })}
          </u>
        </DsmButton>
        <Tooltip
          title={intl.formatMessage({
            id: 'SUSTELL.RESET_DEFAULT.TOOLTIP',
          })}
          placement="left"
          className='ml-3'
          style={{transform: 'translateY(85%)'}}
        >
          <DsmIcon
            name="general/help-circle"
            className={classes.inlineTooltipClass}
            title=""
          />
        </Tooltip>
      </div>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleSaveClick}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default BeefFeedGrowingDialog;
