import { API } from 'aws-amplify';
import { createContext, useEffect, useState } from 'react';
import { Card } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import {
  footprintMainCardStyles,
  useSubheader,
} from '../../../_metronic/layout';
import FootprintHeader from '../components/footprints/FootprintHeader';
import FootprintTabs from '../components/footprints/FootprintTabs';
import useFootprint from '../components/footprints/useFootprint';
import { useIntl } from '../../../_metronic/i18n/customUseIntl';
import { listFacilitiesBrief } from '../../../graphql/queries';
import { FacilityTableItem } from '../models/Facility/FacilityTypes';
import { AnimalType } from '../../../graphql/types';

export const AnimalTypeContext: any = createContext(null);

interface FootPrintPageProps {
  location: {
    state?: {
      farmName?: string;
      processName?: string;
      dataSetName?: string;
      comparisonName?: string;
      databaseName?: string;
      animalType?: string;
    };
  };
  match: {
    params: {
      customerID: string;
      baselineFarmID: string;
      baselineProcessID: string;
      baselineType?: string;
      baselineReference?: string;
      comparisonFarmID?: string;
      comparisonProcessID?: string;
      comparisonType?: string;
      comparisonReference?: string;
      comparisonCustomerID?: string;
    };
  };
}

const FootPrintPage = (props: FootPrintPageProps) => {
  const intl = useIntl();
  // TODO convert useSubheader function to typescript
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const subheader = useSubheader();
  const classes = footprintMainCardStyles();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { match, location } = props;
  const {
    customerID,
    baselineFarmID,
    baselineProcessID,
    baselineType,
    baselineReference,
    comparisonCustomerID,
    comparisonReference,
    comparisonType,
  } = match.params;

  const {
    farmName,
    processName,
    dataSetName,
    comparisonName,
    databaseName,
    animalType,
  } = location.state ?? {};
  const [facilities, setFacilities] = useState<FacilityTableItem[]>([]);

  const loadFacilities = () => {
    (
      API.graphql({
        query: listFacilitiesBrief,
        variables: {
          customerId: customerID,
        },
      }) as Promise<{
        data: {
          listFacilities: {
            items: FacilityTableItem[];
          };
        };
      }>
    )?.then(
      (response) => {
        const filteredByAnimalType = (
          response?.data?.listFacilities?.items || []
        ).filter((fac) =>
          fac.targetSpecies.find((species) => species === animalType)
        );
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setFacilities(filteredByAnimalType);
      },
      (reason: any) => {
        console.log('err, ', reason);
      }
    );
  };

  useEffect(() => {
    loadFacilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // footprint type
  const type = baselineType || comparisonType || 'b';
  // This useFootprint will return other functions like selecting another footprint etc
  const {
    footprint,
    footprintStages,
    setCategoryFilters,
    setAnalysisGroupFilters,
    setStageFilter,
  } = useFootprint({
    baselineName: dataSetName,
    customerID,
    baselineFarmID,
    baselineProcessID,
    baselineReference,
    baselineType,
    comparisonReference,
    comparisonType,
    comparisonCustomerID,
    comparisonName,
    facilities,
    baselineAnimalType: animalType as AnimalType,
  });
  useEffect(
    () => {
      const breadCrumbs: { pathname?: string; title: string; state?: any }[] =
        [];

      if (baselineType === 'f') {
        breadCrumbs.push({
          pathname: `/customers/${customerID}/myfeeds`,
          title: intl.formatMessage({ id: 'SUSTELL.MY_FEEDS' }),
          state: { animalType },
        });
      } else {
        breadCrumbs.push({
          pathname: `/customers/${customerID}/myfarms`,
          title: intl.formatMessage({ id: 'SUSTELL.MY_FARMS' }),
          state: { animalType },
        });

        if (baselineFarmID)
          breadCrumbs.push({
            pathname: `/customers/${customerID}/farm/${baselineFarmID}`,
            title: farmName || 'Farm',
            state: { animalType },
          });

        if (baselineFarmID && baselineProcessID)
          breadCrumbs.push({
            pathname: `/customers/${customerID}/farm/${baselineFarmID}/pp/${baselineProcessID}`,
            title: processName || 'Process',
            state: { animalType },
          });
      }
      breadCrumbs.push({
        title: dataSetName || 'Footprint',
      });

      // Disable eslint due to non-converted (js) subheader
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      subheader.setBreadcrumbs(breadCrumbs);
    },
    // FIXME: Disable eslint due to infinite loop because subheader changes state
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      baselineFarmID,
      baselineReference,
      baselineType,
      baselineProcessID,
      customerID,
      dataSetName,
      farmName,
      footprint,
      // intl,
      processName,
      // subheader,
    ]
  );

  return (
    <Card elevation={4} className={classes.mainCard}>
      {footprint && (
        <>
          <FootprintHeader
            categoryName={intl.formatMessage({
              id: 'SUSTELL.FOOTPRINT.CATEGORYBOX.TITLE',
            })}
            categoryValue={footprint.categories[0]?.value || 0}
            categoryConversationText={intl.formatMessage({
              id: 'SUSTELL.FOOTPRINT.CATEGORYBOX.CONVERSATION.TEXT',
            })}
            categoryUnitDescription={intl.formatMessage({
              id: 'SUSTELL.FOOTPRINT.CATEGORYBOX.UNIT.DESCRIPTION',
            })}
            categoryDescription={intl.formatMessage({
              id: 'SUSTELL.FOOTPRINT.CATEGORYBOX.DESCRIPTION',
            })}
            footprintType={type}
            databaseName={databaseName}
            animalType={animalType}
          />
          <AnimalTypeContext.Provider value={animalType}>
            <FootprintTabs
              footprintStages={footprintStages}
              footprint={footprint}
              customerID={customerID}
              baselineFarmID={baselineFarmID}
              comparisonReference={comparisonReference}
              type={type}
              setCategoryFilters={setCategoryFilters}
              setAnalysisGroupFilters={setAnalysisGroupFilters}
              setStageFilter={setStageFilter}
              animalType={animalType}
              initialTabOpened={searchParams.get('initialTabOpened')}
            />
          </AnimalTypeContext.Provider>
        </>
      )}
    </Card>
  );
};

export default FootPrintPage;
