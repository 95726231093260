export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: Date;
  AWSDateTime: Date;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export type AnimalInput = {
  originId: Scalars['ID'];
  quantity: Scalars['Float'];
  transport: Array<Transport>;
};

export enum AnimalInputType {
  BreedingPullet = 'BREEDING_PULLET',
  Broiler = 'BROILER',
  BroilerTurkey = 'BROILER_TURKEY',
  DayOldChick = 'DAY_OLD_CHICK',
  DayOldTurkey = 'DAY_OLD_TURKEY',
  HatchingEgg = 'HATCHING_EGG',
  LayingPullet = 'LAYING_PULLET'
}

export enum AnimalOutputType {
  Broiler = 'BROILER',
  BroilerTurkey = 'BROILER_TURKEY',
  ConsumptionEgg = 'CONSUMPTION_EGG',
  HatchingEgg = 'HATCHING_EGG',
  SpentHen = 'SPENT_HEN',
  SpentRooster = 'SPENT_ROOSTER'
}

export type AnimalProcessing = {
  facilities: Array<Facility>;
};

export type AnimalsIncoming = {
  number: Scalars['Float'];
  origin: InputAnimalOrigin;
  originStageId?: InputMaybe<Scalars['ID']>;
  transportDistance?: InputMaybe<Scalars['Float']>;
};

export type AuxiliaryInputs = {
  chemicals?: InputMaybe<Array<Chemical>>;
  refrigerants?: InputMaybe<Array<Refrigerant>>;
  wastewaterAmount: Scalars['Float'];
};

export type Bedding = {
  amount: Scalars['Float'];
  type: BeddingType;
};

export enum BeddingType {
  BarleyStraw = 'BARLEY_STRAW',
  MaizeStover = 'MAIZE_STOVER',
  OatStraw = 'OAT_STRAW',
  SawDust = 'SAW_DUST',
  SunflowerHusk = 'SUNFLOWER_HUSK',
  WheatStraw = 'WHEAT_STRAW',
  WoodShavings = 'WOOD_SHAVINGS'
}

export enum BreedingHousingType {
  Aviary = 'AVIARY',
  Cage = 'CAGE',
  FreeRange = 'FREE_RANGE',
  FurnishedCage = 'FURNISHED_CAGE'
}

export type BreedingStage = {
  bedding?: InputMaybe<Array<InputMaybe<Bedding>>>;
  emissionMitigations?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  endDate: Scalars['String'];
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  freeRangeTimePercent?: InputMaybe<Scalars['Float']>;
  henFeedingProgram: FeedingProgram;
  henInput: StageInput;
  housingType?: InputMaybe<BreedingHousingType>;
  id: Scalars['ID'];
  manureManagementSystems?: InputMaybe<Array<InputMaybe<ManureManagementSystem>>>;
  name?: InputMaybe<Scalars['String']>;
  output: BreedingStageOutput;
  roosterFeedingProgram: FeedingProgram;
  roosterInput: StageInput;
  startDate: Scalars['String'];
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type BreedingStageOutput = {
  averageWeight: Scalars['Float'];
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Float']>;
  spentHenAverageWeight?: InputMaybe<Scalars['Float']>;
  spentHenCalculateLca?: InputMaybe<Scalars['Boolean']>;
  spentHenMortalityPercent?: InputMaybe<Scalars['Float']>;
  spentHenPrice?: InputMaybe<Scalars['Float']>;
  spentHenSold: Scalars['Float'];
  spentHenToFacility: Scalars['Float'];
  spentHenWeightAtMortality?: InputMaybe<Scalars['Float']>;
  spentRoosterAverageWeight?: InputMaybe<Scalars['Float']>;
  spentRoosterCalculateLca?: InputMaybe<Scalars['Boolean']>;
  spentRoosterMortalityPercent?: InputMaybe<Scalars['Float']>;
  spentRoosterPrice?: InputMaybe<Scalars['Float']>;
  spentRoosterSold: Scalars['Float'];
  spentRoosterToFacility: Scalars['Float'];
  spentRoosterWeightAtMortality?: InputMaybe<Scalars['Float']>;
  totalSold: Scalars['Float'];
  totalToStage: Scalars['Float'];
};

export type Chemical = {
  amount: Scalars['Float'];
  type: ChemicalType;
};

export enum ChemicalType {
  HydrochloricAcid = 'HYDROCHLORIC_ACID',
  HydrogenPeroxide = 'HYDROGEN_PEROXIDE',
  PotassiumHydroxide = 'POTASSIUM_HYDROXIDE',
  SodiumHydroxide = 'SODIUM_HYDROXIDE',
  SodiumHypochlorite = 'SODIUM_HYPOCHLORITE'
}

export enum ClimateType {
  BorealDry = 'BOREAL_DRY',
  BorealMoist = 'BOREAL_MOIST',
  CoolTemperateDry = 'COOL_TEMPERATE_DRY',
  CoolTemperateMoist = 'COOL_TEMPERATE_MOIST',
  TropicalDry = 'TROPICAL_DRY',
  TropicalMoist = 'TROPICAL_MOIST',
  TropicalMontane = 'TROPICAL_MONTANE',
  TropicalWet = 'TROPICAL_WET',
  WarmTemperateDry = 'WARM_TEMPERATE_DRY',
  WarmTemperateMoist = 'WARM_TEMPERATE_MOIST'
}

export type CompoundFeed = {
  compoundFeedId: Scalars['ID'];
  energyCarriers?: InputMaybe<Array<EnergyCarrier>>;
  feedmillLocation: Scalars['String'];
  ingredientUses: Array<IngredientUse>;
  name: Scalars['String'];
  outboundTransport?: InputMaybe<Array<Transport>>;
  water?: InputMaybe<Array<Water>>;
};

export type CompoundFeedUse = {
  ashContent?: InputMaybe<Scalars['Float']>;
  crudeProteinContent: Scalars['Float'];
  dryMatterContent?: InputMaybe<Scalars['Float']>;
  grossEnergyContent?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  metabolizableEnergyContent?: InputMaybe<Scalars['Float']>;
  pPermille?: InputMaybe<Scalars['Float']>;
  quantity: Scalars['Float'];
};

export enum DatabaseFoundation {
  Afp6 = 'AFP6',
  Gfli = 'GFLI'
}

export type EmissionMitigation = {
  change: Scalars['Float'];
  emissionType: EmissionType;
};

export type EmissionResult = {
  __typename?: 'EmissionResult';
  magnitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
};

export enum EmissionType {
  AmmoniaHousing = 'AMMONIA_HOUSING',
  AmmoniaStorage = 'AMMONIA_STORAGE',
  DinitrogenStorage = 'DINITROGEN_STORAGE',
  MethaneMms = 'METHANE_MMS',
  NitrogenOxidesStorage = 'NITROGEN_OXIDES_STORAGE',
  NitrousOxideDirect = 'NITROUS_OXIDE_DIRECT',
  NitrousOxideLeaching = 'NITROUS_OXIDE_LEACHING',
  NitrousOxideVolatilization = 'NITROUS_OXIDE_VOLATILIZATION',
  NonMethaneVolatileOrganicCompounds = 'NON_METHANE_VOLATILE_ORGANIC_COMPOUNDS',
  Pm2_5 = 'PM2_5',
  Pm10 = 'PM10',
  Tsp = 'TSP'
}

export type EnergyCarrier = {
  amount: Scalars['Float'];
  type: EnergyCarrierType;
};

export enum EnergyCarrierType {
  Coal = 'COAL',
  Diesel = 'DIESEL',
  Electricity = 'ELECTRICITY',
  ElectricityPhotovoltaic = 'ELECTRICITY_PHOTOVOLTAIC',
  ElectricityWind = 'ELECTRICITY_WIND',
  Gasoline = 'GASOLINE',
  NaturalGas = 'NATURAL_GAS',
  PropaneOrLpg = 'PROPANE_OR_LPG'
}

export type Facility = {
  auxiliaryInputs: AuxiliaryInputs;
  energyCarriers: Array<EnergyCarrier>;
  geography: Scalars['String'];
  id: Scalars['ID'];
  inputs: FacilityInputs;
  name?: InputMaybe<Scalars['String']>;
  outputs: FacilityOutputs;
  water: Array<Water>;
};

export type FacilityInputs = {
  animalInputs: Array<AnimalInput>;
  averageWeight: Scalars['Float'];
  name?: InputMaybe<AnimalOutputType>;
  totalLiveWeightEntering: Scalars['Float'];
};

export type FacilityOutputs = {
  cat1And2MaterialAndWaste: OutputSpecification;
  cat3HidesAndSkins: OutputSpecification;
  cat3SlaughterByProducts: OutputSpecification;
  foodGradeBones: OutputSpecification;
  foodGradeFat: OutputSpecification;
  freshMeatAndEdibleOffal: OutputSpecification;
};

export type Farm = {
  bedding?: InputMaybe<Array<InputMaybe<Bedding>>>;
  breedingStages?: InputMaybe<Array<InputMaybe<BreedingStage>>>;
  climateType: ClimateType;
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  finalPeriodOfAssessmentDate?: InputMaybe<Scalars['String']>;
  geography: Scalars['String'];
  growingStages?: InputMaybe<Array<InputMaybe<GrowingStage>>>;
  hatchingStages?: InputMaybe<Array<InputMaybe<HatchingStage>>>;
  initialPeriodOfAssessmentDate?: InputMaybe<Scalars['String']>;
  layingStages?: InputMaybe<Array<InputMaybe<LayingStage>>>;
  turkeyGrowingStages?: InputMaybe<Array<InputMaybe<GrowingStage>>>;
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type FeedingProgram = {
  compoundFeeds?: InputMaybe<Array<InputMaybe<CompoundFeedUse>>>;
  singleIngredientUses?: InputMaybe<Array<InputMaybe<SingleIngredientUse>>>;
};

export enum GrowingHousingType {
  Cage = 'CAGE',
  FloorRaised = 'FLOOR_RAISED',
  FreeRange = 'FREE_RANGE'
}

export type GrowingStage = {
  averageProductionRoundLength: Scalars['Float'];
  bedding?: InputMaybe<Array<InputMaybe<Bedding>>>;
  emissionMitigations?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  endDate: Scalars['String'];
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  feedingProgram: FeedingProgram;
  freeRangeTimePercent?: InputMaybe<Scalars['Float']>;
  housingType?: InputMaybe<GrowingHousingType>;
  id: Scalars['ID'];
  input: StageInput;
  manureManagementSystems?: InputMaybe<Array<InputMaybe<ManureManagementSystem>>>;
  name?: InputMaybe<Scalars['String']>;
  output: GrowingStageOutput;
  startDate: Scalars['String'];
  stockChange?: InputMaybe<GrowingStockChange>;
  totalEmptyDays?: InputMaybe<Scalars['Float']>;
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type GrowingStageOutput = {
  averageWeight: Scalars['Float'];
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  mortalityPercent?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  totalSold: Scalars['Float'];
  totalToStage: Scalars['Float'];
  weightAtMortality?: InputMaybe<Scalars['Float']>;
};

export type GrowingStockChange = {
  finalAnimalAverageWeight?: InputMaybe<Scalars['Float']>;
  finalAnimalStock?: InputMaybe<Scalars['Float']>;
  initialAnimalAverageWeight?: InputMaybe<Scalars['Float']>;
  initialAnimalStock?: InputMaybe<Scalars['Float']>;
};

export type HatchingStage = {
  endDate: Scalars['String'];
  energyCarriers: Array<EnergyCarrier>;
  id: Scalars['ID'];
  input: StageInput;
  name?: InputMaybe<Scalars['String']>;
  output: HatchingStageOutput;
  startDate: Scalars['String'];
  stockChange?: InputMaybe<HatchingStockChange>;
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type HatchingStageOutput = {
  averageWeight: Scalars['Float'];
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  lossesPrice?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  totalSold: Scalars['Float'];
  totalToStage: Scalars['Float'];
  totalWeightEggShellSoldOrWaste?: InputMaybe<Scalars['Float']>;
  totalWeightLosses: Scalars['Float'];
};

export type HatchingStockChange = {
  finalAnimalStock?: InputMaybe<Scalars['Float']>;
  initialAnimalStock?: InputMaybe<Scalars['Float']>;
};

export type ImpactAssessmentResult = {
  __typename?: 'ImpactAssessmentResult';
  category?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  unit?: Maybe<Scalars['String']>;
};

export type Ingredient = {
  addonType?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  origin: Scalars['String'];
  tags?: InputMaybe<Array<InputMaybe<TagInput>>>;
};

export type IngredientUse = {
  inboundTransport?: InputMaybe<Array<InputMaybe<Transport>>>;
  ingredient: Ingredient;
  quantity: Scalars['Float'];
};

export enum InputAnimalOrigin {
  BackgroundData = 'BACKGROUND_DATA',
  ProductionSystem = 'PRODUCTION_SYSTEM'
}

export enum LayingHousingType {
  Aviary = 'AVIARY',
  Cage = 'CAGE',
  FreeRange = 'FREE_RANGE',
  FurnishedCage = 'FURNISHED_CAGE'
}

export type LayingStage = {
  bedding?: InputMaybe<Array<InputMaybe<Bedding>>>;
  emissionMitigations?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  endDate: Scalars['String'];
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  feedingProgram: FeedingProgram;
  freeRangeTimePercent?: InputMaybe<Scalars['Float']>;
  housingType?: InputMaybe<LayingHousingType>;
  id: Scalars['ID'];
  input: StageInput;
  manureManagementSystems?: InputMaybe<Array<InputMaybe<ManureManagementSystem>>>;
  name?: InputMaybe<Scalars['String']>;
  output: LayingStageOutput;
  startDate: Scalars['String'];
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type LayingStageOutput = {
  averageWeight: Scalars['Float'];
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<Scalars['Float']>;
  spentHenAverageWeight?: InputMaybe<Scalars['Float']>;
  spentHenCalculateLca?: InputMaybe<Scalars['Boolean']>;
  spentHenMortalityPercent?: InputMaybe<Scalars['Float']>;
  spentHenPrice?: InputMaybe<Scalars['Float']>;
  spentHenSold: Scalars['Float'];
  spentHenToFacility: Scalars['Float'];
  spentHenWeightAtMortality?: InputMaybe<Scalars['Float']>;
  totalSold: Scalars['Float'];
  totalToStage: Scalars['Float'];
};

export type MmsEmissionResult = {
  __typename?: 'MMSEmissionResult';
  emissions?: Maybe<Array<EmissionResult>>;
  mmsType?: Maybe<Scalars['String']>;
};

export type ManureManagementSystem = {
  emissionMitigations?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  share: Scalars['Float'];
  type: ManureManagementSystemType;
};

export enum ManureManagementSystemType {
  AerobicForced = 'AEROBIC_FORCED',
  AerobicNatural = 'AEROBIC_NATURAL',
  AnaerobicHighLeakHighGastight = 'ANAEROBIC_HIGH_LEAK_HIGH_GASTIGHT',
  AnaerobicHighLeakLowGastight = 'ANAEROBIC_HIGH_LEAK_LOW_GASTIGHT',
  AnaerobicHighLeakOpen = 'ANAEROBIC_HIGH_LEAK_OPEN',
  AnaerobicLowLeakHighGastight = 'ANAEROBIC_LOW_LEAK_HIGH_GASTIGHT',
  AnaerobicLowLeakLowGastight = 'ANAEROBIC_LOW_LEAK_LOW_GASTIGHT',
  AnaerobicLowLeakOpen = 'ANAEROBIC_LOW_LEAK_OPEN',
  CompostingIntensiveWindrow = 'COMPOSTING_INTENSIVE_WINDROW',
  CompostingInVessel = 'COMPOSTING_IN_VESSEL',
  CompostingPassiveWindrow = 'COMPOSTING_PASSIVE_WINDROW',
  CompostingStaticPile = 'COMPOSTING_STATIC_PILE',
  DailySpreadSlurry = 'DAILY_SPREAD_SLURRY',
  DailySpreadSolid = 'DAILY_SPREAD_SOLID',
  LiquidCover_1Month = 'LIQUID_COVER_1MONTH',
  LiquidCover_3Month = 'LIQUID_COVER_3MONTH',
  LiquidCover_4Month = 'LIQUID_COVER_4MONTH',
  LiquidCover_6Month = 'LIQUID_COVER_6MONTH',
  LiquidCover_12Month = 'LIQUID_COVER_12MONTH',
  LiquidCrust_1Month = 'LIQUID_CRUST_1MONTH',
  LiquidCrust_3Month = 'LIQUID_CRUST_3MONTH',
  LiquidCrust_4Month = 'LIQUID_CRUST_4MONTH',
  LiquidCrust_6Month = 'LIQUID_CRUST_6MONTH',
  LiquidCrust_12Month = 'LIQUID_CRUST_12MONTH',
  LiquidNoCrust_1Month = 'LIQUID_NO_CRUST_1MONTH',
  LiquidNoCrust_3Month = 'LIQUID_NO_CRUST_3MONTH',
  LiquidNoCrust_4Month = 'LIQUID_NO_CRUST_4MONTH',
  LiquidNoCrust_6Month = 'LIQUID_NO_CRUST_6MONTH',
  LiquidNoCrust_12Month = 'LIQUID_NO_CRUST_12MONTH',
  Pit_1Month = 'PIT_1MONTH',
  Pit_3Month = 'PIT_3MONTH',
  Pit_4Month = 'PIT_4MONTH',
  Pit_6Month = 'PIT_6MONTH',
  Pit_12Month = 'PIT_12MONTH',
  PoultryManureWithOrWithoutLitter = 'POULTRY_MANURE_WITH_OR_WITHOUT_LITTER',
  SolidAdditives = 'SOLID_ADDITIVES',
  SolidBulking = 'SOLID_BULKING',
  SolidCovered = 'SOLID_COVERED',
  SolidStorage = 'SOLID_STORAGE',
  UncoveredAnaerobicLagoon = 'UNCOVERED_ANAEROBIC_LAGOON'
}

export type OutputSpecification = {
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  packaging?: InputMaybe<Packaging>;
  price?: InputMaybe<Scalars['Float']>;
  weight: Scalars['Float'];
};

export type Packaging = {
  primaryPackaging?: InputMaybe<Array<PrimaryPackaging>>;
  secondaryPackaging?: InputMaybe<Array<SecondaryPackaging>>;
  tertiaryPackaging?: InputMaybe<Array<TertiaryPackaging>>;
};

export type PrimaryPackaging = {
  amount: Scalars['Float'];
  type: PrimaryPackagingType;
};

export enum PrimaryPackagingType {
  AluminiumFoil = 'ALUMINIUM_FOIL',
  HdpeGranulates = 'HDPE_GRANULATES',
  LdpeGranulates = 'LDPE_GRANULATES',
  Paper = 'PAPER',
  Polyester = 'POLYESTER',
  Polypropylene = 'POLYPROPYLENE',
  Polystyrene = 'POLYSTYRENE'
}

export type ProductResultNode = {
  __typename?: 'ProductResultNode';
  aggregatedImpactAssessmentResults?: Maybe<Array<ImpactAssessmentResult>>;
  impactAssessmentResults?: Maybe<Array<ImpactAssessmentResult>>;
  productName?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  upstreamProductNodes?: Maybe<Array<ProductResultNode>>;
};

export type ProductionSystem = {
  animalProcessing?: InputMaybe<AnimalProcessing>;
  farms: Array<InputMaybe<Farm>>;
};

export type ProductionSystemInput = {
  compoundFeeds?: InputMaybe<Array<CompoundFeed>>;
  databaseFoundation: DatabaseFoundation;
  productionSystem: ProductionSystem;
};

export type ProductionSystemResult = {
  __typename?: 'ProductionSystemResult';
  emissionResults?: Maybe<Array<StageEmissionResult>>;
  impactResults?: Maybe<Array<ProductResultNode>>;
  warnings?: Maybe<Array<Maybe<Warning>>>;
};

export type Query = {
  __typename?: 'Query';
  getResultByProductionSystemInput?: Maybe<ProductionSystemResult>;
  getValidationErrorCodes?: Maybe<Array<Maybe<ValidationErrorCode>>>;
};


export type QueryGetResultByProductionSystemInputArgs = {
  productionSystemInput: ProductionSystemInput;
};

export type Refrigerant = {
  amount: Scalars['Float'];
  type: RefrigerantType;
};

export enum RefrigerantType {
  Ammonia = 'AMMONIA',
  CarbonDioxide = 'CARBON_DIOXIDE',
  Propane = 'PROPANE',
  RefrigerantHfc = 'REFRIGERANT_HFC'
}

export type SecondaryPackaging = {
  amount: Scalars['Float'];
  type: SecondaryPackagingType;
};

export enum SecondaryPackagingType {
  CorrugatedBox = 'CORRUGATED_BOX'
}

export type SingleIngredientUse = {
  ashContent?: InputMaybe<Scalars['Float']>;
  crudeProteinContent: Scalars['Float'];
  dryMatterContent: Scalars['Float'];
  grossEnergyContent?: InputMaybe<Scalars['Float']>;
  ingredient: Ingredient;
  metabolizableEnergyContent: Scalars['Float'];
  pPermille: Scalars['Float'];
  quantity: Scalars['Float'];
  transportationDistance?: InputMaybe<Scalars['Float']>;
};

export type StageEmissionResult = {
  __typename?: 'StageEmissionResult';
  emissions?: Maybe<Array<EmissionResult>>;
  mmsEmissionResults?: Maybe<Array<MmsEmissionResult>>;
  stageId?: Maybe<Scalars['String']>;
  stageName?: Maybe<Scalars['String']>;
};

export type StageInput = {
  animalAverageWeight: Scalars['Float'];
  animalType: AnimalInputType;
  animals: Array<InputMaybe<AnimalsIncoming>>;
};

export type Tag = {
  __typename?: 'Tag';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TagInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type TertiaryPackaging = {
  amount: Scalars['Float'];
  type: TertiaryPackagingType;
};

export enum TertiaryPackagingType {
  Pallet = 'PALLET',
  PlasticFilm = 'PLASTIC_FILM'
}

export type Transport = {
  distance: Scalars['Float'];
  mode: TransportMode;
};

export enum TransportMode {
  InlandShip = 'INLAND_SHIP',
  SeaShip = 'SEA_SHIP',
  Train = 'TRAIN',
  Truck = 'TRUCK'
}

export type ValidationErrorCode = {
  __typename?: 'ValidationErrorCode';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type Warning = {
  __typename?: 'Warning';
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Water = {
  amount: Scalars['Float'];
  type: WaterType;
};

export enum WaterType {
  Water = 'WATER'
}
