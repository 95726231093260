/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';
import { BaselineStageProps, DairyV2Farms } from '../../common';
import { AnimalType, StageType } from '../../../../../../graphql/types';
import { defaultValues } from '../../baselineDefaultValues';
import { stageDataPartBeef } from '../../../../../modules/Farms/Baseline/validationSchema/beefBaselineValidation';
import { Origin } from '../types';
import { StageButtonBox } from '../../StageButtonBox_v2';
import DairyV2InputDairyCowDialog from './DairyV2InputDairyCowDialog';
import DairyV2InputContinuousGrowingDialog from './DairyV2InputContinuousGrowingDialog';
import DairyV2FeedDairyCowDialog from './DairyV2FeedDairyCowDialog';
import DairyV2FeedContinuousGrowingDialog from './DairyV2FeedContinuousGrowingDialog';
import DairyV2HousingAndManureDialog from './DairyV2HousingAndManureDialog';
import DairyV2OutputDairyCowDialog from './DairyV2OutputDairyCowDialog';
import DairyV2OutputContinuousGrowingDialog from './DairyV2OutputContinuousGrowingDialog';
import DairyV2EmissionsDialog from './DairyV2EmissionsDialog';
import { DairyV2Baseline } from '../../../../models/Baseline/DairyV2Baseline';

interface DairyV2StagesProps extends Omit<BaselineStageProps, 'baseline'> {
  farms: DairyV2Farms[];
  farmId: string;
  farmName: string;
  productionProcessName: string;
  baseline: DairyV2Baseline;
  origins: Origin[];
  animalType: AnimalType.DairyV2;
  calculateCompleteness: (validationFunction: any, prefix: string) => number;
}

interface CloseDialogData {
  type: string;
  setFunc: React.Dispatch<React.SetStateAction<number>>;
  prefix: 'input' | 'output' | 'feed' | 'housing' | 'emissions';
}

interface InitialCalcuationData {
  prefix: 'input' | 'output' | 'feed' | 'housing' | 'emissions';
}

const DairyV2Stages: FC<DairyV2StagesProps> = ({
  farms,
  farmName,
  farmId,
  productionProcessName,
  stageIndex,
  baseline,
  compoundFeeds,
  singleIngredients,
  origins,
  item,
  formType,
  stageType = StageType.DairyCow,
  animalType,
  calculateCompleteness,
}) => {
  const formContext = useFormContext();
  const [activeDialog, setActiveDialog] = useState<string>('');

  const showDialog = (dialog: SetStateAction<string>) => {
    setActiveDialog(dialog);
  };

  const stagePrefix = `stages.${stageIndex}`;
  const [inputDairyCowCompleteness, setInputDairyCowCompleteness] = useState(0);
  const [inputContinuousGrowingCompleteness, setInputContinuousGrowingCompleteness] = useState(0);
  const [feedDairyCowCompleteness, setFeedDairyCowCompleteness] = useState(0);
  const [feedContinuousGrowingCompleteness, setFeedContinuousGrowingCompleteness] = useState(0);
  const [housingAndManureCompletness, setHousingAndManureCompletness] =
    useState(0);
  const [outputDairyCowCompleteness, setOutputDairyCowCompleteness] =
    useState(0);
  const [outputContinuousGrowingCompleteness, setOutputContinuousGrowingCompleteness] = useState(0);
  const [emissionCompleteness, setEmissionCompleteness] = useState(0);

  const feedCompletenessPercentage = () => {
    switch (stageType) {
      case StageType.DairyCow:
        return feedDairyCowCompleteness;
      case StageType.ContinuousGrowing:
        return feedContinuousGrowingCompleteness;
      default:
        return 0;
    }
  };

  const inputCompletenessPercentage = () => {
    switch (stageType) {
      case StageType.DairyCow:
        return inputDairyCowCompleteness;
      case StageType.ContinuousGrowing:
        return inputContinuousGrowingCompleteness;
      default:
        return 0;
    }
  };

  const outputCompletenessPercentage = () => {
    switch (stageType) {
      case StageType.DairyCow:
        return outputDairyCowCompleteness;
      case StageType.ContinuousGrowing:
        return outputContinuousGrowingCompleteness;
      default:
        return 0;
    }
  };

  const initialCalculation = (
    initialCalcuationData: InitialCalcuationData
  ) => {
    const validationObject = item.stageData[initialCalcuationData.prefix];
    const defaultObject =
      defaultValues[animalType]?.stages[0]?.stageData[
        initialCalcuationData.prefix
      ];
    if (item.newStage && isEqual(validationObject, defaultObject))
      return 0;
    return calculateCompleteness(
      stageDataPartBeef,
      `stages[${stageIndex}].stageData.${initialCalcuationData.prefix}`
    );
  };

  const closeDialog = (closeDialogData: CloseDialogData) => {
    const completeness = calculateCompleteness(
      stageDataPartBeef,
      `stages[${stageIndex}].stageData.${closeDialogData.prefix}`
    );
    if (formType !== 'view' && closeDialogData.type === 'confirm') {
      closeDialogData.setFunc(completeness);
    } else if (closeDialogData.type === 'reset') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      if (!completeness) formContext.trigger(`${stagePrefix}.stageData.${activeDialog}`);
      else formContext.clearErrors(`${stagePrefix}.stageData.${activeDialog}`);
    }
    setActiveDialog('');
  };

  const getSelectableStages = (): string[] => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const currentBaselineValues = formContext.getValues()?.stages
      ? formContext.getValues()?.stages.map((stage: { id: string }) => stage.id)
      : [];
    return [
      ...farms.map((farm) => farm.stageId),
      ...currentBaselineValues,
    ] as string[];
  };

  const checkInternalSources = () => {
    const allStageIds = getSelectableStages();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    formContext.getValues(`${stagePrefix}.stageData.input.internalSources`)?.forEach(
      (internalSource: { originStageId: string }, index: number) => {
        if (
          !allStageIds.includes(internalSource.originStageId) &&
          internalSource.originStageId
        ) {
          formContext.setValue(
            `${stagePrefix}.stageData.input.internalSources[${index}].farmId`,
            ''
          );
          formContext.setValue(
            `${stagePrefix}.stageData.input.internalSources[${index}].originStageId`,
            ''
          );
          formContext.trigger(
            `${stagePrefix}.stageData.input.internalSources[${index}]`
          );
        }
      }
    );
  };

  useEffect(() => {
    if (formType === 'edit' && !item.newStage) {
      if (stageType === StageType.ContinuousGrowing) {
        setInputContinuousGrowingCompleteness(
          initialCalculation({ prefix: 'input' })
        );
        setFeedContinuousGrowingCompleteness(
          initialCalculation({ prefix: 'feed' })
        );
        setHousingAndManureCompletness(
          initialCalculation({ prefix: 'housing' })
        );
        setOutputContinuousGrowingCompleteness(
          initialCalculation({ prefix: 'output' })
        );
        setEmissionCompleteness(
          initialCalculation({ prefix: 'emissions' })
        );
      }
      if (stageType === StageType.DairyCow) {
        setInputDairyCowCompleteness(
          initialCalculation({ prefix: 'input' })
        );
        setFeedDairyCowCompleteness(
          initialCalculation({ prefix: 'feed' })
        );
        setHousingAndManureCompletness(
          initialCalculation({ prefix: 'housing' })
        );
        setOutputDairyCowCompleteness(
          initialCalculation({ prefix: 'output' })
        );
        setEmissionCompleteness(
          initialCalculation({ prefix: 'emissions' })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formType]);

  // Check if selected stages are existing or not
  // In case of some baseline deleted, the loaded data needs
  // to be removed so the user needs to select a different stage
  useEffect(() => {
    if (farms?.length) {
        checkInternalSources();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageType, farms, formType]);

  return (
    <>
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.id`}
        defaultValue={item.id}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.name`}
        defaultValue={item.name}
      />
      <input
        ref={formContext.register()}
        type="hidden"
        name={`${stagePrefix}.type`}
        defaultValue={item.type}
      />
      {stageType === StageType.DairyCow && activeDialog === 'input' && (
        <DairyV2InputDairyCowDialog
          farms={farms}
          farmId={farmId}
          farmName={farmName}
          productionProcessName={productionProcessName}
          formVisible={activeDialog === 'input'}
          formType={formType}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setInputDairyCowCompleteness,
              prefix: 'input',
            })
          }
          baseline={baseline}
          itemIndex={stageIndex}
        />
      )}
      {stageType === StageType.ContinuousGrowing && activeDialog === 'input' && (
        <DairyV2InputContinuousGrowingDialog
          farms={farms}
          farmId={farmId}
          farmName={farmName}
          productionProcessName={productionProcessName}
          formVisible={activeDialog === 'input'}
          formType={formType}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setInputContinuousGrowingCompleteness,
              prefix: 'input',
            })
          }
          baseline={baseline}
          itemIndex={stageIndex}
        />
      )}
      {stageType === StageType.DairyCow && activeDialog === 'feed' && (
        <DairyV2FeedDairyCowDialog
          formVisible={activeDialog === 'feed'}
          formType={formType}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setFeedDairyCowCompleteness,
              prefix: 'feed',
            })
          }
          baseline={baseline}
          itemIndex={stageIndex}
          compoundFeeds={compoundFeeds}
          singleIngredients={singleIngredients}
          origins={origins}
        />
      )}
      {stageType === StageType.ContinuousGrowing && activeDialog === 'feed' && (
        <DairyV2FeedContinuousGrowingDialog
          formVisible={activeDialog === 'feed'}
          formType={formType}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setFeedContinuousGrowingCompleteness,
              prefix: 'feed',
            })
          }
          baseline={baseline}
          itemIndex={stageIndex}
          compoundFeeds={compoundFeeds}
          singleIngredients={singleIngredients}
          origins={origins}
        />
      )}
      {activeDialog === 'housing' && (
        <DairyV2HousingAndManureDialog
          formVisible={activeDialog === 'housing'}
          formType={formType}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setHousingAndManureCompletness,
              prefix: 'housing',
            })
          }
          baseline={baseline}
          itemIndex={stageIndex}
          stageType={stageType}
        />
      )}
      {stageType === StageType.DairyCow && activeDialog === 'output' && (
        <DairyV2OutputDairyCowDialog
          formVisible={activeDialog === 'output'}
          formType={formType}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setOutputDairyCowCompleteness,
              prefix: 'output',
            })
          }
          baseline={baseline}
          itemIndex={stageIndex}
        />
      )}
      {stageType === StageType.ContinuousGrowing && activeDialog === 'output' && (
        <DairyV2OutputContinuousGrowingDialog
          formVisible={activeDialog === 'output'}
          formType={formType}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setOutputContinuousGrowingCompleteness,
              prefix: 'output',
            })
          }
          baseline={baseline}
          itemIndex={stageIndex}
        />
      )}
      {activeDialog === 'emissions' && (
        <DairyV2EmissionsDialog
          formVisible={activeDialog === 'emissions'}
          formType={formType}
          handleCancel={(type: string) =>
            closeDialog({
              type,
              setFunc: setEmissionCompleteness,
              prefix: 'emissions',
            })
          }
          itemIndex={stageIndex}
          stageType={stageType as StageType}
        />
      )}
      <Box
        style={{ display: 'flex', width: '100%' }}
        flexDirection="row"
        justifyContent="space-between"
      >
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.ANIMALS.TITLE"
          iconCode="general/check-heart"
          descriptionTitle="SUSTELL.STAGE.ANIMAL_DETAILS"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={inputCompletenessPercentage()}
          error={
            formContext.errors?.stages && formContext.errors?.stages[stageIndex]?.stageData?.input
          }
          handleOpen={() => showDialog('input')}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE"
          iconCode="shapes/cube-02"
          descriptionTitle="SUSTELL.STAGE.FEED"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={feedCompletenessPercentage()}
          error={
            formContext.errors?.stages && formContext.errors?.stages[stageIndex]?.stageData?.feed
          }
          handleOpen={() => showDialog('feed')}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.MANURE.TITLE"
          iconCode="general/building-06"
          completness={housingAndManureCompletness}
          descriptionTitle="SUSTELL.STAGE.HOUSING"
          description="SUSTELL.STAGE.INPUT_DATA"
          error={
            formContext.errors?.stages &&
            formContext.errors?.stages[stageIndex]?.stageData?.housing
          }
          handleOpen={() => showDialog('housing')}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE"
          iconCode="arrows/arrow-circle-broken-right"
          descriptionTitle="SUSTELL.STAGE.OUTPUT"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={outputCompletenessPercentage()}
          error={
            formContext.errors?.stages &&
            formContext.errors?.stages[stageIndex]?.stageData?.output
          }
          handleOpen={() => showDialog('output')}
        />
        <StageButtonBox
          titleCode="SUSTELL.PROCESS.DIALOG.STAGE.EMISSIONS.TITLE"
          iconCode="maps-travel/globe-04"
          descriptionTitle="SUSTELL.STAGE.MITTIGATION"
          description="SUSTELL.STAGE.INPUT_DATA"
          completness={emissionCompleteness}
          handleOpen={() => showDialog('emissions')}
          error={
            formContext.errors?.stages &&
            formContext.errors?.stages[stageIndex]?.stageData?.emissions
          }
        />
      </Box>
    </>
  );
};

export default DairyV2Stages;
