/* eslint-disable @typescript-eslint/no-floating-promises */
import { FC, useEffect, useRef } from 'react';
import { cloneDeep } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { DsmFieldset, DsmGrid } from '@dsm-dcs/design-system-react';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { BaselineDialogProps, FormType } from '../../common';
import { unitLong } from '../../../../utils/unit-utils';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import {
  DairyV2Baseline,
  DairyV2DairyCowStageData,
  DairyV2OutputDairyCow,
} from '../../../../models/Baseline/DairyV2Baseline';

const DairyV2OutputDairyCowDialog: FC<BaselineDialogProps> = ({
  formType = FormType.Add,
  itemIndex = 0,
  formVisible,
  handleCancel,
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  const classes = processAndStageStylesV2() as CSSClassesList;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const currResetValue = useRef<DairyV2OutputDairyCow>();
  const formTitle = intl.formatMessage({
    id: 'SUSTELL.PROCESS.DIALOG.STAGE.OUTPUT.TITLE',
  });
  const fieldItemPrefix = `stages.${itemIndex}.stageData.output`;
  const formContext = useFormContext<DairyV2Baseline>();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const barnOutputMassUnit = unitLong(userUOM.unitBarnOutputMass as string);

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep(
        formContext.getValues(fieldItemPrefix)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() } as DairyV2Baseline;
      const dairyCowStageData = resetObject.stages[itemIndex]?.stageData as DairyV2DairyCowStageData
      if (dairyCowStageData.output) {
        dairyCowStageData.output = {
          ...currResetValue.current,
        };
        formContext.reset(resetObject, { errors: true });
      }
    }
    handleCancel('reset');
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="arrows/arrow-circle-broken-right"
      formTitle={formTitle}
      variant="wide"
    >
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <div>
          <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
            <span className={classes.headingTitle}>
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.COWS',
              })}
            </span>
          </div>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.cowsToAnotherStage`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.COWS_TO_ANOTHER_STAGE',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.COWS_TO_ANOTHER_STAGE.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.BREEDING.COWS_TO_ANOTHER_STAGE.TOOLTIP',
              })}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.cowsToAnotherStage`
              )}
              required
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.averageWeightOfCowsLeavingTheStage`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.AVERAGE_WEIGHT_COWS_LEAVING_STAGE',
              })}
              adornment={intl.formatMessage(
                {
                  id: 'SUSTELL.STAGE.BEEF.OUTPUT.AVERAGE_WEIGHT_COWS_LEAVING_STAGE.PLACEHOLDER',
                },
                { unit: barnOutputMassUnit }
              )}
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.BREEDING.AVERAGE_WEIGHT_COWS_LEAVING_STAGE.TOOLTIP',
              })}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.averageWeightOfCowsLeavingTheStage`
              )}
              required
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.cowsSold`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.COWS_SOLD',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.COWS_SOLD.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.cowsSold`
              )}
              required
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.numberOfMortalitiesCows`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.NUMBER_OF_MORTALITIES_COWS',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.NUMBER_OF_MORTALITIES_COWS.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.numberOfMortalitiesCows`
              )}
              required
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.averageWeightAtMortalityCows`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.AVERAGE_WEIGHT_AT_MORTALITY_COWS',
              })}
              adornment={intl.formatMessage(
                {
                  id: 'SUSTELL.STAGE.BEEF.OUTPUT.AVERAGE_WEIGHT_AT_MORTALITY_COWS.PLACEHOLDER',
                },
                { unit: barnOutputMassUnit }
              )}
              disabled={formType === FormType.View}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.averageWeightAtMortalityCows`
              )}
              required
            />
          </DsmFieldset>
        </div>
        <div>
          <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
            <span className={classes.headingTitle}>
              {intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.CALVES',
              })}
            </span>
          </div>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.weanedCalvesToAnotherStage`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.WEANED_CALVES_TO_ANOTHER_STAGE',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.WEANED_CALVES_TO_ANOTHER_STAGE.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.WEANED_CALVES_TO_ANOTHER_STAGE.TOOLTIP',
              })}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.weanedCalvesToAnotherStage`
              )}
              required
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.averageWeightOfCalvesLeavingTheStage`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.AVERAGE_WEIGHT_OF_CALVES_LEAVING_THE_STAGE',
              })}
              adornment={intl.formatMessage(
                {
                  id: 'SUSTELL.STAGE.BEEF.OUTPUT.AVERAGE_WEIGHT_OF_CALVES_LEAVING_THE_STAGE.PLACEHOLDER',
                },
                { unit: barnOutputMassUnit }
              )}
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.AVERAGE_WEIGHT_OF_CALVES_LEAVING_THE_STAGE.TOOLTIP',
              })}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.averageWeightOfCalvesLeavingTheStage`
              )}
              required
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.weanedCalvesSold`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.WEANED_CALVES_SOLD',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.WEANED_CALVES_SOLD.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.weanedCalvesSold`
              )}
              required
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.numberOfMortalitiesCalves`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.NUMBER_OF_MORTALITIES_CALVES',
              })}
              adornment={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.NUMBER_OF_MORTALITIES_CALVES.PLACEHOLDER',
              })}
              disabled={formType === FormType.View}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.numberOfMortalitiesCalves`
              )}
              required
            />
          </DsmFieldset>
          <DsmFieldset>
            <ReactHookDsmInput
              name={`${fieldItemPrefix}.averageWeightAtMortalityCalves`}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.OUTPUT.AVERAGE_WEIGHT_AT_MORTALITY_CALVES',
              })}
              adornment={intl.formatMessage(
                {
                  id: 'SUSTELL.STAGE.BEEF.OUTPUT.AVERAGE_WEIGHT_AT_MORTALITY_CALVES.PLACEHOLDER',
                },
                { unit: barnOutputMassUnit }
              )}
              disabled={formType === FormType.View}
              type="number"
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.averageWeightAtMortalityCalves`
              )}
              required
            />
          </DsmFieldset>
        </div>
      </DsmGrid>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {
          await formContext.trigger(`stages.${itemIndex}.stageData.output`);
          handleCancel('confirm');
        }}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default DairyV2OutputDairyCowDialog;
