import { useState, type ChangeEvent } from 'react';
import { DsmGrid, DsmLoadingIndicator } from '@dsm-dcs/design-system-react';
import { API } from 'aws-amplify';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { type ClassNameMap } from '@material-ui/styles';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import {
  accessSampleDataDialogStyles,
  processAndStageStylesV2,
} from '../../../../_metronic/layout';
import DsmButtonControlGroup from '../helpers/DsmButtonControlGroup';
import {
  benchmarkRequests,
  updateUserSettings,
} from '../../../../graphql/mutations';
import { UserSettings } from '../../../../graphql/types';
import useAdobeDataLayer from '../../analytics/adobeDataLayer';

interface AccessSampleDataProps {
  onClose: () => void;
  isShowPostLogin: boolean;
  customerID: string;
}

const SAMPLE_DATA_REQUEST_TYPES = [
  { label: 'Farm', value: 'FARM' },
  { label: 'Feed', value: 'FEED' },
];

const SAMPLE_DATA_REQUEST_ANIMAL_TYPES = [
  { label: 'Pig', value: 'PIG' },
  { label: 'Salmon', value: 'SALMON_V2' },
  { label: 'Sea bass', value: 'SEA_BASS' },
  { label: 'Sea bream', value: 'SEA_BREAM' },
  { label: 'Dairy', value: 'DAIRY' },
  { label: 'Broilers', value: 'BROILER_V2' },
  { label: 'Laying hen', value: 'LAYING_HENS_V2' },
  { label: 'Turkey', value: 'TURKEY' },
];

const SAMPLE_DATA_REQUEST_REGION_FOR_PIG = [
  { label: 'Select all', value: 'ALL' },
  { label: 'Brazil', value: 'BRAZIL' },
  { label: 'France', value: 'FRANCE' },
  { label: 'Netherlands - with Processing', value: 'NETHERLANDS' },
  { label: 'Spain', value: 'SPAIN' },
];

const SAMPLE_DATA_REQUEST_REGION_FOR_BROILER = [
  { label: 'Netherlands - with Processing', value: 'NETHERLANDS' },
];

const AccessSampleData = ({
  onClose,
  isShowPostLogin,
  customerID,
}: AccessSampleDataProps) => {
  const intl = useIntl();
  const classes: ClassNameMap = processAndStageStylesV2();
  const dialogClasses: ClassNameMap = accessSampleDataDialogStyles();

  const [selectedType, setSelectedType] = useState('');
  const [selectedAnimalType, setSelectedAnimalType] = useState('');
  const [checked, setChecked] = useState(false);
  const [regions, setRegions] = useState<string[]>([]);
  const [msg, setMsg] = useState('');
  const [saving, setSaving] = useState(false);
  const history = useHistory();
  const { formSubmissionEvent } = useAdobeDataLayer();

  const closeDialog = () => {
    setSelectedType('');
    setSelectedAnimalType('');
    setMsg('');
    setChecked(false);
    onClose();
    setRegions([]);
  };

  const handleCancel = () => {
    closeDialog();
  };

  const onSuccess = () => {
    setSaving(false);
    if (selectedType === 'FEED') {
      history.push(`/customers/${customerID}/myfeeds`);
    } else if (selectedType === 'FARM') {
      history.push(`/customers/${customerID}/myfarms`);
    }
    closeDialog();
  };

  const updateDonotShowSettings = async () => {
    try {
      await (API.graphql({
        query: updateUserSettings,
        variables: {
          input: { sampleDataAccessPopup: checked },
          segment: 'BENCHMARKS',
        },
      }) as Promise<{
        data: {
          updateUserSettings: UserSettings | null;
        };
      }>);
      onSuccess();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('error: ', err);
      setSaving(false);
    }
  };

  const handleSave = async () => {
    setSaving(true);
    formSubmissionEvent('Request sample data', 'add', selectedAnimalType);
    if (selectedType && selectedAnimalType) {
      if ((selectedAnimalType === 'PIG' || selectedAnimalType === 'BROILER_V2') && regions.length === 0) {
        setMsg(
          intl.formatMessage({
            id: 'REQUEST.ACCESS.SAMPLE.DATA.SELECT.REGION.ERROR',
          })
        );
        setSaving(false);
        return;
      }
      try {
        await (API.graphql({
          query: benchmarkRequests,
          variables: {
            input: {
              type: selectedType,
              animalType: selectedAnimalType,
              regions,
            },
            customerId: customerID,
          },
        }) as Promise<{
          data: {
            updateUserSettings: UserSettings | null;
          };
        }>);
        // updating usersettings
        if (checked) {
          await updateDonotShowSettings();
        } else {
          onSuccess();
        }
      } catch (err) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (err.errors?.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const error = err.errors[0] as Error;
          if (error) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            setMsg(error.message);
          }
        }
        setSaving(false);
      }
    } else if (checked) {
      await updateDonotShowSettings();
    }
  };

  const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedType((event.target as HTMLInputElement).value);
  };

  const handleAnimalTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMsg('');
    setSelectedAnimalType((event.target as HTMLInputElement).value);
    if ((event.target as HTMLInputElement).value !== 'PIG') {
      setRegions([]);
    }
  };

  const handleCheckBoxChanges = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleRegionBoxChanges = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.checked &&
      (event.target as HTMLInputElement).value === 'ALL'
    ) {
      setRegions(['ALL']);
    } else if (event.target.checked) {
      setRegions(regions.concat([(event.target as HTMLInputElement).value]));
    } else if ((event.target as HTMLInputElement).value === 'ALL') {
      setRegions([]);
    } else {
      const updatedRegions = regions.filter(
        (r) => r !== (event.target as HTMLInputElement).value
      );
      setRegions(updatedRegions);
    }
  };

  return (
    <div>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,  @typescript-eslint/no-unsafe-assignment */}
      <DsmGrid className={classes.dsmGridOneColumnNoRowgap}>
        <div style={{ fontWeight: 500 }}>
          {intl.formatMessage({
            id: 'REQUEST.ACCESS.SAMPLE.DATA.SUB.TITLE',
          })}
        </div>
        <div>
          {intl.formatMessage({
            id: 'REQUEST.ACCESS.SAMPLE.DATA.DESCRIPTION',
          })}
        </div>
        {isShowPostLogin && (
          <div style={{ paddingTop: '10px' }}>
            {intl.formatMessage({
              id: 'REQUEST.ACCESS.SAMPLE.DATA.DESCRIPTION.SUB',
            })}
          </div>
        )}
        <FormControl>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,  @typescript-eslint/no-unsafe-assignment */}
          <FormLabel
            className={dialogClasses.formLabel}
            style={{ color: '#1b1b1b' }}
          >
            {intl.formatMessage({
              id: 'REQUEST.ACCESS.SAMPLE.DATA.SELECT.FARM_OR_FEED',
            })}
          </FormLabel>
          <RadioGroup
            value={selectedType}
            onChange={handleTypeChange}
            aria-required
          >
            {SAMPLE_DATA_REQUEST_TYPES.map((eachType) => (
              <FormControlLabel
                value={eachType.value}
                label={eachType.label}
                key={eachType.value}
                control={<Radio />}
                className={
                  /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
                  selectedType === eachType.value
                    ? dialogClasses.formControlLabelSelected
                    : dialogClasses.formControlLabel
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
        <FormControl>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,  @typescript-eslint/no-unsafe-assignment */}
          <FormLabel
            className={dialogClasses.formLabel}
            style={{ color: '#1b1b1b' }}
          >
            {intl.formatMessage({
              id: 'REQUEST.ACCESS.SAMPLE.DATA.SELECT.ANIMALTYPE',
            })}
          </FormLabel>
          <RadioGroup
            row
            value={selectedAnimalType}
            onChange={handleAnimalTypeChange}
            aria-required
          >
            {SAMPLE_DATA_REQUEST_ANIMAL_TYPES.map((eachType) => (
              <FormControlLabel
                value={eachType.value}
                label={eachType.label}
                key={eachType.value}
                control={<Radio />}
                style={{ width: '160px', paddingLeft: '30px' }}
              />
            ))}
          </RadioGroup>
        </FormControl>
        {selectedAnimalType && (selectedAnimalType === 'PIG' || selectedAnimalType === 'BROILER_V2') && (
          <FormControl>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,  @typescript-eslint/no-unsafe-assignment */}
            <FormLabel
              className={dialogClasses.formLabel}
              style={{ color: '#1b1b1b' }}
            >
              {intl.formatMessage({
                id: 'REQUEST.ACCESS.SAMPLE.DATA.SELECT.REGION',
              })}
            </FormLabel>
            <DsmGrid
              style={{ display: 'inline-flex' }}
              className={classes.dsmGridOneColumnNoRowgap}
            >
              {selectedAnimalType === 'PIG' && SAMPLE_DATA_REQUEST_REGION_FOR_PIG.map((eachType) => (
                <FormControlLabel
                  value={eachType.value}
                  label={eachType.label}
                  key={eachType.value}
                  checked={
                    regions.includes(eachType.value) || regions.includes('ALL')
                  }
                  control={<Checkbox />}
                  onChange={handleRegionBoxChanges}
                  style={{ width: '150px', paddingLeft: '10px' }}
                />
              ))}
               {selectedAnimalType === 'BROILER_V2' && SAMPLE_DATA_REQUEST_REGION_FOR_BROILER.map((eachType) => (
                <FormControlLabel
                  value={eachType.value}
                  label={eachType.label}
                  key={eachType.value}
                  checked={
                    regions.includes(eachType.value) || regions.includes('ALL')
                  }
                  control={<Checkbox />}
                  onChange={handleRegionBoxChanges}
                  style={{ width: '250px', paddingLeft: '10px' }}
                />
              ))}
            </DsmGrid>
          </FormControl>
        )}
        {isShowPostLogin && (
          <FormControlLabel
            checked={checked}
            onChange={handleCheckBoxChanges}
            control={<Checkbox />}
            label={intl.formatMessage({
              id: 'REQUEST.ACCESS.SAMPLE.DATA.DO_NOT_SHOW_CHECKBOX',
            })}
          />
        )}
        {msg && msg.length > 0 && (
          <div
            style={{ color: '#E51F22', fontSize: '14px', paddingLeft: '30px' }}
          >
            {msg}
          </div>
        )}
        {((selectedType && selectedAnimalType) || checked) && (
          <DsmButtonControlGroup
            cancelHandler={handleCancel}
            saveHandler={handleSave}
            saveLabel={intl.formatMessage({
              id: 'REQUEST.ACCESS.SAMPLE.DATA.SAVE.BUTTON',
            })}
          />
        )}
        {(selectedType.length === 0 || selectedAnimalType.length === 0) &&
          !checked && (
            <DsmButtonControlGroup
              cancelHandler={handleCancel}
              saveLabel={intl.formatMessage({
                id: 'REQUEST.ACCESS.SAMPLE.DATA.SAVE.BUTTON',
              })}
            />
          )}
        {saving && (
          <DsmLoadingIndicator
            style={{
              textAlign: 'center',
              position: 'fixed',
              left: '50%',
              top: '50%',
            }}
          />
        )}
      </DsmGrid>
    </div>
  );
};

export default AccessSampleData;
