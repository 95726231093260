import { Maybe } from '../../../../graphql/types';
import { ProcessingStage, ProcessingStageData } from '../Facility/Processing';
import {
  AnimalSourceOrigin,
  Baseline,
  BaselineFeed,
  BaselineFeedWithOrigin,
  BaselinePropertyValue,
} from './Baseline';

export interface Stage {
  id: string;
  farmId: string;
  name: string;
  type: string;
  productionSystem: string;
}

export interface ExternalSource {
  id: string;
  distanceTruck?: number;
  numberAnimals: number;
  origin: AnimalSourceOrigin;
  shrinkingRate?: number;
}
export interface InternalSource extends ExternalSource {
  farmId: string;
  farmName: string;
  originStageId: string;
  stageName: string;
  baselineRef?: string;
  animalType?: string;
  stageType: string;
}

export enum DairyV2BeddingType {
  Sand = 'SAND',
  SawDust = 'SAW_DUST',
  WheatStraw = 'WHEAT_STRAW',
  WoodShavings = 'WOOD_SHAVINGS',
}

export interface BeddingSystem {
  beddingType: DairyV2BeddingType;
  beddingAmount: BaselinePropertyValue;
}

export interface ManureManagementSystem {
  id: string;
  mmsType: DairyV2ManureManagementSystemType;
  storagePeriod?: StoragePeriod;
  share: BaselinePropertyValue;
  manurePhase?: ManurePhase;
}

export enum ManurePhase {
  LiquidSlurry = 'LIQUID_SLURRY',
  Solid = 'SOLID',
}

export enum StoragePeriod {
  ONE_MONTH = '1 month',
  THREE_MONTHS = '3 month',
  FOUR_MONTHS = '4 month',
  SIX_MONTHS = '6 month',
  TWELVE_MONTHS = '12 month',
  OVER_ONE_MONTH = 'over 1 month',
  UNDER_ONE_MONTH = 'under 1 month',
}

export enum DairyV2ManureManagementSystemType {
  // NO STORAGE
  AerobicForced = 'AEROBIC_FORCED',
  AerobicNatural = 'AEROBIC_NATURAL',
  AnaerobicHighLeakHighGastight = 'ANAEROBIC_HIGH_LEAK_HIGH_GASTIGHT',
  AnaerobicHighLeakLowGastight = 'ANAEROBIC_HIGH_LEAK_LOW_GASTIGHT',
  AnaerobicHighLeakOpen = 'ANAEROBIC_HIGH_LEAK_OPEN',
  AnaerobicLowLeakHighGastight = 'ANAEROBIC_LOW_LEAK_HIGH_GASTIGHT',
  AnaerobicLowLeakLowGastight = 'ANAEROBIC_LOW_LEAK_LOW_GASTIGHT',
  AnaerobicLowLeakOpen = 'ANAEROBIC_LOW_LEAK_OPEN',
  CompostingIntensiveWindrow = 'COMPOSTING_INTENSIVE_WINDROW',
  CompostingInVessel = 'COMPOSTING_IN_VESSEL',
  CompostingPassiveWindrow = 'COMPOSTING_PASSIVE_WINDROW',
  CompostingStaticPile = 'COMPOSTING_STATIC_PILE',
  DailySpread = 'DAILY_SPREAD',

  // OVER / UNDER 1 MONTH
  DeepBeddingActiveMixing = 'DEEP_BEDDING_ACTIVE_MIXING',
  DeepBeddingNoMixing = 'DEEP_BEDDING_NO_MIXING',

  // 1 to 12
  LiquidCover = 'LIQUID_COVER',
  LiquidCrust = 'LIQUID_CRUST',
  LiquidNoCrust = 'LIQUID_NO_CRUST',
  Pit = 'PIT',

  // NO STORAGE
  SolidAdditives = 'SOLID_ADDITIVES',
  SolidBulking = 'SOLID_BULKING',
  SolidCovered = 'SOLID_COVERED',
  SolidStorage = 'SOLID_STORAGE',
  UncoveredAnaerobicLagoon = 'UNCOVERED_ANAEROBIC_LAGOON',
}

export const availableMmsTypeForMonthlyStoragePeriods = [
  DairyV2ManureManagementSystemType.LiquidCover,
  DairyV2ManureManagementSystemType.LiquidCrust,
  DairyV2ManureManagementSystemType.LiquidNoCrust,
  DairyV2ManureManagementSystemType.Pit,
];

export const availableMmsTypeForOverOrUnderStoragePeriod = [
  DairyV2ManureManagementSystemType.DeepBeddingActiveMixing,
  DairyV2ManureManagementSystemType.DeepBeddingNoMixing,
];

export interface DairyV2Input {
  startDate: Date | string;
  cattleProductivity: 'HIGH' | 'LOW';
  averageWeightNewAnimals: number;
  internalSources: InternalSource[];
  externalSources: ExternalSource[];
}

export interface DairyV2InputDairyCow extends DairyV2Input {
  animalsPresentAtStart: number;
  animalsPresentAtEnd: number;
  averageWeightAtStart: number;
  averageWeightAtBirth?: number;
  // TODO - Attribute used for the feedIntake estimator (must check how to handle in and out mapper)
  permanencePeriod?: number;
}

export interface DairyV2InputContinuousGrowing extends DairyV2Input {
  endDate: BaselinePropertyValue;
  isStageRepeated: BaselinePropertyValue;
  numberOfRepetitions: BaselinePropertyValue;
  growingPurpose: BaselinePropertyValue;
  averageAgeAtStart: BaselinePropertyValue;
}

export interface DairyV2ContinuousGrowingFeed {
  cows: DairyV2Feed;
  calves: DairyV2Feed;
}

export interface MothersMilk {
  amount: BaselinePropertyValue;
  crudeProteinContent: BaselinePropertyValue;
  digestibility: BaselinePropertyValue;
  dryMatterContent: BaselinePropertyValue;
}

export interface FreshGrass extends MothersMilk {
  type: string;
  grossEnergyContent?: BaselinePropertyValue;
  urinaryEnergy?: BaselinePropertyValue;
  ashContent?: BaselinePropertyValue;
}

export interface DairyV2Feed {
  databaseFoundation: string;
  dietCharacterisation:
    | 'FEEDLOT_CORN'
    | 'FEEDLOT_OTHER_GRAIN'
    | 'HIGH_FORAGE_HIGH_QUALITY'
    | 'HIGH_FORAGE_LOW_QUALITY'
    | 'MIXED_RATIONS';
  averageMilkFat?: Maybe<number>;
  mothersMilk?: MothersMilk;
  compoundFeeds?: Maybe<BaselineFeed[]>;
  singleIngredients?: BaselineFeedWithOrigin[];
  freshGrass: Array<FreshGrass>;
}

export interface DairyV2HousingAndManure {
  timeInFeedlot: Maybe<BaselinePropertyValue>;
  timeInHousing: Maybe<BaselinePropertyValue>;
  timeGrazingLargeAreas: Maybe<BaselinePropertyValue>;
  timeGrazingPastures: Maybe<BaselinePropertyValue>;
  manureSystems: ManureManagementSystem[];
  beddingSystems?: BeddingSystem[];
}

export interface DairyV2Housing {
  beddingSystems?: BeddingSystem[];
  calves: Omit<DairyV2HousingAndManure, 'beddingSystems'>;
  cows: Omit<DairyV2HousingAndManure, 'beddingSystems'>;
}

export enum TargetAnimal {
  COWS = 'cows',
  CALVES = 'calves',
}

export interface DairyV2Output {
  cowsToAnotherStage: number;
  averageWeightOfCowsLeavingTheStage: number;
  cowsSold: number;
  pricePerCowSold?: number;
  numberOfMortalitiesCows: number;
  averageWeightAtMortalityCows: number;
}

export interface DairyV2OutputCalves {
  weanedCalvesToAnotherStage: number;
  averageWeightOfCalvesLeavingTheStage?: number;
  weanedCalvesSold: number;
  pricePerWeanedCalfSold?: number;
  numberOfMortalitiesCalves: number;
  averageWeightAtMortalityCalves?: number;
}

export type DairyV2OutputDairyCow = DairyV2Output & DairyV2OutputCalves;

export interface DairyV2OutputContinuousGrowing extends DairyV2Output {
  averageAge: number;
}

export interface DairyV2Emission {
  methaneEntericFermentation?: string;
  NMVOC?: string;
  methaneMms?: string;
  nitrousOxideLeaching?: string;
  nitrousOxideDirect?: string;
  nitrousOxideVolatilization?: string;
  nitrogenOxidesFromManureStorage?: string;
  amoniaStorage?: string;
  amoniaHousing?: string;
  amoniaYard?: string;
  dinitrogenStorage?: string;
  PM2_5?: string;
  PM10?: string;
  TSP?: string;
  NOP?: string;
}
export interface DairyV2DairyCowStageData {
  input: DairyV2InputDairyCow;
  feed: DairyV2Feed;
  housing: DairyV2Housing;
  output: DairyV2OutputDairyCow;
  emissions?: {
    cows?: DairyV2Emission;
    calves?: DairyV2Emission;
  };
}

export interface DairyV2ContinuousGrowingStageData {
  feed: DairyV2ContinuousGrowingFeed;
  input: DairyV2InputContinuousGrowing;
  housing: DairyV2HousingAndManure;
  output: DairyV2OutputContinuousGrowing;
  emissions?: DairyV2Emission;
}

export interface DairyV2Stage extends Stage {
  stageData: DairyV2ContinuousGrowingStageData | DairyV2DairyCowStageData | ProcessingStageData;
}

export interface DairyV2Baseline extends Baseline {
  stages: Array<DairyV2Stage | ProcessingStage>;
}
