import { AnimalType } from '../../../../../graphql/types';

const POULTRY_DEFAULTS = {
  animalType: '',
  info: {
    name: '',
    interventionCategory: '',
    description: '',
  },
  resourceUse: {
    
  },
  stages: [{
    name: '',
    id: '',
    type: '',
    stageData: {
      input: {
        startDate: '',
        endDate: '',
        durationOfProductionCycle: '',
        durationOfProductionCycle_newValue: '',
        durationOfProductionCycle_changeMetric: '',
        emptyPeriod: '',
        emptyPeriod_newValue: '',
        emptyPeriod_changeMetric: '',
        animalsPresentAtStart: '',
        animalsPresentAtStart_newValue: '',
        animalsPresentAtStart_changeMetric: '',
        animalsPresentAtEnd: '',
        animalsPresentAtEnd_newValue: '',
        animalsPresentAtEnd_changeMetric: '',
        eggsPresentAtStart: '',
        eggsPresentAtStart_newValue: '',
        eggsPresentAtStart_changeMetric: '',
        eggsPresentAtEnd: '',
        eggsPresentAtEnd_newValue: '',
        eggsPresentAtEnd_changeMetric: '',
        averageWeightAtStart: '',
        averageWeightAtStart_newValue: '',
        averageWeightAtStart_changeMetric: '',
        averageWeightAtEnd: '',
        averageWeightAtEnd_newValue: '',
        averageWeightAtEnd_changeMetric: '',
        originAnimalType: '',
        internalSources: [], // this is only used for the growing stages
        externalSources: [], // this is only used for the growing stages
        internalSource: {},
        externalSource: {},
        henInternalSource: {},
        henExternalSource: {},
        roosterInternalSource: {},
        roosterExternalSource: {},
      },
      housing: {
        resourceUse: {
          electricityUse: '',
          electricityUse_newValue: '',
          electricityUse_changeMetric: 'relative',
          electricityPhotovoltaicUse: '',
          electricityPhotovoltaicUse_newValue: '',
          electricityPhotovoltaicUse_changeMetric: 'relative',
          electricityWindUse: '',
          electricityWindUse_newValue: '',
          electricityWindUse_changeMetric: 'relative',
          dieselUse: '',
          dieselUse_newValue: '',
          dieselUse_changeMetric: 'relative',
          gasUse: '',
          gasUse_newValue: '',
          gasUse_changeMetric: 'relative',
          waterUse: '',
          waterUse_newValue: '',
          waterUse_changeMetric: 'relative',
          coalUse: '',
          coalUse_newValue: '',
          coalUse_changeMetric: 'relative',
          gasolineUse: '',
          gasolineUse_newValue: '',
          gasolineUse_changeMetric: 'relative',
          propaneOrLpgUse:'',
          propaneOrLpgUse_newValue: '',
          propaneOrLpgUse_changeMetric: 'relative'
        },
        housingType: '',
        timeInFreeRange: '',
        timeInHousing: '',
        manureSystemsAdditions: [],
        manureSystems: [],
        beddingSystems: [],
        beddingSystemsAdditions: [],
      },
      feed: {
        totalFeedIntake: '',
        compoundFeeds: [],
        singleIngredients: [],
        compoundFeedsAdditions: [],
        singleIngredientsAdditions: [],
        henFeed: {
          totalFeedIntake: '',
          compoundFeeds: [],
          singleIngredients: [],
          compoundFeedsAdditions: [],
          singleIngredientsAdditions: [],
        },
        roosterFeed: {
          totalFeedIntake: '',
          compoundFeeds: [],
          singleIngredients: [],
          compoundFeedsAdditions: [],
          singleIngredientsAdditions: [],
        },
      },
      emissions: {
        methane: '',
        methane_newValue: '',
        methane_changeMetric: 'relative',
        nitrousOxideDirect: '',
        nitrousOxideDirect_newValue: '',
        nitrousOxideDirect_changeMetric: 'relative',
        nitrousOxideIndirect: '',
        nitrousOxideIndirect_newValue: '',
        nitrousOxideIndirect_changeMetric: 'relative',
        amonia: '',
        amonia_newValue: '',
        amonia_changeMetric: 'relative',
        nitricOxide: '',
        nitricOxide_newValue: '',
        nitricOxide_changeMetric: 'relative',
        nonMethaneVolatile: '',
        nonMethaneVolatile_newValue: '',
        nonMethaneVolatile_changeMetric: 'relative',
        PM10: '',
        PM10_newValue: '',
        PM10_changeMetric: 'relative',
        PM25: '',
        PM25_newValue: '',
        PM25_changeMetric: 'relative',
        totalSuspendedParticles: '',
        totalSuspendedParticles_newValue: '',
        totalSuspendedParticles_changeMetric: 'relative',
      },
      output: {
        totalToStage: '',
        totalToStage_newValue: '',
        totalToStage_changeMetric: 'relative',
        totalEggsToStage: '',
        totalEggsToStage_newValue: '',
        totalEggsToStage_changeMetric: 'relative',
        totalHensToStage: '',
        totalHensToStage_newValue: '',
        totalHensToStage_changeMetric: 'relative',
        averageWeight: '',
        averageWeight_newValue: '',
        averageWeight_changeMetric: 'relative',
        averageEggsWeight: '',
        averageEggsWeight_newValue: '',
        averageEggsWeight_changeMetric: 'relative',
        averageHensWeight: '',
        averageHensWeight_newValue: '',
        averageHensWeight_changeMetric: 'relative',
        averageRoostersWeight: '',
        averageRoostersWeight_newValue: '',
        averageRoostersWeight_changeMetric: 'relative',
        totalSold: '',
        totalSold_newValue: '',
        totalSold_changeMetric: 'relative',
        totalEggsSold: '',
        totalEggsSold_newValue: '',
        totalEggsSold_changeMetric: 'relative',
        totalHensSold: '',
        totalHensSold_newValue: '',
        totalHensSold_changeMetric: 'relative',
        totalRoostersSold: '',
        totalRoostersSold_newValue: '',
        totalRoostersSold_changeMetric: 'relative',
        price: '',
        price_newValue: '',
        price_changeMetric: 'relative',
        priceEggs: '',
        priceEggs_newValue: '',
        priceEggs_changeMetric: 'relative',
        priceHens: '',
        priceHens_newValue: '',
        priceHens_changeMetric: 'relative',
        mortalityAtPercent: '',
        mortalityAtPercent_newValue: '',
        mortalityAtPercent_changeMetric: 'relative',
        mortalityHensAtPercent: '',
        mortalityHensAtPercent_newValue: '',
        mortalityHensAtPercent_changeMetric: 'relative',
        mortalityRoostersAtPercent: '',
        mortalityRoostersAtPercent_newValue: '',
        mortalityRoostersAtPercent_changeMetric: 'relative',
        weightAtMortality: '',
        weightAtMortality_newValue: '',
        weightAtMortality_changeMetric: 'relative',
        weightHensAtMortality: '',
        weightHensAtMortality_newValue: '',
        weightHensAtMortality_changeMetric: 'relative',
        weightRoostersAtMortality: '',
        weightRoostersAtMortality_newValue: '',
        weightRoostersAtMortality_changeMetric: 'relative',
        lossRate: '',
        lossRate_newValue: '',
        lossRate_changeMetric: 'relative',
        priceOfLossesSold: '',
        priceOfLossesSold_newValue: '',
        priceOfLossesSold_changeMetric: 'relative',
        totalEggShellsSold: '',
        totalEggShellsSold_newValue: '',
        totalEggShellsSold_changeMetric: 'relative',
      },
    }
  }]
};

const MARINE_DEFAULTS = {
  copyFrom: 'New',
  info: {
    name: '',
    interventionCategory: '',
    description: '',
  },
  resourceUse: {
  
  },
  stages: [
    {
      farmId: '',
      name: '',
      id: '',
      productionSystem: '',
      stageData: {
        output: {
          production: '',
          averageWeight: '',
          mortalities: '',
          escapees: '',
          animalBiomassStartOfYear: '',
          liveWeight: '',
          animalBiomasEndOfYear: '',
        },
        input: {
          numberOfStockedAnimals: '',
          averageLiveWeightStockedAnimals: '',
          weightOfStockedAnimals: '',
        },
        operations: {
          antifouling: '',
          materialsFarmConstruction: [
            {
              type: '',
              amount: '',
            },
          ],
          anitibioticsUse: [
            {
              type: '',
              amount: '',
            },
          ],
          liceTreatment: [
            {
              type: '',
              amount: '',
            },
          ],
          transport: [
            {
              boatName: '',
              type: '',
              amount: '',
            },
          ],
        },
        feed: {
          totalFeedIntake: '',
          efcr: '',
          compoundFeeds: [
            {
              feedType: '',
              kgPerAnimal: '',
            },
          ],
          nutrientComposition: {
            crudeProtein: '',
            crudeLipid: '',
            crudeFibre: '',
            starch: '',
            sugar: '',
            phosphorous: '',
            feedSpill: '5',
            dryMatter: '',
            solubleNitrogen: '15',
            solublePhosphorous: '15',
            solubleCarbon: '15',
            digestibleNitrogen: '85',
            digestibleCarbon: '80',
            digestiblePhosphorous: '50',
          },
        },
      },
    },
  ],
};

// eslint-disable-next-line import/prefer-default-export
export const defaultValues = {
  LAYING_HENS: {
    animalType: '',
    info: {
      name: '',
      interventionCategory: '',
      description: '',
    },
    resourceUse: {
      electricityUse: '',
      electricityUse_newValue: '',
      electricityUse_changeMetric: 'relative',
      dieselUse: '',
      dieselUse_newValue: '',
      dieselUse_changeMetric: 'relative',
      gasUse: '',
      gasUse_newValue: '',
      gasUse_changeMetric: 'relative',
      waterUse: '',
      waterUse_newValue: '',
      waterUse_changeMetric: 'relative',
    },
    stages: [
      {
        farmId: '',
        name: '',
        id: '',
        productionSystem: 'Aviary',
        stageData: {
          input: {
            purchasedAnimalsLayingHens: '',
            purchasedAnimalsLayingHens_newValue: '',
            purchasedAnimalsLayingHens_changeMetric: 'relative',
            mortalityRate: '',
            mortalityRate_newValue: '',
            mortalityRate_changeMetric: 'relative',
            averageLiveWeightMortalities: '',
            averageLiveWeightMortalities_newValue: '',
            averageLiveWeightMortalities_changeMetric: 'relative',
          },
          feed: {
            totalFeedIntake: '',
            digestibility: '',
            digestibility_newValue: '',
            digestibility_changeMetric: 'relative',
            nitrogenContent: '',
            nitrogenContent_newValue: '',
            nitrogenContent_changeMetric: 'relative',
            dryMatterContent: '',
            dryMatterContent_newValue: '',
            dryMatterContent_changeMetric: 'relative',
            compoundFeeds: [
              {
                feedType: '',
                kgPerAnimal: '',
                kgPerAnimal_newValue: '',
                kgPerAnimal_changeMetric: 'relative',
              },
            ],
            singleIngredients: [
              {
                feedType: '',
                kgPerAnimal: '',
                kgPerAnimal_newValue: '',
                kgPerAnimal_changeMetric: 'relative',
              },
            ],
            compoundFeedsAdditions: [],
            singleIngredientsAdditions: [],
          },
          manure: {
            averageAnimalsPresent: '',
            averageAnimalsPresent_newValue: '',
            averageAnimalsPresent_changeMetric: 'relative',
            manureManagementSystem: '',
            manureManagementSystem_newValue: '',
            manureManagementSystem_changeMetric: 'relative',
            solidManureStorage: '',
            solidManureStorage_newValue: '',
            solidManureStorage_changeMetric: 'relative',
            slurryManureStorage: '',
            slurryManureStorage_newValue: '',
            slurryManureStorage_changeMetric: 'relative',
            ashContent: '',
            ashContent_newValue: '',
            ashContent_changeMetric: 'relative',
            strawForBedding: '',
            strawForBedding_newValue: '',
            strawForBedding_changeMetric: 'relative',
            sawDust: '',
            sawDust_newValue: '',
            sawDust_changeMetric: 'relative',
          },
          emissions: {
            methane: '',
            methane_newValue: '',
            methane_changeMetric: 'relative',
            nitrousOxideDirect: '',
            nitrousOxideDirect_newValue: '',
            nitrousOxideDirect_changeMetric: 'relative',
            nitrousOxideIndirect: '',
            nitrousOxideIndirect_newValue: '',
            nitrousOxideIndirect_changeMetric: 'relative',
            amonia: '',
            amonia_newValue: '',
            amonia_changeMetric: 'relative',
            nitricOxide: '',
            nitricOxide_newValue: '',
            nitricOxide_changeMetric: 'relative',
            nonMethaneVolatile: '',
            nonMethaneVolatile_newValue: '',
            nonMethaneVolatile_changeMetric: 'relative',
            PM10: '',
            PM10_newValue: '',
            PM10_changeMetric: 'relative',
            PM25: '',
            PM25_newValue: '',
            PM25_changeMetric: 'relative',
            totalSuspendedParticles: '',
            totalSuspendedParticles_newValue: '',
            totalSuspendedParticles_changeMetric: 'relative',
          },
          output: {
            eggs: '',
            eggs_newValue: '',
            eggs_changeMetric: 'relative',
            liveWeight: '',
            liveWeight_newValue: '',
            liveWeight_changeMetric: 'relative',
          },
        },
      },
    ],
  },
  BROILER: {
    animalType: '',
    info: {
      name: '',
      interventionCategory: '',
      description: '',
    },
    resourceUse: {
      electricityUse: '',
      electricityUse_newValue: '',
      electricityUse_changeMetric: 'relative',
      dieselUse: '',
      dieselUse_newValue: '',
      dieselUse_changeMetric: 'relative',
      gasUse: '',
      gasUse_newValue: '',
      gasUse_changeMetric: 'relative',
      waterUse: '',
      waterUse_newValue: '',
      waterUse_changeMetric: 'relative',
    },
    stages: [
      {
        farmId: '',
        name: '',
        id: '',
        productionSystem: '',
        stageData: {
          input: {
            purchasedOneDayChickens: '',
            purchasedOneDayChickens_newValue: '',
            purchasedOneDayChickens_changeMetric: 'relative',
            mortalityRate: '',
            mortalityRate_newValue: '',
            mortalityRate_changeMetric: 'relative',
            averageLiveWeightMortalities: '',
            averageLiveWeightMortalities_newValue: '',
            averageLiveWeightMortalities_changeMetric: 'relative',
          },
          feed: {
            totalFeedIntake: '',
            digestibility: '',
            digestibility_newValue: '',
            digestibility_changeMetric: 'relative',
            nitrogenContent: '',
            nitrogenContent_newValue: '',
            nitrogenContent_changeMetric: 'relative',
            dryMatterContent: '',
            dryMatterContent_newValue: '',
            dryMatterContent_changeMetric: 'relative',
            compoundFeeds: [
              {
                feedType: '',
                kgPerAnimal: '',
                kgPerAnimal_newValue: '',
                kgPerAnimal_changeMetric: 'relative',
              },
            ],
            singleIngredients: [
              {
                feedType: '',
                kgPerAnimal: '',
                kgPerAnimal_newValue: '',
                kgPerAnimal_changeMetric: 'relative',
              },
            ],
            compoundFeedsAdditions: [],
            singleIngredientsAdditions: [],
          },
          manure: {
            averageAnimalsPresent: '',
            averageAnimalsPresent_newValue: '',
            averageAnimalsPresent_changeMetric: 'relative',
            manureManagementSystem: '',
            manureManagementSystem_newValue: '',
            manureManagementSystem_changeMetric: 'relative',
            solidManureStorage: '',
            solidManureStorage_newValue: '',
            solidManureStorage_changeMetric: 'relative',
            slurryManureStorage: '',
            slurryManureStorage_newValue: '',
            slurryManureStorage_changeMetric: 'relative',
            ashContent: '',
            ashContent_newValue: '',
            ashContent_changeMetric: 'relative',
            strawForBedding: '',
            strawForBedding_newValue: '',
            strawForBedding_changeMetric: 'relative',
            sawDust: '',
            sawDust_newValue: '',
            sawDust_changeMetric: 'relative',
          },
          emissions: {
            methane: '',
            methane_newValue: '',
            methane_changeMetric: 'relative',
            nitrousOxideDirect: '',
            nitrousOxideDirect_newValue: '',
            nitrousOxideDirect_changeMetric: 'relative',
            nitrousOxideIndirect: '',
            nitrousOxideIndirect_newValue: '',
            nitrousOxideIndirect_changeMetric: 'relative',
            amonia: '',
            amonia_newValue: '',
            amonia_changeMetric: 'relative',
            nitricOxide: '',
            nitricOxide_newValue: '',
            nitricOxide_changeMetric: 'relative',
            nonMethaneVolatile: '',
            nonMethaneVolatile_newValue: '',
            nonMethaneVolatile_changeMetric: 'relative',
            PM10: '',
            PM10_newValue: '',
            PM10_changeMetric: 'relative',
            PM25: '',
            PM25_newValue: '',
            PM25_changeMetric: 'relative',
            totalSuspendedParticles: '',
            totalSuspendedParticles_newValue: '',
            totalSuspendedParticles_changeMetric: 'relative',
          },
          output: {
            liveWeight: '',
            liveWeight_newValue: '',
            liveWeight_changeMetric: 'relative',
          },
        },
      },
    ],
  },
  PIG_FATTENING: {
    animalType: '',
    info: {
      name: '',
      interventionCategory: '',
      description: '',
    },
    resourceUse: {
      electricityUse: '',
      electricityUse_newValue: '',
      electricityUse_changeMetric: 'relative',
      dieselUse: '',
      dieselUse_newValue: '',
      dieselUse_changeMetric: 'relative',
      gasUse: '',
      gasUse_newValue: '',
      gasUse_changeMetric: 'relative',
      waterUse: '',
      waterUse_newValue: '',
      waterUse_changeMetric: 'relative',
    },
    stages: [
      {
        farmId: '',
        name: '',
        id: '',
        productionSystem: 'Aviary',
        stageData: {
          input: {
            purchasedPiglets: '',
            purchasedPiglets_newValue: '',
            purchasedPiglets_changeMetric: 'relative',
            averageLiveWeight: '',
            averageLiveWeight_newValue: '',
            averageLiveWeight_changeMetric: 'relative',
            mortalityRate: '',
            mortalityRate_newValue: '',
            mortalityRate_changeMetric: 'relative',
            averageLiveWeightMortalities: '',
            averageLiveWeightMortalities_newValue: '',
            averageLiveWeightMortalities_changeMetric: 'relative',
          },
          feed: {
            totalFeedIntake: '',
            digestibility: '',
            digestibility_newValue: '',
            digestibility_changeMetric: 'relative',
            grossEnergyIntake: '',
            grossEnergyIntake_newValue: '',
            grossEnergyIntake_changeMetric: 'relative',
            nitrogenContent: '',
            nitrogenContent_newValue: '',
            nitrogenContent_changeMetric: 'relative',
            compoundFeeds: [
              {
                feedType: '',
                kgPerAnimal: '',
                kgPerAnimal_newValue: '',
                kgPerAnimal_changeMetric: 'relative',
              },
            ],
            singleIngredients: [
              {
                feedType: '',
                kgPerAnimal: '',
                kgPerAnimal_newValue: '',
                kgPerAnimal_changeMetric: 'relative',
              },
            ],
            compoundFeedsAdditions: [],
            singleIngredientsAdditions: [],
          },
          manure: {
            averageAnimalsPresent: '',
            averageAnimalsPresent_newValue: '',
            averageAnimalsPresent_changeMetric: 'relative',
            manureManagementSystem: '',
            manureManagementSystem_newValue: '',
            manureManagementSystem_changeMetric: 'relative',
            solidManureStorage: '',
            solidManureStorage_newValue: '',
            solidManureStorage_changeMetric: 'relative',
            slurryManureStorage: '',
            slurryManureStorage_newValue: '',
            slurryManureStorage_changeMetric: 'relative',
            ashContent_changeMetric: 'relative',
            strawForBedding: '',
            strawForBedding_newValue: '',
            strawForBedding_changeMetric: 'relative',
            sawDust: '',
            sawDust_newValue: '',
            sawDust_changeMetric: 'relative',
          },
          emissions: {
            methaneEntericFermentation: '',
            methaneEntericFermentation_newValue: '',
            methaneEntericFermentation_changeMetric: 'relative',
            methane: '',
            methane_newValue: '',
            methane_changeMetric: 'relative',
            nitrousOxideDirect: '',
            nitrousOxideDirect_newValue: '',
            nitrousOxideDirect_changeMetric: 'relative',
            nitrousOxideIndirect: '',
            nitrousOxideIndirect_newValue: '',
            nitrousOxideIndirect_changeMetric: 'relative',
            amonia: '',
            amonia_newValue: '',
            amonia_changeMetric: 'relative',
            nitricOxide: '',
            nitricOxide_newValue: '',
            nitricOxide_changeMetric: 'relative',
            nonMethaneVolatile: '',
            nonMethaneVolatile_newValue: '',
            nonMethaneVolatile_changeMetric: 'relative',
            PM10: '',
            PM10_newValue: '',
            PM10_changeMetric: 'relative',
            PM25: '',
            PM25_newValue: '',
            PM25_changeMetric: 'relative',
            totalSuspendedParticles: '',
            totalSuspendedParticles_newValue: '',
            totalSuspendedParticles_changeMetric: 'relative',
          },
          output: {
            liveWeight: '',
            liveWeight_newValue: '',
            liveWeight_changeMetric: 'relative',
            inorganicNFertilizerReplacementRate: '',
            inorganicNFertilizerReplacementRate_newValue: '',
            inorganicNFertilizerReplacementRate_changeMetric: 'relative',
            inorganicPFertilizerReplacementRate: '',
            inorganicPFertilizerReplacementRate_newValue: '',
            inorganicPFertilizerReplacementRate_changeMetric: 'relative',
          },
        },
      },
    ],
  },
  DAIRY: {
    animalType: '',
    info: {
      name: '',
      interventionCategory: '',
      description: '',
    },
    resourceUse: {
      electricityUse: '',
      electricityUse_newValue: '',
      electricityUse_changeMetric: 'relative',
      dieselUse: '',
      dieselUse_newValue: '',
      dieselUse_changeMetric: 'relative',
      gasUse: '',
      gasUse_newValue: '',
      gasUse_changeMetric: 'relative',
      waterUse: '',
      waterUse_newValue: '',
      waterUse_changeMetric: 'relative',
    },
    stages: [
      {
        farmId: '',
        name: '',
        id: '',
        productionSystem: '',
        stageData: {
          feed: {
            totalFeedIntake: '',
            totalFeedIntake_changeMetric: 'set',
            digestibility: '',
            digestibility_newValue: '',
            digestibility_changeMetric: 'relative',
            grossEnergyIntake: '',
            grossEnergyIntake_newValue: '',
            grossEnergyIntake_changeMetric: 'relative',
            timeSpentGrazing: '',
            timeSpentGrazing_newValue: '',
            timeSpentGrazing_changeMetric: 'relative',
            timeSpentInOpenYardAreas: '',
            timeSpentInOpenYardAreas_newValue: '',
            timeSpentInOpenYardAreas_changeMetric: 'relative',
            timeSpentInBuildings: '',
            timeSpentInBuildings_newValue: '',
            timeSpentInBuildings_changeMetric: 'relative',
            crudeProteinContent: '',
            crudeProteinContent_newValue: '',
            crudeProteinContent_changeMetric: 'relative',
            percentageOfSilage: '',
            percentageOfSilage_newValue: '',
            percentageOfSilage_changeMetric: 'relative',
            compoundFeeds: [
              {
                feedType: '',
                kgPerAnimal: '',
                kgPerAnimal_newValue: '',
                kgPerAnimal_changeMetric: 'relative',
              },
            ],
            singleIngredients: [
              {
                feedType: '',
                kgPerAnimal: '',
                kgPerAnimal_newValue: '',
                kgPerAnimal_changeMetric: 'relative',
              },
            ],
          },
          manure: {
            averageAnimalsPresent: '',
            averageAnimalsPresent_newValue: '',
            averageAnimalsPresent_changeMetric: 'relative',
            manureManagementSystem: '',
            manureManagementSystem_newValue: '',
            manureManagementSystem_changeMetric: 'relative',
            solidManureStorage: '',
            solidManureStorage_newValue: '',
            solidManureStorage_changeMetric: 'relative',
            slurryManureStorage: '',
            slurryManureStorage_newValue: '',
            slurryManureStorage_changeMetric: 'relative',
            strawForBedding: '',
            strawForBedding_newValue: '',
            strawForBedding_changeMetric: 'relative',
            sawDust: '',
            sawDust_newValue: '',
            sawDust_changeMetric: 'relative',
          },
          emissions: {
            methaneEntericFermentation: '',
            methaneEntericFermentation_newValue: '',
            methaneEntericFermentation_changeMetric: 'relative',
            methane: '',
            methane_newValue: '',
            methane_changeMetric: 'relative',
            nitrousOxideDirect: '',
            nitrousOxideDirect_newValue: '',
            nitrousOxideDirect_changeMetric: 'relative',
            nitrousOxideIndirect: '',
            nitrousOxideIndirect_newValue: '',
            nitrousOxideIndirect_changeMetric: 'relative',
            amonia: '',
            amonia_newValue: '',
            amonia_changeMetric: 'relative',
            nitricOxide: '',
            nitricOxide_newValue: '',
            nitricOxide_changeMetric: 'relative',
            nonMethaneVolatile: '',
            nonMethaneVolatile_newValue: '',
            nonMethaneVolatile_changeMetric: 'relative',
            nonMethaneVolatileSilage: '',
            nonMethaneVolatileSilage_newValue: '',
            nonMethaneVolatileSilage_changeMetric: 'relative',
            PM25: '',
            PM25_newValue: '',
            PM25_changeMetric: 'relative',
            is3NOPSelected: false,
            supplement3NOP: {
              _3nopDosePerCowPerDay: '',
              _3nopDosePerCowPerDay_newValue: '',
              _3nopDosePerCowPerDay_changeMetric: 'relative',
              dmiAmountPerKg: '',
              dmiAmountPerKg_newValue: '',
              dmiAmountPerKg_changeMetric: 'relative',
              fatAmountPercentage: '',
              fatAmountPercentage_newValue: '',
              fatAmountPercentage_changeMetric: 'relative',
              ndfAmountPercentage: '',
              ndfAmountPercentage_newValue: '',
              ndfAmountPercentage_changeMetric: 'relative',
              numberOfCowsCovered: '',
              numberOfCowsCovered_newValue: '',
              numberOfCowsCovered_changeMetric: 'relative',
              calculatedResult: '',
            },
          },
          output: {
            milk: '',
            milk_newValue: '',
            milk_changeMetric: 'relative',
            proteinContent: '',
            proteinContent_newValue: '',
            proteinContent_changeMetric: 'relative',
            fatContent: '',
            fatContent_newValue: '',
            fatContent_changeMetric: 'relative',
            liveWeight: '',
            liveWeight_newValue: '',
            liveWeight_changeMetric: 'relative',
          },
        },
      },
    ],
  },
  SALMON: {
    animalType: '',
    info: {
      name: '',
      interventionCategory: '',
      description: '',
    },
    resourceUse: {
      electricityUse: '',
      electricityUse_newValue: '',
      electricityUse_changeMetric: 'relative',
      waterUse: '',
      waterUse_newValue: '',
      waterUse_changeMetric: 'relative',
      waterSource: '',
      waterSource_newValue: '',
      waterSource_changeMetric: 'set',
    },
    stages: [
      {
        farmId: '',
        name: '',
        id: '',
        productionSystem: '',
        index: '',
        stageData: {
          input: {
            numberOfStockedAnimals: '',
            numberOfStockedAnimals_newValue: '',
            numberOfStockedAnimals_changeMetric: 'relative',
            averageLiveWeightStockedAnimals: '',
            averageLiveWeightStockedAnimals_newValue: '',
            averageLiveWeightStockedAnimals_changeMetric: 'relative',
            weightOfStockedAnimals: '',
            weightOfStockedAnimals_newValue: '',
            weightOfStockedAnimals_changeMetric: 'relative',
          },
          feed: {
            totalFeedIntake: '',
            efcr: '',
            efcr_newValue: '',
            efcr_changeMetric: 'relative',
            compoundFeeds: [
              {
                feedType: '',
                kgPerAnimal: '',
                kgPerAnimal_newValue: '',
                kgPerAnimal_changeMetric: 'relative',
              },
            ],
            compoundFeedsAdditions: [],
            requiredNutritionalContent: {
              crude_protein: '',
              crude_protein_newValue: '',
              crude_protein_changeMetric: 'relative',
              crude_lipid: '',
              crude_lipid_newValue: '',
              crude_lipid_changeMetric: 'relative',
              crude_fibre: '',
              crude_fibre_newValue: '',
              crude_fibre_changeMetric: 'relative',
              starch: '',
              starch_newValue: '',
              starch_changeMetric: 'relative',
              sugar: '',
              sugar_newValue: '',
              sugar_changeMetric: 'relative',
              phosphorus: '',
              phosphorus_newValue: '',
              phosphorus_changeMetric: 'relative',
              dm_feed: '',
              dm_feed_newValue: '',
              dm_feed_changeMetric: 'relative',
            },
            optionalNutritionalContent: {
              gross_energy: '',
              gross_energy_newValue: '',
              gross_energy_changeMetric: 'relative',

              digestible_energy: '',
              digestible_energy_newValue: '',
              digestible_energy_changeMetric: 'relative',

              digestible_nitrogen: '',
              digestible_nitrogen_newValue: '',
              digestible_nitrogen_changeMetric: 'relative',

              digestible_phosphorus: '',
              digestible_phosphorus_newValue: '',
              digestible_phosphorus_changeMetric: 'relative',

              digestible_carbon: '',
              digestible_carbon_newValue: '',
              digestible_carbon_changeMetric: 'relative',

              soluble_nitrogen: '',
              soluble_nitrogen_newValue: '',
              soluble_nitrogen_changeMetric: 'relative',

              soluble_phosphorus: '',
              soluble_phosphorus_newValue: '',
              soluble_phosphorus_changeMetric: 'relative',

              soluble_carbon: '',
              soluble_carbon_newValue: '',
              dsoluble_carbon_changeMetric: 'relative',

              feed_spill: '',
              feed_spill_newValue: '',
              feed_spill_changeMetric: 'relative',
            },
          },
          operations: {
            antifouling: '',
            antifouling_newValue: '',
            antifouling_changeMetric: 'relative',
            materialsFarmConstruction: [
              {
                type: '',
                amount: '',
                amount_newValue: '',
                amount_changeMetric: 'relative',
              },
            ],
            materialsFarmConstructionAddition: [],
            anitibioticsUse: [
              {
                type: '',
                amount: '',
                amount_newValue: '',
                amount_changeMetric: 'relative',
              },
            ],
            anitibioticsUseAddition: [],
            liceTreatment: [
              {
                type: '',
                amount: '',
                amount_newValue: '',
                amount_changeMetric: 'relative',
              },
            ],
            liceTreatmentAddition: [],
            transport: [
              {
                boatName: '',
                type: '',
                amount: '',
                amount_newValue: '',
                amount_changeMetric: 'relative',
              },
            ],
            transportAddition: [],
          },
          output: {
            production: '',
            production_newValue: '',
            production_changeMetric: 'relative',

            averageWeight: '',
            averageWeight_newValue: '',
            averageWeight_changeMetric: 'relative',

            mortalities: '',
            mortalitiest_newValue: '',
            mortalities_changeMetric: 'relative',

            escapees: '',
            escapees_newValue: '',
            escapees_changeMetric: 'relative',

            animalBiomassStartOfYear: '',
            animalBiomassStartOfYear_newValue: '',
            animalBiomassStartOfYear_changeMetric: 'relative',

            liveWeight: '',
            liveWeight_newValue: '',
            liveWeight_changeMetric: 'relative',

            animalBiomasEndOfYear: '',
            animalBiomasEndOfYear_newValue: '',
            animalBiomasEndOfYear_changeMetric: 'relative',
          },
        },
      },
    ],
  },
  SALMON_V2: {
    animalType: AnimalType.SalmonV2,
    ...MARINE_DEFAULTS,
  },
  SEA_BASS: {
    animalType: AnimalType.SeaBass,
    ...MARINE_DEFAULTS,
  },
  SEA_BREAM: {
    animalType: AnimalType.SeaBream,
    ...MARINE_DEFAULTS,
  },
  LAYING_HENS_V2: {
    animalType: AnimalType.LayingHensV2,
    ...POULTRY_DEFAULTS
  },
  BROILER_V2: {
    animalType: AnimalType.BroilerV2,
    ...POULTRY_DEFAULTS
  },
  TURKEY: {
    animalType: AnimalType.Turkey,
    ...POULTRY_DEFAULTS
  },
  PIG: {
    animalType: '',
    info: {
      name: '',
      interventionCategory: '',
      description: '',
    },
    resourceUse: {
      
    },
    stages: [
      {
        farmId: '',
        name: '',
        id: '',
        productionSystem: '',
        stageData: {
          input: {
            optionalInput: {
              pigsPresentAtStart: '',
              pigsPresentAtStart_changeMetric: 'relative',

              pigsPresentAtEnd: '',
              pigsPresentAtEnd_changeMetric: 'relative',

              averageWeightPigsStart: '',
              averageWeightPigsStart_changeMetric: 'relative',

              averageWeightPigsEnd: '',
              averageWeightPigsEnd_changeMetric: 'relative',

              averageAgePigsStart: '',
              averageAgePigsStart_changeMetric: 'relative',

              averageAgePigsEnd: '',
              averageAgePigsEnd_changeMetric: 'relative',
            },
            stockPresent: false,
            pigsPresentAtStart: '',
            pigsPresentAtStart_newValue: '',
            pigsPresentAtStart_changeMetric: 'relative',
            pigsPresentAtEnd: '',
            pigsPresentAtEnd_newValue: '',
            pigsPresentAtEnd_changeMetric: 'relative',
          },
          stockPresent: false,
          pigsPresentAtStart: '',
          pigsPresentAtStart_newValue: '',
          pigsPresentAtStart_changeMetric: 'relative',
          pigsPresentAtEnd: '',
          pigsPresentAtEnd_newValue: '',
          pigsPresentAtEnd_changeMetric: 'relative',
          internalSourcesAdditions:[
            // {
            //   id:'',
            //   numberOfNewPigs:'',
            //   originStageId:'',
            //   distanceTransport:'',
            //   hasBreedingInput:'false', // used internally in input stage component to enable visible validation
            //   originAnimalType:'' // used internally in input stage component to provide UI and enable visible validation
            // }
          ],
          externalSourcesAdditions:[
            // {
            //   id:'',
            //   numberOfNewPigs:'',
            //   averageAgeOfPigs:'',
            //   averageWightOfPigs:'',
            //   distanceTransport:''
            // }
          ],
          originAnimalType: '',
        },
        feed: {
          totalFeedIntake: '',
          compoundFeeds: [
            // {
            // feedType: '',
            // kgPerAnimal: '',
            // kgPerAnimal_newValue: '',
            // kgPerAnimal_changeMetric: 'relative',
            // }
          ],
          singleIngredients: [
            // {
            // feedType: '',
            // kgPerAnimal: '',
            // origin: '',
            // kgPerAnimal_newValue: '',
            // kgPerAnimal_changeMetric: 'relative',
            // }
          ],
          compoundFeedsAdditions: [],
          singleIngredientsAdditions: [],
        },
        housing: {
          surfaceType: '',
          manureForm: '',
          Nreplacement: '',
          Nreplacement_newValue: '',
          Nreplacement_changeMetric: 'relative',
          Preplacement: '',
          Preplacement_newValue: '',
          Preplacement_changeMetric: 'relative',
          manureSystemsAdditions: [],
          manureSystems: [], //must be empty
          beddingSystems: [
            // {
            //   beddingType: '',
            //   beddingAmount: '',
            //   beddingAmount_newValue: '',
            //   beddingAmount_changeMetric: 'relative',
            // }
          ],
          beddingSystemsAdditions: [],
          materials: [
            // {
            //   materialType: '',
            //   materialAmount: '',
            //   materialAmount_newValue: '',
            //   materialAmount_changeMetric: 'relative',
            // }
          ],
          materialsAdditions: [],
        },
        emissions: {
          methaneEntericFermentation: '',
          methaneEntericFermentation_newValue: '',
          methaneEntericFermentation_changeMetric: 'relative',
          methane: '',
          methane_newValue: '',
          methane_changeMetric: 'relative',
          nitrousOxideDirect: '',
          nitrousOxideDirect_newValue: '',
          nitrousOxideDirect_changeMetric: 'relative',
          nitrousOxideIndirect: '',
          nitrousOxideIndirect_newValue: '',
          nitrousOxideIndirect_changeMetric: 'relative',
          amonia: '',
          amonia_newValue: '',
          amonia_changeMetric: 'relative',
          nitricOxide: '',
          nitricOxide_newValue: '',
          nitricOxide_changeMetric: 'relative',
          nonMethaneVolatile: '',
          nonMethaneVolatile_newValue: '',
          nonMethaneVolatile_changeMetric: 'relative',
          PM10: '',
          PM10_newValue: '',
          PM10_changeMetric: 'relative',
          PM25: '',
          PM25_newValue: '',
          PM25_changeMetric: 'relative',
          totalSuspendedParticles: '',
          totalSuspendedParticles_newValue: '',
          totalSuspendedParticles_changeMetric: 'relative',
        },
        output: {
          averageAgePigs: '',
          averageAgePigs_newValue: '',
          averageAgePigs_changeMetric: 'relative',
          averageWeightPigs: '',
          averageWeightPiglets_newValue: '',
          averageWeightPiglets_changeMetric: 'relative',
          mortalityPigs: '',
          mortalityPigs_newValue: '',
          mortalityPigs_changeMetric: 'relative',
          pigsToStage: '',
          pigsToStage_newValue: '',
          pigsToStage_changeMetric: 'relative',
          soldPigs: '',
          soldPigs_newValue: '',
          soldPigs_changeMetric: 'relative',
          priceSoldPigs: '',
          priceSoldPiglets_newValue: '',
          priceSoldPiglets_changeMetric: 'relative',
          mortalityPiglets: '',
          mortalityPiglets_newValue: '',
          mortalityPiglets_changeMetric: 'relative',
          pigletsToStage: '',
          pigletsToStage_newValue: '',
          pigletsToStage_changeMetric: 'relative',
          soldPiglets: '',
          soldPiglets_newValue: '',
          soldPiglets_changeMetric: 'relative',
          priceSoldPiglets: '',
          // priceSoldPiglets_newValue: '',
          // priceSoldPiglets_changeMetric: 'relative',
        },
      },
    ],
  },
};
