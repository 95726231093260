import { FC } from 'react';

import { DsmGrid } from '@dsm-dcs/design-system-react';
import { AnimalType } from '../../../../graphql/types';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../_metronic/layout/_core/MaterialThemeProvider';
import { CSSClassesList } from '../../helpers/helperTypes';
import { DialogContainer } from '../../components/FarmFlow/CommonDataParts/DialogContainer2';
import { isPoultry, isBeef, isPig } from '../../helpers/animals';
import lookupValues from '../../helpers/lookupValues';
import WarningDialog from '../../components/helpers/WarningDialog';

type StageSelectionDefinitionsDialogProps = {
  open: boolean;
  animalType: AnimalType;
  handleClose: () => void;
};

// a static dialog loading and showing definitions for stage types
const StageSelectionDefinitionsDialog: FC<
  StageSelectionDefinitionsDialogProps
> = ({ open = false, animalType, handleClose }) => {
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const animalTypeKey: string = isPoultry(animalType) ? 'POULTRY' : animalType;
  const animalTypeDefinitions: string[] = lookupValues.stageDefinitions[
    animalTypeKey as keyof typeof lookupValues.stageDefinitions
  ];
  const isSupportedAnimalType = () => 
    isPoultry(animalType) || 
    isPig(animalType) || 
    isBeef(animalType);

  return (
    <>
      {isSupportedAnimalType() ? (
        <DialogContainer
          formVisible={open}
          variant="descriptionbox"
          handleClose={handleClose}
          withIcon={false}
        >
          <DsmGrid
            className={classes.dsmGridTwoColumn3_NoRowSpacingWithLines}
            style={{ overflow: 'unset' }}
          >
            <div>
              <h6>
                {intl.formatMessage({
                  id: 'SUSTELL.ANIMAL.SYSTEM.STAGE.SELECTION.DEFINITION.ANIMAL_SYSTEM_TYPE',
                })}
              </h6>
            </div>
            <div>
              <h6>
                {intl.formatMessage({
                  id: 'SUSTELL.ANIMAL.SYSTEM.STAGE.SELECTION.DEFINITION.ANIMAL_SYSTEM_STAGE_TYPE',
                })}
              </h6>
            </div>
            <div>
              <h6>
                {intl.formatMessage({
                  id: 'SUSTELL.ANIMAL.SYSTEM.STAGE.SELECTION.DEFINITION.ANIMAL_SYSTEM_DESCRIPTION',
                })}
              </h6>
            </div>
          </DsmGrid>
          <DsmGrid
            className={classes.dsmGridTwoColumn3_NoRowSpacingWithLines}
            style={{ overflow: 'unset' }}
          >
            {animalTypeDefinitions.map((item, _) => (
              <>
                <div>
                  {intl.formatMessage({
                    id: `SUSTELL.ANIMAL.SYSTEM.STAGE.SELECTION.DEFINITION.${animalTypeKey}.ANIMAL_SYSTEM_TYPE.${item}`,
                  })}
                </div>
                <div>
                  {intl.formatMessage({
                    id: `SUSTELL.ANIMAL.SYSTEM.STAGE.SELECTION.DEFINITION.${animalTypeKey}.ANIMAL_SYSTEM_STAGE_TYPE.${item}`,
                  })}
                </div>
                <div>
                  {intl.formatMessage(
                    {
                      id: `SUSTELL.ANIMAL.SYSTEM.STAGE.SELECTION.DEFINITION.${animalTypeKey}.ANIMAL_SYSTEM_DESCRIPTION.${item}`,
                    },
                    { br: <br /> }
                  )}
                </div>
              </>
            ))}
          </DsmGrid>
        </DialogContainer>
      ) : (
        <WarningDialog
          isOpen={open}
          closeModal={handleClose}
          title={'SUSTELL.ANIMAL.SYSTEM.STAGE.SELECTION.DEFINITION.COMING_SOON.TITLE'}
          body={'SUSTELL.ANIMAL.SYSTEM.STAGE.SELECTION.DEFINITION.COMING_SOON.BODY'}
        />
      )}
    </>
  );
};

export default StageSelectionDefinitionsDialog;
