export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: Date;
  AWSDateTime: Date;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export type AnimalInput = {
  name?: InputMaybe<Scalars['String']>;
  originId: Scalars['ID'];
  quantity: Scalars['Float'];
  transport: Array<Transport>;
};

export type AnimalProcessing = {
  facilities: Array<Facility>;
};

export type AnimalsIncoming = {
  origin: InputAnimalOrigin;
  originOutputName?: InputMaybe<Scalars['String']>;
  originStageId?: InputMaybe<Scalars['ID']>;
  transport?: InputMaybe<Array<InputMaybe<Transport>>>;
  weight: Scalars['Float'];
};

export type AnimalsOutgoing = {
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  proteinContent?: InputMaybe<Scalars['Float']>;
  soldExternally: Scalars['Boolean'];
  weight: Scalars['Float'];
};

export type AuxiliaryInputs = {
  chemicals?: InputMaybe<Array<Chemical>>;
  refrigerants?: InputMaybe<Array<Refrigerant>>;
  wastewaterAmount: Scalars['Float'];
};

export type Chemical = {
  amount: Scalars['Float'];
  type: ChemicalType;
};

export enum ChemicalType {
  HydrochloricAcid = 'HYDROCHLORIC_ACID',
  HydrogenPeroxide = 'HYDROGEN_PEROXIDE',
  PotassiumHydroxide = 'POTASSIUM_HYDROXIDE',
  SodiumHydroxide = 'SODIUM_HYDROXIDE',
  SodiumHypochlorite = 'SODIUM_HYPOCHLORITE'
}

export type CompoundFeed = {
  compoundFeedId: Scalars['ID'];
  energyCarriers?: InputMaybe<Array<EnergyCarrier>>;
  feedmillLocation: Scalars['String'];
  ingredientUses: Array<IngredientUse>;
  name: Scalars['String'];
  outboundTransport?: InputMaybe<Array<Transport>>;
  water?: InputMaybe<Array<Water>>;
};

export type CompoundFeedUse = {
  crudeProteinContent: Scalars['Float'];
  id: Scalars['ID'];
  importedImpactId?: InputMaybe<Scalars['ID']>;
  quantity: Scalars['Float'];
};

export enum DatabaseFoundation {
  Afp6 = 'AFP6',
  Gfli = 'GFLI'
}

export type EmissionResult = {
  __typename?: 'EmissionResult';
  magnitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
};

export type EnergyCarrier = {
  amount: Scalars['Float'];
  type: EnergyCarrierType;
};

export enum EnergyCarrierType {
  Coal = 'COAL',
  Diesel = 'DIESEL',
  Electricity = 'ELECTRICITY',
  ElectricityPhotovoltaic = 'ELECTRICITY_PHOTOVOLTAIC',
  ElectricityWind = 'ELECTRICITY_WIND',
  Gasoline = 'GASOLINE',
  NaturalGas = 'NATURAL_GAS',
  PropaneOrLpg = 'PROPANE_OR_LPG'
}

export type Facility = {
  auxiliaryInputs: AuxiliaryInputs;
  energyCarriers: Array<EnergyCarrier>;
  geography: Scalars['String'];
  id: Scalars['ID'];
  inputs: FacilityInputs;
  name?: InputMaybe<Scalars['String']>;
  outputs: FacilityOutputs;
  water: Array<Water>;
};

export type FacilityInputs = {
  animalInputs: Array<AnimalInput>;
  averageWeight: Scalars['Float'];
  totalLiveWeightEntering: Scalars['Float'];
};

export type FacilityOutputs = {
  cat1And2MaterialAndWaste: OutputSpecification;
  cat3HidesAndSkins: OutputSpecification;
  cat3SlaughterByProducts: OutputSpecification;
  foodGradeBones: OutputSpecification;
  foodGradeFat: OutputSpecification;
  freshMeatAndEdibleOffal: OutputSpecification;
};

export type FeedingProgram = {
  compoundFeeds?: InputMaybe<Array<InputMaybe<CompoundFeedUse>>>;
  fertilizers?: InputMaybe<Array<InputMaybe<Fertilizer>>>;
  singleIngredientUses?: InputMaybe<Array<InputMaybe<SingleIngredientUse>>>;
};

export type Fertilizer = {
  quantity: Scalars['Float'];
  type: FertilizerType;
};

export enum FertilizerType {
  Ammonia = 'AMMONIA',
  AmmoniumNitrate = 'AMMONIUM_NITRATE',
  AmmoniumSulfate = 'AMMONIUM_SULFATE',
  CalciumAmmoniumNitrate = 'CALCIUM_AMMONIUM_NITRATE',
  DiAmmoniumPhosphate = 'DI_AMMONIUM_PHOSPHATE',
  LiquidUreaAmmoniumNitrateSolution = 'LIQUID_UREA_AMMONIUM_NITRATE_SOLUTION',
  NpkCompound = 'NPK_COMPOUND',
  PhosphateRock = 'PHOSPHATE_ROCK',
  PkCompound = 'PK_COMPOUND',
  PotassiumChloride = 'POTASSIUM_CHLORIDE',
  PotassiumSulfate = 'POTASSIUM_SULFATE',
  SiliconDioxide = 'SILICON_DIOXIDE',
  SingleSuperphosphate = 'SINGLE_SUPERPHOSPHATE',
  SodiumNitrate = 'SODIUM_NITRATE',
  TripleSuperphosphate = 'TRIPLE_SUPERPHOSPHATE',
  Urea = 'UREA'
}

export type GrowingFarm = {
  age: Scalars['Float'];
  chemicals?: InputMaybe<Array<InputMaybe<ShrimpProductionChemical>>>;
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  geography: Scalars['String'];
  ice?: InputMaybe<Array<InputMaybe<Ice>>>;
  materials?: InputMaybe<Array<InputMaybe<GrowingStageMaterial>>>;
  stages?: InputMaybe<Array<InputMaybe<GrowingStage>>>;
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type GrowingStage = {
  chemicals?: InputMaybe<Array<InputMaybe<ShrimpProductionChemical>>>;
  emptyPeriod?: InputMaybe<Scalars['Float']>;
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  feedingProgram: FeedingProgram;
  ice?: InputMaybe<Array<InputMaybe<Ice>>>;
  id: Scalars['ID'];
  input: Array<AnimalsIncoming>;
  materials?: InputMaybe<Array<InputMaybe<GrowingStageMaterial>>>;
  name?: InputMaybe<Scalars['String']>;
  output: Array<AnimalsOutgoing>;
  pondProductionArea: Scalars['Float'];
  pondWaterProperties?: InputMaybe<PondWaterProperties>;
  stageDuration: Scalars['Float'];
  stock?: InputMaybe<Stock>;
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type GrowingStageMaterial = {
  amount: Scalars['Float'];
  lifeTime: Scalars['Float'];
  transport?: InputMaybe<Array<InputMaybe<Transport>>>;
  type: GrowingStageMaterialType;
};

export enum GrowingStageMaterialType {
  CastIron = 'CAST_IRON',
  ConcreteBlock = 'CONCRETE_BLOCK',
  Gravel = 'GRAVEL',
  HdpeGranulates = 'HDPE_GRANULATES',
  LdpeGranulates = 'LDPE_GRANULATES',
  LowAlloyedSteel = 'LOW_ALLOYED_STEEL',
  Nylon = 'NYLON',
  Pvc = 'PVC',
  Rebar = 'REBAR',
  Wood = 'WOOD'
}

export type HatcheryFarm = {
  age: Scalars['Float'];
  chemicals?: InputMaybe<Array<InputMaybe<ShrimpProductionChemical>>>;
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  geography: Scalars['String'];
  ice?: InputMaybe<Array<InputMaybe<Ice>>>;
  materials?: InputMaybe<Array<InputMaybe<HatcheryStageMaterial>>>;
  stages?: InputMaybe<Array<InputMaybe<HatcheryStage>>>;
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type HatcheryFeedingProgram = {
  compoundFeeds?: InputMaybe<Array<InputMaybe<CompoundFeedUse>>>;
  fertilizers?: InputMaybe<Array<InputMaybe<Fertilizer>>>;
  naturalFeed?: InputMaybe<Array<InputMaybe<NaturalFeed>>>;
  singleIngredientUses?: InputMaybe<Array<InputMaybe<SingleIngredientUse>>>;
};

export type HatcheryStage = {
  chemicals?: InputMaybe<Array<InputMaybe<ShrimpProductionChemical>>>;
  emptyPeriod?: InputMaybe<Scalars['Float']>;
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  feedingProgram: HatcheryFeedingProgram;
  ice?: InputMaybe<Array<InputMaybe<Ice>>>;
  id: Scalars['ID'];
  materials?: InputMaybe<Array<InputMaybe<HatcheryStageMaterial>>>;
  name?: InputMaybe<Scalars['String']>;
  output: Array<AnimalsOutgoing>;
  pondProductionArea: Scalars['Float'];
  pondWaterProperties?: InputMaybe<PondWaterProperties>;
  stageDuration: Scalars['Float'];
  stock?: InputMaybe<Stock>;
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type HatcheryStageMaterial = {
  amount: Scalars['Float'];
  lifeTime: Scalars['Float'];
  transport?: InputMaybe<Array<InputMaybe<Transport>>>;
  type: HatcheryStageMaterialType;
};

export enum HatcheryStageMaterialType {
  Aluminium = 'ALUMINIUM',
  CastIron = 'CAST_IRON',
  ConcreteBlock = 'CONCRETE_BLOCK',
  Gravel = 'GRAVEL',
  HdpeGranulates = 'HDPE_GRANULATES',
  LdpeGranulates = 'LDPE_GRANULATES',
  LowAlloyedSteel = 'LOW_ALLOYED_STEEL',
  Nylon = 'NYLON',
  Pvc = 'PVC',
  Rebar = 'REBAR',
  Wood = 'WOOD'
}

export type Ice = {
  amount: Scalars['Float'];
  type: IceType;
};

export enum IceType {
  Ice = 'ICE'
}

export type Impact = {
  category: Scalars['String'];
  quantity: Scalars['Float'];
  unit: Scalars['String'];
};

export type ImpactAssessmentResult = {
  __typename?: 'ImpactAssessmentResult';
  category?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  unit?: Maybe<Scalars['String']>;
};

export type ImportedImpact = {
  id: Scalars['ID'];
  impacts: Array<Impact>;
  productName: Scalars['String'];
};

export type Ingredient = {
  addonType?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  origin: Scalars['String'];
  tags?: InputMaybe<Array<InputMaybe<TagInput>>>;
};

export type IngredientUse = {
  inboundTransport?: InputMaybe<Array<InputMaybe<Transport>>>;
  ingredient: Ingredient;
  quantity: Scalars['Float'];
};

export enum InputAnimalOrigin {
  BackgroundData = 'BACKGROUND_DATA',
  ProductionSystem = 'PRODUCTION_SYSTEM'
}

export type NaturalFeed = {
  crudeProteinContent?: InputMaybe<Scalars['Float']>;
  origin?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Float'];
  transport?: InputMaybe<Array<InputMaybe<Transport>>>;
  type: NaturalFeedType;
};

export enum NaturalFeedType {
  Artemia = 'ARTEMIA',
  NaturalFeedFish = 'NATURAL_FEED_FISH',
  Yeast = 'YEAST'
}

export type OutputSpecification = {
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  packaging?: InputMaybe<Packaging>;
  price?: InputMaybe<Scalars['Float']>;
  weight: Scalars['Float'];
};

export type Packaging = {
  primaryPackaging?: InputMaybe<Array<PrimaryPackaging>>;
  secondaryPackaging?: InputMaybe<Array<SecondaryPackaging>>;
  tertiaryPackaging?: InputMaybe<Array<TertiaryPackaging>>;
};

export type PondWaterProperties = {
  salinity: Salinity;
  waterIn?: InputMaybe<WaterProperties>;
  waterOut?: InputMaybe<WaterProperties>;
};

export type PrimaryPackaging = {
  amount: Scalars['Float'];
  type: PrimaryPackagingType;
};

export enum PrimaryPackagingType {
  AluminiumFoil = 'ALUMINIUM_FOIL',
  HdpeGranulates = 'HDPE_GRANULATES',
  LdpeGranulates = 'LDPE_GRANULATES',
  Paper = 'PAPER',
  Polyester = 'POLYESTER',
  Polypropylene = 'POLYPROPYLENE',
  Polystyrene = 'POLYSTYRENE'
}

export type ProductResultNode = {
  __typename?: 'ProductResultNode';
  aggregatedImpactAssessmentResults?: Maybe<Array<ImpactAssessmentResult>>;
  impactAssessmentResults?: Maybe<Array<ImpactAssessmentResult>>;
  productName?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  upstreamProductNodes?: Maybe<Array<ProductResultNode>>;
};

export type ProductionSystem = {
  animalProcessing?: InputMaybe<AnimalProcessing>;
  growingFarms?: InputMaybe<Array<InputMaybe<GrowingFarm>>>;
  hatcheryFarms?: InputMaybe<Array<InputMaybe<HatcheryFarm>>>;
  species?: InputMaybe<ShrimpSpecies>;
};

export type ProductionSystemInput = {
  compoundFeeds?: InputMaybe<Array<CompoundFeed>>;
  databaseFoundation: DatabaseFoundation;
  importedImpacts?: InputMaybe<Array<InputMaybe<ImportedImpact>>>;
  productionSystem: ProductionSystem;
};

export type ProductionSystemResult = {
  __typename?: 'ProductionSystemResult';
  emissionResults?: Maybe<Array<StageEmissionResult>>;
  impactResults?: Maybe<Array<ProductResultNode>>;
  warnings?: Maybe<Array<Maybe<Warning>>>;
};

export type Query = {
  __typename?: 'Query';
  getResultByProductionSystemInput?: Maybe<ProductionSystemResult>;
  getValidationCodes?: Maybe<ValidationCodes>;
  /** @deprecated getValidationErrorCodes is deprecated. Use getValidationCodes instead. */
  getValidationErrorCodes?: Maybe<Array<Maybe<ValidationCode>>>;
};


export type QueryGetResultByProductionSystemInputArgs = {
  productionSystemInput: ProductionSystemInput;
};

export type Refrigerant = {
  amount: Scalars['Float'];
  type: RefrigerantType;
};

export enum RefrigerantType {
  Ammonia = 'AMMONIA',
  CarbonDioxide = 'CARBON_DIOXIDE',
  Propane = 'PROPANE',
  RefrigerantHfc = 'REFRIGERANT_HFC'
}

export enum Salinity {
  BrackishOrFreshwater = 'BRACKISH_OR_FRESHWATER',
  Saline = 'SALINE'
}

export type SecondaryPackaging = {
  amount: Scalars['Float'];
  type: SecondaryPackagingType;
};

export enum SecondaryPackagingType {
  CorrugatedBox = 'CORRUGATED_BOX'
}

export type ShrimpProductionChemical = {
  amount: Scalars['Float'];
  type: ShrimpProductionChemicalType;
};

export enum ShrimpProductionChemicalType {
  Chlorine = 'CHLORINE',
  CitricAcid = 'CITRIC_ACID',
  HydrogenPeroxide = 'HYDROGEN_PEROXIDE',
  Lime = 'LIME',
  PotassiumDiformate = 'POTASSIUM_DIFORMATE',
  PropionicAcid = 'PROPIONIC_ACID',
  SodiumMetasilicate = 'SODIUM_METASILICATE',
  SodiumPercarbonate = 'SODIUM_PERCARBONATE'
}

export enum ShrimpSpecies {
  LitopenaeusVannamei = 'LITOPENAEUS_VANNAMEI',
  PenaeusMonodon = 'PENAEUS_MONODON'
}

export type SingleIngredientUse = {
  crudeProteinContent: Scalars['Float'];
  importedImpactId?: InputMaybe<Scalars['ID']>;
  ingredient: Ingredient;
  quantity: Scalars['Float'];
  transport?: InputMaybe<Array<InputMaybe<Transport>>>;
};

export type StageEmissionResult = {
  __typename?: 'StageEmissionResult';
  emissions?: Maybe<Array<EmissionResult>>;
  stageId?: Maybe<Scalars['String']>;
  stageName?: Maybe<Scalars['String']>;
};

export type Stock = {
  priceAtEnd?: InputMaybe<Scalars['Float']>;
  priceAtStart?: InputMaybe<Scalars['Float']>;
  weightAtEnd?: InputMaybe<Scalars['Float']>;
  weightAtStart?: InputMaybe<Scalars['Float']>;
};

export type Tag = {
  __typename?: 'Tag';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TagInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type TertiaryPackaging = {
  amount: Scalars['Float'];
  type: TertiaryPackagingType;
};

export enum TertiaryPackagingType {
  Pallet = 'PALLET',
  PlasticFilm = 'PLASTIC_FILM'
}

export type Transport = {
  distance: Scalars['Float'];
  mode: TransportMode;
};

export enum TransportMode {
  InlandShip = 'INLAND_SHIP',
  SeaShip = 'SEA_SHIP',
  Train = 'TRAIN',
  Truck = 'TRUCK'
}

export type ValidationCode = {
  __typename?: 'ValidationCode';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type ValidationCodes = {
  __typename?: 'ValidationCodes';
  errorCodes?: Maybe<Array<Maybe<ValidationCode>>>;
  warningCodes?: Maybe<Array<Maybe<ValidationCode>>>;
};

export type Warning = {
  __typename?: 'Warning';
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Water = {
  amount: Scalars['Float'];
  type: WaterType;
};

export type WaterProperties = {
  amount: Scalars['Float'];
  nitrogenConcentration?: InputMaybe<Scalars['Float']>;
};

export enum WaterType {
  Water = 'WATER'
}
