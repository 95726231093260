import { FC, useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { BaselineDialogProps, FormType } from '../../common';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { unitLong } from '../../../../utils/unit-utils';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import PackagingForOutputCategory from './PackagingForOutputCategory';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import { enumToOptionsArrayWithTranslatedStrings } from '../../../../utils/obj-utils';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import {
  FacilityOutputs,
  ProcessingPackaging,
  ProcessingStageData,
} from '../../../../models/Facility/Processing';
import { PigBaseline } from '../../../../models/Baseline/PigBaseline';

interface SlaughterhouseAnimalsFormDialogProps extends BaselineDialogProps {
  formTitle: string;
}

const SlaughterhousePackagingFormDialog: FC<
  SlaughterhouseAnimalsFormDialogProps
> = ({
  handleCancel,
  formType = FormType.Add,
  itemIndex = 0,
  formVisible = false,
  baseline,
  formTitle,
}) => {
  const intl = useIntl();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const classes = processAndStageStylesV2() as any;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
  const barnOutputMassUnit = userUOM?.unitBarnOutputMass
    ? unitLong(userUOM.unitBarnOutputMass)
    : 'kg';
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  // const precision = userProfile.getUnitBarnOutputMassPrecision() as number;
  const [showSelectNewPackaging, setShowSelectNewPackaging] =
    useState<boolean>(false);
  const fieldItemPrefix = `stages.${itemIndex}.stageData.processingPackaging`;

  const currResetValue = useRef<ProcessingPackaging>();
  const formContext = useFormContext<PigBaseline>();

  const {
    fields: additionalPackagingCategories,
    append: appendAdditionalPackagingCategory,
    remove: removeAdditionalPackagingCategory,
  } = useFieldArray({
    name: `${fieldItemPrefix}.additionalCategories`,
    control: formContext.control,
    keyName: 'keyId',
  });

  useEffect(() => {
    if (formVisible) {
      currResetValue.current = cloneDeep<ProcessingPackaging>(
        formContext.getValues(fieldItemPrefix)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const handleResetClick = () => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() };
      const stageData = resetObject.stages[itemIndex]
        ?.stageData as ProcessingStageData;
      if (stageData?.processingPackaging) {
        stageData.processingPackaging = currResetValue.current;
        formContext.reset(resetObject, { errors: true });
      }
    }

    if (handleCancel) handleCancel();
  };

  const getAvailableOptionsOutputType = () => {
    const allOptions = enumToOptionsArrayWithTranslatedStrings(
      FacilityOutputs,
      intl,
      'SUSTELL.STAGES.PROCESSING.PACKAGING.OUTPUT.TYPE.'
    ).map((item) => ({
      text: intl.formatMessage({
        id: `SUSTELL.STAGES.PROCESSING.PACKAGING.OUTPUT.TYPE.${item.value}`,
      }),
      value: item.value,
    }));
    const existing = additionalPackagingCategories.map(
      (ad) => ad.outputType as FacilityOutputs
    );

    existing.push(FacilityOutputs.freshMeatAndEdibleOffal);
    return allOptions.filter(
      (option) => !existing.includes(option.value as FacilityOutputs)
    );
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="shapes/cube-02"
      variant="wide"
      formTitle={formTitle}
      introText={intl.formatMessage({
        id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.DESCRIPTION',
      })}
    >
      <DsmGrid
        className={classes.additionalEntriesBlockHolder}
        key="packaging_key"
      >
        <PackagingForOutputCategory
          formVisible={formVisible}
          stageIndex={itemIndex}
          outputCategory={FacilityOutputs.freshMeatAndEdibleOffal}
          formType={formType}
          barnOutputMassUnit={barnOutputMassUnit}
        />
      </DsmGrid>

      {additionalPackagingCategories?.map((item, index) => (
        <DsmGrid
          className={classes.additionalEntriesBlockHolder}
          key={`additional_packaging_key_${item.keyId}`}
        >
          <PackagingForOutputCategory
            formVisible={formVisible}
            stageIndex={itemIndex}
            index={index}
            removeHandler={(ind: number) =>
              removeAdditionalPackagingCategory(ind)
            }
            isAddition
            outputCategory={item.outputType}
            formType={formType}
            barnOutputMassUnit={barnOutputMassUnit}
          />
        </DsmGrid>
      ))}

      {formType !== FormType.View && (
        <>
          <br />
          <DsmButton
            variant="text"
            onClick={() => {
              if (!showSelectNewPackaging) {
                setShowSelectNewPackaging(true);
              } else {
                setShowSelectNewPackaging(false);
              }
            }}
            widthFull={false}
          >
            <DsmIcon
              slot="before"
              name={
                !showSelectNewPackaging
                  ? 'general/plus-circle'
                  : 'general/minus-circle'
              }
            />
            {intl.formatMessage({
              id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.ADD_ANOTHER_PACKAGING',
            })}
          </DsmButton>
          <br />
        </>
      )}
      {showSelectNewPackaging && (
        <>
          <br />
          <ReactHookDsmSelect
            name={`${fieldItemPrefix}.newCatName`}
            label={intl.formatMessage({
              id: 'SUSTELL.STAGES.PROCESSING.PACKAGING.ADD_ANOTHER_PACKAGING',
            })}
            disabled={formType === FormType.View}
            options={getAvailableOptionsOutputType()}
            changeHandler={(e: any) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
              appendAdditionalPackagingCategory({ outputType: e.target.value });
              setShowSelectNewPackaging(false);
            }}
          />
        </>
      )}

      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={async () => {
          await formContext.trigger(
            `stages.${itemIndex}.stageData.processingPackaging`
          );
          handleCancel('confirm');
        }}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default SlaughterhousePackagingFormDialog;
