import {
  baselineValidationSchemaGeneralPart,
  baselineValidationSchemaGeneralPartV2,
} from './baselineValidationSchemaGeneralPart';

import assembleValidationSustellHens from './layingHensBaselineValidation';
import assembleValidationSustellBroiler from './broilersBaselineValidation';
import assembleValidationSustellPigFattening from './pigFatteningBaselineValidation';
import assembleValidationSustellDairy from './dairyBaselineValidation';
import assembleValidationSustellSalmon from './salmonBaselineValidation';
import assembleValidationSustellPig from './pigV2BaselineValidation';
import assembleValidationSustellMarine from './marineBaselineValidation';
import assembleValidationSustellPoultry from './poultryBaselineValidation';
import assembleValidationSchemaSustellShrimp from './shrimpBaselineValidation';
import assembleValidationSustellBeef from './beefBaselineValidation';

const getValidationSchemaSustell = (confObj) => {
  const { animalContext } = confObj;
  // minimal validation for general part of baseline is added to all
  let validationSchema = baselineValidationSchemaGeneralPart(confObj);

  switch (animalContext) {
    case 'LAYING_HENS':
      validationSchema = assembleValidationSustellHens(
        validationSchema,
        confObj
      );
      break;
    case 'DAIRY':
      validationSchema = assembleValidationSustellDairy(
        validationSchema,
        confObj
      );
      break;
    case 'PIG_FATTENING':
      validationSchema = assembleValidationSustellPigFattening(
        validationSchema,
        confObj
      );
      break;
    case 'PIG':
      validationSchema = assembleValidationSustellPig(
        baselineValidationSchemaGeneralPartV2(confObj),
        confObj
      );
      break;
    case 'BROILER':
      validationSchema = assembleValidationSustellBroiler(
        validationSchema,
        confObj
      );
      break;
    case 'SALMON':
      validationSchema = assembleValidationSustellSalmon(
        validationSchema,
        confObj
      );
      break;
    case 'SALMON_V2':
    case 'SEA_BASS':
    case 'SEA_BREAM':
      validationSchema = assembleValidationSustellMarine(
        baselineValidationSchemaGeneralPartV2(confObj),
        confObj
      );
      break;
    case 'LAYING_HENS_V2':
    case 'BROILER_V2':
    case 'TURKEY':
      validationSchema = assembleValidationSustellPoultry(
        baselineValidationSchemaGeneralPartV2(confObj),
        confObj
      );
      break;
    case 'LITOPENAEUS_VANNAMEI':
    case 'PENAEUS_MONODON':
      validationSchema = assembleValidationSchemaSustellShrimp(
        baselineValidationSchemaGeneralPartV2(confObj),
        confObj
      );
      break;
    case 'BEEF':
      validationSchema = assembleValidationSustellBeef(
        baselineValidationSchemaGeneralPartV2(confObj),
        confObj
      );
      break;
    default:
      return validationSchema;
  }
  return validationSchema;
};

export default getValidationSchemaSustell;
