import { mapResourceUse } from './inCommonMapperSustellV2';
import {
  AnimalsIncomingDBModel,
  BeefBaselineDBModel,
  BeefBeddingDBModel,
  BeefBreedingStageDataDBModel,
  BeefGrowingStageDataDBModel,
  BeefStageDBModel,
  BreedingOutputDBModel,
  CompundFeedInfoDBModel,
  EmissionMitigationDBModel,
  FeedingProgramCalfDBModel,
  FeedingProgramDBModel,
  FreshGrassDBModel,
  ManureManagementSystemDBModel,
  SingleIngredientUseDBModel,
  TimeSpentDBModel,
} from '../../../../sustell_15/models/TempBackendModels/TempBeefDB';
import {
  BeefBreedingStageData,
  BeefFeed,
  BeefGrowingStageData,
  BeefHousingAndManure,
  BeefStage,
  FreshGrass,
  ManureManagementSystem,
  BeddingSystem,
  BeefBeddingType,
  BeefEmission,
  BeefOutput,
  BeefOutputCalves,
  BeefBaseline,
  InternalSource,
  BeefInput,
  BeefManureManagementSystemType,
  ManurePhase,
} from '../../../../sustell_15/models/Baseline/BeefBaseline';
import {
  BaselineFeed,
  ResourceUse,
} from '../../../../sustell_15/models/Baseline/Baseline';
import {
  AnimalType,
  ReportingPeriod,
  StageType,
} from '../../../../../graphql/types';
import { BaselineFeedWithOrigin } from '../../../../sustell_15/models/Baseline';
import {
  formatInMassInput,
  formatInTerrestrialTransportInput,
} from './inMapperHelpers';
import { mapInProcessingStage } from './inProcessingMapperSustell';
import { ProcessingStageDBModel } from '../../../../sustell_15/models/TempBackendModels/TempProcessingDB';
import { ProcessingStage } from '../../../../sustell_15/models/Facility/Processing';

function mapFreshGrass(
  incomingData: Array<FreshGrassDBModel>
): Array<FreshGrass> {
  if (!incomingData || !incomingData?.length) {
    const emptyFreshGrass = {
      amount: '',
      type: '',
    } as FreshGrass;

    return [emptyFreshGrass];
  }
  return incomingData.map((grass) => ({
    amount: formatInMassInput(Number(grass.amount)),
    type: grass.type,
    crudeProteinContent: grass.crudeProteinContent,
    digestibility: grass.digestibility,
    dryMatterContent: grass.dryMatterContent,
    ashContent: grass.ashContent,
    grossEnergyContent: grass.grossEnergyContent,
    urinaryEnergy: grass.urinaryEnergy,
  }));
}

function mapSingleIngredients(
  incomingData?: Array<SingleIngredientUseDBModel>
): Array<BaselineFeedWithOrigin> {
  if (!incomingData || !incomingData.length) {
    return [
      {
        feedType: '',
        kgPerAnimal: '',
        origin: '',
        id: '',
        isSilage: undefined,
      },
    ];
  }

  return incomingData.map((singleIngredient) => ({
    id: singleIngredient.ingredient.id,
    feedType: singleIngredient.ingredient.name,
    origin: singleIngredient.ingredient.origin,
    kgPerAnimal: formatInMassInput(singleIngredient?.quantity),
    isSilage: singleIngredient?.silage || false,
  }));
}

function mapCompoundFeeds(
  incomingData?: Array<CompundFeedInfoDBModel>
): Array<BaselineFeed> {
  if (!incomingData || !incomingData.length) {
    return [{ feedType: '', kgPerAnimal: '' }];
  }

  return incomingData.map((compoundFeed) => ({
    feedType: compoundFeed.compoundFeedId,
    kgPerAnimal: formatInMassInput(compoundFeed?.quantity),
  }));
}

function mapCalfFeedingProgram(
  incomingData: FeedingProgramCalfDBModel
): Pick<BeefFeed, 'averageMilkFat' | 'mothersMilk'> {
  return {
    averageMilkFat: Number(incomingData?.averageMilkFat),
    mothersMilk: incomingData?.mothersMilk
      ? {
          amount: formatInMassInput(Number(incomingData.mothersMilk.amount)),
          crudeProteinContent: incomingData.mothersMilk.crudeProteinContent,
          digestibility: incomingData.mothersMilk.digestibility,
          dryMatterContent: incomingData.mothersMilk.dryMatterContent,
        }
      : undefined,
  };
}

function mapFeedingProgram(
  incomingData: FeedingProgramDBModel | FeedingProgramCalfDBModel
): Pick<
  BeefFeed,
  | 'freshGrass'
  | 'dietCharacterisation'
  | 'singleIngredients'
  | 'compoundFeeds'
  | 'databaseFoundation'
> {
  return {
    databaseFoundation: String(incomingData?.databaseFoundation),
    dietCharacterisation: incomingData?.dietCharacterisation,
    freshGrass: mapFreshGrass(incomingData?.freshGrass),
    ...mapCalfFeedingProgram(incomingData),
    singleIngredients: mapSingleIngredients(incomingData?.singleIngredients),
    compoundFeeds: mapCompoundFeeds(incomingData.compoundFeeds),
  };
}

export const mapBeefMmsFromDBModel = (
  mmsSystem: ManureManagementSystemDBModel
): ManureManagementSystem => {
  const storagePeriod = mmsSystem?.storagePeriod as string;
  const phase = mmsSystem?.manurePhase;

  const baseMms: Omit<ManureManagementSystem, 'storagePeriod'> = phase ? 
  {
    id: mmsSystem.id,
    share: Number(mmsSystem.share),
    mmsType: mmsSystem.mmsViewModelType as BeefManureManagementSystemType,
    manurePhase: phase as ManurePhase,
  } : 
  {
    id: mmsSystem.id,
    share: Number(mmsSystem.share),
    mmsType: mmsSystem.mmsViewModelType as BeefManureManagementSystemType,
  };

  if (storagePeriod) {
    return {
      ...baseMms,
      storagePeriod,
    } as ManureManagementSystem;
  }

  return baseMms;
};

function mapManureSystems(
  incomingData: Array<ManureManagementSystemDBModel>
): Array<ManureManagementSystem> {
  if (!incomingData?.length) return [];
  return incomingData.map(mapBeefMmsFromDBModel);
}

function mapTimeSpent(
  incomingData: TimeSpentDBModel
): Omit<BeefHousingAndManure, 'beddingSystems' | 'manureSystems'> {
  return {
    timeInFeedlot: incomingData?.timeInFeedlot,
    timeInHousing: incomingData?.timeInHousing,
    timeGrazingLargeAreas: incomingData?.timeOnLargeGrazingAreas,
    timeGrazingPastures: incomingData?.timeOnSmallGrazingAreas,
  };
}

function mapBeddingSystems(
  incomingData: Array<BeefBeddingDBModel>
): Array<BeddingSystem> {
  if (!incomingData || !incomingData?.length) {
    return [{ beddingAmount: '', beddingType: '' as BeefBeddingType }];
  }
  return incomingData.map((beddingSystem) => ({
    beddingAmount: formatInMassInput(beddingSystem.amount),
    beddingType: beddingSystem.type as BeefBeddingType,
  }));
}

function mapCommonOutputCows(incomingData: BreedingOutputDBModel): BeefOutput {
  return {
    numberOfMortalitiesCows: incomingData?.mortalities,
    averageWeightAtMortalityCows: formatInMassInput(
      incomingData?.weightAtMortality
    ),
    averageWeightOfCowsLeavingTheStage: formatInMassInput(
      incomingData?.averageWeight
    ),
    cowsSold: incomingData?.soldExternally,
    cowsToAnotherStage: incomingData?.animalsOutgoing,
  };
}

function mapOutputCalves(
  incomingData: BreedingOutputDBModel
): BeefOutputCalves {
  return {
    numberOfMortalitiesCalves: incomingData?.mortalities,
    weanedCalvesSold: incomingData?.soldExternally,
    weanedCalvesToAnotherStage: incomingData?.animalsOutgoing,
    averageWeightAtMortalityCalves: formatInMassInput(
      incomingData?.weightAtMortality
    ),
    averageWeightOfCalvesLeavingTheStage: formatInMassInput(
      incomingData?.averageWeight
    ),
  };
}

function mapEmissionsAndMitigations(
  incomingData?: Array<EmissionMitigationDBModel>
): BeefEmission {
  if (incomingData && incomingData.length) {
    return incomingData.reduce(
      (mappedData, emission) =>
        ({
          ...mappedData,
          [emission.emissionType]: emission.change,
        } as BeefEmission),
      {}
    );
  }
  return {};
}

type StageInfo = {
  startDate: string;
  endDate?: string;
};

type AnimalSources = Pick<BeefInput, 'internalSources' | 'externalSources'>;

function mapAnimalSources(
  incomingData: Array<AnimalsIncomingDBModel>
): AnimalSources {
  return incomingData?.reduce(
    (mappedData: AnimalSources, animalSource) => {
      const isInternalSource = animalSource.origin === 'PRODUCTION_SYSTEM';
      const commonAttributes: Pick<
        InternalSource,
        'id' | 'distanceTruck' | 'numberAnimals' | 'origin' | 'shrinkingRate'
      > = {
        id: animalSource.id,
        distanceTruck: formatInTerrestrialTransportInput(
          Number(animalSource.transportDistance)
        ),
        numberAnimals: animalSource.numberOfAnimals,
        origin: animalSource.origin,
        shrinkingRate: Number(animalSource.shrinkingRate),
      };

      if (isInternalSource) {
        mappedData.internalSources.push({
          ...commonAttributes,
          farmId: String(animalSource.originFarmId!),
          farmName: String(animalSource.originFarmName!),
          originStageId: String(animalSource.originStageId!),
          stageName: String(animalSource.originStageName!),
          stageType: String(animalSource.stageType),
          animalType: String(animalSource.animalType),
          baselineRef: String(animalSource.baselineRef),
        });

        return mappedData;
      }

      mappedData.externalSources.push(commonAttributes);
      return mappedData;
    },
    {
      internalSources: [],
      externalSources: [],
    }
  );
}

function mapBreedingStageData(
  incomingData: BeefBreedingStageDataDBModel,
  stageInfo: StageInfo
): BeefBreedingStageData {
  // Bedding systems are the same for cows and calves, therefore only one of them is required to map data.
  const beddingSystems =
    incomingData.beddingCalves as Array<BeefBeddingDBModel>;
  return {
    feed: {
      calves: {
        ...mapFeedingProgram(incomingData.feedingProgramCalves),
      },
      cows: {
        ...mapFeedingProgram(incomingData.feedingProgramCows),
      },
    },
    housing: {
      beddingSystems: mapBeddingSystems(beddingSystems),
      calves: {
        manureSystems: mapManureSystems(incomingData.mmsCalves),
        ...mapTimeSpent(incomingData.timeSpentCalves),
      },
      cows: {
        manureSystems: mapManureSystems(incomingData.mmsCows),
        ...mapTimeSpent(incomingData.timeSpentCows),
      },
    },
    input: {
      startDate: stageInfo.startDate,
      cattleProductivity: incomingData.animalProductivity as 'HIGH' | 'LOW',
      animalsPresentAtEnd: incomingData.stockChange?.cowsPresentAtEnd,
      animalsPresentAtStart: incomingData.stockChange?.cowsPresentAtStart,
      averageWeightAtStart: formatInMassInput(
        incomingData.stockChange?.averageWeightCowsStart
      ),
      averageWeightNewAnimals: formatInMassInput(
        incomingData.input?.averageWeight
      ),
      averageWeightAtBirth: formatInMassInput(incomingData?.calvesBirthWeight),
      ...mapAnimalSources(incomingData.input?.animals),
      permanencePeriod: Number(incomingData?.lengthStageCalves) || 0,
    },
    output: {
      ...mapCommonOutputCows(incomingData.outputCows),
      ...mapOutputCalves(incomingData.outputCalves),
    },
    emissions: {
      cows: mapEmissionsAndMitigations(incomingData.emissionMitigationsCows),
      calves: mapEmissionsAndMitigations(
        incomingData.emissionMitigationsCalves
      ),
    },
  };
}

function mapGrowingStageData(
  incomingData: BeefGrowingStageDataDBModel,
  stageInfo: StageInfo
): BeefGrowingStageData {
  return {
    feed: {
      ...mapFeedingProgram(incomingData.feedingProgramCows),
    },
    housing: {
      manureSystems: mapManureSystems(incomingData.mmsCows),
      ...mapTimeSpent(incomingData.timeSpentCows),
      beddingSystems: mapBeddingSystems(
        incomingData.beddingCows as Array<BeefBeddingDBModel>
      ),
    },
    input: {
      startDate: stageInfo.startDate,
      endDate: stageInfo.endDate || '',
      growingPurpose: String(incomingData.growingPurpose),
      isStageRepeated: String(incomingData.isStageRepeated),
      numberOfRepetitions: Number(incomingData.numberOfRepetitions),
      averageAgeAtStart: incomingData.input.averageAge,
      averageWeightNewAnimals: formatInMassInput(
        incomingData.input.averageWeight
      ),
      cattleProductivity: incomingData.animalProductivity as 'HIGH' | 'LOW',
      ...mapAnimalSources(incomingData.input.animals),
    },
    output: {
      averageAge: incomingData.outputCows.averageAge,
      ...mapCommonOutputCows(incomingData.outputCows),
    },
    emissions: mapEmissionsAndMitigations(incomingData.emissionMitigationsCows),
  };
}

function mapStageData(
  incomingData: BeefStageDBModel | ProcessingStageDBModel
): BeefStage | ProcessingStage {
  const commonStageData: Pick<BeefStage, 'id' | 'name' | 'type'> = {
    id: incomingData.id,
    type: incomingData.type,
    name: incomingData.name,
  };

  if (incomingData.type === StageType.Processing) {
    const processingStage = incomingData as ProcessingStageDBModel;
    return mapInProcessingStage(processingStage);
  }

  const beefStage = incomingData as BeefStageDBModel;
  const stageInfo: StageInfo = {
    startDate: beefStage.startDate || '',
    endDate: beefStage.endDate || '',
  };

  if (beefStage.type === StageType.Breeding) {
    const mappedStage = mapBreedingStageData(
      JSON.parse(String(beefStage.stageData)) as BeefBreedingStageDataDBModel,
      stageInfo
    );

    return {
      ...commonStageData,
      stageData: mappedStage,
    } as BeefStage;
  }

  const mappedStageData = mapGrowingStageData(
    JSON.parse(String(beefStage.stageData)) as BeefGrowingStageDataDBModel,
    stageInfo
  );

  return {
    ...commonStageData,
    stageData: mappedStageData,
  } as BeefStage;
}

function mapBeefProductionDataInSustell(
  inData: BeefBaselineDBModel
): BeefBaseline {
  const mappedData: BeefBaseline = {
    info: {
      name: inData.name,
      oldName: inData.oldName || inData.name,
      databaseFoundation: inData?.databaseFoundation,
      description: inData.description,
      numOfCyclesYear: Number(inData.roundsPerYear),
      timeUnit: inData?.reportingPeriod as ReportingPeriod,
      year: inData.year,
      validFrom: inData.validFrom,
      validTo: inData.validTo,
    },
    copyFrom: 'New',
    stages: [],
    animalType: AnimalType.Beef,
    resourceUse: {} as ResourceUse,
  };

  mappedData.resourceUse = inData.resourceUse
    ? (mapResourceUse(
        inData.resourceUse,
        mappedData.resourceUse,
        [ 
          'electricityUse',
          'gasUse',
          'waterUse'
        ]
      ) as ResourceUse)
    : ({} as ResourceUse);

  if (inData?.stages && !Array.isArray(inData.stages)) {
    return mappedData;
  }

  return inData.stages.reduce(
    (payload, stage: BeefStageDBModel) => {
      const mappedStage = mapStageData(stage);

      return {
        ...payload,
        stages: [...payload.stages, mappedStage],
      };
    },
    { ...mappedData }
  );
}

export default mapBeefProductionDataInSustell;
