// import Auth from '@aws-amplify/auth';
import { Auth, Hub } from 'aws-amplify';
// import { Hub } from '@aws-amplify/core';
import { Ability, AbilityBuilder } from '@casl/ability';

const DSM_EMAIL_SUFFIX =
  process.env.REACT_APP_DSM_EMAIL_SUFFIX || '@dsm.com, @dsm-firmenich.com';

const DSM_EMAIL_SUFFIXES =
  DSM_EMAIL_SUFFIX?.split(',')?.map((suffix) =>
    suffix?.trim()?.toLocaleLowerCase()
  ) || [];

const ability = new Ability();

function isMemberOf(user, groupName) {
  return (
    user?.signInUserSession.accessToken.payload['cognito:groups']?.indexOf(
      groupName
    ) >= 0
  );
}

function isDsmEmployee(user) {
  const email = user?.attributes?.email?.toLowerCase();

  return DSM_EMAIL_SUFFIXES?.some((suffix) => email?.endsWith(suffix));
}

function defineRulesFor(user) {
  const { can, rules } = new AbilityBuilder(ability);
  // add right according to role/ability matrix
  if (isMemberOf(user, 'Administrators')) {
    can("access", "Management pages")
    can("access", "Admin pages")
    can("access", "Dashboard")
    can("export", "Dashboard")
    can("read", "Footprint")
    can("link", "Users")

    can('create', 'Customer');
    can('read', 'Customer');
    can('update', 'Customer');
    can('delete', 'Customer');
    can('setFarmsNumberLimit', 'Customer');
    can('setReference', 'Customer');
    if (!isDsmEmployee(user)) {
      can('setFeedsVisible', 'Customer');
    }
    can("create", "Farm")
    can("read", "Farm")
    can("update", "Farm")
    can("delete", "Farm")
    can("addFarmToProject", "Farm")
    can("removeFarmFromProject", "Farm")

    can('create', 'Project');
    can('read', 'Project');
    can('update', 'Project');
    can('delete', 'Project');

    can('create', 'Baseline');
    can('read', 'Baseline');
    can('update', 'Baseline');
    can('delete', 'Baseline');

    can('create', 'Intervention');
    can('read', 'Intervention');
    can('update', 'Intervention');
    can('delete', 'Intervention');

    can('create', 'User');
    can('read', 'User');
    can('update', 'User');
    can('delete', 'User');

    can("create", "Compound Feed")
    can("update", "Compound Feed")
    can("read", "Compound Feed")
    can("delete", "Compound Feed")

    can('create', 'Facility');
    can('update', 'Facility');
    can('read', 'Facility');
  }

  if (isMemberOf(user, 'Editor')) {
    can("access", "Dashboard")
    can("export", "Dashboard")
    can("access", "Management pages")
    can("read", "Customer")

    can("create", "Farm")
    can("read", "Farm")
    can("update", "Farm")
    can("delete", "Farm")
    can("addFarmToProject", "Farm")
    can("removeFarmFromProject", "Farm")

    can("create", "Project")
    can("read", "Project")
    can("update", "Project")
    can("delete", "Project")

    can("create", "Baseline")
    can("read", "Baseline")
    can("update", "Baseline")
    can("delete", "Baseline")

    can("create", "Intervention")
    can("read", "Intervention")
    can("update", "Intervention")
    can("delete", "Intervention")

    can("create", "Compound Feed")
    can("update", "Compound Feed")
    can("read", "Compound Feed")
    can("delete", "Compound Feed")

    can("read", "User")

    can("read", "Footprint")

    can("read", "Facility")//check this one
  }

  if (isMemberOf(user, 'ViewerAll')) {

    can("access", "Dashboard")

    can("access", "Management pages")
    can("read", "Customer")
    can("read", "Farm")
    can("read", "Project")
    can("read", "Baseline")
    can("read", "Intervention")
    can("read", "User")
    //can("update", "User")
    can("read", "Footprint")
    can("read", "Compound Feed")
    can("read", "Facility")

    
  }

  if (isMemberOf(user, 'ViewerDashboard')) {

    can("access", "Dashboard")
    can("onlyAccess", "Dashboard")
    can('read', 'Customer')
  }

  if (isMemberOf(user, 'Support')) {
    can("access", "Dashboard")
    can("export", "Dashboard")
    can("access", "Management pages")
    can('access', 'Admin pages');
    can("read", "Customer")
    can("read", "Farm")
    can("read", "Project")
    can("read", "Baseline")
    can("read", "Intervention")

    can('create', 'Customer');
    can('read', 'Customer');
    can('update', 'Customer');
    can('delete', 'Customer');
    can('setFarmsNumberLimit', 'Customer');
    can('setReference', 'Customer');
    if (!isDsmEmployee(user)) {
      can('setFeedsVisible', 'Customer');
    }

    can("link", "User")
    can("create", "User")
    can("read", "User")
    can("update", "User")
    can("delete", "User")

    can("read", "Footprint")
    can("read", "Facility")
    can("read", "Compound Feed")
  }

  // Default / Viewer permissions
  can('access', 'Dashboard');

  return rules;
}

const updateUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    ability.update(defineRulesFor(user));
  } catch (err) {
    console.log('error setting permissions:');
    console.log(err);
  }
};

Hub.listen('auth', updateUser);
updateUser();

const canIDo = (action, subject) => ability.can(action, subject);

export default canIDo;

// export default createCanBoundTo(ability)
