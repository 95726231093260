import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import {
  defaultUnitsV2 as defaultMetric,
  explicitConvertValue,
} from '../../../../sustell_15/utils/unit-utils';
import { TransportMode } from '../../../../../graphql/types';
import { addDateProperties } from '../../../../sustell_15/utils/datetime-utils';
import { getImpactData } from '../../../../sustell_15/components/helpers/ImpactNumbers';
import { emissionFieldsMapInV2 } from '../../Intervention/mapper/emissionFields';
import { CompoundFeedDatabase, StageType } from '../../../../../graphql/types';
import mapInProcessingStage from './inProcessingMapperSustell';
const math = require('mathjs');

export const getUserTransportUnit = (selectedTransportMode) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  
  switch (selectedTransportMode) {
    case TransportMode.InlandShip:
      return userUOM.unitTransportDistanceInlandWaterways;      
    case TransportMode.SeaShip:
      return userUOM.unitTransportDistanceSea;    
    case TransportMode.Train:
    case TransportMode.Truck:
    default:
      return userUOM.unitTransportDistanceTerrestrial;
  }
}

export const getDefaultTransportUnit = (selectedTransportMode) => {
  switch (selectedTransportMode) {
    case TransportMode.InlandShip:
      return defaultMetric.unitTransportDistanceInlandWaterways;      
    case TransportMode.SeaShip:
      return defaultMetric.unitTransportDistanceSea;    
    case TransportMode.Train:
    case TransportMode.Truck:
    default:
      return defaultMetric.unitTransportDistanceTerrestrial;
  }
}

export const floatValueInUserUnits = (
  value,
  userUnit,
  defaultUnit,
  precision = 5
) => {
  if (value || value === 0) {
    if (userUnit !== defaultUnit)
      return math.round(
        explicitConvertValue(value, defaultUnit, userUnit),
        precision
      );
    return math.round(value, precision);
  }
  return value;
};

export const createCoreForm = (inData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();

  const tempValue = floatValueInUserUnits(
    inData.averageAnnualTemperature,
    userUOM.unitAverageAnnualTemperature,
    defaultMetric.unitAverageAnnualTemperature,
    1
  );

  // values in case of "copy from" are set in calling page
  const result = {
    id: inData.id,
    farmId: inData.farmId,
    productionProcessId: inData.productionProcessId,
    info: {
      name: inData.name,
      oldName: inData.oldName || inData.name,
      description: inData.description,
      year: inData.year,
      timeUnit: inData.reportingPeriod,
      numOfCyclesYear: inData.roundsPerYear,
      validFrom: inData.validFrom,
      validTo: inData.validTo,
      age: inData.age,
      databaseFoundation:
        inData.databaseFoundation || CompoundFeedDatabase.Gfli,
    },
    resourceUse: {},
    stages: [],
    copyFrom: 'New',
    updatedAt: inData.updatedAt,
    lastUpdatedBy: inData.lastUpdatedBy,
    // used for Footprint box
    impactSummary: {},
  };

  if (tempValue !== undefined) result.info.avgAnualTemperature = tempValue;

  // add date fileds
  addDateProperties(result, 'updatedAt');

  return result;
};

const mapCompoundFeedForOneStage = (stageFeedData, formFeedData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  let totalFeed = 0;

  if (
    stageFeedData?.compoundFeedUses &&
    Array.isArray(stageFeedData?.compoundFeedUses)
  ) {
    stageFeedData?.compoundFeedUses.forEach((oneFeedData) => {
      const value = floatValueInUserUnits(
        Number(oneFeedData?.quantity),
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass,
        precision
      );

      if (!isNaN(value)) totalFeed += value;
      formFeedData.compoundFeeds.push({
        feedType: oneFeedData?.compoundFeedId,
        kgPerAnimal: value,
      });
    });
    if (formFeedData.compoundFeeds.length === 0)
      formFeedData.compoundFeeds.push({ feedType: '', kgPerAnimal: '' });
  }

  return totalFeed;
};

const mapSingleIngridientsForOneStage = (stageFeedData, formFeedData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  let totalFeed = 0;

  if (
    stageFeedData?.singleIngredientUses &&
    Array.isArray(stageFeedData.singleIngredientUses)
  ) {
    stageFeedData?.singleIngredientUses.forEach((oneFeedData) => {
      const value = floatValueInUserUnits(
        Number(oneFeedData?.quantity),
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass,
        precision
      );

      if (!isNaN(oneFeedData?.quantity)) totalFeed += value;
      formFeedData.singleIngredients.push({
        id: oneFeedData?.ingredient?.id || '',
        feedType: oneFeedData?.ingredient?.name || '',
        origin: oneFeedData?.ingredient?.origin || '',
        kgPerAnimal: value,
      });
    });
    // needed if draft was saved without any single ingredient
    if (formFeedData.singleIngredients.length === 0)
      formFeedData.singleIngredients.push({
        feedType: '',
        kgPerAnimal: '',
        origin: '',
      });
  }
  return totalFeed;
};

const mapNaturalFeedForOneStage = (stageFeedData, formFeedData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const precision = userPrefs.getUnitBarnOutputMassPrecision();

  let totalFeed = 0;

  if (
    stageFeedData?.naturalFeed &&
    Array.isArray(stageFeedData.naturalFeed)
  ) {
    stageFeedData?.naturalFeed.forEach((oneFeedData) => {
      const value = floatValueInUserUnits(
        Number(oneFeedData?.quantity),
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass,
        precision
      );

      if (!isNaN(oneFeedData?.quantity)) totalFeed += value;

      const naturalFeedItem = {
        id: oneFeedData?.id || '',
        type: oneFeedData?.type || '',
        quantity: value,
        crudeProteinContent: oneFeedData?.crudeProteinContent || '',
        origin: oneFeedData?.origin || '',
      };

      if (
        oneFeedData?.transport?.[0]?.distance ||
        oneFeedData?.transport?.[0]?.distance === 0
      ) {
        naturalFeedItem.distanceTraveled = floatValueInUserUnits(
          oneFeedData?.transport[0]?.distance,
          getUserTransportUnit(oneFeedData?.transport?.[0]?.mode),
          getDefaultTransportUnit(oneFeedData?.transport?.[0]?.mode)
        );
        naturalFeedItem.transportMode = oneFeedData?.transport?.[0]?.mode;
      }

      formFeedData.naturalFeed.push(naturalFeedItem);
    });
    // needed if draft was saved without any single ingredient
    if (formFeedData.naturalFeed.length === 0)
      formFeedData.naturalFeed.push({
        type: '',
        quantity: '',
        crudeProteinContent: '',
        origin: '',
        distanceTraveled: '',
        transportMode: '',
      });
  }
  return totalFeed;
};

export const mapRationForOneStage = (
  stageRationData,
  formFeedDataObject,
  isDraft = false
) => {
  const formFeedData = formFeedDataObject;
  
  let totalFeed = 0;
  totalFeed = mapCompoundFeedForOneStage(stageRationData, formFeedData) || 0;
  totalFeed += mapSingleIngridientsForOneStage(stageRationData, formFeedData) || 0;
  totalFeed += mapNaturalFeedForOneStage(stageRationData, formFeedData) || 0;
  
  formFeedData.totalFeedIntake = totalFeed;

  return formFeedData;
};

const mapEmissionForOneStage = (stageEmmisionData, formEmissionDataObject) => {
  const formEmissionData = formEmissionDataObject;
  // check if emission data exists for this stage
  if (stageEmmisionData && Array.isArray(stageEmmisionData)) {
    stageEmmisionData.forEach((emission) => {
      // console.log('input emissions:', emission)
      const fieldName = emissionFieldsMapInV2.get(emission.emissionType);
      formEmissionData[fieldName] = emission.change;
    });
  }
};

const mapStageData = (stages, formDataObject, isDraft = false) => {
  const formData = formDataObject;
  const stageInputs = [];
  if (stages && Array.isArray(stages)) {
    stages.forEach((stageItem) => {
      // Added to prevent type error for changing readonly property stage.stageData
      const stage =  JSON.parse(JSON.stringify(stageItem));
      const stageData = JSON.parse(stage.stageData);

      const oneStageFormData = {
        id: stage?.id,
        name: stage?.name,
        type: stage?.type,
        pondName: stage?.pondName,
        stageData: {
          animalProductivity: stageData?.animalProductivity || 'HIGH', // for now may be fixed, as it's not set anywhere
          input: {
            startDate: stage.startDate || '',
            endDate: stage.endDate || '',
          },
          feed: {
            compoundFeeds: [],
            singleIngredients: [],
            naturalFeed: [],
          },
          housing: {},
          emissions: {},
          output: {},
        },
      };
      // some functions require isDraft flag to avoid inserting default values if draft is loaded
      mapRationForOneStage(
        stageData.feedingProgram,
        oneStageFormData.stageData.feed,
        isDraft
      );
      mapEmissionForOneStage(
        stageData.emissionMitigations,
        oneStageFormData.stageData.emissions
      );

      // for now one common mapper for processing stages
      // split per type if needed later
      if (stage.type === StageType.Processing) {
        // eslint-disable-next-line no-param-reassign
        stage.stageData = stageData;
        const oneStageFormData = mapInProcessingStage(stage);
        stageInputs.push(oneStageFormData);
      } else {
        const oneStageFormData = {
          id: stage?.id,
          name: stage?.name,
          type: stage?.type,
          pondName: stage?.pondName,
          stageData: {
            animalProductivity: stageData?.animalProductivity || 'HIGH', // for now may be fixed, as it's not set anywhere
            input: {
              startDate: stage.startDate || '',
              endDate: stage.endDate || '',
            },
            feed: {
              compoundFeeds: [],
              singleIngredients: [],
              naturalFeed: [],
            },
            housing: {},
            emissions: {},
            output: {},
          },
        };
        // console.log("oneStageFormData", oneStageFormData);
        // some functions require isDraft flag to avoid inserting default values if draft is loaded
        mapRationForOneStage(
          stageData.feedingProgram,
          oneStageFormData.stageData.feed,
          isDraft
        );
        mapEmissionForOneStage(
          stageData.emissionMitigations,
          oneStageFormData.stageData.emissions
        );

        stageInputs.push(oneStageFormData);
      }
      // console.log("oneStageFormData",oneStageFormData)
    });
  }
  formData.stages = stageInputs;

  return formData;
};

export const mapCommonProductionDataIn = (inData, isDraft = false) => {
  const formData = createCoreForm(inData);
  mapStageData(inData.stages, formData, isDraft);
  formData.impactSummary = null;
  getImpactData(inData, formData);
  return formData;
};

export const mapResourceUse = (
  inDataResources,
  formDataResourcesObj,
  resources = [
    'electricityUse',
    'gasUse',
    'waterUse',
    'dieselUse',
    'gasolineUse',
    'propaneOrLpgUse',
    'coalUse',
    'electricityPhotovoltaicUse',
    'electricityWindUse',
  ]
) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formDataResources = formDataResourcesObj;
  const precision = userPrefs.getUnitResourcePrecision();

  if (resources?.includes('electricityUse') && inDataResources.electricityUse)
    formDataResources.electricityUse = floatValueInUserUnits(
      inDataResources.electricityUse,
      userUOM.unitElectricityUse,
      defaultMetric.unitElectricityUse,
      precision
    );

  if (resources?.includes('gasUse') && inDataResources.gasUse)
    formDataResources.gasUse = floatValueInUserUnits(
      inDataResources.gasUse,
      userUOM.unitNaturalGasUse,
      defaultMetric.unitNaturalGasUse,
      precision
    );

  if (resources?.includes('waterUse') && inDataResources.waterUse)
    formDataResources.waterUse = floatValueInUserUnits(
      inDataResources.waterUse,
      userUOM.unitWaterUse,
      defaultMetric.unitWaterUse,
      precision
    );

  formDataResources.energyTypes = [];

  if (resources?.includes('dieselUse') && inDataResources.dieselUse) {
    formDataResources.dieselUse = floatValueInUserUnits(
      inDataResources.dieselUse,
      userUOM.unitDieselUse,
      defaultMetric.unitDieselUse,
      precision
    );
  } else if (!resources?.includes('dieselUse') && inDataResources.dieselUse) {
    formDataResources.energyTypes.push({
      type: 'dieselUse',
      value: floatValueInUserUnits(
        inDataResources.dieselUse,
        userUOM.unitDieselUse,
        defaultMetric.unitDieselUse,
        precision
      ),
    });
  }

  if (inDataResources.propaneOrLpgUse)
    formDataResources.energyTypes.push({
      type: 'propaneOrLpgUse',
      value: floatValueInUserUnits(
        inDataResources.propaneOrLpgUse,
        userUOM.unitNaturalGasUse,
        defaultMetric.unitNaturalGasUse,
        precision
      ),
    });

  if (inDataResources.coalUse)
    formDataResources.energyTypes.push({
      type: 'coalUse',
      value: floatValueInUserUnits(
        inDataResources.coalUse,
        userUOM.unitHeatUse,
        defaultMetric.unitHeatUse,
        precision
      ),
    });

  if (inDataResources.gasolineUse)
    formDataResources.energyTypes.push({
      type: 'gasolineUse',
      value: floatValueInUserUnits(
        inDataResources.gasolineUse,
        userUOM.unitDieselUse,
        defaultMetric.unitDieselUse,
        precision
      ),
    });

    formDataResources.selfGeneratedRenewables = [];
  if (inDataResources.electricityPhotovoltaicUse)
    formDataResources.selfGeneratedRenewables.push({
      type: 'electricityPhotovoltaicUse',
      value: floatValueInUserUnits(
        inDataResources.electricityPhotovoltaicUse,
        userUOM.unitElectricityUse,
        defaultMetric.unitElectricityUse,
        precision
      ),
    });
  
    if (inDataResources.electricityWindUse)
    formDataResources.selfGeneratedRenewables.push({
      type: 'electricityWindUse',
      value: floatValueInUserUnits(
        inDataResources.electricityWindUse,
        userUOM.unitElectricityUse,
        defaultMetric.unitElectricityUse,
        precision
      ),
    });
  return formDataResources;
};
