import { Grid, Table, TableBody, TableRow } from '@material-ui/core';
import {
  ColumnDef,
  PaginationState,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  SortingState,
  useReactTable,
  ColumnFiltersState,
  getFilteredRowModel,
  getFacetedUniqueValues,
  Row,
  RowData,
} from '@tanstack/react-table';
import { v4 as uuidv4 } from 'uuid';
import React, {
  useState,
  useMemo,
  useEffect,
  MouseEvent,
  ChangeEvent,
} from 'react';
import { useHistory } from 'react-router';
import { FormatDateOptions } from 'react-intl';
import { InfiniteQueryObserverResult, useQueryClient } from '@tanstack/react-query';
import { compoundFeedsStyles } from '../../../../_metronic/layout';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import DeleteConfirmationDialog from '../helpers/DeleteConfirmationDialog';
import { CompoundFeedDatabase, Maybe } from '../../../../graphql/types';
import useAdobeDataLayer from '../../analytics/adobeDataLayer';
import { dateDisplayOptionsShort } from '../../utils/datetime-utils';
import { footprintURL } from '../../utils/footprint-url-utils';
import {
  CompoundFeedAndCompoundFeedV2,
  DialogProps,
} from './CompoundFeeds.types';
import { isFeedV2Database, masterDataV2 } from '../CompoundFeedWizard/utils';
import { getUIDatabaseFoundation } from '../../utils/compound-feed-utils';
import TableSearchHeader from '../Table/TableSearchHeader';
import TableHeader from '../Table/TableRowHeader';
import TableFooterRow from '../Table/TableFooterRow';
import TableLoadingSkeleton from '../Table/TableLoadingSkeleton';
import DeleteCompoundFeedDialog from './DeleteCompoundFeedDialog';
import TableRowItem from '../Table/TableRowItem';
import CompoundFeedMoreTableActionsProps from './CompoundFeedsTableMoreActions';
import {
  useDeleteCoumpoundFeed,
  useDeleteCoumpoundFeedV2,
} from '../../../../api';
import filterByBenchmarkSortByName from '../Table/utils';
import CompoundFeedsTableFilter from './CompoundFeedsTableFilter';
import Can from '../../../modules/Auth/Can';
import { DsmButtonV2 } from '../helpers/DsmButton';

interface CompoundFeedsTableProps {
  feedsList: CompoundFeedAndCompoundFeedV2[];
  loading: boolean;
  customerId: string;
  fetchNextPage: any;
  hasNextPage: boolean;
}

const CompoundFeedsTable = ({
  feedsList,
  loading,
  customerId,
  fetchNextPage,
  hasNextPage,
}: CompoundFeedsTableProps) => {
  const intl = useIntl();
  const classes = compoundFeedsStyles();
  const { ctaClickEvent, formStartEvent } = useAdobeDataLayer();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [searchValue, setSearchValue] = useState<string>('');
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] =
    useState<null | CompoundFeedAndCompoundFeedV2>(null);
  const [deleteFeedDialog, setDeleteFeedDialog] = useState<null | DialogProps>(
    null
  );
  const [data, setData] = useState(feedsList);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const {
    mutateAsync: deleteCoumpoundFeed,
    isLoading: isDeleteCoumpoundFeedLoading,
  } = useDeleteCoumpoundFeed(
    queryClient,
    ctaClickEvent,
    intl.formatMessage({
      id: 'CUSTOMER.COMPOUND_FEED.DELETE.OPERATION_RESULT.TITLE',
    }),
    intl.formatMessage({
      id: 'CUSTOMER.COMPOUND_FEED.DELETE.ERROR.1',
    }),
    intl.formatMessage({
      id: 'CUSTOMER.COMPOUND_FEED.DELETE.SUCCESS',
    }),
    intl.formatMessage({
      id: 'CUSTOMER.COMPOUND_FEED.DELETE.ERROR',
    }),
    setDeleteFeedDialog
  );
  const {
    mutateAsync: deleteCoumpoundFeedV2,
    isLoading: isDeleteCoumpoundFeedLoadingV2,
  } = useDeleteCoumpoundFeedV2(
    queryClient,
    ctaClickEvent,
    intl.formatMessage({
      id: 'CUSTOMER.COMPOUND_FEED.DELETE.OPERATION_RESULT.TITLE',
    }),
    intl.formatMessage({
      id: 'CUSTOMER.COMPOUND_FEED.DELETE.ERROR.1',
    }),
    intl.formatMessage({
      id: 'CUSTOMER.COMPOUND_FEED.DELETE.SUCCESS',
    }),
    intl.formatMessage({
      id: 'CUSTOMER.COMPOUND_FEED.DELETE.ERROR',
    }),
    setDeleteFeedDialog
  );

  const multiSelect = <TData extends RowData>(
    row: Row<TData>,
    columnId: string,
    filterValue: string[]
  ): boolean => filterValue.includes(row.getValue(columnId));

  const multiSelectArray = <TData extends RowData>(
    row: Row<TData>,
    columnId: string,
    filterValue: string[]
  ): boolean =>
    filterValue
      .map((value) => value.includes(row.getValue(columnId)))
      .some((value) => value === true);

  const columns = useMemo<ColumnDef<CompoundFeedAndCompoundFeedV2>[]>(
    () => [
      {
        id: 'checkbox',
        header: '',
      },
      {
        accessorKey: 'name',
        header: intl.formatMessage({ id: 'COMPOUNDFEEDS.FEED_NAME' }),
        minSize: 200,
        maxSize: 200,
      },
      {
        accessorKey: 'benchmark',
        filterFn: 'equals',
      },
      {
        accessorFn: (row) => row.id,
        id: 'id',
        enableSorting: false,
      },
      {
        accessorKey: 'databaseFoundation',
        header: intl.formatMessage({
          id: 'COMPOUNDFEEDS.MY_FEEDS.DATABASE_SELECTED',
        }),
        sortUndefined: 'last',
        cell: (info) =>
          getUIDatabaseFoundation(
            info.getValue() as Maybe<CompoundFeedDatabase>
          ),
        filterFn: multiSelect<CompoundFeedAndCompoundFeedV2>,
      },
      {
        accessorKey: 'modified_date',
        cell: (info) =>
          intl.formatDate(
            info.getValue() as string,
            dateDisplayOptionsShort as FormatDateOptions
          ),
        header: intl.formatMessage({ id: 'GENERAL.LAST_MODIFIED' }),
      },
      {
        accessorKey: 'year.value',
        header: intl.formatMessage({ id: 'GENERAL.YEAR' }),
      },
      {
        accessorKey: 'feedmill_location.name',
        header: intl.formatMessage({
          id: 'COMPOUNDFEEDS.CARD.TABLE.FEEDMILL_LOCATION',
        }),
        id: 'feedmillName',
        filterFn: multiSelect<CompoundFeedAndCompoundFeedV2>,
      },
      {
        accessorKey: 'target_species',
        header: intl.formatMessage({
          id: 'REPORT.COMPOUND_FEED_DATASETS.GENERAL.ANIMAL_SYSTEM',
        }),
        sortUndefined: 'last',
        cell: ({ row }) =>
          row.original.target_species.map((species, index) => (
            <span key={`species_span_${row.original.id}${species ?? ''}`}>
              {species && species.toLowerCase().indexOf('all animal') > -1
                ? intl.formatMessage({
                    id: 'COMPOUNDFEEDS.ALL_TYPES',
                  })
                : species && species.replace(' system', '').replace('V2', '')}
              {index !== row.original.target_species.length - 1 ? ', ' : ' '}
            </span>
          )),
        filterFn: multiSelectArray<CompoundFeedAndCompoundFeedV2>,
      },
      {
        accessorKey: 'actions',
        header: '',
      },
    ],
    [intl]
  );

  const table = useReactTable({
    columns,
    data,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      pagination,
      rowSelection,
      columnFilters,
      columnVisibility: { benchmark: false },
    },
  });

  useEffect(() => {
    if (searchValue) {
      table.setPageIndex(0);
    }
    if (feedsList) {
      setData(
        filterByBenchmarkSortByName<CompoundFeedAndCompoundFeedV2>(
          feedsList,
          'name'
        ).filter((item) =>
          item.name.toLowerCase().includes(searchValue.toLowerCase().trim())
        )
      );
    }
  }, [searchValue, feedsList, table]);

  const onTablePageChange = (
    _: MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null,
    page: number
  ) => {
    table.setPageIndex(page);
  };

  const onTableRowsPerPageChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newVal = event.target.value;
    table.setPageSize(Number(newVal));
  };

  const compareFeedsFootprint = async () => {
    const selectedItems = Object.keys(rowSelection).map((id) =>
      table.getRow(id)
    );
    const firstSelectedFeed = selectedItems[0];
    const secondSelectedFeedId = selectedItems[1];
    const comparisonFootprintURL = footprintURL({
      baselineCustomerID: customerId,
      baselineFarmID: '',
      baselineProcessID: '',
      baselineType: 'f',
      baselineReference: firstSelectedFeed.original.id,
      comparisonType: 'f',
      comparisonReference: secondSelectedFeedId.original.id,
      comparisonCustomerID: customerId,
    });
    const variables = {
      dataSetName: firstSelectedFeed.original.name,
      comparisonName: secondSelectedFeedId.original.name,
    };
    await ctaClickEvent(
      window.location.href,
      'button',
      'Compare Feeds Footprint',
      'My Feeds',
      'My Feeds'
    );
    history.push(comparisonFootprintURL, variables);
  };

  const openFeed = async (
    event: React.MouseEvent,
    feed: CompoundFeedAndCompoundFeedV2
  ) => {
    event.preventDefault();
    await ctaClickEvent(
      window.location.href,
      'link',
      'View Feed',
      'My Feeds',
      'My Feeds'
    );
    history.push({
      pathname: `/customers/${customerId}/compoundfeeds/${feed.id}/view`,
      search: `?step=0&database=${getUIDatabaseFoundation(
        feed.databaseFoundation
      )}`,
      state: {
        // customerName: customerName,
        feedName: feed.name,
      },
    });
  };

  const handleEditFeed = async (item: CompoundFeedAndCompoundFeedV2) => {
    await ctaClickEvent(
      window.location.href,
      'link',
      'Edit Feed',
      'My Feeds',
      'My Feeds'
    );
    history.push({
      pathname: `/customers/${customerId}/compoundfeeds/${item.id}/edit`,
      search: `?step=0&database=${getUIDatabaseFoundation(
        item.databaseFoundation
      )}`,
      state: {
        // customerName: customerName,
        feedName: item.name,
      },
    });
    await formStartEvent('Edit Feed', 'edit');
  };

  const handleDuplicateFeed = async (item: CompoundFeedAndCompoundFeedV2) => {
    await ctaClickEvent(
      window.location.href,
      'link',
      'Duplicate Feed',
      'My Feeds',
      'My Feeds'
    );
    const feedCopyName = `${item.name} - COPY`;
    history.push({
      pathname: `/customers/${customerId}/compoundfeeds/new`,
      search: `?step=0&database=${getUIDatabaseFoundation(
        item.databaseFoundation
      )}`,
      state: {
        // customerName: customerName,
        formType: 'new',
        newDuplicateFeed: true,
        formValueFeedName: feedCopyName,
        feedID: item.id,
      },
    });
    await formStartEvent('Create Feed', 'add');
  };

  const deleteFeed = () => {
    if (!isFeedV2Database(deleteConfirmationDialog?.databaseFoundation)) {
      deleteCoumpoundFeed({
        customerId,
        feedId: deleteConfirmationDialog?.id ?? '',
      }).finally(() => setDeleteConfirmationDialog(null));
    } else {
      deleteCoumpoundFeedV2({
        customerId,
        feedId: deleteConfirmationDialog?.id ?? '',
      }).finally(() => setDeleteConfirmationDialog(null));
    }
  };

  // TODO: use this if only compatible species feeds should be allowed to  copy
  const isGfliCompatible = (targetSpecies: Maybe<string>[]) =>
    !!(
      targetSpecies.find((el) => el === 'All animal production system types') ||
      masterDataV2.target_species['GFLI 2.0'].some((el) =>
        targetSpecies.includes(el.value)
      )
    );

  return (
    <>
      <DeleteConfirmationDialog
        open={!!deleteConfirmationDialog}
        loading={isDeleteCoumpoundFeedLoading || isDeleteCoumpoundFeedLoadingV2}
        confirmHandler={deleteFeed}
        cancelHandler={() => setDeleteConfirmationDialog(null)}
        itemType={intl.formatMessage({
          id: 'SUSTELL.DELETE.FEED',
        })}
        customText={intl.formatMessage({ id: 'GENERAL.WARNING' })}
      />
      <DeleteCompoundFeedDialog
        openDialog={!!deleteFeedDialog}
        header={deleteFeedDialog?.dialogTitle ?? ''}
        description={deleteFeedDialog?.dialogTxt ?? ''}
        feedUsageItems={deleteFeedDialog?.feedUsageItems ?? []}
        handleOk={() => setDeleteFeedDialog(null)}
        customerId={customerId}
      />

      <TableSearchHeader
        title={intl.formatMessage({ id: 'COMPOUNDFEEDS.ALL_FEEDS' })}
        description={intl.formatMessage({
          id: 'COMPOUNDFEEDS.ALL_FEEDS_DESCRIPTION',
        })}
        showSearch
        searchPlaceholder={intl.formatMessage({
          id: 'COMPOUNDFEEDS.SEARCH',
        })}
        showTableFilter
        tableFilterComponent={
          <CompoundFeedsTableFilter<CompoundFeedAndCompoundFeedV2>
            table={table}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
          />
        }
        showCompareFootprints
        isCompareFootprintsDisabled={Object.keys(rowSelection).length !== 2}
        compareButtonText={intl.formatMessage({
          id: 'COMPOUNDFEEDS.COMPARE_FOOTPRINTS',
        })}
        search={(value) => setSearchValue(value)}
        handleCompareFootprint={compareFeedsFootprint}
      />
      <Table className={classes.table}>
        <TableHeader<CompoundFeedAndCompoundFeedV2>
          items={table.getHeaderGroups()}
        />
        <TableBody>
          {loading ? (
            <TableLoadingSkeleton
              description={intl.formatMessage({
                id: 'COMPOUNDFEEDS.LOADING',
              })}
            />
          ) : (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={uuidv4()}
                style={{
                  background: row.original.benchmark ? '#F0F7FB' : '',
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableRowItem<CompoundFeedAndCompoundFeedV2>
                    key={uuidv4()}
                    cell={cell}
                    row={row}
                    itemId={row.original.id}
                    rowSelection={rowSelection}
                    isBenchmark={row.original.benchmark || false}
                    canSubject="Compound Feed"
                    handleView={openFeed}
                    standAloneMoreActions={
                      (Can('read', 'Compound Feed') || Can('create', 'Compound Feed') || Can('update', 'Compound Feed') || Can('delete', 'Compound Feed')) ?
                      (<CompoundFeedMoreTableActionsProps
                        customerId={customerId}
                        feed={row.original}
                        item={row.original}
                        handleEdit={handleEditFeed}
                        handleDuplicate={handleDuplicateFeed}
                        handleDelete={setDeleteConfirmationDialog}
                      />
                      ) : <></>
                    }
                  />
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
        <TableFooterRow
          customElements={table.getRowCount()}
          page={table.getState().pagination.pageIndex}
          rowsPerPage={table.getState().pagination.pageSize}
          onTablePageChange={onTablePageChange}
          onTableRowsPerPageChange={onTableRowsPerPageChange}
        />
      </Table>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-return */}
      {hasNextPage &&
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: '10vh' }} // Optional: to center vertically as well
        >
          <Grid item>
            <DsmButtonV2 onClick={() => fetchNextPage()}>Load more</DsmButtonV2>
          </Grid>
        </Grid>
      }
    </>
  );
};

export default CompoundFeedsTable;
