/* eslint-disable @typescript-eslint/no-floating-promises */
import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import {
  DsmButton,
  DsmFieldset,
  DsmGrid,
  DsmIcon,
} from '@dsm-dcs/design-system-react';
import { getFarm } from '../../../../../../graphql/queries';
import {
  CreateAnimalSystemInput,
  Farm,
  AnimalSystem,
  UpdateAnimalSystemInput,
  AnimalType,
  ProductionType,
} from '../../../../../../graphql/types';
import { saveProductionProcess } from '../../../../apicalls/farmFunctions';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import lookupValues from '../../../../helpers/lookupValues';
import { FormType } from '../../common';
import { forbiddenChars } from '../../../../helpers/validations';
import adobeDataLayer from '../../../../analytics/adobeDataLayer';
import { TextBox } from '../../../pageparts/TextBox';
import {
  selectProductionTypeOptions,
} from './AnimalSystemAddForm';
import { isShrimp, selectOptions } from '../../../../helpers/animals';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import DefinitionsDialog from './DefinitionsDialog';

type AnimalSystemFormProps = {
  formType: FormType;
  formData?: AnimalSystem;
  farmID: string;
  handleFormSave?: (savedData: AnimalSystem) => void; // may be external
  formVisible: boolean;
  handleClose?: () => void; // may be external
};

const AnimalSystemForm = ({
  formType = FormType.View,
  formData,
  farmID,
  handleFormSave,
  handleClose,
}: AnimalSystemFormProps) => {
  const intl = useIntl();
  const [farm, setFarm] = useState<Farm>();
  const [isInError, setIsInError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { formSubmissionEvent, formErrorsEvent } = adobeDataLayer();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);

  const fetchFarmData = async (farmId: string) => {
    try {
      const response = await (API.graphql({
        query: getFarm,
        variables: {
          farmId,
        },
      }) as Promise<{ data: { getFarm: Farm } }>);

      // set fetched farm data to component state
      setFarm(response.data.getFarm);
    } catch (err) {
      console.log('error: ', err);
    }
  };

  useEffect(() => {
    fetchFarmData(farmID);
  }, []);

  const errorHandler = (message: string) => {
    setErrorMessage(intl.formatMessage({ id: message }));
    setIsInError(true);
    const formErrors = Object.keys(methods.formState.errors).length;
    formErrorsEvent(
      formType === FormType.Edit
        ? 'Edit Production Process'
        : 'Add Production Process',
      formType,
      formErrors
    );
  };

  const onSubmit = (submitData: AnimalSystem) => {
    setIsInError(false);

    let data: CreateAnimalSystemInput | UpdateAnimalSystemInput;
    if (formType === FormType.Edit)
      data = {
        ...submitData,
        old_name: formData?.name as string,
        id: formData?.id,
      };
    else data = { ...submitData };
    saveProductionProcess(
      formType,
      farmID,
      data,
      handleFormSave,
      errorHandler,
      formSubmissionEvent
    );
  };

  const onErrors = () => {
    errorHandler('SUSTELL.ERROR.MANDATORY_FIELDS');
  };

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        name: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
          .min(
            3,
            intl.formatMessage(
              { id: 'VALIDATION.FIELD.MIN_LENGTH' },
              { count: 3 }
            )
          )
          .max(
            256,
            intl.formatMessage(
              { id: 'VALIDATION.FIELD.MAX_LENGTH' },
              { count: 256 }
            )
          )
          // prevent use of quotation marks in the name
          .concat(
            forbiddenChars(
              intl,
              ['"', "'"],
              'VALIDATION.FIELD.FORBIDDEN_QUOTATION'
            )
          ),
        animalType: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
        ),
        farmingMethod: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
        ),
      })
    ),
    defaultValues: {
      name: formType === FormType.Edit ? formData?.name : '',
      animalType: formType === FormType.Edit ? formData?.animalType : 'PIG',
      farmingMethod:
        formType === FormType.Edit ? formData?.farmingMethod : 'CONVENTIONAL',
    },
  });

  const countAnimalSystems = (animalType: AnimalType) => {
    if (!farm) fetchFarmData(farmID);
    if (!farm || !farm.animalSystems || !farm.animalSystems.items) {
      return '1';
    }

    const filteredItems = farm.animalSystems.items.filter(
      (item) => item?.animalType === animalType
    );

    return (filteredItems.length + 1).toString();
  };

  const onAnimalSystemSelected = (animalType: {
    target: { value: AnimalType };
  }) =>
    methods.setValue(
      'name',
      `${intl.formatMessage({
        id: `SUSTELL.STAGE.${animalType.target.value}`,
      })} ${countAnimalSystems(animalType.target.value)}`
    );

  const formSubmit = () => methods.handleSubmit(onSubmit);

  return (
    <div
      style={{
        overflow: 'hidden',
        padding: 0,
        marginTop: 'var(--dsm-spacing-px-16',
      }}
    >
      {openDescriptionDialog && (
        <DefinitionsDialog
          open
          prefix='SUSTELL.PRODUCTION.TYPE'
          itemHeader='SUSTELL.PRODUCTION.TYPE.HEADER'
          items={Object.entries(ProductionType).map(([_, value]) => value)}
          handleClose={() => setOpenDescriptionDialog(false)}
        />
      )}
      {isInError && (
        <Grid
          container
          direction="column"
          spacing={1}
          style={{ marginBottom: 10 }}
        >
          <Grid item xs={12}>
            <Alert severity="error">
              <AlertTitle>{errorMessage}</AlertTitle>
            </Alert>
          </Grid>
        </Grid>
      )}
      <FormProvider
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...methods}
      >
        <form onSubmit={formSubmit}>
          <TextBox
            title={intl.formatMessage({
              id: 'SUSTELL.ANIMAL.SYSTEM.INFORMATION.TITLE',
            })}
            content={intl.formatMessage({
              id: 'SUSTELL.ANIMAL.SYSTEM.INFORMATION.DESCRIPTION',
            })}
            translationKeyTitle={undefined}
            translationKeyText={undefined}
          />
          <DsmGrid style={{ grid: 'auto/1fr auto ', padding: '0' }}>
            <DsmFieldset
              style={{
                marginTop: 'var(--dsm-spacing-px-8',
                width: isShrimp(formData?.animalType) ? '80%' : '50%',
              }}
            >
              <ReactHookDsmSelect
                name="animalType"
                label={`${intl.formatMessage({
                  id: 'SUSTELL.ANIMAL.SYSTEM.TYPE',
                })}`}
                defaultValue={lookupValues.animalType.PIG}
                placeholder={intl.formatMessage({
                  id: 'SUSTELL.FIELD_PRODUCTION_PROCESSES.TYPE.TOOLTIP',
                })}
                options={selectOptions}
                changeHandler={onAnimalSystemSelected}
                required
                disabled
              />
              <ReactHookDsmInput
                name="name"
                label={`${intl.formatMessage({
                  id: 'SUSTELL.ANIMAL.SYSTEM.NAME',
                })}`}
                disabled={formType === FormType.View}
                defaultValue={formData?.name ?? undefined}
                required
              />
              {isShrimp(formData?.animalType) && (
                <DsmGrid className={classes.dsmGridOneColumnNoRowgap}>
                  <ReactHookDsmSelect
                    label={intl.formatMessage({
                      id: 'SUSTELL.PRODUCTION.TYPE',
                    })}
                    name="productionType"
                    options={selectProductionTypeOptions(intl)}
                    required
                    defaultValue={formData?.productionType || undefined}
                    tooltip={intl.formatMessage({
                      id: 'SUSTELL.PRODUCTION.TYPE.TOOLTIP'
                    })}
                  />
                  <DsmButton
                    variant="text"
                    onClick={() => setOpenDescriptionDialog(true)}
                  >
                    <DsmIcon slot="before" name="general/eye" />
                    {intl.formatMessage({
                      id: 'DATABASE_FOUNDATION_DEFINITIONS',
                    })}
                  </DsmButton>
                </DsmGrid>
              )}
              <Controller
                control={methods.control}
                name="farmingMethod"
                defaultValue="Conventional"
                // eslint-disable-next-line @typescript-eslint/no-shadow
                render={({ value, name }) => (
                  <input
                    type="hidden"
                    name={name}
                    value={value as string | undefined}
                  />
                )}
              />
            </DsmFieldset>
            {formType === FormType.Edit && (
              <DsmGrid
                style={{
                  alignSelf: 'start',
                  grid: 'auto/1fr auto',
                  padding: '0px',
                  columnGap: 'var(--dsm-spacing-px-6',
                  paddingTop: '54px',
                }}
              >
                <DsmButton variant="primary" destructive onClick={handleClose}>
                  <DsmIcon slot="before" name="general/trash-01" />
                  {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
                </DsmButton>
                <DsmButton
                  variant="primary"
                  onClick={methods.handleSubmit(onSubmit, onErrors)}
                >
                  {intl.formatMessage({ id: 'GENERAL.SAVE' })}
                  <DsmIcon slot="after" name="arrows/chevron-right" />
                </DsmButton>
              </DsmGrid>
            )}
          </DsmGrid>
          {formType === FormType.Add && (
            <DsmFieldset style={{ marginTop: 'var(--dsm-spacing-px-8' }}>
              <Box
                style={{
                  display: 'flex',
                  width: '100%',
                  marginTop: 'var(--dsm-spacing-px-8)',
                }}
                flexDirection="row"
                justifyContent="space-between"
              >
                <DsmButton variant="primary" destructive onClick={handleClose}>
                  <DsmIcon slot="before" name="general/trash-01" />
                  {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
                </DsmButton>
                <Box
                  style={{ display: 'flex', width: '30%' }}
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {/* <DsmButton variant="text">Skip for now</DsmButton> */}
                  <DsmButton
                    style={{ marginLeft: 'var(--dsm-spacing-px-4)' }}
                    variant="primary"
                    onClick={methods.handleSubmit(onSubmit, onErrors)}
                  >
                    {intl.formatMessage({ id: 'SUSTELL.SAVE.PROCCES' })}
                  </DsmButton>
                </Box>
              </Box>
            </DsmFieldset>
          )}
        </form>
      </FormProvider>
    </div>
  );
};

AnimalSystemForm.defaultProps = {
  formData: undefined,
};

export default AnimalSystemForm;
