import { Box, List, ListItem, ListItemText, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { FC } from "react";

import { useIntl } from "../../../../_metronic/i18n/customUseIntl";
import { footprintCategoryTableStyles } from "../../../../_metronic/layout/_core/MaterialThemeProvider";
import { FootprintModel } from "../../models/Footprint";
import WarningErrorModel from "../../models/Footprint/WarningError";
import { EmissionsStyledTableCell, EmissionStyledTableRow } from "./FootprintEmissionDialog";
import { FootPrintType } from "../../../../graphql/types";
import { MessageTextCode } from "../../models/Footprint/FootprintTypes";

interface ResultAndErrorsTableProps {
  // eslint-disable-next-line react/require-default-props
  items?: WarningErrorModel[];
  // eslint-disable-next-line react/require-default-props
  comparisonItems?: WarningErrorModel[]; // Filter comparison items to show if there are some that are not in baseline
  title: string;
  baselineName: string,
  comparisonName: string | undefined;
  hasComparison: boolean,
  type: FootPrintType,
}

/** Reuse for: calculateion errors, warnings, emission errors, performance indicator errors */
const ResultAndErrorsTable: FC<ResultAndErrorsTableProps> = ({
  items = [],
  comparisonItems = [],
  title,
  baselineName,
  comparisonName = 'Comparison',
  hasComparison = false,
  type
}) => {
  const intl = useIntl();
  const classes = footprintCategoryTableStyles();

  const baselineDisplayNames = items.map(el => el.displayName);
  const onlyComparsionItems = comparisonItems.filter(compItem => baselineDisplayNames.indexOf(compItem.displayName) === -1);

  const showProxyWarning = type === FootPrintType.CompoundFeed && items?.[0]?.proxyDataUsed;

  if ((!items || items.length === 0) 
    && (!onlyComparsionItems || onlyComparsionItems.length === 0)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  const translateMessage = (msg: MessageTextCode) : string => {
    // code is missing, return the Blonk's error text
    if(!msg.code) return msg.text;

    const translatedText = msg.values ? intl.formatMessage({id: msg.code}, msg.values) : intl.formatMessage({id: msg.code});

    return translatedText !== msg.code ? translatedText : msg.text;
  }

  return (
    <Box mt="12px">
    <h3>{title}</h3>
      <TableContainer style={{marginTop: '12px'}}>
          <Table aria-label={title} className={classes.table}>
            <TableHead>
              <TableRow>
                <EmissionsStyledTableCell>
                  {title}
                </EmissionsStyledTableCell>
                <EmissionsStyledTableCell>
                { baselineName }
                </EmissionsStyledTableCell>
                { hasComparison &&
                  <EmissionsStyledTableCell>
                    {comparisonName}
                  </EmissionsStyledTableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
            { items.map((item: WarningErrorModel) => (
              <EmissionStyledTableRow key={`${item.key}_key`}>
                <EmissionsStyledTableCell
                  style={{ width: '40%'}}
                >
                  <Box style={{ display: 'flex' }}>
                    <Typography
                      className={classes.emissionsTableCellTitle}
                      style={{ paddingLeft: '10px', fontWeight: '500', fontSize: '12px !important' }}
                    >
                      {!item.shouldTranslate ? item.displayName : intl.formatMessage({id: item.displayName})}
                    </Typography>
                    </Box>
                  </EmissionsStyledTableCell>
                  <EmissionsStyledTableCell  style={{ width: '30%'}}>
                    <List dense>
                    {item.entries.map( err =>
                      <ListItem key={`baseline-${err.text}`}>
                        <ListItemText
                          primary={translateMessage(err)}
                        />
                      </ListItem>
                    )}
                    </List>
                  </EmissionsStyledTableCell>
                  <EmissionsStyledTableCell style={{ width: '30%'}}>
                    <List dense>
                    { (item.comparison && item.comparison.entries?.length > 0) ?
                      item.comparison.entries.map( err =>
                      <ListItem key={`comp-${err.text}`}>
                        <ListItemText
                          primary={translateMessage(err)}
                        />
                      </ListItem> )
                      : ''
                    }
                    </List>
                  </EmissionsStyledTableCell>
              </EmissionStyledTableRow>
              )
              )
            }
            { onlyComparsionItems?.length > 0 &&
             onlyComparsionItems.map((item: WarningErrorModel) => (
              <EmissionStyledTableRow key={`${item.key}_key_only_comp`}>
                <EmissionsStyledTableCell
                  style={{ width: '40%'}}
                >
                  <Box style={{ display: 'flex' }}>
                    <Typography
                      className={classes.emissionsTableCellTitle}
                      style={{ paddingLeft: '10px', fontWeight: '500', fontSize: '12px !important' }}
                    >
                      {!item.shouldTranslate ? item.displayName : intl.formatMessage({id: item.displayName})}
                    </Typography>
                    </Box>
                  </EmissionsStyledTableCell>
                  <EmissionsStyledTableCell  style={{ width: '30%'}}>
                    <List />
                  </EmissionsStyledTableCell>
                  <EmissionsStyledTableCell style={{ width: '30%'}}>
                    <List dense>
                    { (item.entries?.length > 0) ?
                      item.entries.map( err =>
                      <ListItem key={`comp-${err.text}`}>
                        <ListItemText
                          primary={translateMessage(err)}
                        />
                      </ListItem> )
                      : ''
                    }
                    </List>
                  </EmissionsStyledTableCell>
                </EmissionStyledTableRow>
              ))
            }
            {showProxyWarning && <EmissionStyledTableRow key="proxy_data_row">
                <EmissionsStyledTableCell
                  style={{ width: '40%'}}
                >
                  <Box style={{ display: 'flex' }}>
                    <Typography
                      className={classes.emissionsTableCellTitle}
                      style={{ paddingLeft: '10px', fontWeight: '500', fontSize: '12px !important' }}
                    >
                      {intl.formatMessage({id:"SUSTELL.FOOTPRINT.TABS.WARNING.PROXY_DATA.TITLE"})}
                    </Typography>
                    </Box>
                  </EmissionsStyledTableCell>
                  <EmissionsStyledTableCell  style={{ width: '30%'}}>
                    {intl.formatMessage({id:"SUSTELL.FOOTPRINT.TABS.WARNING.PROXY_DATA.TEXT"})}
                  </EmissionsStyledTableCell>
                  <EmissionsStyledTableCell style={{ width: '30%'}}/>
                  {/* </EmissionsStyledTableCell> */}
              </EmissionStyledTableRow>     
            }
            </TableBody>
            </Table>
      </TableContainer>
    </Box>
  )
}

const ResultAndErrors: FC<{footprint: FootprintModel}> = ({footprint}) => {
  const intl = useIntl();

  return (<>
      <ResultAndErrorsTable 
        items={footprint.emissionContainer?.agregatedEmissions?.errors}
        comparisonItems={footprint.comparison?.emissionContainer?.agregatedEmissions?.errors}
        title={intl.formatMessage({id:"SUSTELL.FOOTPRINT.TABS.EMISSIONS.TITLE"})}
        baselineName={footprint.name} 
        comparisonName={footprint.comparison?.name}
        hasComparison={!!footprint.comparison}
        type={footprint.type}
      />
      <ResultAndErrorsTable 
        items={footprint.warnings} 
        comparisonItems={footprint.comparison?.warnings}
        title={intl.formatMessage({id: "REPORT.WARNINGS.TITLE"})}
        baselineName={footprint.name} 
        comparisonName={footprint.comparison?.name}
        hasComparison={!!footprint.comparison}
        type={footprint.type}
      />
      <ResultAndErrorsTable 
        items={footprint.calculationErrors} 
        comparisonItems={footprint.comparison?.calculationErrors}
        title={intl.formatMessage({id: "GENERAL.CALCULATION_ERRROS"})}
        baselineName={footprint.name} 
        comparisonName={footprint.comparison?.name}
        hasComparison={!!footprint.comparison}
        type={footprint.type}
      />
    </>);
}

export default ResultAndErrors;