import { cloneDeep } from 'lodash';
import { useState, useEffect, useRef, FC, ReactNode, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, Tab, Tabs, Tooltip } from '@material-ui/core';
import { DsmButton, DsmIcon } from '@dsm-dcs/design-system-react';
import { DialogContainer } from '../../CommonDataParts/DialogContainer2';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { beefTabsStyles, processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { BaselineDialogProps, FormType } from '../../common';
import { DatabaseFoundation } from '../../../../../../graphql/generated/blonk/pigs';
import { CompoundFeedNameDatabase, Origin, SingleIngredient } from '../types';
import { Maybe, StageType } from '../../../../../../graphql/types';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { BaselineFeed } from '../../../../models/Baseline';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';

import {
  DairyV2Baseline,
  DairyV2ContinuousGrowingFeed,
  DairyV2ContinuousGrowingStageData,
  DairyV2DairyCowStageData,
  DairyV2Stage,
  TargetAnimal,
} from '../../../../models/Baseline/DairyV2Baseline';
import { defaultValues } from '../../baselineDefaultValues';
import { getInitialDatabaseValue } from '../../../../helpers/initialValues';
import DairyV2FeedComponent from './DairyV2FeedComponent';
import { CSSClassesList } from '../../../../helpers/helperTypes';

interface DairyV2FeedContinuousGrowingDialogProps extends BaselineDialogProps {
  compoundFeeds: Maybe<Array<CompoundFeedNameDatabase>>;
  singleIngredients: Maybe<Array<SingleIngredient>>;
  origins: Maybe<Array<Origin>>;
}

interface TabPanelProps {
  children: ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`beef-breeding-feed-${index}`}
      aria-labelledby={`beef-breeding-feed-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const dairyV2FeedStageDefaultValues = defaultValues.DAIRY_V2.stages[0].stageData.feed;

const DairyV2FeedContinuousGrowingDialog: FC<DairyV2FeedContinuousGrowingDialogProps> = ({
  formVisible,
  formType,
  itemIndex,
  handleCancel,
  handleSave = handleCancel,
  compoundFeeds,
  singleIngredients,
  origins,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  const formContext = useFormContext();
  const [currentTab, setCurrentTab] = useState(0);
  const currResetValue = useRef<{
    feed: DairyV2ContinuousGrowingFeed;
    databaseFoundation: DatabaseFoundation;
  }>();
  const fieldItemPrefix = `stages.${itemIndex}.stageData.feed`;
  const classes: CSSClassesList = processAndStageStylesV2();

  const handleResetClick = useCallback(() => {
    if (currResetValue.current) {
      const resetObject = { ...formContext.getValues() } as DairyV2Baseline;
      const continuousGrowingStageData = resetObject.stages[itemIndex]
        ?.stageData as DairyV2ContinuousGrowingStageData;
      if (continuousGrowingStageData?.feed) {
        continuousGrowingStageData.feed = {
          ...currResetValue.current.feed,
        };
        formContext.reset(resetObject, { errors: true });
      }
    }
    if (handleCancel) handleCancel('reset');
  }, [formContext, handleCancel, itemIndex]);

  const handleTabChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setCurrentTab(newValue);
  };

  const intl = useIntl();
  const classesTabs = beefTabsStyles();
  const baselineDatabaseFoundation =
    getInitialDatabaseValue<DatabaseFoundation>(
      formContext,
      fieldItemPrefix,
      userProfile
    );
  const initialDatabase = useRef<DatabaseFoundation>(
    getInitialDatabaseValue<DatabaseFoundation>(
      formContext,
      fieldItemPrefix,
      userProfile
    )
  );

  const updateContinuousGrowingFeeds = (stage: DairyV2Stage, index: number) => {
    const cowsFeeds = (stage.stageData as DairyV2ContinuousGrowingStageData).feed.cows
      .compoundFeeds;
    const updatedCowsFeeds: BaselineFeed[] | undefined = cowsFeeds?.map(() => ({
      feedType: '',
      kgPerAnimal: 0,
    }));
    formContext.setValue(
      `stages.${index}.stageData.feed.cows.compoundFeeds`,
      updatedCowsFeeds
    );
    const calvesFeeds = (stage.stageData as DairyV2ContinuousGrowingStageData).feed.calves
      .compoundFeeds;
    const updatedCalvesFeeds: BaselineFeed[] | undefined = calvesFeeds?.map(
      () => ({ feedType: '', kgPerAnimal: 0 })
    );
    formContext.setValue(
      `stages.${index}.stageData.feed.calves.compoundFeeds`,
      updatedCalvesFeeds
    );
  };

  const updateFeeds = (stage: DairyV2Stage, index: number) => {
    const feeds = (stage.stageData as DairyV2DairyCowStageData).feed.compoundFeeds;
    const updatedFeeds: BaselineFeed[] | undefined = feeds?.map(() => ({
      feedType: '',
      kgPerAnimal: 0,
    }));
    formContext.setValue(
      `stages.${index}.stageData.feed.compoundFeeds`,
      updatedFeeds
    );
  };

  const handleSaveClick = async () => {
    const currFormValues = formContext.getValues();
    formContext.reset(currFormValues, { errors: true });
    const stages = currFormValues?.stages as Array<DairyV2Stage> || [];

    stages.forEach((stage: DairyV2Stage, i: number) => {
      if (
        i !== itemIndex &&
        initialDatabase.current !== baselineDatabaseFoundation
      ) {
        if (stage.type === StageType.ContinuousGrowing) updateContinuousGrowingFeeds(stage, i);
        else updateFeeds(stage, i);
      }
    });
    if (handleSave) {
      await formContext.trigger(`stages.${itemIndex}.stageData.feed.cows`);
      await formContext.trigger(`stages.${itemIndex}.stageData.feed.calves`);
      handleSave('confirm');
    }
  };

  useEffect(() => {
    if (formVisible) {
      const serializedData = formContext.getValues(
        `stages.${itemIndex}.stageData.feed`
      ) as DairyV2ContinuousGrowingFeed;
      currResetValue.current = cloneDeep({
        feed: serializedData,
        databaseFoundation: baselineDatabaseFoundation,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVisible]);

  const resetToDefaultValues = () => {
    formContext.setValue(
      `${fieldItemPrefix}.${TargetAnimal.CALVES}.averageMilkFat`,
      dairyV2FeedStageDefaultValues.calves.averageMilkFat
    );
  };

  return (
    <DialogContainer
      formVisible={formVisible}
      handleClose={handleResetClick}
      iconCode="shapes/cube-02"
      formTitle={intl.formatMessage({
        id: 'SUSTELL.PROCESS.DIALOG.STAGE.FEED.TITLE',
      })}
      variant="ultrawide"
      introText={intl.formatMessage({
        id: 'SUSTELL.STAGE.BEEF.FEED.BREEDING.DESCRIPTION',
      })}
    >
      <Box className={classesTabs.tabsBox}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="beef-breeding-feed"
          className={classesTabs.tabs}
        >
          <Tab
            label={intl.formatMessage({
              id: `SUSTELL.STAGE.BEEF.INPUT.COWS`,
            })}
          />
          <Tab
            label={intl.formatMessage({
              id: `SUSTELL.STAGE.BEEF.INPUT.CALVES`,
            })}
          />
        </Tabs>
      </Box>

      <TabPanel value={currentTab} index={0}>
        <DairyV2FeedComponent
          formType={formType}
          fieldItemPrefix={`${fieldItemPrefix}.${TargetAnimal.COWS}`}
          targetAnimal={TargetAnimal.COWS}
          stageIndex={itemIndex}
          compoundFeeds={compoundFeeds}
          singleIngredients={singleIngredients}
          formVisible={formVisible}
          origins={origins}
        />
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <DairyV2FeedComponent
          formType={formType}
          fieldItemPrefix={`${fieldItemPrefix}.${TargetAnimal.CALVES}`}
          targetAnimal={TargetAnimal.CALVES}
          stageIndex={itemIndex}
          compoundFeeds={compoundFeeds}
          singleIngredients={singleIngredients}
          formVisible={formVisible}
          origins={origins}
        />
      </TabPanel>
      <div className={classes.resetButtonContainer}>
        <DsmButton
          widthFull
          variant="text"
          onClick={() => resetToDefaultValues()}
          disabled={formType === FormType.View}
          className="mt-3"
        >
          <DsmIcon slot="before" name="arrows/refresh-cw-01" />
          <u style={{ textDecoration: 'none' }}>
            {intl.formatMessage({
              id: 'SUSTELL.STAGE.BEEF.INPUT.RESET_DEFAULT_VALUES',
            })}
          </u>
        </DsmButton>
        <Tooltip
          title={intl.formatMessage({
            id: 'SUSTELL.RESET_DEFAULT.TOOLTIP',
          })}
          placement="left"
          className='ml-3'
          style={{transform: 'translateY(85%)'}}
        >
          <DsmIcon
            name="general/help-circle"
            className={classes.inlineTooltipClass}
            title=""
          />
        </Tooltip>
      </div>
      <DsmButtonControlGroup
        cancelHandler={handleResetClick}
        saveHandler={handleSaveClick}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </DialogContainer>
  );
};

export default DairyV2FeedContinuousGrowingDialog;
