/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import { IntlShape } from 'react-intl';
import {
  numericOptionalWithMin,
  numericRequiredWithGreaterThanMin,
  numericRequiredWithMin,
} from './validationObjectBuilderFunctions';
import {
  PrimaryPackagingType,
  SecondaryPackagingType,
  StageType,
  TertiaryPackagingType,
} from '../../../../../graphql/types';
import { enumToOptionsArrayWithTranslatedStrings } from '../../../../sustell_15/utils/obj-utils';
import { FacilitySpecies } from '../../../../sustell_15/models/Facility/FacilityTypes';
import {
  isBeefFacility,
  isMarineFishFacility,
  isPoultryFacility,
} from '../../../../sustell_15/helpers/facilities';
import { AnimalType } from '../../../../../graphql/generated/blonk/beef';

const getOutputFieldNames = (
  facilitySpecies: FacilitySpecies,
  stageType: StageType,
  animalType: string
): string[] => {
  if (isPoultryFacility(facilitySpecies)) return ['totalToStage', 'totalSold'];
  if (isBeefFacility(facilitySpecies)) {
    if (stageType === StageType.Breeding && animalType === AnimalType.Calf)
      return ['weanedCalvesToAnotherStage', 'weanedCalvesSold'];

    return ['cowsToAnotherStage', 'cowsSold'];
  }

  return ['pigsToStage', 'soldPigs'];
}

export const processingInput = (
  intl: IntlShape,
  facilitySpecies: FacilitySpecies
) =>
  Yup.object().shape({
    originStageId: Yup.string().required(
      intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
    ),
    quantity: numericRequiredWithGreaterThanMin(intl, 0).test(
      'checkOutputNoAnimals',
      '',
      // Number of animals in processing stage must be <= animals output of origin stage
      function (val: any): Yup.ValidationError | boolean {
        const { path, createError } = this;
        let from;
        if ('from' in this) {
          from = this.from as any;
        }
        const { originStageId, stageType, animalType } = from.at(0).value;
        if (!originStageId) {
          return true;
        }

        if (!val) {
          return createError({
            path,
            message: intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' }),
          });
        }

        // For partial validaiton of completeness
        if (!from.at(3)) {
          return true;
        }

        const originStage = from
          .at(3)
          ?.value?.stages.find((st: { id: any }) => st.id === originStageId);

        if (isMarineFishFacility(facilitySpecies)) {
          // If is Marine Fish, quantity must be 1
          return val === 1;
        }

        // console.log(this.resolve("stages[1].stageData.processingInput.quantity"))
        let totalFromAnimals = 0;

        if (originStage?.type !== StageType.Processing) {
          const outputNames = getOutputFieldNames(facilitySpecies, stageType, animalType);
          const incomingAnimalsKey = outputNames[0];
          const soldAnimalsKey = outputNames[1];
          totalFromAnimals =
            Number(originStage?.stageData?.output?.[soldAnimalsKey] || 0) +
            Number(originStage?.stageData?.output?.[incomingAnimalsKey] || 0);
        } else {
          // If origin is processing stage then this field remains optional
          return true;
        }

        if (val > totalFromAnimals) {
          return createError({
            path,
            message: intl.formatMessage(
              { id: 'SUSTELL.STAGES.PROCESSING.ANIMALS.QUANTITY_ERROR' },
              { value: totalFromAnimals }
            ),
          });
        }

        return true;
      }
    ),
    transport: Yup.array().of(
      Yup.object({
        transportMode: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
        ),
        distance: numericRequiredWithGreaterThanMin(intl, 0),
      })
    ),
    stageType: Yup.string(),
    animalType: Yup.string()
      .when('stageType',{
        is: (value: any) => value === StageType.Breeding && isBeefFacility(facilitySpecies),
        then: Yup.string().required(
          intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
        ),
        otherwise: Yup.string()
      }),
  });

const processingOutputObject = (intl: IntlShape) =>
  Yup.object({
    calculate: Yup.bool(),
    weight: Yup.number().when('calculate', {
      is: true,
      then: numericRequiredWithGreaterThanMin(intl, 0),
      otherwise: numericOptionalWithMin(intl, 0),
    }),
    price: Yup.number().when('calculate', {
      is: true,
      then: numericRequiredWithMin(intl, 0),
      otherwise: numericOptionalWithMin(intl, 0),
    }),
  });

export const processingOutput = (intl: IntlShape) =>
  Yup.object({
    freshMeatAndEdibleOffal: Yup.object({
      calculate: Yup.boolean(),
      weight: numericRequiredWithGreaterThanMin(intl, 0),
      price: numericRequiredWithGreaterThanMin(intl, 0),
    }),
    foodGradeBones: processingOutputObject(intl),
    foodGradeFat: processingOutputObject(intl),
    cat1And2MaterialAndWaste: processingOutputObject(intl),
    cat3SlaughterByProducts: processingOutputObject(intl),
    cat3HidesAndSkins: processingOutputObject(intl),
  });

const getErrorMessageForEnum = (
  intl: IntlShape,
  enumObj: any,
  messageKey: string
) => {
  const enumsIntl = enumToOptionsArrayWithTranslatedStrings(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    enumObj,
    intl,
    'SUSTELL.STAGES.PROCESSING.PACKAGING.OUTPUT.TYPE.'
  );
  // console.log('rnums intl', enumsIntl);
  return intl.formatMessage(
    { id: messageKey },
    { values: enumsIntl.map((item) => item.text).join(', ') }
  );
};

export const packagingBaselineItem = (intl: IntlShape, packagingType: any) =>
  Yup.object({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    type: Yup.string().when('amount', {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      is: (val: any) => val,
      then: Yup.string().required(
        getErrorMessageForEnum(
          intl,
          packagingType,
          'SUSTELL.STAGES.PROCESSING.PACKAGING.ERROR.INVALID_TYPE'
        )
      ),
      otherwise: Yup.string(),
    }),
    amount: numericOptionalWithMin(intl, 0),
  }).test('checkBothInputs', '', function (value: any):
    | Yup.ValidationError
    | boolean {
    const { path, createError } = this;
    const type = value?.type;
    const amount = value?.amount;
    // console.log(type, amount);
    if (!type && !!amount) {
      return createError({
        path: `${path}.type`,
        message: getErrorMessageForEnum(
          intl,
          packagingType,
          'SUSTELL.STAGES.PROCESSING.PACKAGING.ERROR.INVALID_TYPE'
        ),
      });
    }
    if (!!type && !amount) {
      return createError({
        path: `${path}.amount`,
        message: intl.formatMessage({ id: 'VALIDATION.NUMERIC.INPUT' }),
      });
    }
    return true;
  });

export const processingPackaging = (intl: IntlShape) =>
  Yup.object({
    primary: Yup.array().of(packagingBaselineItem(intl, PrimaryPackagingType)),
    secondary: packagingBaselineItem(intl, SecondaryPackagingType),
    tertiary: Yup.array().of(
      packagingBaselineItem(intl, TertiaryPackagingType)
    ),
  });

export const processingPackagings = (intl: IntlShape) =>
  Yup.object({
    freshMeatAndEdibleOffal: processingPackaging(intl),
    foodGradeBones: processingPackaging(intl),
    foodGradeFat: processingPackaging(intl),
    cat1And2MaterialAndWaste: processingPackaging(intl),
    cat3SlaughterByProducts: processingPackaging(intl),
    cat3HidesAndSkins: processingPackaging(intl),
  });

export const processingStageData = (
  intl: IntlShape,
  facilitySpecies: FacilitySpecies
) =>
  Yup.object({
    processingInput: processingInput(intl, facilitySpecies),
    processingOutput: processingOutput(intl),
    processingPackaging: processingPackagings(intl),
  });
