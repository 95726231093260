import {
  BeefBaseline,
  BeefBreedingStageData,
  BeefGrowingStageData,
  BeefStage,
  BeddingSystem,
  BeefEmission,
  ManureManagementSystem,
  BeefOutputBreeding,
  BeefOutputGrowing,
  BeefInputBreeding,
  InternalSource,
  ExternalSource,
  BeefFeed,
  BeefHousingAndManure,
  BeefInputGrowing,
  FreshGrass,
} from '../../../../sustell_15/models/Baseline/BeefBaseline';
import {
  createCorePayloadSustell,
  mapResourceUseSustell,
} from './outCommonMapperSustellV2';
import {
  BeefBreedingStageDataDBModel,
  BeefStageDBModel,
  AnimalsIncomingDBModel,
  CommonStageInputModel,
  BeefGrowingStageDataDBModel,
  FeedingProgramDBModel,
  FeedingProgramCalfDBModel,
  SingleIngredientUseDBModel,
  GrowingStageInputModel,
  ManureManagementSystemDBModel,
  EmissionMitigationDBModel,
  BreedingOutputDBModel,
  GrowingOutputDBModel,
  StockChangeDBModel,
  TimeSpentDBModel,
  BeefBeddingDBModel,
  StageDBModel,
  CompundFeedInfoDBModel,
  CompoundFeedDatabase,
  StageType,
  FreshGrassDBModel,
} from '../../../../sustell_15/models/TempBackendModels/TempBeefDB';
import {
  BaselineFeed,
  BaselineFeedWithOrigin,
} from '../../../../sustell_15/models/Baseline';
import {
  formatMassInput,
  formatTerrestrialTransportInput,
} from './outMapperHelpers';
import {
  ProcessingStage,
  ProcessingStageData,
} from '../../../../sustell_15/models/Facility/Processing';
import { ProcessingStageDBModel } from '../../../../sustell_15/models/TempBackendModels/TempProcessingDB';
import { mapOutProcessingStage } from './outProcessingMapperSustell';
import lookupValues from '../../../../sustell_15/helpers/lookupValues';

function mapBeddingSystems(
  beddingSystem?: Array<BeddingSystem>
): Array<BeefBeddingDBModel> | undefined {
  if (!beddingSystem || !beddingSystem.length) return [];

  const mappedBeddingSystems = beddingSystem.reduce(
    (mappedSystems: Array<BeefBeddingDBModel>, beddingSystem) => {
      const { beddingAmount, beddingType } = beddingSystem;

      if (!beddingAmount || Number.isNaN(beddingAmount)) return mappedSystems;

      return [
        ...mappedSystems,
        { type: beddingType, amount: formatMassInput(beddingAmount) },
      ] as BeefBeddingDBModel[];
    },
    []
  );

  return mappedBeddingSystems.length ? mappedBeddingSystems : undefined;
}

function mapEmissionsAndMitigations(
  incomingEmissions?: BeefEmission
): Array<EmissionMitigationDBModel> {
  if (!incomingEmissions) return [];
  const entries = Object.entries(incomingEmissions);
  if (!entries.length) return [];

  return entries.reduce(
    (acc: Array<EmissionMitigationDBModel>, [key, value]) => {
      if (!value || Number.isNaN(value)) return acc;

      return [
        ...acc,
        {
          emissionType: key,
          change: parseFloat(value as string),
        },
      ];
    },
    []
  );
}

export const mapBeefMmsToDBModel = (
  mmsSystem: ManureManagementSystem
): ManureManagementSystemDBModel => {
  const storagePeriod = mmsSystem?.storagePeriod as string;
  const mmsShare = mmsSystem.share;
  const type = mmsSystem.mmsType;
  const phase = mmsSystem.manurePhase;

  const baseMms = phase ? 
  {
    id: mmsSystem.id,
    share: Number(mmsShare),
    mmsViewModelType: type,
    manurePhase: phase,
  } : 
  {
    id: mmsSystem.id,
    share: Number(mmsShare),
    mmsViewModelType: type,
  };

  if (storagePeriod) {
    // TODO - Move this logic to Blonk Mapper when refactoring data model  to domain
    const formatedStoragePeriod = type.toUpperCase().includes('DEEP_BEDDING')
      ? storagePeriod.replace(/ /, '_').replace(/ /, '')
      : storagePeriod.replace(/ /g, '');

    return {
      ...baseMms,
      mmsType: `${type}_${formatedStoragePeriod.toUpperCase()}`,
      storagePeriod,
    } as ManureManagementSystemDBModel;
  }

  return {
    ...baseMms,
    mmsType: type.toUpperCase(),
  } as ManureManagementSystemDBModel;
};

function mapManureSystems(
  incomingData: Array<ManureManagementSystem>
): Array<ManureManagementSystemDBModel> {
  if (!incomingData || !incomingData.length) return [];

  return incomingData.filter((mms) => !!mms.mmsType).map(mapBeefMmsToDBModel);
}

function mapCalvesOutput(
  incomingData: BeefOutputBreeding
): BreedingOutputDBModel {
  if (!incomingData) return {} as BreedingOutputDBModel;

  return {
    animalsOutgoing: Number(incomingData.weanedCalvesToAnotherStage),
    averageWeight: formatMassInput(
      incomingData.averageWeightOfCalvesLeavingTheStage as number
    ),
    mortalities: Number(incomingData.numberOfMortalitiesCalves),
    soldExternally: Number(incomingData.weanedCalvesSold),
    weightAtMortality: formatMassInput(
      incomingData.averageWeightAtMortalityCalves as number
    ),
  };
}

function mapCowsOutput(
  incomingData: BeefOutputBreeding | BeefOutputGrowing
): BreedingOutputDBModel {
  return {
    animalsOutgoing: Number(incomingData.cowsToAnotherStage),
    averageWeight: formatMassInput(
      incomingData.averageWeightOfCowsLeavingTheStage
    ),
    mortalities: Number(incomingData.numberOfMortalitiesCows),
    soldExternally: Number(incomingData.cowsSold),
    weightAtMortality: formatMassInput(
      incomingData.averageWeightAtMortalityCows
    ),
  };
}

function mapGrowingStageCowsOutput(
  incomingData: BeefOutputGrowing
): GrowingOutputDBModel {
  return {
    ...mapCowsOutput(incomingData),
    averageAge: Number(incomingData.averageAge),
  };
}

function mapInternalSource(
  incomingData: Array<InternalSource>
): Array<AnimalsIncomingDBModel> {
  const internalSources: Array<AnimalsIncomingDBModel> = [];

  if (!incomingData) return internalSources;

  incomingData?.forEach((internalSource) => {
    if (
      internalSource 
      && internalSource.numberAnimals 
      && Number(internalSource.numberAnimals) > 0
    ) {
      internalSources.push({
        id: String(internalSource.id),
        origin: 'PRODUCTION_SYSTEM',
        baselineRef: String(internalSource.baselineRef),
        originStageId: String(internalSource.originStageId),
        originStageName: String(internalSource.stageName),
        originFarmName: String(internalSource.farmName),
        originFarmId: String(internalSource.farmId),
        stageType: String(internalSource.stageType),
        animalType: internalSource.animalType === 'CALF' ? 'CALF' : 'COW',
        numberOfAnimals: Number(internalSource.numberAnimals) || 0,
        shrinkingRate: internalSource.shrinkingRate
          ? Number(internalSource.shrinkingRate)
          : undefined,
        transportDistance: internalSource.distanceTruck
          ? formatTerrestrialTransportInput(internalSource.distanceTruck)
          : undefined,
      });
    }
  });

  return internalSources;
}

function mapExternalSources(
  incomingData: Array<ExternalSource>
): Array<AnimalsIncomingDBModel> {
  const externalSources: Array<AnimalsIncomingDBModel> = [];

  if (!incomingData) return externalSources;

  incomingData.forEach((externalSource) => {
    if (
      externalSource 
      && externalSource.numberAnimals 
      && Number(externalSource.numberAnimals) > 0
    ) {
      externalSources.push({
        id: String(externalSource.id),
        origin: 'BACKGROUND_DATA',
        numberOfAnimals: Number(externalSource.numberAnimals),
        transportDistance: externalSource.distanceTruck
          ? formatTerrestrialTransportInput(externalSource.distanceTruck)
          : undefined,
        shrinkingRate: externalSource.shrinkingRate
          ? Number(externalSource.shrinkingRate)
          : undefined,
      });
    }
  });

  return externalSources;
}

function mapBreedingInput(
  incomingData: BeefInputBreeding
): CommonStageInputModel {
  const internalSources = mapInternalSource(incomingData?.internalSources);
  const externalSources = mapExternalSources(incomingData?.externalSources);

  return {
    animals: [...internalSources, ...externalSources],
    averageWeight: formatMassInput(incomingData?.averageWeightNewAnimals),
  };
}

function mapGrowingInput(
  incomingData: BeefInputGrowing
): GrowingStageInputModel {
  const internalSources = mapInternalSource(incomingData?.internalSources);
  const externalSources = mapExternalSources(incomingData?.externalSources);

  return {
    animals: [...internalSources, ...externalSources],
    averageWeight: formatMassInput(incomingData?.averageWeightNewAnimals),
    averageAge: Number(incomingData.averageAgeAtStart),
  };
}

function mapSingleIngredients(
  incomingData?: Array<BaselineFeedWithOrigin>
): Array<SingleIngredientUseDBModel> {
  if (!incomingData || !incomingData.length) {
    return [];
  }

  return incomingData
    .filter(
      (singleIngredient) =>
        Boolean(singleIngredient?.feedType) &&
        Number(singleIngredient?.kgPerAnimal) > 0
    )
    .map((singleIngredient) => ({
      quantity: formatMassInput(singleIngredient.kgPerAnimal),
      silage: singleIngredient?.isSilage || false,
      ingredient: {
        id: singleIngredient.id,
        name: singleIngredient.feedType,
        origin: singleIngredient.origin,
      },
    }));
}

function mapCompoundFeeds(
  incomingData?: Array<BaselineFeed> | null
): Array<CompundFeedInfoDBModel> {
  if (!incomingData || !incomingData.length) {
    return [];
  }

  return incomingData
    .filter(
      (compoundFeed) =>
        Boolean(compoundFeed?.feedType) && Number(compoundFeed?.kgPerAnimal) > 0
    )
    ?.map(
      (compoundFeed) =>
        ({
          quantity: formatMassInput(compoundFeed.kgPerAnimal),
          compoundFeedId: compoundFeed.feedType,
        } as CompundFeedInfoDBModel)
    );
}

function mapFreshGrass(
  incomingData?: Array<FreshGrass>
): Array<FreshGrassDBModel> {
  if (!incomingData?.length) return [];

  const mappedFreshGrass = incomingData
    ?.filter(
      (freshGrass) => Boolean(freshGrass.type) && Boolean(freshGrass.amount)
    )
    .map(
      (freshGrass: FreshGrass) =>
        ({
          type: String(freshGrass.type),
          amount: formatMassInput(Number(freshGrass.amount)),
          crudeProteinContent: Number(freshGrass.crudeProteinContent),
          dryMatterContent: Number(freshGrass.dryMatterContent),
          digestibility: Number(freshGrass.digestibility),
          grossEnergyContent: freshGrass.grossEnergyContent
            ? Number(freshGrass.grossEnergyContent)
            : undefined,
          urinaryEnergyContent: Number(
            lookupValues.compoundFeedNutrientsDefault['Beef system']
              .urinaryEnergyContent
          ),
          ashContent: freshGrass.ashContent
            ? Number(freshGrass.ashContent)
            : undefined,
        } as FreshGrassDBModel)
    );

  return mappedFreshGrass;
}

function mapFeedingProgram(incomingData: BeefFeed): FeedingProgramDBModel {
  return {
    databaseFoundation: incomingData?.databaseFoundation
      ? (incomingData?.databaseFoundation as CompoundFeedDatabase)
      : ('' as CompoundFeedDatabase),
    dietCharacterisation: incomingData?.dietCharacterisation,
    freshGrass: mapFreshGrass(incomingData?.freshGrass),
    compoundFeeds: mapCompoundFeeds(incomingData?.compoundFeeds),
    singleIngredients: mapSingleIngredients(incomingData?.singleIngredients),
  };
}

function mapCalfFeedingProgram(
  incomingData: BeefFeed
): FeedingProgramCalfDBModel {
  return {
    ...mapFeedingProgram(incomingData),
    averageMilkFat: incomingData?.averageMilkFat
      ? Number(incomingData.averageMilkFat)
      : undefined,
    mothersMilk: {
      amount: incomingData.mothersMilk?.amount
        ? formatMassInput(Number(incomingData.mothersMilk?.amount))
        : null,
      crudeProteinContent: Number(
        incomingData.mothersMilk?.crudeProteinContent
      ),
      digestibility: Number(incomingData.mothersMilk?.digestibility),
      dryMatterContent: Number(incomingData.mothersMilk?.dryMatterContent),
    },
  };
}

function mapTimeSpend(incomingData: BeefHousingAndManure): TimeSpentDBModel {
  return {
    timeInFeedlot: Number(incomingData.timeInFeedlot),
    timeInHousing: Number(incomingData.timeInHousing),
    timeOnLargeGrazingAreas: Number(incomingData.timeGrazingLargeAreas),
    timeOnSmallGrazingAreas: Number(incomingData.timeGrazingPastures),
  };
}

function mapStockChange(incomingData: BeefInputBreeding): StockChangeDBModel {
  return {
    averageWeightCowsStart: formatMassInput(incomingData.averageWeightAtStart),
    cowsPresentAtEnd: Number(incomingData.animalsPresentAtEnd),
    cowsPresentAtStart: Number(incomingData.animalsPresentAtStart),
  };
}

function mapBreedingStageData(
  incomingData: BeefBreedingStageData
): BeefBreedingStageDataDBModel {
  const { housing } = incomingData;

  const mappedBeddingSystems = mapBeddingSystems(housing?.beddingSystems);
  return {
    beddingCows: mappedBeddingSystems,
    beddingCalves: mappedBeddingSystems,
    emissionMitigationsCows: mapEmissionsAndMitigations(
      incomingData.emissions?.cows
    ),
    emissionMitigationsCalves: mapEmissionsAndMitigations(
      incomingData.emissions?.calves
    ),
    mmsCows: mapManureSystems(incomingData.housing.cows?.manureSystems),
    mmsCalves: mapManureSystems(incomingData.housing.calves?.manureSystems),
    outputCalves: mapCalvesOutput(incomingData.output),
    outputCows: mapCowsOutput(incomingData.output),
    input: mapBreedingInput(incomingData.input),
    timeSpentCalves: mapTimeSpend(incomingData.housing.calves),
    timeSpentCows: mapTimeSpend(incomingData.housing.cows),
    stockChange: mapStockChange(incomingData.input),
    feedingProgramCalves: mapCalfFeedingProgram(incomingData.feed.calves),
    feedingProgramCows: mapFeedingProgram(incomingData.feed.cows),
    lengthStageCalves: Number(incomingData.input?.permanencePeriod) || 0,
    calvesBirthWeight: incomingData.input.averageWeightAtBirth
      ? formatMassInput(Number(incomingData.input.averageWeightAtBirth))
      : undefined,

    animalProductivity: incomingData.input.cattleProductivity,

    milkProduction: {
      amount: Number(incomingData.feed.calves.mothersMilk?.amount),
      fatContent: Number(incomingData.feed.calves.averageMilkFat),
      proteinContent: Number(
        incomingData.feed.calves.mothersMilk?.crudeProteinContent
      ),
    },
  };
}

function mapGrowingStageData(
  incomingData: BeefGrowingStageData
): BeefGrowingStageDataDBModel {
  return {
    feedingProgramCows: mapFeedingProgram(incomingData?.feed),
    beddingCows: mapBeddingSystems(incomingData.housing?.beddingSystems),
    outputCows: mapGrowingStageCowsOutput(incomingData.output),
    input: mapGrowingInput(incomingData.input),
    timeSpentCows: mapTimeSpend(incomingData.housing),
    animalProductivity: incomingData.input.cattleProductivity,
    emissionMitigationsCows: mapEmissionsAndMitigations(incomingData.emissions),
    mmsCows: mapManureSystems(incomingData.housing.manureSystems),
    isStageRepeated: String(incomingData.input.isStageRepeated),
    numberOfRepetitions: Number(incomingData.input.numberOfRepetitions),
    growingPurpose: String(incomingData.input.growingPurpose) as
      | 'REPLACEMENT'
      | 'BEEF_PRODUCTION',
  };
}

function getStartDateFromStage(
  incomingData: BeefGrowingStageData | BeefBreedingStageData
) {
  return incomingData.input.startDate as string;
}

function getEndDateFromStage(incomingData: BeefGrowingStageData) {
  return incomingData.input.endDate as string;
}

function setAverageWeightOnProcessingStage(
  processingStage: ProcessingStage,
  averageWeight: number,
  quantity?: number
): ProcessingStage {
  const currentQuantity = processingStage.stageData.processingInput
    .quantity as number;
  return {
    ...processingStage,
    stageData: {
      ...processingStage.stageData,
      processingInput: {
        ...processingStage.stageData.processingInput,
        averageWeight,
        quantity: quantity || currentQuantity,
      },
    },
  };
}

function getProcessingStageWithAverageWeight(
  incomingData: BeefBaseline,
  processingStage: ProcessingStage
): ProcessingStage {
  const originStage = incomingData.stages.find(
    (stage) =>
      stage.id === processingStage.stageData?.processingInput?.originStageId
  );

  if (!originStage) return processingStage;

  if (originStage.type === StageType.Growing) {
    const originStageData = originStage.stageData as BeefGrowingStageData;
    const averageWeight =
      originStageData.output.averageWeightOfCowsLeavingTheStage;

    return setAverageWeightOnProcessingStage(processingStage, averageWeight);
  }

  if (originStage.type === StageType.Breeding) {
    const originStageData = originStage.stageData as BeefBreedingStageData;
    const isCowInput =
      processingStage.stageData.processingInput.animalType === 'COW';
    const averageWeight = isCowInput
      ? originStageData.output.averageWeightOfCowsLeavingTheStage
      : originStageData.output.averageWeightOfCalvesLeavingTheStage;

    return setAverageWeightOnProcessingStage(
      processingStage,
      Number(averageWeight)
    );
  }

  if (originStage.type === StageType.Processing) {
    const originStageData = originStage.stageData as ProcessingStageData;
    const averageWeight = Number(
      originStageData?.processingOutput?.freshMeatAndEdibleOffal?.weight
    );
    const quantity = 1;
    return setAverageWeightOnProcessingStage(
      processingStage,
      averageWeight,
      quantity
    );
  }

  return processingStage;
}

function mapStageData(
  incomingData: BeefStage | ProcessingStage,
  formData: BeefBaseline
): BeefStageDBModel | ProcessingStageDBModel {
  const { stageData } = incomingData;
  if (incomingData.type === StageType.Processing) {
    const processingStage = getProcessingStageWithAverageWeight(
      formData,
      incomingData as ProcessingStage
    );
    return mapOutProcessingStage(processingStage);
  }

  const commonStageData: Omit<StageDBModel, 'stageData'> = {
    id: incomingData.id,
    name: incomingData.name,
  };

  if (incomingData.type === StageType.Breeding) {
    return {
      ...commonStageData,
      stageData: mapBreedingStageData(stageData as BeefBreedingStageData),
      type: StageType.Breeding,
      startDate: getStartDateFromStage(stageData as BeefBreedingStageData),
    };
  }

  return {
    ...commonStageData,
    stageData: mapGrowingStageData(stageData as BeefGrowingStageData),
    type: StageType.Growing,
    startDate: getStartDateFromStage(stageData as BeefGrowingStageData),
    endDate: getEndDateFromStage(stageData as BeefGrowingStageData),
  };
}

const mapBeefProductionDataOutSustell = (
  formData: BeefBaseline,
  isDraft: boolean
) => {
  try {
    let mappedData = createCorePayloadSustell(formData, isDraft);
    // TODO - Map resource is not typed, so it's not possible to fix this issue yet.
    /* eslint-disable @typescript-eslint/no-unsafe-assignment */
    mappedData = mapResourceUseSustell(formData, mappedData);
  
    if (formData?.stages && !Array.isArray(formData.stages)) return mappedData;
  
    return formData.stages.reduce(
      (payload, stage) => {
        if (!stage?.stageData) return mappedData;
  
        const mappedStage = mapStageData(stage, formData);
  
        return {
          ...payload,
          stages: [
            ...payload.stages,
            { ...mappedStage, stageData: JSON.stringify(mappedStage.stageData) },
          ],
        };
      },
      { ...mappedData }
    );
  } catch (err) {
    console.log('Error on mapBeefProductionDataOutSustell: ', err)
  }

  return {}
};

export default mapBeefProductionDataOutSustell;
