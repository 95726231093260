import type { GraphQLResult } from '@aws-amplify/api-graphql';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@material-ui/core';
import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import * as Yup from 'yup';

import {
  DsmButton,
  DsmFieldset,
  DsmGrid,
  DsmIcon,
  DsmSectionHeader,
} from '@dsm-dcs/design-system-react';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { createFarm, updateFarm } from '../../../../graphql/mutations';
import { getFarmBaseData } from '../../../../graphql/queries';

import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { ClimateType } from '../../../../graphql/generated/blonk/pigs';
import Can from '../../../modules/Auth/Can';
import ReactHookDsmInput from '../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../modules/Helpers/ReactHookDsmSelect2';
import useAdobeDataLayer from '../../analytics/adobeDataLayer';
import {
  ApiCallErrorMessage,
  ApiCallErrors,
} from '../../commonTypes/apiErrors';
import { listEntryDataSorter } from '../../helpers/sortingFunctions';
import { forbiddenChars } from '../../helpers/validations';
import {
  enumToOptionsArrayWithTranslatedStrings,
  objectToOptionsArrayWithTranslatedStrings,
} from '../../utils/obj-utils';
import { AnimalType } from '../../../../graphql/types';
import countryIsoCodes from '../dashboard/isoCodes';
import ClimateMapHolder from './CommonDataParts/v2.0/ClilmateMapHolder';
import { FormType } from './common';
import { isMarineFish } from '../../helpers/animals';

type Farm = {
  id?: string;
  name: string;
  oldName?: string;
  country: string;
  city: string;
  size: number | null;
  updatedAt?: string;
  climateType: ClimateType;
  benchmark?: boolean;
};

// should be replaced with CreateFarmInput and UpdateFarmInput - this type combines both
type FarmSendData = {
  id?: string;
  name: string;
  old_name?: string;
  country: string;
  city: string;
  size: number;
  climateType: ClimateType;
};

type FarmResponseData = {
  id: string;
};

interface FarmFormProps {
  formType: FormType;
  farmId?: string;
  farmInit?: {
    farmName?: string;
    farmCity?: string;
    farmCountry?: string;
    animalType?: AnimalType;
  };
  customerId: string;
  onFarmAdded?: (id: string | undefined) => void | undefined;
  switchToEdit?: () => void;
}

type FarmCreateUpdateAPIResponse = {
  data?: {
    createFarm?: FarmResponseData;
    updateFarm?: FarmResponseData;
  };
};

// placeholders for now
type Errors = any;

// image isn't in indexed colors, so some deviations are possible,
// for some climate zones several detected colors are matched in map
const colorClimateZoneMapper: Map<string, ClimateType> = new Map([
  ['102,154,206', ClimateType.TropicalMontane],
  ['66,138,113', ClimateType.TropicalWet],
  ['66,138,112', ClimateType.TropicalWet],
  ['69,139,120', ClimateType.TropicalWet],
  ['138,206,102', ClimateType.TropicalMoist],
  ['142,208,107', ClimateType.TropicalMoist],
  ['245,245,123', ClimateType.TropicalDry],
  ['116,224,255', ClimateType.WarmTemperateMoist],
  ['255,212,128', ClimateType.WarmTemperateDry],
  ['206,245,123', ClimateType.CoolTemperateMoist],
  ['195,159,216', ClimateType.CoolTemperateDry],
  ['195,159,216', ClimateType.CoolTemperateDry],
  ['159,172,216', ClimateType.BorealMoist],
  ['159,171,216', ClimateType.BorealMoist],
  ['216,216,159', ClimateType.BorealDry],
]);

const SustellFarmBaseForm: React.FC<FarmFormProps> = ({
  formType = FormType.Add,
  customerId,
  farmInit,
  farmId,
  switchToEdit,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const [mapOpen, setMapOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [benchmark, setbenchMark] = useState(false);

  const { formErrorsEvent, formSubmissionEvent } = useAdobeDataLayer();
  let farmName = '';
  let farmCity = '';
  let farmCountry = '';
  let animalType = AnimalType.Pig;
  if (farmInit) {
    farmName = farmInit?.farmName || '';
    farmCity = farmInit?.farmCity || '';
    farmCountry = farmInit?.farmCountry || '';
    animalType = farmInit?.animalType || AnimalType.Pig;
  }

  const isMarineFishFarm = isMarineFish(animalType);

  const getFarmGeneralData = async (farmID: string) => {
    const response = await (API.graphql({
      query: getFarmBaseData as string,
      variables: {
        farmId: farmID,
      },
    }) as Promise<{
      data: {
        getFarm: Farm;
      };
    }>);

    return response.data;
  };

  const validationSchemaBuild = () => {
    let validationSchema = Yup.object({
      name: Yup.string()
        .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
        .min(
          3,
          intl.formatMessage(
            { id: 'VALIDATION.FIELD.MIN_LENGTH' },
            { count: 3 }
          )
        )
        // prevent use of quotation marks in the name
        .concat(
          forbiddenChars(
            intl,
            ['"', "'"],
            'VALIDATION.FIELD.FORBIDDEN_QUOTATION'
          )
        ),
      country: Yup.string().required(
        intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
      ),
    });
    if (!isMarineFishFarm) {
      validationSchema = validationSchema.concat(
        Yup.object({
          city: Yup.string()
            .required(intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' }))
            .min(
              2,
              intl.formatMessage(
                { id: 'VALIDATION.FIELD.MIN_LENGTH' },
                { count: 2 }
              )
            ),
          climateType: Yup.string()
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            .transform((changed, original) =>
              original === '' ? undefined : changed
            )
            .typeError(
              intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
            )
            .required(
              intl.formatMessage({ id: 'VALIDATION.FIELD.INPUT_SELECT' })
            ),
        })
      );
    }
    return validationSchema;
  };

  const methods = useForm<Farm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchemaBuild()),
    defaultValues: {
      id: '',
      oldName: '',
      name: farmName || '',
      country: farmCountry || '',
      city: farmCity || '',
      size: null,
      climateType: undefined,
    },
  });

  useEffect(() => {
    if (farmId && farmId.toLowerCase() !== 'new') {
      const result = getFarmGeneralData(farmId);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      result
        .then((response) => {
          // create union of BROILERS and SALMON, as only Salmon has different countries
          // console.log("getFarm response", response);
          methods.reset({
            ...response.getFarm,
            oldName: response.getFarm.oldName ?? response.getFarm.name,
          });
          if (response.getFarm?.benchmark) {
            setbenchMark(response.getFarm?.benchmark);
          }
          if (formType === FormType.Edit && !response.getFarm.climateType) {
            // console.log("editing farm")
            methods
              .trigger('climateType')
              .then(() => {})
              .catch(() => {});
          }
        })
        .catch((error: Errors) => {
          // eslint-disable-next-line  no-console
          console.log('get farm data error: ', error);
        });
    }
    // I really want to update only when farmId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmId, isMarineFishFarm]);

  const handleCancel = () => {
    methods.reset({});
    setErrorMessage('');
    history.push(`/customers/${customerId}/myfarms`);
  };

  const onSubmit = (submitData: Farm) => {
    (async () => {
      formSubmissionEvent(
        formType === FormType.Add ? 'Create New Farm' : 'Edit Farm',
        formType
      );
      const inputData: FarmSendData = {
        name: submitData.name,
        city: submitData.city || '',
        size: submitData.size || 0,
        country: submitData.country,
        climateType: submitData.climateType,
      };

      if (formType === FormType.Edit) {
        inputData.id = submitData.id;
        inputData.old_name = submitData.oldName;
      }

      const result: FarmCreateUpdateAPIResponse = (await API.graphql({
        query: formType === FormType.Add ? createFarm : updateFarm,
        variables: {
          customerId,
          input: inputData,
        },
      })) as GraphQLResult;
      setErrorMessage('');

      const resultFarmData: FarmResponseData | undefined =
        formType === FormType.Add
          ? result.data?.createFarm
          : result.data?.updateFarm; // object containg only the id of inserted entity
      if (resultFarmData && resultFarmData.id) {
        methods.reset({});
        history.push(`/customers/${customerId}/farm/${resultFarmData.id}/pp`, {
          animalType,
        });
      } else {
        setErrorMessage(intl.formatMessage({ id: 'GENERAL.ERROR.SAVING' }));
      }
    })().catch((err) => {
      const apiErrors: ApiCallErrors = err as ApiCallErrors;
      if (apiErrors.errors?.length > 0) {
        const error: ApiCallErrorMessage = apiErrors.errors[0];
        if (error.errorType === 'ValidationError') {
          setErrorMessage(error.message);
        }
      }
    });
  };

  const onErrors = (errors: any) => {
    console.log(errors);
    setErrorMessage(intl.formatMessage({ id: 'VALIDATION.GENERAL.MESSAGE' }));
    const formErrors = Object.keys(methods.formState.errors).length;
    formErrorsEvent(
      formType === FormType.Add ? 'Create New Farm' : 'Edit Farm',
      formType,
      formErrors
    );
  };

  const openMap = () => setMapOpen(true);

  const setClimateType = (value: ClimateType) => {
    if (value) {
      methods.setValue('climateType', value);
      setMapOpen(false);
    }
  };

  const setZoneInfo = (r: number, g: number, b: number) => {
    const strValue = `${r},${g},${b}`;
    const selClimateZone = colorClimateZoneMapper.get(strValue) || undefined;
    return selClimateZone;
  };

  const continueHandler = () => {
    if (farmId)
      history.push(`/customers/${customerId}/farm/${farmId}/pp`, {
        animalType,
      });
  };

  const introText = () => {
    if (!isMarineFish(animalType))
      return intl.formatMessage({ id: 'SUSTELL.FARM.INFORMATION.INTRO' });
    return intl.formatMessage({
      id: 'SUSTELL.FARM.INFORMATION.MARINE_FISH.INTRO',
    });
  };

  return (
    <React.Fragment key="farm-form">
      {/* <Prompt
        when={methods.formState.isDirty}
        message={intl.formatMessage({
          id: 'FARMS.FORM.VALIDATION.UNSAVED_CHANGES',
        })}
      /> */}
      <ClimateMapHolder
        formVisible={mapOpen}
        handleSave={setClimateType}
        handleClose={() => setMapOpen(false)}
        setZoneInfo={setZoneInfo}
      />
      {/* eslint-disable react/jsx-props-no-spreading */}
      <DsmGrid
        style={{
          grid: 'auto/1fr auto',
          justifyContent: 'space-between',
          padding: '0',
          alignItems: 'baseline',
          columnGap: 'var(--dsm-spacing-px-4)',
        }}
      >
        <DsmSectionHeader
          style={{ marginTop: `var(--dsm-spacing-px-16)` }}
          header={intl.formatMessage({ id: 'SUSTELL.FARM.INFORMATION' })}
          description={introText()}
        />
        {!benchmark &&
          formType === FormType.View &&
          Can('update', 'Farm') && (
            <DsmButton variant="secondary" onClick={switchToEdit}>
              {intl.formatMessage({ id: 'GENERAL.EDIT' })}
            </DsmButton>
          )}
      </DsmGrid>

      {errorMessage && (
        <Grid container spacing={1} style={{ marginBottom: 10 }}>
          <Grid item xs={12}>
            <Alert severity="error">
              <AlertTitle>
                {intl.formatMessage({ id: 'GENERAL.ERROR' })}
              </AlertTitle>
              {errorMessage}
            </Alert>
          </Grid>
        </Grid>
      )}
      <FormProvider {...methods}>
        <form>
          {/* id and oldName used for edit mode */}
          <input ref={methods.register()} type="hidden" name="id" />
          <input ref={methods.register()} type="hidden" name="oldName" />
          <input ref={methods.register()} type="hidden" name="size" />
          <DsmFieldset
            style={{ width: '70%', marginTop: `var(--dsm-spacing-px-4)` }}
          >
            <ReactHookDsmInput
              name="name"
              label={intl.formatMessage({ id: 'FARM.NAME' })}
              placeholder={intl.formatMessage({ id: 'FARM.NAME' })}
              disabled={formType === FormType.View}
              required
            />
            {!isMarineFishFarm && (
              <ReactHookDsmInput
                name="city"
                label={intl.formatMessage({ id: 'GENERAL.CITY.LOCATION' })}
                placeholder={intl.formatMessage({
                  id: 'GENERAL.CITY.LOCATION',
                })}
                disabled={formType === FormType.View}
              />
            )}
            {isMarineFishFarm && <div />}
          </DsmFieldset>
          <DsmFieldset
            style={{ width: '70%', marginTop: `var(--dsm-spacing-px-4)` }}
          >
            <ReactHookDsmSelect
              name="country"
              label={intl.formatMessage({
                id: 'GENERAL.COUNTRY',
              })}
              disabled={formType === FormType.View}
              placeholder={intl.formatMessage({ id: 'GENERAL.COUNTRY' })}
              options={objectToOptionsArrayWithTranslatedStrings(
                countryIsoCodes,
                intl,
                'SUSTELL.GEOGRAPHY'
              ).sort(listEntryDataSorter)}
              required
            />
            {!isMarineFishFarm && (
              <ReactHookDsmSelect
                name="climateType"
                label={intl.formatMessage({
                  id: 'SUSTELL.CLIMATE_TYPE',
                })}
                placeholder={intl.formatMessage({
                  id: 'SUSTELL.CLIMATE_TYPE',
                })}
                disabled={formType === FormType.View}
                options={enumToOptionsArrayWithTranslatedStrings(
                  ClimateType,
                  intl,
                  'SUSTELL.GEOGRAPHY.CLIMATE_ZONE'
                )}
                required
              />
            )}
            {isMarineFishFarm && <div />}
          </DsmFieldset>
          {!isMarineFishFarm && (
            <DsmFieldset style={{ width: '70%' }}>
              <div />
              <DsmButton
                variant="text"
                onClick={openMap}
                disabled={formType === FormType.View}
              >
                <DsmIcon slot="before" name="maps-travel/map-01" />
                {intl.formatMessage({
                  id: 'SUSTELL.GEOGRAPHY.CLIMATE_ZONE.CLICK_MAP',
                })}
              </DsmButton>
            </DsmFieldset>
          )}
        </form>

        <Box
          style={{
            display: 'flex',
            width: '100%',
            marginTop: 'var(--dsm-spacing-px-8)',
          }}
          flexDirection="row"
          justifyContent="space-between"
        >
          <DsmButton
            variant="primary"
            destructive={true}
            onClick={handleCancel}
          >
            <DsmIcon slot="before" name="general/trash-01" />
            {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
          </DsmButton>
          <Box
            style={{ display: 'flex', width: '30%' }}
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            {/* <DsmButton variant="text">Skip for now</DsmButton> */}
            {formType !== FormType.View && (
              <DsmButton
                style={{ marginLeft: 'var(--dsm-spacing-px-4)' }}
                variant="primary"
                onClick={methods.handleSubmit(onSubmit, onErrors)}
              >
                {intl.formatMessage({ id: 'SUSTELL.SAVE_CONTINUE' })}
                <DsmIcon slot="after" name="arrows/chevron-right" />
              </DsmButton>
            )}
            {formType === FormType.View && (
              <DsmButton
                style={{ marginLeft: 'var(--dsm-spacing-px-4)' }}
                variant="primary"
                onClick={continueHandler}
              >
                {intl.formatMessage({ id: 'SUSTELL.CONTINUE' })}
                <DsmIcon slot="after" name="arrows/chevron-right" />
              </DsmButton>
            )}
          </Box>
        </Box>
      </FormProvider>
    </React.Fragment>
  );
};

SustellFarmBaseForm.defaultProps = {};

export default SustellFarmBaseForm;
