import { v4 as uuidv4 } from 'uuid';
import { defaultUnitsV2 } from '../../../../sustell_15/utils/unit-utils';
import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import { floatValueInDefaultUnit } from './floatHelperFunctions';
import {
  mapCommonProductionDataOutSustell,
  mapResourceUseSustell,
  getUserTransportUnit,
  getDefaultTransportUnit,
} from './outCommonMapperSustellV2';
import { InputAnimalOrigin } from '../../../../../graphql/generated/blonk/shrimp';

const ResourceUseType = {
  Coal: 'coalUse',
  Diesel: 'dieselUse',
  ElectricityPhotovoltaic: 'electricityPhotovoltaicUse',
  ElectricityWind: 'electricityWindUse',
  Gasoline: 'gasolineUse',
  PropaneOrLpg: 'propaneOrLpgUse',
};

const mapInputForOneStage = (inputBlockData, stageGeneralData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const outputMassPrecision = userPrefs.getUnitOutputMassPrecision();
  const { stageData } = stageGeneralData;
  const { stock, input } = stageData;

  if (inputBlockData) {
    stageData.pondProductionArea = parseFloat(inputBlockData.totalPondArea);
    stageData.emptyPeriod = parseFloat(inputBlockData.emptyPeriod);

    // stock
    if (
      inputBlockData.totalWeightAtStartDate ||
      inputBlockData.totalWeightAtStartDate === 0
    )
      stock.weightAtStart = floatValueInDefaultUnit(
        inputBlockData.totalWeightAtStartDate,
        userUOM.unitOutputMass,
        defaultUnitsV2.unitOutputMass,
        outputMassPrecision
      );
    if (
      inputBlockData.totalWeightAtEndDate ||
      inputBlockData.totalWeightAtEndDate === 0
    )
      stock.weightAtEnd = floatValueInDefaultUnit(
        inputBlockData.totalWeightAtEndDate,
        userUOM.unitOutputMass,
        defaultUnitsV2.unitOutputMass,
        outputMassPrecision
      );
    if (
      inputBlockData.priceAtStartDate ||
      inputBlockData.priceAtStartDate === 0
    )
      stock.priceAtStart = parseFloat(inputBlockData.priceAtStartDate);
    if (inputBlockData.priceAtEndDate || inputBlockData.priceAtEndDate === 0)
      stock.priceAtEnd = parseFloat(inputBlockData.priceAtEndDate);

    // input - supply
    inputBlockData.internalSources?.forEach((item) => {
      const inputData = {
        id: item.id || uuidv4(),
        weight: floatValueInDefaultUnit(
          item.totalWeight,
          userUOM.unitOutputMass,
          defaultUnitsV2.unitOutputMass,
          outputMassPrecision
        ),
        origin: InputAnimalOrigin.ProductionSystem,
        originBaselineId: item.originBaselineId,
        originStageId: item.comingFromStage,
        originFarmId: item.comingFromFarm,
        originOutputName: item.outputName,
      };

      if (item.distanceTraveled || item.distanceTraveled === 0) {
        inputData.transport = [
          {
            distance: floatValueInDefaultUnit(
              item.distanceTraveled,
              getUserTransportUnit(item.transportMode),
              getDefaultTransportUnit(item.transportMode)
            ),
            mode: item.transportMode,
          },
        ];
      }

      input.push(inputData);
    });
    // input - external sources
    inputBlockData.externalSources?.forEach((item) => {
      const externalSource = {
        id: item.id || uuidv4(),
        weight: floatValueInDefaultUnit(
          item.totalWeight,
          userUOM.unitOutputMass,
          defaultUnitsV2.unitOutputMass,
          outputMassPrecision
        ),
        origin: InputAnimalOrigin.BackgroundData,
      };

      if (item.distanceTraveled || item.distanceTraveled === 0) {
        externalSource.transport = [
          {
            distance: floatValueInDefaultUnit(
              item.distanceTraveled,
              getUserTransportUnit(item.transportMode),
              getDefaultTransportUnit(item.transportMode)
            ),
            mode: item.transportMode,
          },
        ];
      }

      input.push(externalSource);
    });
  }
};

const mapOperationsForOneStage = (operationsBlockData, stageGeneralData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = stageGeneralData;
  const {
    chemicalsUse,
    resourceUse,
    pondWaterProperties,
    materialsUse,
    feedingProgram,
  } = stageData;
  const { fertilizers } = feedingProgram;

  if (operationsBlockData) {
    // chemicalsUse
    operationsBlockData.chemicalTypes?.forEach((item) => {
      if (
        item.amount !== undefined &&
        !Number.isNaN(item.amount) &&
        item.amount > 0 &&
        item.type !== undefined &&
        item.type !== ''
      ) {
        const chemicalType = {
          id: item.id || uuidv4(),
          amount: floatValueInDefaultUnit(
            item.amount,
            userUOM.unitBarnOutputMass,
            defaultUnitsV2.unitBarnOutputMass
          ),
          type: item.type,
        };
        chemicalsUse.push(chemicalType);
      }
    });

    // resourceUse
    if (
      operationsBlockData.resourceUse?.electricityUse ||
      operationsBlockData.resourceUse?.electricityUse === 0
    )
      resourceUse.electricityUse = floatValueInDefaultUnit(
        operationsBlockData.resourceUse.electricityUse,
        userUOM.unitElectricityUse,
        defaultUnitsV2.unitElectricityUse
      );
    if (
      operationsBlockData.resourceUse.gasUse ||
      operationsBlockData.resourceUse.gasUse === 0
    )
      resourceUse.gasUse = floatValueInDefaultUnit(
        operationsBlockData.resourceUse.gasUse,
        userUOM.unitNaturalGasUse,
        defaultUnitsV2.unitNaturalGasUse
      );
    if (
      operationsBlockData.resourceUse.waterUse ||
      operationsBlockData.resourceUse.waterUse === 0
    )
      resourceUse.waterUse = floatValueInDefaultUnit(
        operationsBlockData.resourceUse.waterUse,
        userUOM.unitWaterUse,
        defaultUnitsV2.unitWaterUse
      );

    operationsBlockData.resourceUse.selfGeneratedRenewables?.forEach((item) => {
      if (item.type === ResourceUseType.ElectricityPhotovoltaic)
        resourceUse.electricityPhotovoltaicUse = floatValueInDefaultUnit(
          item.value,
          userUOM.unitElectricityUse,
          defaultUnitsV2.unitElectricityUse
        );
      if (item.type === ResourceUseType.ElectricityWind)
        resourceUse.electricityWindUse = floatValueInDefaultUnit(
          item.value,
          userUOM.unitElectricityUse,
          defaultUnitsV2.unitElectricityUse
        );
    });

    operationsBlockData.resourceUse.energyTypes?.forEach((item) => {
      if (item.type === ResourceUseType.Diesel)
        resourceUse.dieselUse = floatValueInDefaultUnit(
          item.value,
          userUOM.unitDieselUse,
          defaultUnitsV2.unitDieselUse
        );
      if (item.type === ResourceUseType.Gasoline)
        resourceUse.gasolineUse = floatValueInDefaultUnit(
          item.value,
          userUOM.unitDieselUse,
          defaultUnitsV2.unitDieselUse
        );
      if (item.type === ResourceUseType.PropaneOrLpg)
        resourceUse.propaneOrLpgUse = floatValueInDefaultUnit(
          item.value,
          userUOM.unitNaturalGasUse,
          defaultUnitsV2.unitNaturalGasUse
        );
      if (item.type === ResourceUseType.Coal)
        resourceUse.coalUse = floatValueInDefaultUnit(
          item.value,
          userUOM.unitHeatUse,
          defaultUnitsV2.unitHeatUse
        );
    });

    // pondWaterProperties
    if (
      operationsBlockData.resourceUse.waterEnteringPondIn ||
      operationsBlockData.resourceUse.waterEnteringPondIn === 0
    )
      pondWaterProperties.waterInAmount = floatValueInDefaultUnit(
        operationsBlockData.resourceUse.waterEnteringPondIn,
        userUOM.unitWaterUse,
        defaultUnitsV2.unitWaterUse
      );
    if (
      operationsBlockData.resourceUse.nitrogenConcentrationIn ||
      operationsBlockData.resourceUse.nitrogenConcentrationIn === 0
    )
      pondWaterProperties.waterInNitrogen = parseFloat(
        operationsBlockData.resourceUse.nitrogenConcentrationIn
      );
    if (
      operationsBlockData.resourceUse.waterEnteringPondOut ||
      operationsBlockData.resourceUse.waterEnteringPondOut === 0
    )
      pondWaterProperties.waterOutAmount = floatValueInDefaultUnit(
        operationsBlockData.resourceUse.waterEnteringPondOut,
        userUOM.unitWaterUse,
        defaultUnitsV2.unitWaterUse
      );
    if (
      operationsBlockData.resourceUse.nitrogenConcentrationOut ||
      operationsBlockData.resourceUse.nitrogenConcentrationOut === 0
    )
      pondWaterProperties.waterOutNitrogen = parseFloat(
        operationsBlockData.resourceUse.nitrogenConcentrationOut
      );
    if (operationsBlockData.resourceUse.watersalinity)
      pondWaterProperties.salinity =
        operationsBlockData.resourceUse.watersalinity;

    // ice
    stageData.ice = floatValueInDefaultUnit(
      operationsBlockData.iceAmount,
      userUOM.unitBarnOutputMass,
      defaultUnitsV2.unitBarnOutputMass
    );

    // materialsUse
    operationsBlockData.materialTypes?.forEach((item) => {
      if (item && item.type) {
        const materialType = {
          id: item.id || uuidv4(),
          type: item.type,
          amount: floatValueInDefaultUnit(
            item.amount,
            userUOM.unitBarnOutputMass,
            defaultUnitsV2.unitBarnOutputMass
          ),
          lifeTime: parseFloat(item.lifetimeOfMaterial),
        };

        if (item.distanceTraveled || item.distanceTraveled === 0)
          materialType.transport = [
            {
              distance: floatValueInDefaultUnit(
                item.distanceTraveled,
                getUserTransportUnit(item.transportMode),
                getDefaultTransportUnit(item.transportMode)
              ),
              mode: item.transportMode,
            },
          ];

        materialsUse.push(materialType);
      }
    });

    // fertilizers
    operationsBlockData.fertilizerTypes?.forEach((item) => {
      if (item.type) {
        const fertilizerType = {
          id: item.id || uuidv4(),
          quantity: floatValueInDefaultUnit(
            item.amount,
            userUOM.unitBarnOutputMass,
            defaultUnitsV2.unitBarnOutputMass
          ),
          type: item.type,
        };
        fertilizers.push(fertilizerType);
      }
    });
  }
};

const mapOutputForOneStage = (outputBlockData, stageGeneralData) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const { stageData } = stageGeneralData;
  const { output } = stageData;

  if (outputBlockData) {
    outputBlockData.outputs?.forEach((item) => {
      const outputData = {
        id: item.id || uuidv4(),
        weight: floatValueInDefaultUnit(
          item.totalWeight,
          userUOM.unitBarnOutputMass,
          defaultUnitsV2.unitBarnOutputMass
        ),
        calculateLca: item.calculateLca,
        name: item.name,
        price:
          item.price || item.price === 0 ? parseFloat(item.price) : undefined,
        soldExternally: item.soldExternally === 'Yes',
        proteinContent: parseFloat(item.proteinContent),
      };
      output.push(outputData);
    });
  }
};

const mapShrimpDataOutSustell = (formData, isDraft = false) => {
  let payloadObj = mapCommonProductionDataOutSustell(formData);
  payloadObj = mapResourceUseSustell(formData, payloadObj);

  if (formData.stages)
    formData.stages.forEach((formDataForOneStage, index) => {
      if (payloadObj.stages[index] !== undefined) {
        const formStageData = formDataForOneStage?.stageData;
        const stageGeneralData = payloadObj.stages[index];
        const { stageData } = stageGeneralData;

        if (formStageData && 'input' in formStageData) {
          stageData.stock = {};
          stageData.input = [];
          stageGeneralData.stageDuration = parseFloat(
            formStageData.input.stageDuration
          );
          mapInputForOneStage(formStageData.input, stageGeneralData, formData);
        }
        if (formStageData && 'operations' in formStageData) {
          stageData.chemicalsUse = [];
          stageData.resourceUse = {};
          stageData.pondWaterProperties = {};
          stageData.materialsUse = [];
          if (!stageData.feedingProgram) stageData.feedingProgram = {};
          stageData.feedingProgram.fertilizers = [];
          mapOperationsForOneStage(
            formStageData.operations,
            stageGeneralData,
            formData
          );
        }
        if (formStageData && 'output' in formStageData) {
          stageData.output = [];
          mapOutputForOneStage(formStageData.output, stageGeneralData);
        }
      }
    });
  payloadObj.stages.forEach((item) => {
    const { stageData } = item;
    // eslint-disable-next-line no-param-reassign
    item.stageData = JSON.stringify(stageData);
  });

  return payloadObj;
};

export default mapShrimpDataOutSustell;
