import { FC } from 'react';
import { DsmCheckbox, DsmGrid } from '@dsm-dcs/design-system-react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../common';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import DsmButtonControlGroup from '../../../helpers/DsmButtonControlGroup';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';

type BeefEmissionProps = {
  formType: FormType;
  fieldItemPrefix: string;
  stageIndex?: number;
  handleCancel: (param?: string | null) => void;
  handleReset: () => void;
};

const BeefEmissionInputsComponent: FC<BeefEmissionProps> = ({
  formType,
  fieldItemPrefix,
  handleReset,
  handleCancel,
  stageIndex = 0,
}) => {
  const intl = useIntl();
  const formContext = useFormContext();
  const classes = processAndStageStylesV2() as CSSClassesList;

  return (
    <div>
      <DsmGrid className={classes.dsmGridTwoColumnNoRowSpacing}>
        <ReactHookDsmInput
          type="number"
          name={`${fieldItemPrefix}.methaneEntericFermentation`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION',
          })}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.METHANE_ENTERIC_FERMENTATION.TOOLTIP',
          })}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={
            formContext.getValues(
              `${fieldItemPrefix}.methaneEntericFermentation`
            ) as string
          }
        />
        <ReactHookDsmInput
          type="number"
          name={`${fieldItemPrefix}.NMVOC`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.NON_METHANE_VOLATILE_ORGANIC_COMPOUNDS_NO_SOURCE',
          })}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.NON_METHANE_VOLATILE_ORGANIC_COMPOUNDS_NO_SOURCE',
          })}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.NMVOC`) as string
          }
        />
        <ReactHookDsmInput
          type="number"
          name={`${fieldItemPrefix}.methaneMms`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.METHANE_FROM_MANURE',
          })}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.METHANE_FROM_MANURE',
          })}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.methaneMms`) as string
          }
        />
        <ReactHookDsmInput
          type="number"
          name={`${fieldItemPrefix}.nitrousOxideLeaching`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_EMISSION_MANURE_LEACHING',
          })}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_EMISSION_MANURE_LEACHING',
          })}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={
            formContext.getValues(
              `${fieldItemPrefix}.nitrousOxideLeaching`
            ) as string
          }
        />
        <ReactHookDsmInput
          type="number"
          name={`${fieldItemPrefix}.nitrousOxideDirect`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_DIRECT_NO_SOURCE',
          })}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_DIRECT_NO_SOURCE',
          })}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={
            formContext.getValues(
              `${fieldItemPrefix}.nitrousOxideDirect`
            ) as string
          }
        />
        <ReactHookDsmInput
          type="number"
          name={`${fieldItemPrefix}.nitrousOxideVolatilization`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_VOLATILIZATION_FROM_MANURE',
          })}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.NITROUS_OXIDE_VOLATILIZATION_FROM_MANURE',
          })}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={
            formContext.getValues(
              `${fieldItemPrefix}.nitrousOxideVolatilization`
            ) as string
          }
        />
        <ReactHookDsmInput
          type="number"
          name={`${fieldItemPrefix}.nitrogenOxidesFromManureStorage`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.NITROGEN_OXIDE_FROM_MANURE_STORAGE',
          })}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.NITROGEN_OXIDE_FROM_MANURE_STORAGE',
          })}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={
            formContext.getValues(
              `${fieldItemPrefix}.nitrogenOxidesFromManureStorage`
            ) as string
          }
        />
        <ReactHookDsmInput
          type="number"
          name={`${fieldItemPrefix}.amoniaStorage`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_STORAGE',
          })}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_STORAGE',
          })}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.amoniaStorage`) as string
          }
        />
        <ReactHookDsmInput
          type="number"
          name={`${fieldItemPrefix}.amoniaHousing`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_HOUSING',
          })}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_HOUSING',
          })}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.amoniaHousing`) as string
          }
        />
        <ReactHookDsmInput
          type="number"
          name={`${fieldItemPrefix}.amoniaYard`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_YARD',
          })}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.AMMONIA_FROM_MANURE_EXCRETED_IN_YARD',
          })}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.amoniaYard`) as string
          }
        />

        <ReactHookDsmInput
          type="number"
          name={`${fieldItemPrefix}.PM2_5`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_2_5',
          })}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_2_5',
          })}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.PM2_5`) as string
          }
        />
        <ReactHookDsmInput
          type="number"
          name={`${fieldItemPrefix}.PM10`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_10',
          })}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.PARTICULATE_MATTER_SMALLER_THAN_10',
          })}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.PM10`) as string
          }
        />
        <ReactHookDsmInput
          type="number"
          name={`${fieldItemPrefix}.TSP`}
          label={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.TSP_NO_SOURCE',
          })}
          tooltip={intl.formatMessage({
            id: 'BASELINE.FORM.EMISSIONS.TSP_NO_SOURCE',
          })}
          adornment="%"
          disabled={formType === FormType.View}
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.TSP`) as string
          }
        />
        <div />
        <DsmCheckbox
          name={`${fieldItemPrefix}.NOP`}
          defaultValue={
            formContext.getValues(`${fieldItemPrefix}.NOP`) as string
          }
          checked={formContext.getValues(`${fieldItemPrefix}.NOP`) as boolean}
          disabled={formType === FormType.View}
          onDsmChange={(e) => {
            formContext.setValue(`${fieldItemPrefix}.NOP`, e?.target?.checked);
          }}
          label={intl.formatMessage({
            id: `BASELINE.FORM.EMISSIONS.3_NOP_SUPPLEMENTS`,
          })}
          hidden
        />
      </DsmGrid>

      <DsmButtonControlGroup
        cancelHandler={handleReset}
        saveHandler={async () => {
          await formContext.trigger(`stages.${stageIndex}.stageData.emissions`);
          handleCancel('confirm');
        }}
        saveLabel={intl.formatMessage({ id: 'GENERAL.CONFIRM' })}
      />
    </div>
  );
};

BeefEmissionInputsComponent.defaultProps = {
  stageIndex: 0,
};

export default BeefEmissionInputsComponent;
