/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, useState } from 'react';
import {
  FieldError,
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import { Box, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import {
  DsmButton,
  DsmFieldset,
  DsmGrid,
  DsmIcon,
} from '@dsm-dcs/design-system-react';
import {
  CreateAnimalSystemInput,
  AnimalSystem,
  AnimalType,
  FarmingMethod,
  ProductionType,
} from '../../../../../../graphql/types';
import { saveProductionProcess } from '../../../../apicalls/farmFunctions';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../../../modules/Helpers/ReactHookDsmSelect2';
import { FormType } from '../../common';
import adobeDataLayer from '../../../../analytics/adobeDataLayer';
import { TextBox } from '../../../pageparts/TextBox';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { resolvePath } from '../../../../../modules/Helpers/resolvePathFn';
import { isShrimp, selectOptions } from '../../../../helpers/animals';
import DefinitionsDialog from './DefinitionsDialog';
import { IntlShape } from 'react-intl';

export const selectProductionTypeOptions = (intl: IntlShape) => Object.entries(ProductionType).map(
  ([key, value]) => {
    let text = key;
    
    text = intl.formatMessage({id: `SUSTELL.PRODUCTION.TYPE.${value}`});

    return {
      text,
      value,
    };
  }
);

export type AnimalSystemAddFormProps = {
  formType: FormType;
  formData?: AnimalSystem[];
  farmID: string;
  handleFormSave: (savedData: AnimalSystem[]) => void; // may be external
  formVisible: boolean;
  handleClose?: () => void; // may be external
};

type AnimalTypeCounters = {
  [key in typeof AnimalType[keyof typeof AnimalType]]: number;
};

const initializeAnimalTypeCounters = (): AnimalTypeCounters => {
  const counters: AnimalTypeCounters = {
    [AnimalType.Beef]: 0,
    [AnimalType.Broiler]: 0,
    [AnimalType.BroilerV2]: 0,
    [AnimalType.Dairy]: 0,
    [AnimalType.DairyV2]: 0,
    [AnimalType.LayingHens]: 0,
    [AnimalType.LayingHensV2]: 0,
    [AnimalType.Pig]: 0,
    [AnimalType.PigFattening]: 0,
    [AnimalType.Salmon]: 0,
    [AnimalType.SalmonV2]: 0,
    [AnimalType.SeaBass]: 0,
    [AnimalType.SeaBream]: 0,
    [AnimalType.Turkey]: 0,
    [AnimalType.LitopenaeusVannamei]: 0,
    [AnimalType.PenaeusMonodon]: 0,
  };

  return counters;
};

const AnimalSystemAddForm = ({
  formType = FormType.Add,
  formData,
  farmID,
  formVisible,
  handleFormSave,
  handleClose,
}: AnimalSystemAddFormProps) => {
  const intl = useIntl();
  // The idea for this is to count how many animal systems of each type exists so
  // when the user is adding another, the default name will be '{animalType} {numberOfAnimalSystemsOfThisType}'
  const [animalTypeCounters, setAnimalTypeCounters] =
    useState<AnimalTypeCounters>(initializeAnimalTypeCounters());
  const [isInError, setIsInError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { formSubmissionEvent, formErrorsEvent } = adobeDataLayer();
  const [newAnimalSystems, setNewAnimalSystems] = useState<AnimalSystem[]>(
    []
  );
  const [selectedAnimalType, setSelectedAnimalType] = useState<AnimalType>();
  const [selectAnimalTypeOptions, setSelectAnimalTypeOptions] =
    useState(selectOptions);
  const [openDescriptionDialog, setOpenDescriptionDialog] = useState(false);
  const formContext = useFormContext();
  const { control } = formContext;
  const fieldItemPrefix = `animalSystems`;
  const {
    fields: animalSystems,
    append: appendAnimalSystem,
    remove: removeAnimalSystem,
  } = useFieldArray({
    name: `${fieldItemPrefix}.animalSystems`,
    control,
    keyName: 'keyId',
  });
  const classes = processAndStageStylesV2() as CSSClassesList;
  const classBorderBeddings =
    animalSystems.length > 1 ? classes.additionalEntriesBlockHolder : '';

  const addNewAnimalSystem = (data: AnimalSystem) => {
    const animalSystemsToBeAdded = [...newAnimalSystems];
    animalSystemsToBeAdded.push(data);
    setNewAnimalSystems(animalSystemsToBeAdded);
  };

  const updateAnimalTypeCounters = (data: AnimalSystem[] | undefined) => {
    const countersCopy = { ...animalTypeCounters };
    if (data) {
      data.forEach((process) => {
        countersCopy[process.animalType] += 1;
      });
    }
    setAnimalTypeCounters(countersCopy);
  };

  const updateSelectAnimalTypeOptions = (
    data: AnimalSystem[] | undefined
  ) => {
    const isShrimpFarm = data?.filter(
      (pp) =>
        pp.animalType === AnimalType.PenaeusMonodon ||
        pp.animalType === AnimalType.LitopenaeusVannamei
    );
    if (isShrimpFarm && isShrimpFarm.length > 0) {
      const updatedSelectAnimalTypeOptions = selectAnimalTypeOptions.filter(
        (animalOption) =>
          animalOption.value === AnimalType.PenaeusMonodon ||
          animalOption.value === AnimalType.LitopenaeusVannamei
      );
      setSelectAnimalTypeOptions(updatedSelectAnimalTypeOptions);
    }
  };

  const addAnimalType = (type: AnimalType) => {
    const countersCopy = { ...animalTypeCounters };
    countersCopy[type] += 1;
    setAnimalTypeCounters(countersCopy);
    return countersCopy[type] < 10
      ? `0${countersCopy[type]}`
      : countersCopy[type];
  };

  const addAnimalSystem = () => {
    appendAnimalSystem({
      animalType: AnimalType.Pig,
      animalSystemName: animalTypeCounters.PIG,
    });
  };

  useEffect(() => {
    updateAnimalTypeCounters(formData);
    updateSelectAnimalTypeOptions(formData);
    if (animalSystems.length === 0) {
      addAnimalSystem();
    }
  }, [formData]);

  const onSubmit = (submitData: any) => {
    setIsInError(false);
    const animalSystemsArray = submitData.animalSystems.animalSystems;

    // This form allows for multiple animal systems to be add at the same time time
    // Thus, if more than one animal system is created simultaneously, you have to wait for the promises to get the complete array of new processes
    // and render them on screen, otherwise it will only show one of them
    const promises = animalSystemsArray.map(
      (x: any) =>
        new Promise(async (resolve, reject) => {
          try {
            const data: CreateAnimalSystemInput = {
              animalType: x.animalType,
              name: x.animalSystemName,
              farmingMethod: FarmingMethod.Conventional,
              productionType: x.productionType,
            };

            saveProductionProcess(
              FormType.Add,
              farmID,
              data,
              (savedData: AnimalSystem) => {
                addNewAnimalSystem(savedData);
                resolve(savedData);
              },
              (error: string) => {
                errorHandler(error);
                reject(error);
              },
              formSubmissionEvent
            );
          } catch (error) {
            reject(error);
          }
        })
    );

    Promise.all(promises)
      .then((results) => {
        handleFormSave(results);
      })
      .catch((error) => {
        // Handle errors from any of the asynchronous operations
        console.error('Error while saving production processes:', error);
      });
  };

  const animalSystemsError: FieldError | null = resolvePath(
    formContext.errors,
    `${fieldItemPrefix}.animalSystemSystem`,
    null
  ) as FieldError | null;

  interface FormData {
    animalType: AnimalType;
    animalSystemName: string;
  }

  const methods = useForm<FormData[]>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const errorHandler = (message: string) => {
    setErrorMessage(intl.formatMessage({ id: message }));
    setIsInError(true);
    const formErrors = Object.keys(methods.formState.errors).length;
    formErrorsEvent('Add Animal System', formType, formErrors);
  };

  const onErrors = () => {
    errorHandler('SUSTELL.ERROR.MANDATORY_FIELDS');
  };

  const countAnimalSystems = (animalType: AnimalType) =>
    addAnimalType(animalType).toString();

  const onAnimalSystemSelected =
    (index: number) => (event: React.ChangeEvent<{ value: AnimalType }>) => {
      const selectedAnimalTypeVal = event.target.value;
      setSelectedAnimalType(selectedAnimalTypeVal);
      methods.setValue(
        `${fieldItemPrefix}.animalSystems.${index}.animalSystemName`,
        `${intl.formatMessage({
          id: `SUSTELL.STAGE.${selectedAnimalTypeVal}`,
        })} ${countAnimalSystems(selectedAnimalTypeVal)}`
      );
    };
  const formSubmit = () => methods.handleSubmit(onSubmit);

  return (
    <div
      style={{
        overflow: 'hidden',
        padding: 0,
        marginTop: 'var(--dsm-spacing-px-6',
      }}
    >
      {openDescriptionDialog && (
        <DefinitionsDialog
          open
          prefix='SUSTELL.PRODUCTION.TYPE'
          itemHeader='SUSTELL.PRODUCTION.TYPE.HEADER'
          items={Object.entries(ProductionType).map(([_, value]) => value)}
          handleClose={() => setOpenDescriptionDialog(false)}
        />
      )}
      {isInError && (
        <Grid
          container
          direction="column"
          spacing={1}
          style={{ marginBottom: 10 }}
        >
          <Grid item xs={12}>
            <Alert severity="error">
              <AlertTitle>{errorMessage}</AlertTitle>
            </Alert>
          </Grid>
        </Grid>
      )}
      <FormProvider
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...methods}
      >
        <form onSubmit={formSubmit}>
          <TextBox
            title={intl.formatMessage({
              id: 'SUSTELL.ANIMAL.SYSTEM.INFORMATION.TITLE',
            })}
            content={intl.formatMessage({
              id: 'SUSTELL.ANIMAL.SYSTEM.INFORMATION.DESCRIPTION',
            })}
            translationKeyTitle={undefined}
            translationKeyText={undefined}
          />
          <div style={{ paddingTop: 'var(--dsm-spacing-px-4' }}>
            {!!animalSystemsError && animalSystemsError.type === 'min' && (
              <DsmGrid className={classes.dsmGridOneColumn}>
                <Alert severity="error">
                  <AlertTitle>
                    {intl.formatMessage({ id: 'GENERAL.ERROR' })}
                  </AlertTitle>
                  {animalSystemsError.message}
                </Alert>
              </DsmGrid>
            )}
            {animalSystems &&
              animalSystems?.map((item, index) => (
                <div className={classBorderBeddings} key={item.keyId}>
                  {removeAnimalSystem && animalSystems.length > 1 && (
                    <DsmButton
                      variant="text"
                      style={{ position: 'relative', width: '100%' }}
                      onClick={() => removeAnimalSystem(index)}
                    >
                      <DsmIcon
                        name="general/x-close"
                        style={{
                          position: 'absolute',
                          color: 'var(--dsm-color-neutral-darker',
                          right: '0',
                        }}
                      />
                    </DsmButton>
                  )}
                  <DsmGrid
                    style={{
                      width: '100%',
                      grid: isShrimp(selectedAnimalType)
                        ? 'auto/4fr 4fr 4fr 1fr 2fr 3fr'
                        : 'auto/4fr 4fr 2fr 1fr 2fr',
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      columnGap: '20px',
                    }}
                  >
                    <ReactHookDsmSelect
                      label={intl.formatMessage({
                        id: 'SUSTELL.ANIMAL.SYSTEM.TYPE',
                      })}
                      name={`${fieldItemPrefix}.animalSystems.${index}.animalType`}
                      options={selectAnimalTypeOptions}
                      changeHandler={onAnimalSystemSelected(index)}
                      required
                    />
                    <ReactHookDsmInput
                      label={intl.formatMessage({
                        id: 'SUSTELL.ANIMAL.SYSTEM.NAME',
                      })}
                      name={`${fieldItemPrefix}.animalSystems.${index}.animalSystemName`}
                      required
                    />
                    {isShrimp(selectedAnimalType) && (
                      <DsmGrid className={classes.dsmGridOneColumnNoRowgap}>
                        <ReactHookDsmSelect
                          label={intl.formatMessage({
                            id: 'SUSTELL.PRODUCTION.TYPE',
                          })}
                          name={`${fieldItemPrefix}.animalSystems.${index}.productionType`}
                          options={selectProductionTypeOptions(intl)}
                          required
                          tooltip={intl.formatMessage({
                            id: 'SUSTELL.PRODUCTION.TYPE.TOOLTIP'
                          })}
                        />
                        <DsmButton
                          variant="text"
                          onClick={() => setOpenDescriptionDialog(true)}
                        >
                          <DsmIcon slot="before" name="general/eye" />
                          {intl.formatMessage({
                            id: 'DATABASE_FOUNDATION_DEFINITIONS',
                          })}
                        </DsmButton>
                      </DsmGrid>
                    )}
                    <div />
                    <DsmButton
                      variant="primary"
                      destructive
                      style={{ alignSelf: 'center' }}
                      onClick={handleClose}
                    >
                      <DsmIcon slot="before" name="general/trash-01" />
                      {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
                    </DsmButton>
                    <DsmButton
                      style={{
                        marginLeft: 'var(--dsm-spacing-px-4)',
                        alignSelf: 'center',
                      }}
                      variant="primary"
                      onClick={() => {
                        methods.handleSubmit(async (data) => {
                          try {
                            onSubmit(data);
                            // Handle success if needed
                          } catch (error) {
                            // Handle error if needed
                            console.error(error);
                          }
                        }, onErrors)();
                      }}
                    >
                      {intl.formatMessage({ id: 'GENERAL.SAVE_AND_CONTINUE' })}
                    </DsmButton>
                  </DsmGrid>
                </div>
              ))}
          </div>
          {!formVisible && (
            <DsmGrid
              className={classes.dsmGridTwoColumn}
              style={{ marginBottom: 'var(--dsm-spacing-px-4' }}
            >
              <DsmButton
                variant="text"
                onClick={addAnimalSystem}
                disabled={formType === FormType.View}
              >
                <DsmIcon slot="before" name="general/plus-circle" />
                {intl.formatMessage({
                  id: 'SUSTELL.ANIMAL.SYSTEM.ADD.ANOTHER.ANIMAL.SYSTEM',
                })}
              </DsmButton>
            </DsmGrid>
          )}
          {formType === FormType.Add && (
            <DsmFieldset style={{ marginTop: 'var(--dsm-spacing-px-8' }}>
              <Box
                style={{
                  display: 'flex',
                  width: '100%',
                  marginTop: 'var(--dsm-spacing-px-8)',
                }}
                flexDirection="row"
                justifyContent="space-between"
              ></Box>
            </DsmFieldset>
          )}
        </form>
      </FormProvider>
    </div>
  );
};

AnimalSystemAddForm.defaultProps = {
  formData: undefined,
};

export default AnimalSystemAddForm;
