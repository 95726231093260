/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
// eslint-disable-next-line import/no-extraneous-dependencies
import { StageType } from '../../../../../graphql/types';
import { UserProfilePrefs } from '../../../Helpers/UserProfilePrefs';
import {
  floatValueInUserUnits,
  createCoreForm,
  mapResourceUse,
  mapRationForOneStage,
} from './inCommonMapperSustellV2';
import { emissionFieldsMapInPoultryV2 } from '../../Intervention/mapper/emissionFields';
import { defaultUnitsV2 as defaultMetric } from '../../../../sustell_15/utils/unit-utils';
import { getBaseTypeForMonthEndingMMS } from '../../../../sustell_15/utils/string-utils';
import { isProcessingStage } from '../../../../sustell_15/helpers/facilities';
import mapInProcessingStage from './inProcessingMapperSustell';

const initializeEmptyStage = (stage, stageType) => {
  const initialData = {
    id: stage?.id,
    name: stage?.name,
    type: stage?.type,
    stageData: {
      input: {
        startDate: stage.startDate || '',
        endDate: stage.endDate || '',
      },
      housing: {},
      output: {},
    },
  };
  if (stageType === StageType.Laying || stageType === StageType.Growing) {
    initialData.stageData.emissions = {};
    initialData.stageData.feed = {
      compoundFeeds: [],
      singleIngredients: [],
    };
  }
  if (stageType === StageType.Breeding) {
    initialData.stageData.emissions = {};
    initialData.stageData.feed = {};
    initialData.stageData.feed.henFeed = {
      compoundFeeds: [],
      singleIngredients: [],
    };
    initialData.stageData.feed.roosterFeed = {
      compoundFeeds: [],
      singleIngredients: [],
    };
  }
  return initialData;
};

const mapInternalSource = (
  stage,
  formOutputData,
  userUOM,
  key,
  kgUnitsDefault = true
) => {
  const defaultUserUnit = kgUnitsDefault
    ? userUOM.unitBarnOutputMass
    : userUOM.unitBarnSmallQuantityUnits;
  const defaultUnit = kgUnitsDefault
    ? defaultMetric.unitBarnOutputMass
    : defaultMetric.unitBarnSmallQuantityUnits;
  const form = formOutputData;
  form[key] = {
    id: stage?.id,
    farmId: stage.originFarmId,
    farmName: stage.originFarmName,
    originStageId: stage.originStageId,
    stageName: stage.originStageName,
    originAnimalType: stage.originAnimalType,
    baselineRef: stage.baselineRef,
    numberAnimals: stage.number,
    stageType: stage.stageType,
    averageWeight: floatValueInUserUnits(
      stage.averageWeight,
      defaultUserUnit,
      defaultUnit
    ),
    transportDistance: floatValueInUserUnits(
      stage.transportDistance,
      userUOM.unitTransportDistanceTerrestrial,
      defaultMetric.unitTransportDistanceTerrestrial
    ),
  };
};

const mapExternalSource = (
  stage,
  formOutputData,
  userUOM,
  key,
  kgUnitsDefault = true
) => {
  const defaultUserUnit = kgUnitsDefault
    ? userUOM.unitBarnOutputMass
    : userUOM.unitBarnSmallQuantityUnits;
  const defaultUnit = kgUnitsDefault
    ? defaultMetric.unitBarnOutputMass
    : defaultMetric.unitBarnSmallQuantityUnits;
  const form = formOutputData;
  form[key] = {
    id: stage.id,
    numberAnimals: stage.number,
    averageWeight: floatValueInUserUnits(
      stage.averageWeight,
      defaultUserUnit,
      defaultUnit
    ),
    transportDistance: floatValueInUserUnits(
      stage.transportDistance,
      userUOM.unitTransportDistanceTerrestrial,
      defaultMetric.unitTransportDistanceTerrestrial
    ),
  };
};

const mapInputForOneStageBreeding = (stageData, stageInput) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formOutputData = stageInput;
  formOutputData.henInternalSource = null;
  formOutputData.henExternalSource = null;
  formOutputData.roosterInternalSource = null;
  formOutputData.roosterExternalSource = null;
  stageData.henInput.animals.forEach((stage) => {
    if (stage.origin === 'PRODUCTION_SYSTEM') {
      formOutputData.henSelected = true;
      mapInternalSource(stage, formOutputData, userUOM, 'henInternalSource');
    } else {
      formOutputData.henSelected = false;
      mapExternalSource(stage, formOutputData, userUOM, 'henExternalSource');
    }
  });
  stageData.roosterInput.animals.forEach((stage) => {
    if (stage.origin === 'PRODUCTION_SYSTEM') {
      formOutputData.roosterSelected = true;
      mapInternalSource(
        stage,
        formOutputData,
        userUOM,
        'roosterInternalSource'
      );
    } else {
      formOutputData.roosterSelected = false;
      mapExternalSource(
        stage,
        formOutputData,
        userUOM,
        'roosterExternalSource'
      );
    }
  });
};

const mapInputForOneStageGrowing = (stageData, stageInput) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formOutputData = stageInput;
  formOutputData.internalSources = [];
  formOutputData.externalSources = [];
  formOutputData.selection = {
    internalSelected: false,
    externalSelected: false,
  };

  stageData.animals.forEach((stage) => {
    if (stage.origin === 'PRODUCTION_SYSTEM') {
      formOutputData.selection.internalSelected = true;
      const intSource = {};
      mapInternalSource(stage, intSource, userUOM, 'internalSource');
      formOutputData.internalSources.push(intSource.internalSource);
    } else {
      formOutputData.selection.externalSelected = true;
      const extSource = {};
      mapExternalSource(stage, extSource, userUOM, 'externalSource');
      formOutputData.externalSources.push(extSource.externalSource);
    }
  });
};

const mapInputForOneStageLaying = (stageData, stageInput) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formOutputData = stageInput;
  formOutputData.internalSource = null;
  formOutputData.externalSource = null;

  stageData.animals.forEach((stage) => {
    if (stage.origin === 'PRODUCTION_SYSTEM') {
      formOutputData.selected = true;
      mapInternalSource(
        stage,
        formOutputData,
        userUOM,
        'internalSource',
        false
      );
    } else {
      formOutputData.selected = false;
      mapExternalSource(
        stage,
        formOutputData,
        userUOM,
        'externalSource',
        false
      );
    }
  });
};

const mapInputForOneStageHatching = (stageData, stageInput) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formOutputData = stageInput;
  formOutputData.internalSource = null;
  formOutputData.externalSource = null;

  stageData.animals.forEach((stage) => {
    if (stage.origin === 'PRODUCTION_SYSTEM') {
      formOutputData.selected = true;
      mapInternalSource(stage, formOutputData, userUOM, 'internalSource');
    } else {
      formOutputData.selected = false;
      mapExternalSource(stage, formOutputData, userUOM, 'externalSource');
    }
  });
};

const mapInputForOneStage = (stageType, stageData, stageInput) => {
  if (stageType === StageType.Breeding)
    mapInputForOneStageBreeding(stageData, stageInput);
  else if (stageType === StageType.Growing)
    mapInputForOneStageGrowing(stageData.input, stageInput);
  else if (stageType === StageType.Laying)
    mapInputForOneStageLaying(stageData.input, stageInput);
  else mapInputForOneStageHatching(stageData.input, stageInput);
};

const mapStockChangeForOneStage = (stageType, stageData, stageInput) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formOutputData = stageInput;
  if (
    stageData.averageProductionRoundLength ||
    stageData.averageProductionRoundLength === 0
  )
    formOutputData.durationOfProductionCycle = Number(
      stageData.averageProductionRoundLength
    );
  if (stageData.totalEmptyDays || stageData.totalEmptyDays === 0)
    formOutputData.emptyPeriod = Number(stageData.totalEmptyDays);
  if (stageType === StageType.Hatching) {
    if (
      stageData.stockChange?.initialAnimalStock ||
      stageData.stockChange?.initialAnimalStock === 0
    )
      formOutputData.eggsPresentAtStart = Number(
        stageData.stockChange.initialAnimalStock
      );
    if (
      stageData.stockChange?.finalAnimalStock ||
      stageData.stockChange?.finalAnimalStock === 0
    )
      formOutputData.eggsPresentAtEnd = Number(
        stageData.stockChange.finalAnimalStock
      );
  } else if (stageType === StageType.Growing) {
    if (
      stageData.stockChange?.initialAnimalStock ||
      stageData.stockChange?.initialAnimalStock === 0
    )
      formOutputData.animalsPresentAtStart = Number(
        stageData.stockChange.initialAnimalStock
      );
    if (
      stageData.stockChange?.finalAnimalStock ||
      stageData.stockChange?.finalAnimalStock === 0
    )
      formOutputData.animalsPresentAtEnd = Number(
        stageData.stockChange.finalAnimalStock
      );
    if (
      stageData.stockChange?.initialAnimalAverageWeight ||
      stageData.stockChange?.initialAnimalAverageWeight === 0
    )
      formOutputData.averageWeightAtStart = floatValueInUserUnits(
        stageData.stockChange.initialAnimalAverageWeight,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );
    if (
      stageData.stockChange?.finalAnimalAverageWeight ||
      stageData.stockChange?.finalAnimalAverageWeight === 0
    )
      formOutputData.averageWeightAtEnd = floatValueInUserUnits(
        stageData.stockChange.finalAnimalAverageWeight,
        userUOM.unitBarnOutputMass,
        defaultMetric.unitBarnOutputMass
      );
  }
};

const mapFeedForOneStage = (stageType, stageData, stageInput) => {
  if (stageType === StageType.Breeding && stageData?.henFeedingProgram) {
    mapRationForOneStage(stageData.henFeedingProgram, stageInput.feed?.henFeed);
  }
  if (stageType === StageType.Breeding && stageData?.roosterFeedingProgram) {
    mapRationForOneStage(
      stageData.roosterFeedingProgram,
      stageInput.feed?.roosterFeed
    );
  }
  if (stageType !== StageType.Breeding && stageData.feedingProgram) {
    mapRationForOneStage(stageData.feedingProgram, stageInput.feed);
  }
};

const mapHousingForOneStage = (stageData, stageInput) => {
  const formOutputData = stageInput;
  formOutputData.housingType = stageData.housingType;
  if (stageData.housingType === 'FREE_RANGE')
    formOutputData.timeInFreeRange = stageData.freeRangeTimePercent;
};

const mapManureManagemenetSystems = (stageData, stageInput) => {
  const formOutputData = stageInput;
  formOutputData.manureSystems = [];
  if ('manureManagementSystems' in stageData) {
    stageData.manureManagementSystems.forEach((item) => {
      const baseName = getBaseTypeForMonthEndingMMS(item.mmsType);
      const newItem = {};
      newItem.id = item.id;
      newItem.share = item.share;
      newItem.mmsType = baseName;
      newItem.mmsHoldingDuration =
        baseName === item.mmsType ? '' : item.mmsType;
      formOutputData.manureSystems.push(newItem);
    });
  }
};

const mapBeddingForOneStage = (stageData, stageInput) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formHousingData = stageInput;

  formHousingData.beddingSystems = [];
  if (stageData) {
    Object.keys(stageData).forEach((key) => {
      let value = '';
      if (stageData[key])
        value = floatValueInUserUnits(
          Number(stageData[key]),
          defaultMetric.unitBarnOutputMass,
          userUOM.unitBarnOutputMass
        );
      let trimmedKey = key;
      const suffixIndex = key.lastIndexOf('Use');
      if (suffixIndex > -1) trimmedKey = key.substring(0, suffixIndex);
      const beddingType = trimmedKey
        .replace(/[A-Z]/g, (m) => `_${m.toLowerCase()}`)
        .toUpperCase();
      const item = { beddingType, beddingAmount: value };
      formHousingData.beddingSystems.push(item);
    });
  }
};

const mapOutputForOneStageBreeding = (stageData, stageInput) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formOutputData = stageInput;
  if (stageData.totalToStage)
    formOutputData.totalEggsToStage = Number(stageData.totalToStage);
  if (stageData.averageWeight)
    formOutputData.averageWeight = floatValueInUserUnits(
      stageData.averageWeight,
      userUOM.unitBarnSmallQuantityUnits,
      defaultMetric.unitBarnSmallQuantityUnits
    );
  if (stageData.totalSold)
    formOutputData.totalEggsSold = Number(stageData.totalSold);
  if (stageData.price) formOutputData.priceEggs = Number(stageData.price);
  if (stageData.spentHenSold)
    formOutputData.totalHensSold = Number(stageData.spentHenSold);
  if (stageData.spentHenAverageWeight)
    formOutputData.averageHensWeight = floatValueInUserUnits(
      stageData.spentHenAverageWeight,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (
    stageData.spentHenMortalityPercent ||
    stageData.spentHenMortalityPercent === 0
  )
    formOutputData.mortalityHensAtPercent = Number(
      stageData.spentHenMortalityPercent
    );
  if (
    stageData.spentHenWeightAtMortality ||
    stageData.spentHenWeightAtMortality === 0
  )
    formOutputData.weightHensAtMortality = floatValueInUserUnits(
      stageData.spentHenWeightAtMortality,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (stageData.spentRoosterSold)
    formOutputData.totalRoostersSold = Number(stageData.spentRoosterSold);
  if (stageData.spentRoosterAverageWeight)
    formOutputData.averageRoostersWeight = floatValueInUserUnits(
      stageData.spentRoosterAverageWeight,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (
    stageData.spentRoosterMortalityPercent ||
    stageData.spentRoosterMortalityPercent === 0
  )
    formOutputData.mortalityRoostersAtPercent = Number(
      stageData.spentRoosterMortalityPercent
    );
  if (
    stageData.spentRoosterWeightAtMortality ||
    stageData.spentRoosterWeightAtMortality === 0
  )
    formOutputData.weightRoostersAtMortality = floatValueInUserUnits(
      stageData.spentRoosterWeightAtMortality,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (stageData.spentHenPrice || stageData.spentHenPrice === 0)
    formOutputData.price = Number(stageData.spentHenPrice);
};

const mapOutputForOneStageGrowing = (stageData, stageInput) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formOutputData = stageInput;
  if (stageData.totalToStage || stageData.totalToStage === 0)
    formOutputData.totalToStage = Number(stageData.totalToStage);
  if (stageData.averageWeight || stageData.averageWeight === 0)
    formOutputData.averageWeight = floatValueInUserUnits(
      stageData.averageWeight,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (stageData.totalSold || stageData.totalSold === 0)
    formOutputData.totalSold = Number(stageData.totalSold);
  if (stageData.mortalityPercent || stageData.mortalityPercent === 0)
    formOutputData.mortalityAtPercent = Number(stageData.mortalityPercent);
  if (stageData.weightAtMortality || stageData.weightAtMortality === 0)
    formOutputData.weightAtMortality = floatValueInUserUnits(
      stageData.weightAtMortality,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
};

const mapOutputForOneStageLaying = (stageData, stageInput) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formOutputData = stageInput;
  if (stageData.totalSold)
    formOutputData.totalSold = Number(stageData.totalSold);
  if (stageData.averageWeight)
    formOutputData.averageWeight = floatValueInUserUnits(
      stageData.averageWeight,
      userUOM.unitBarnSmallQuantityUnits,
      defaultMetric.unitBarnSmallQuantityUnits
    );
  if (stageData.price) formOutputData.priceEggs = Number(stageData.price);
  if (stageData.spentHenSold)
    formOutputData.totalHensToStage = Number(stageData.spentHenSold);
  if (stageData.spentHenAverageWeight)
    formOutputData.averageHensWeight = floatValueInUserUnits(
      stageData.spentHenAverageWeight,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (stageData.spentHenPrice)
    formOutputData.priceHens = Number(stageData.spentHenPrice);
  if (
    stageData.spentHenMortalityPercent ||
    stageData.spentHenMortalityPercent === 0
  )
    formOutputData.mortalityAtPercent = Number(
      stageData.spentHenMortalityPercent
    );
  if (
    stageData.spentHenWeightAtMortality ||
    stageData.spentHenWeightAtMortality === 0
  )
    formOutputData.weightAtMortality = floatValueInUserUnits(
      stageData.spentHenWeightAtMortality,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
};

const mapOutputForOneStageHatching = (stageData, stageInput) => {
  const userPrefs = UserProfilePrefs.getInstance();
  const userUOM = userPrefs.getUserUnitPrefs();
  const formOutputData = stageInput;
  if (stageData.totalToStage || stageData.totalToStage === 0)
    formOutputData.totalToStage = Number(stageData.totalToStage);
  if (stageData.totalSold || stageData.totalSold === 0)
    formOutputData.totalSold = Number(stageData.totalSold);
  if (stageData.averageWeight || stageData.averageWeight === 0)
    formOutputData.averageWeight = floatValueInUserUnits(
      stageData.averageWeight,
      userUOM.unitBarnSmallQuantityUnits,
      defaultMetric.unitBarnSmallQuantityUnits
    );
  if (stageData.price || stageData.price === 0)
    formOutputData.price = Number(stageData.price);
  if (stageData.totalWeightLosses || stageData.totalWeightLosses === 0)
    formOutputData.lossRate = floatValueInUserUnits(
      stageData.totalWeightLosses,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
  if (stageData.lossesPrice || stageData.lossesPrice === 0)
    formOutputData.priceOfLossesSold = Number(stageData.lossesPrice);
  if (
    stageData.totalWeightEggShellSoldOrWaste ||
    stageData.totalWeightEggShellSoldOrWaste === 0
  )
    formOutputData.totalEggShellsSold = floatValueInUserUnits(
      stageData.totalWeightEggShellSoldOrWaste,
      userUOM.unitBarnOutputMass,
      defaultMetric.unitBarnOutputMass
    );
};

const mapOutputForOneStage = (stageType, stageData, stageInput) => {
  if (stageType === StageType.Breeding)
    mapOutputForOneStageBreeding(stageData, stageInput);
  else if (stageType === StageType.Growing)
    mapOutputForOneStageGrowing(stageData, stageInput);
  else if (stageType === StageType.Laying)
    mapOutputForOneStageLaying(stageData, stageInput);
  else mapOutputForOneStageHatching(stageData, stageInput);
};

const mapEmissionForOneStage = (stageData, stageInput) => {
  const formEmissionData = stageInput;
  if (stageData && Array.isArray(stageData)) {
    stageData.forEach((emission) => {
      const fieldName = emissionFieldsMapInPoultryV2.get(emission.emissionType);
      formEmissionData[fieldName] = emission.change;
    });
  }
};

const mapPoultryProductionDataInSustell = (inData) => {
  const formData = createCoreForm(inData);
  if (inData.stages && Array.isArray(inData.stages)) {
    inData.stages.forEach((stage) => {
      const { stageData: stageDataJSON } = stage;
      const stageData = JSON.parse(stageDataJSON);

      if (isProcessingStage(stage.type)) {
        const inProcessingStage = {
          ...stage,
          stageData,
        };
        const mappedStageData = mapInProcessingStage(inProcessingStage);
        formData.stages.push(mappedStageData);
      } else {
        const stageInput = initializeEmptyStage(stage, stage.type);
        // mapOutputForOneStage(stageData.animalsOutput, stageFormData.output);
        mapInputForOneStage(stage.type, stageData, stageInput.stageData.input);
        // Map stock change
        mapStockChangeForOneStage(
          stage.type,
          stageData,
          stageInput.stageData.input
        );
        // Map feed
        if (stage.type !== StageType.Hatching)
          mapFeedForOneStage(stage.type, stageData, stageInput.stageData);
        // Map resource use on stage level
        stageInput.stageData.housing.resourceUse = {};
        mapResourceUse(
          stageData.resourceUse,
          stageInput.stageData.housing.resourceUse,
          ['electricityUse', 'gasUse', 'waterUse']
        );
        // Map housing
        if (stage.type !== StageType.Hatching)
          mapHousingForOneStage(stageData, stageInput.stageData.housing);
        // Map manure managements systems
        if (stage.type !== StageType.Hatching)
          mapManureManagemenetSystems(stageData, stageInput.stageData.housing);
        // Map bedding
        if (stage.type !== StageType.Hatching)
          mapBeddingForOneStage(
            stageData.beddingUse,
            stageInput.stageData.housing
          );
        // Map output
        mapOutputForOneStage(
          stage.type,
          stageData.output,
          stageInput.stageData.output
        );
        // Map emissions
        if (stage.type !== StageType.Hatching)
          mapEmissionForOneStage(
            stageData.emissionMitigations,
            stageInput.stageData.emissions
          );
        formData.stages.push(stageInput);
      }
    });
  }
  mapResourceUse(inData.resourceUse, formData.resourceUse, [
    'electricityUse',
    'gasUse',
    'waterUse',
  ]);
  return formData;
};

export default mapPoultryProductionDataInSustell;
