import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { DsmFieldset, DsmGrid } from '@dsm-dcs/design-system-react';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { TargetAnimal } from '../../../../models/Baseline/DairyV2Baseline';

type DairyV2HousingComponentProps = {
  titleId?: string;
  stageIndex: number;
  target?: TargetAnimal;
  isViewMode: boolean;
};

const DairyV2HousingComponent: FC<DairyV2HousingComponentProps> = ({
  titleId,
  stageIndex,
  target,
  isViewMode
}) => {
  const formContext = useFormContext();
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const baseItemPrefix = `stages.${stageIndex}.stageData.housing`;
  const fieldItemPrefix = target
    ? `${baseItemPrefix}.${target}`
    : baseItemPrefix;
  const boldTextStyle = {
    fontWeight: 'bold',
    fontSize: '18px',
    color: 'black',
    margin: 0,
    padding: 0,
  };
  
  const timeInHousingWatch = useWatch({
    name: `${fieldItemPrefix}.timeInHousing`,
  });
  const timeInFeedlotWatch = useWatch({
    name: `${fieldItemPrefix}.timeInFeedlot`,
  });
  const timeGrazingLargeAreasWatch = useWatch({
    name: `${fieldItemPrefix}.timeGrazingLargeAreas`,
  });
  const timeGrazingPasturesWatch = useWatch({
    name: `${fieldItemPrefix}.timeGrazingPastures`,
  });

  const getTimeInHousingValue = (): number => timeInHousingWatch ? Number(timeInHousingWatch) : 0;
  
  const getTimeInFeedlotValue = (): number => timeInFeedlotWatch ? Number(timeInFeedlotWatch) : 0;
  
  const getTimeGrazingLargeAreasValue = (): number => timeGrazingLargeAreasWatch ? Number(timeGrazingLargeAreasWatch) : 0;

  const getTimeGrazingPasturesValue = (): number => timeGrazingPasturesWatch ? Number(timeGrazingPasturesWatch) : 0;

  const handleHousingTime = async () => {
    if (
      !timeInHousingWatch && timeInFeedlotWatch 
      && timeGrazingLargeAreasWatch && timeGrazingPasturesWatch
    ) {
      let value = getTimeInFeedlotValue()
      + getTimeGrazingLargeAreasValue()
      + getTimeGrazingPasturesValue();

      value = 100 - value;

      formContext.setValue(`${fieldItemPrefix}.timeInHousing`, (value > 0 ? value : 0));
    }

    if (
      timeInHousingWatch && !timeInFeedlotWatch 
      && timeGrazingLargeAreasWatch && timeGrazingPasturesWatch
    ) {
      let value = getTimeInHousingValue()
      + getTimeGrazingLargeAreasValue()
      + getTimeGrazingPasturesValue();

      value = 100 - value;

      formContext.setValue(`${fieldItemPrefix}.timeInFeedlot`, (value > 0 ? value : 0));
    }

    if (
      timeInHousingWatch && timeInFeedlotWatch 
      && !timeGrazingLargeAreasWatch && timeGrazingPasturesWatch
    ) {
      let value = getTimeInHousingValue()
      + getTimeInFeedlotValue()
      + getTimeGrazingPasturesValue();

      value = 100 - value;

      formContext.setValue(`${fieldItemPrefix}.timeGrazingLargeAreas`, (value > 0 ? value : 0));
    }

    if (
      timeInHousingWatch && timeInFeedlotWatch 
      && timeGrazingLargeAreasWatch && !timeGrazingPasturesWatch
    ) {
      let value = getTimeInHousingValue()
      + getTimeInFeedlotValue()
      + getTimeGrazingLargeAreasValue();

      value = 100 - value;

      formContext.setValue(`${fieldItemPrefix}.timeGrazingPastures`, (value > 0 ? value : 0));
    }

    const total = getTimeInHousingValue()
    + getTimeInFeedlotValue()
    + getTimeGrazingLargeAreasValue()
    + getTimeGrazingPasturesValue();

    if (total === 100) {
      if (!timeInHousingWatch) formContext.setValue(`${fieldItemPrefix}.timeInHousing`, 0);
      if (!timeInFeedlotWatch) formContext.setValue(`${fieldItemPrefix}.timeInFeedlot`, 0);
      if (!timeGrazingLargeAreasWatch) formContext.setValue(`${fieldItemPrefix}.timeGrazingLargeAreas`, 0);
      if (!timeGrazingPasturesWatch) formContext.setValue(`${fieldItemPrefix}.timeGrazingPastures`, 0);
    }

    await formContext.trigger(`${fieldItemPrefix}.timeInHousing`);
    await formContext.trigger(`${fieldItemPrefix}.timeInFeedlot`);
    await formContext.trigger(`${fieldItemPrefix}.timeGrazingLargeAreas`);
    await formContext.trigger(`${fieldItemPrefix}.timeGrazingPastures`);
  }

  return (
    <div>
      {titleId && (
        <div style={{ marginBottom: 'var(--dsm-spacing-px-4)' }}>
          <span className={classes.headingTitle}>
            {intl.formatMessage({
              id: titleId,
            })}
          </span>
        </div>
      )}

      <DsmGrid className={classes.dsmGridOneColumn}>
        <DsmGrid className={classes.additionalEntriesBlockHolder}>
          <DsmFieldset
            style={{
              height: '55px',
            }}
          >
            <ReactHookDsmInput
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.HOUSING.TIME_SPENT_IN_HOUSING',
              })}
              name={`${fieldItemPrefix}.timeInHousing`}
              adornment="%"
              type="number"
              disabled={isViewMode}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.HOUSING.TIME_SPENT_IN_HOUSING.TOOLTIP',
              })}
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.timeInHousing`
              )}
              required
              focusLostHandler={handleHousingTime}
            />
            <ReactHookDsmInput
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.HOUSING.TIME_SPENT_IN_FEEDLOT',
              })}
              name={`${fieldItemPrefix}.timeInFeedlot`}
              adornment="%"
              type="number"
              disabled={isViewMode}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.HOUSING.TIME_SPENT_IN_FEEDLOT.TOOLTIP',
              })}
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.timeInFeedlot`
              )}
              required
              focusLostHandler={handleHousingTime}
            />
          </DsmFieldset>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              margin: 0,
              padding: 0,
            }}
          >
            <span style={boldTextStyle}>+</span>
          </div>
          <DsmFieldset style={{ marginBottom: '16px' }}>
            <ReactHookDsmInput
              style={{ height: '60px' }}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.HOUSING.TIME_SPENT_GRAZING_LARGE_AREAS',
              })}
              name={`${fieldItemPrefix}.timeGrazingLargeAreas`}
              adornment="%"
              type="number"
              disabled={isViewMode}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.HOUSING.TIME_SPENT_GRAZING_LARGE_AREAS.TOOLTIP',
              })}
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.timeGrazingLargeAreas`
              )}
              required
              focusLostHandler={handleHousingTime}
            />
            <ReactHookDsmInput
              style={{ height: '60px' }}
              label={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.HOUSING.TIME_SPENT_GRAZING_PASTURES',
              })}
              name={`${fieldItemPrefix}.timeGrazingPastures`}
              adornment="%"
              type="number"
              disabled={isViewMode}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.STAGE.BEEF.HOUSING.TIME_SPENT_GRAZING_PASTURES.TOOLTIP',
              })}
              defaultValue={formContext.getValues(
                `${fieldItemPrefix}.timeGrazingPastures`
              )}
              required
              focusLostHandler={handleHousingTime}
            />
          </DsmFieldset>
          <p style={boldTextStyle}>= 100% (Total)</p>
          <p style={{ padding: 0, margin: '0 0 0 14px' }}>Total time spent</p>
        </DsmGrid>
      </DsmGrid>
    </div>
  );
};

DairyV2HousingComponent.defaultProps = {
  target: undefined,
  titleId: undefined,
};

export default DairyV2HousingComponent;
