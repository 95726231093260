/**
 * Entry application component used to compose providers and render Routes.
 * */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Analytics } from 'aws-amplify';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Routes } from './Routes';
import { I18nProvider } from '../_metronic/i18n';
import { LayoutSplashScreen, MaterialThemeProvider } from '../_metronic/layout';
import { ConfirmDialog } from './modules/Helpers/ConfirmDialog';
import useAdobeDataLayer from '../app/sustell_15/analytics/adobeDataLayer';

import { initializeProfileListener } from './modules/Auth/UserSettings';

const App = (props) => {
  const queryClient = new QueryClient();
  const [confirm, setConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState(
    'There is unsaved data. If you leave the page, current changes will be lost.'
  );
  const [confirmCallback, setConfirmCallback] = useState(null);

  function getConfirmation(message, callback) {
    // console.log("Inside getConfirmation function...", message);
    setConfirmMessage(message);
    setConfirmCallback(() => callback);
    setConfirm(true);
  }

  const { pageLoadEvent } = useAdobeDataLayer();

  const profileListenerInitialized = React.useRef(false);

  if (!profileListenerInitialized.current) {
    // console.log("profile listener needs to initialize");
    profileListenerInitialized.current = true;
    const userPrefs = initializeProfileListener();
    const entries = performance.getEntriesByType('navigation');

    if (entries && entries[0]?.type === 'reload') {
      // console.log("page reloaded - sign in will be missing");
      // if page is reloaded, the userPrefs will be reloaded, but lastLogin timestamp will not be changed
      userPrefs.getUserProfile();
    }
  }

  Analytics.autoTrack('pageView', {
    enable: true,
    eventName: 'pageView',
    type: 'SPA',
    provider: 'AWSPinpoint',
  });

  Analytics.autoTrack('event', {
    enable: true,
    events: ['click'],
    selectorPrefix: 'data-amplify-analytics-',
    provider: 'AWSPinpoint',
  });

  useEffect(() => {
    pageLoadEvent();
  }, []);

  return (
    /* Add high level `Suspense` in case if was not handled inside the React tree. */
    <React.Suspense fallback={<LayoutSplashScreen />}>
      {/* Override `basename` (e.g: `homepage` in `package.json`) */}
      <BrowserRouter
        basename={props.basename}
        getUserConfirmation={getConfirmation}
        forceRefresh={false}
      >
        {/* This library only returns the location that has been active before the recent location change in the current window lifetime. */}
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <MaterialThemeProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <Routes />
                {confirm && (
                  <ConfirmDialog
                    confirmCallback={confirmCallback}
                    setConfirm={setConfirm}
                    message={confirmMessage}
                  />
                )}
              </I18nProvider>
            </MuiPickersUtilsProvider>
          </MaterialThemeProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default App;
