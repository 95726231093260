import mapLayingHensProductionDataInSustell from './inLayingHensMapperSustell';
import mapLayingHensProductionDataOutSustell from './outLayingHensMapperSustell';
import mapBroilerProductionDataInSustell from './inBroilerMapperSustell';
import mapBroilerProductionDataOutSustell from './outBroilerMapperSustell';
import mapPigFatteningProductionDataInSustell from './inPigFatteningMapperSustell';
import mapPigFatteningProductionDataOutSustell from './outPigFatteningMapperSustell';
import mapDairyProductionDataInSustell from './inDairyMapperSustell';
import mapDairyProductionDataOutSustell from './outDairyMapperSustell';
import mapSalmonProductionDataInSustell from './inSalmonMapperSustell';
import mapSalmonProductionDataOutSustell from './outSalmonMapperSustell';
import mapPoultryProductionDataInSustell from './inPoultryMapperSustell';
import mapPoultryDataOutSustell from './outPoultryMapperSustell';
import mapPigOutSustell from './outPigMapperSustellV2';
import mapPigProductionDataInSustell from './inPigMapperSustellV2';
import mapMarineFishProductionDataOutSustell from './outMarineFishMapperSustell';
import mapMarineFishProductionDataInSustell from './inMarineFishMapperSustell';
import mapShrimpDataOutSustell from './outShrimpMapperSustell'
import mapShrimpProductionDataInSustell from './inShrimpMapperSustell'
import mapBeefProductionDataOutSustell from './outBeefMapperSustell';
import mapBeefProductionDataInSustell from './inBeefMapperSustell';

export const mapProductionDataOutSustell = (
  formData,
  animalType,
  isDraft = false
) => {
  switch (animalType) {
    case 'LAYING_HENS':
      return mapLayingHensProductionDataOutSustell(formData, isDraft);
    case 'DAIRY':
      return mapDairyProductionDataOutSustell(formData, isDraft);
    case 'BROILER':
      return mapBroilerProductionDataOutSustell(formData, isDraft);
    case 'PIG_FATTENING':
      return mapPigFatteningProductionDataOutSustell(formData, isDraft);
    case 'PIG':
      return mapPigOutSustell(formData, isDraft);
    case 'SALMON':
      return mapSalmonProductionDataOutSustell(formData, isDraft);
    case 'SALMON_V2':
    case 'SEA_BASS':
    case 'SEA_BREAM':
      return mapMarineFishProductionDataOutSustell(formData, isDraft);
    case 'LAYING_HENS_V2':
    case 'BROILER_V2':
    case 'TURKEY':
      return mapPoultryDataOutSustell(formData, animalType, isDraft);
    case 'LITOPENAEUS_VANNAMEI':
    case 'PENAEUS_MONODON':
      return mapShrimpDataOutSustell(formData, animalType, isDraft);
    case 'BEEF':
      return mapBeefProductionDataOutSustell(formData, isDraft);
    default:
      console.error(`Unsupported anymal type for out mapping ${animalType}`);
      return {};
  }
};

export const mapProductionDataInSustell = (
  receivedData,
  animalType,
  isDraft = false
) => {
  const inData = { ...receivedData };
  const production =
    typeof inData.productionData === 'string'
      ? JSON.parse(inData.productionData)
      : inData.productionData;
  inData.productionData = production;

  switch (animalType) {
    case 'LAYING_HENS':
      return mapLayingHensProductionDataInSustell(inData, isDraft);
    case 'DAIRY':
      return mapDairyProductionDataInSustell(inData, isDraft);
    case 'BROILER':
      return mapBroilerProductionDataInSustell(inData, isDraft);
    case 'PIG_FATTENING':
      return mapPigFatteningProductionDataInSustell(inData, isDraft);
    case 'PIG':
      return mapPigProductionDataInSustell(inData, isDraft);
    case 'SALMON':
      return mapSalmonProductionDataInSustell(inData, isDraft);
    case 'SALMON_V2':
    case 'SEA_BASS':
    case 'SEA_BREAM':
      return mapMarineFishProductionDataInSustell(inData, isDraft);
    case 'LAYING_HENS_V2':
    case 'BROILER_V2':
    case 'TURKEY':
      return mapPoultryProductionDataInSustell(inData, isDraft);
    case 'LITOPENAEUS_VANNAMEI':
    case 'PENAEUS_MONODON':
      return mapShrimpProductionDataInSustell(inData, isDraft);
    case 'BEEF':
      return mapBeefProductionDataInSustell(inData);
    default:
      console.error(
        `Unsupported anymal type for out mapping ${inData.animalType}`
      );
      return {};
  }
};
