import { FC } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DsmButton, DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';

import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { FormType } from '../../common';
import ReactHookDsmInput from '../../../../../modules/Helpers/ReactHookDsmInput2';
import { UserProfilePrefs } from '../../../../../modules/Helpers/UserProfilePrefs';
import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import {
  defaultUnits,
  defaultUnitsV2,
  unitLong,
} from '../../../../utils/unit-utils';
import lookupValues from '../../../../helpers/lookupValues';
import {
  AnimalType,
  CompoundFeedDatabase,
} from '../../../../../../graphql/types';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import SustellResourceAdditionalItem from '../../../../../modules/Helpers/SustellResourceAdditionalItem';
import { isMarineFish, isShrimp } from '../../../../helpers/animals';

interface ResourcesProps {
  formType: FormType;
  inputType: string;
  // eslint-disable-next-line react/require-default-props
  databaseFoundation?: CompoundFeedDatabase;
  // eslint-disable-next-line react/require-default-props
  animalType?: AnimalType;
  // eslint-disable-next-line react/require-default-props
  required?: boolean;
  // eslint-disable-next-line react/require-default-props
  isBaselineDialog?: boolean;
  // eslint-disable-next-line react/require-default-props
  onChange?: () => void;
}

const Resources: FC<ResourcesProps> = ({
  formType = FormType.View,
  inputType,
  databaseFoundation,
  animalType,
  required = false,
  isBaselineDialog = false,
  onChange,
}) => {
  const intl = useIntl();
  const { control, getValues } = useFormContext();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const userUOM = userProfile.getUserUnitPrefs();
  const classes = processAndStageStylesV2() as CSSClassesList;

  const {
    fields: selfGeneratedRenewables,
    append: appendSelfGeneratedRenewables,
    remove: removeSelfGeneratedRenewablesItem,
  } = useFieldArray({
    name: `${inputType}.selfGeneratedRenewables`,
    control,
    keyName: 'keyId',
  });

  const {
    fields: energyTypes,
    append: appendAnotherEnergyType,
    remove: removeAnotherEnergyTypeItem,
  } = useFieldArray({
    name: `${inputType}.energyTypes`,
    control,
    keyName: 'keyId',
  });

  const isFeed = inputType === 'on_site_activities_input';

  const isV2Changes =
    (databaseFoundation && databaseFoundation !== CompoundFeedDatabase.Afp_5) ||
    animalType;

  const removeSelfGeneratedRenewables = (index: number) => {
    removeSelfGeneratedRenewablesItem(index);
    // To enforce draft saving set touched to true
    control.updateFormState({
      touched: { [`${inputType}.selfGeneratedRenewables`]: true },
    });
  };

  const removeAnotherEnergyType = (index: number) => {
    removeAnotherEnergyTypeItem(index);
    control.updateFormState({
      touched: { [`${inputType}.energyTypes`]: true },
    });
  };

  // CF form fields have _ naming
  const getFormFieldName = (fieldName: string): string => {
    if (isFeed) {
      return `${inputType}.${fieldName.replace('Use', '_use')}`;
    }
    return `${inputType}.${fieldName}`;
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!isV2Changes ? (
        // This is the old view, used for AFP5 compound feeds
        <DsmGrid
          className={classes.dsmGridTwoColumn}
          style={{ marginTop: `var(--dsm-spacing-px-6)`, width: '62%' }}
        >
          <ReactHookDsmInput
            name={getFormFieldName('electricityUse')}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.RESOURCES.ELECTRICITY',
            })}
            disabled={formType === FormType.View}
            tooltip={intl.formatMessage({
              id: isShrimp(animalType) 
                ? 'BASELINE.FORM.RESOURCES.ELECTRICITY.SHRIMP.TOOLTIP'
                : 'BASELINE.FORM.RESOURCES.ELECTRICITY.TOOLTIP',
            })}
            adornment={
              userUOM?.unitElectricityUse
                ? unitLong(userUOM.unitElectricityUse)
                : defaultUnits.unitElectricityUse
            }
            type="number"
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            defaultValue={getValues(getFormFieldName('electricityUse')) || ''}
          />

          <ReactHookDsmInput
            name={getFormFieldName('gasUse')}
            label={intl.formatMessage({ id: 'BASELINE.FORM.RESOURCES.GAS' })}
            tooltip={intl.formatMessage({
              id: isShrimp(animalType) 
              ? 'BASELINE.FORM.RESOURCES.GAS.SHRIMP.TOOLTIP'
              : 'BASELINE.FORM.RESOURCES.GAS.TOOLTIP',
            })}
            adornment={
              userUOM?.unitNaturalGasUse
                ? unitLong(userUOM.unitNaturalGasUse)
                : defaultUnits.unitNaturalGasUse
            }
            disabled={formType === FormType.View}
            type="number"
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            defaultValue={getValues(getFormFieldName('gasUse')) || ''}
          />

          <ReactHookDsmInput
            name={getFormFieldName('heatUse')}
            label={intl.formatMessage({
              id: 'COMPOUND_FEED.ON_SITE_ACTIVITIES.HEAT_USE',
            })}
            tooltip={intl.formatMessage({
              id: 'COMPOUND_FEED.ON_SITE_ACTIVITIES.HEAT_USE',
            })}
            adornment={
              userUOM?.unitHeatUse
                ? unitLong(userUOM.unitHeatUse)
                : defaultUnits.unitHeatUse
            }
            disabled={formType === FormType.View}
            type="number"
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            defaultValue={getValues(getFormFieldName('heatUse')) || ''}
          />

          <ReactHookDsmInput
            name={getFormFieldName('waterUse')}
            label={intl.formatMessage({
              id: 'BASELINE.FORM.RESOURCES.WATER',
            })}
            tooltip={intl.formatMessage({
              id: 'BASELINE.FORM.RESOURCES.WATER.TOOLTIP',
            })}
            adornment={
              // eslint-disable-next-line no-nested-ternary
              isFeed
                ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
                  userUOM?.unitCompoundFeedWaterUse
                  ? unitLong(userUOM.unitCompoundFeedWaterUse)
                  : defaultUnits.unitCompoundFeedWaterUse
                : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
                userUOM?.unitWaterUse
                ? unitLong(userUOM.unitWaterUse)
                : defaultUnits.unitWaterUse
            }
            disabled={formType === FormType.View}
            type="number"
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            defaultValue={getValues(getFormFieldName('waterUse')) || ''}
          />
        </DsmGrid>
      ) : (
        // This is the new view with renewables and additinal energy types, used for v2 feeds and baselines
        <>
          <DsmGrid
            className={classes.dsmGridTwoColumn}
            style={{
              marginTop: `var(--dsm-spacing-px-6)`,
              width: isBaselineDialog ? '' : '75%',
            }}
          >
            <ReactHookDsmInput
              name={getFormFieldName('electricityUse')}
              label={intl.formatMessage({
                id: 'BASELINE.FORM.RESOURCES.ELECTRICITY',
              })}
              disabled={formType === FormType.View}
              tooltip={intl.formatMessage({
                id: isShrimp(animalType) 
                  ? 'BASELINE.FORM.RESOURCES.ELECTRICITY.SHRIMP.TOOLTIP'
                  : 'BASELINE.FORM.RESOURCES.ELECTRICITY.TOOLTIP',
              })}
              type="number"
              adornment={
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                userUOM?.unitElectricityUse
                  ? unitLong(userUOM.unitElectricityUse)
                  : defaultUnitsV2.unitElectricityUse
              }
              required={required}
              changeHandler={() => {
                if (onChange) onChange();
              }}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              defaultValue={getValues(getFormFieldName('electricityUse')) || ''}
            />
          </DsmGrid>

          <DsmGrid
            className={classes.dsmGridOneColumnNoRowgap}
            style={{ width: isBaselineDialog ? '' : '79%' }}
          >
            {selfGeneratedRenewables?.map((item, index) => (
              <div key={item.keyId}>
                <DsmGrid
                  style={{
                    grid: `auto/1fr ${
                      isBaselineDialog ? '0.88fr' : '1fr'
                    } 32px`,
                    gap: 'var(--dsm-spacing-px-4)',
                    alignItems: 'start',
                    alignContent: ' space-between',
                    padding: '0px',
                  }}
                >
                  <SustellResourceAdditionalItem
                    labelInput={intl.formatMessage({
                      id: 'BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_USE',
                    })}
                    labelSelect={intl.formatMessage({
                      id: 'BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_TYPE',
                    })}
                    placeholderSelect=""
                    tooltipType={intl.formatMessage({
                      id: 'BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_TYPE.TOOLTIP',
                    })}
                    tooltipUse={intl.formatMessage({
                      id: 'BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_USE.TOOLTIP',
                    })}
                    index={index}
                    formType={formType}
                    defaultValueAmount={item?.value}
                    deafultValueType={item?.type}
                    required={required}
                    amountFieldName="value"
                    fieldItemPrefix={`${inputType}.selfGeneratedRenewables`}
                    isFeeds={isFeed}
                    isRenewables
                    onChange={onChange}
                  />
                  {formType !== FormType.View &&
                  removeSelfGeneratedRenewables &&
                    selfGeneratedRenewables.length > 0 && (
                      <DsmButton
                        variant="text"
                        destructive
                        style={{
                          position: 'relative',
                          width: '100%',
                          padding: '0px',
                          marginTop: '24px',
                        }}
                        onClick={() => {
                          removeSelfGeneratedRenewables(index);
                          if (onChange) onChange();
                        }}
                      >
                        <DsmIcon
                          name="general/trash-01"
                          style={{
                            position: 'absolute',
                            right: '0',
                          }}
                        />
                      </DsmButton>
                    )}
                </DsmGrid>
              </div>
            ))}
            <DsmButton
              variant="text"
              onClick={() => {
                appendSelfGeneratedRenewables({ type: '', value: '' });
                if (onChange) onChange();
              }}
              disabled={
                formType === FormType.View ||
                selfGeneratedRenewables.length >=
                  lookupValues.selfGeneratedRenewablesList.length
              }
            >
              <DsmIcon slot="before" name="general/plus-circle" />
              {intl.formatMessage({
                id: 'BASELINE.FORM.RESOURCES.ADD.SELF.GENERATED.RENEWABLES_BUTTON',
              })}
            </DsmButton>
          </DsmGrid>

          {(!isMarineFish(animalType) || isFeed) && (
            <DsmGrid
              className={classes.dsmGridTwoColumn}
              style={{
                marginTop: `var(--dsm-spacing-px-6)`,
                width: isBaselineDialog ? '' : '75%',
              }}
            >
              <ReactHookDsmInput
                name={getFormFieldName('gasUse')}
                label={intl.formatMessage({
                  id: 'BASELINE.FORM.RESOURCES.GAS',
                })}
                tooltip={intl.formatMessage({
                  id: isShrimp(animalType) 
                  ? 'BASELINE.FORM.RESOURCES.GAS.SHRIMP.TOOLTIP'
                  : 'BASELINE.FORM.RESOURCES.GAS.TOOLTIP',
                })}
                type="number"
                adornment={
                  userUOM?.unitNaturalGasUse
                    ? unitLong(userUOM.unitNaturalGasUse)
                    : defaultUnitsV2.unitNaturalGasUse
                }
                disabled={formType === FormType.View}
                required={required}
                changeHandler={() => {
                  if (onChange) onChange();
                }}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                defaultValue={getValues(getFormFieldName('gasUse')) || ''}
              />
            </DsmGrid>
          )}

          {isMarineFish(animalType) && !isFeed && (
            <DsmGrid
              className={classes.dsmGridTwoColumn}
              style={{
                marginTop: `var(--dsm-spacing-px-6)`,
                width: isBaselineDialog ? '' : '75%',
              }}
            >
              <ReactHookDsmInput
                name={getFormFieldName('dieselUse')}
                label={intl.formatMessage({
                  id: 'BASELINE.FORM.RESOURCES.DIESEL',
                })}
                tooltip={intl.formatMessage({
                  id: isShrimp(animalType) 
                  ? 'BASELINE.FORM.RESOURCES.DIESEL.SHRIMP.TOOLTIP'
                  : 'BASELINE.FORM.RESOURCES.DIESEL.TOOLTIP',
                })}
                type="number"
                adornment={
                  userUOM?.unitDieselUse
                    ? unitLong(userUOM.unitDieselUse)
                    : defaultUnitsV2.unitDieselUse
                }
                disabled={formType === FormType.View}
                required={required}
                changeHandler={() => {
                  if (onChange) onChange();
                }}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                defaultValue={getValues(getFormFieldName('dieselUse')) || ''}
              />
            </DsmGrid>
          )}

          <DsmGrid
            className={classes.dsmGridOneColumnNoRowgap}
            style={{ width: isBaselineDialog ? '' : '79%' }}
          >
            {energyTypes?.map((item, index) => (
              <div key={item.keyId}>
                <DsmGrid
                  style={{
                    grid: `auto/1fr ${
                      isBaselineDialog ? '0.88fr' : '1fr'
                    } 32px`,
                    gap: 'var(--dsm-spacing-px-4)',
                    alignItems: 'start',
                    alignContent: ' space-between',
                    padding: '0px',
                  }}
                >
                  <SustellResourceAdditionalItem
                    labelInput={intl.formatMessage({
                      id: 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_USE',
                    })}
                    labelSelect={intl.formatMessage({
                      id: 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_TYPE',
                    })}
                    placeholderSelect=""
                    tooltipType={isShrimp(animalType) 
                      ? intl.formatMessage({
                          id:'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_TYPE.SHRIMP.TOOLTIP',
                        }) 
                      : ''
                    }
                    tooltipUse={intl.formatMessage({
                      id: isShrimp(animalType) 
                      ? 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_USE.SHRIMP.TOOLTIP'
                      : 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY_USE.TOOLTIP',
                    })}
                    index={index}
                    formType={formType}
                    defaultValueAmount={item?.value}
                    deafultValueType={item?.type}
                    required={required}
                    amountFieldName="value"
                    fieldItemPrefix={`${inputType}.energyTypes`}
                    isFeeds={isFeed}
                    unavailableOptions={
                      !isMarineFish(animalType) ? [] : ['dieselUse']
                    }
                    onChange={onChange}
                  />
                  {formType !== FormType.View && 
                  removeAnotherEnergyType && 
                  energyTypes.length > 0 && (
                    <DsmButton
                      variant="text"
                      destructive
                      style={{
                        position: 'relative',
                        width: '100%',
                        padding: '0px',
                        marginTop: '24px',
                      }}
                      onClick={() => {
                        removeAnotherEnergyType(index);
                        if (onChange) onChange();
                      }}
                    >
                      <DsmIcon
                        name="general/trash-01"
                        style={{
                          position: 'absolute',
                          right: '0',
                        }}
                      />
                    </DsmButton>
                  )}
                </DsmGrid>
              </div>
            ))}
            <DsmButton
              variant="text"
              onClick={() => {
                appendAnotherEnergyType({ type: '', value: '' });
                if (onChange) onChange();
              }}
              disabled={
                formType === FormType.View ||
                energyTypes.length >=
                  (!isMarineFish(animalType)
                    ? lookupValues.energyTypesList.length
                    : lookupValues.energyTypesList.length - 1)
              }
            >
              <DsmIcon slot="before" name="general/plus-circle" />
              {intl.formatMessage({
                id: 'BASELINE.FORM.RESOURCES.ADD.ANOTHER.ENERGY.TYPE_BUTTON',
              })}
            </DsmButton>
          </DsmGrid>

          <DsmGrid
            className={classes.dsmGridTwoColumn}
            // eslint-disable-next-line no-nested-ternary
            style={{
              marginTop: `var(--dsm-spacing-px-6)`,
              width: isV2Changes ? (isBaselineDialog ? '' : '75%') : '62%',
            }}
          >
            {!isV2Changes && (
              <ReactHookDsmInput
                name={getFormFieldName('heatUse')}
                label={intl.formatMessage({
                  id: 'COMPOUND_FEED.ON_SITE_ACTIVITIES.HEAT_USE',
                })}
                tooltip={intl.formatMessage({
                  id: 'COMPOUND_FEED.ON_SITE_ACTIVITIES.HEAT_USE',
                })}
                type="number"
                adornment={
                  userUOM?.unitHeatUse
                    ? unitLong(userUOM.unitHeatUse)
                    : defaultUnitsV2.unitHeatUse
                }
                disabled={formType === FormType.View}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                defaultValue={getValues(getFormFieldName('heatUse')) || ''}
              />
            )}

            {(!isShrimp(animalType) || !isBaselineDialog) && 
              (
                <ReactHookDsmInput
                  name={getFormFieldName('waterUse')}
                  label={intl.formatMessage({
                    id: isShrimp(animalType) ? 
                    'BASELINE.FORM.OPERATIONS.SHRIMP.FRESH_WATER' : 
                    'BASELINE.FORM.RESOURCES.WATER',
                  })}
                  tooltip={intl.formatMessage({
                    id: isShrimp(animalType) ? 
                    'BASELINE.FORM.OPERATIONS.SHRIMP.FRESH_WATER.TOOLTIP' : 
                    'BASELINE.FORM.RESOURCES.WATER.TOOLTIP',
                  })}
                  type="number"
                  adornment={
                    // eslint-disable-next-line no-nested-ternary
                    isFeed
                      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
                        userUOM?.unitCompoundFeedWaterUse
                        ? unitLong(userUOM.unitCompoundFeedWaterUse)
                        : defaultUnitsV2.unitCompoundFeedWaterUse
                      : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument
                      userUOM?.unitWaterUse
                      ? unitLong(userUOM.unitWaterUse)
                      : defaultUnitsV2.unitWaterUse
                  }
                  disabled={formType === FormType.View}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  defaultValue={getValues(getFormFieldName('waterUse')) || ''}
                />
              )
            }
          </DsmGrid>
        </>
      )}
    </>
  );
};

export default Resources;
