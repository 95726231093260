import { API } from 'aws-amplify';
import { GraphQLError } from 'graphql';

import { FC, useEffect, useState } from 'react';
import { DsmButton, DsmGrid } from '@dsm-dcs/design-system-react';
import { useHistory } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import slaughterHouseFacilityFormValidation, {
  slaughterhouseFacilityDefaultValues,
} from '../components/Facilities/slaughterhouseFacilityValidation';
// eslint-disable-next-line import/no-named-as-default
import StepperButtons from '../components/FarmFlow/CommonDataParts/v2.0/StepperButtons';
import Can from '../../modules/Auth/Can';
import { useIntl } from '../../../_metronic/i18n/customUseIntl';
import { FormType } from '../components/FarmFlow/common';
import { DSM_BLUE, processAndStageStylesV2 } from '../../../_metronic/layout';
import ReactHookDsmInput from '../../modules/Helpers/ReactHookDsmInput2';
import { enumToOptionsArrayWithTranslatedStrings } from '../utils/obj-utils';
import {
  Facility,
  FacilityType,
  Currency,
  AnimalType,
} from '../../../graphql/types';
import ReactHookDsmSelect from '../../modules/Helpers/ReactHookDsmSelect2';
import { listEntryDataSorter } from '../helpers/sortingFunctions';
import FacilityResources from '../components/Facilities/FacilityResources';
import FacilityAuxiliaryInputs from '../components/Facilities/FacilityAuxiliaryInputs';
import {
  FacilitySpecies,
  SlaughterhouseFacilityForm,
} from '../models/Facility/FacilityTypes';
import mapFacilityDataOut from '../components/Facilities/mapper/outFacilityMapper';
import { saveFacility } from '../apicalls/facilityFunctions';
import { getFacility } from '../../../graphql/queries';
import { mapFacilityDataIn } from '../components/Facilities/mapper/inFacilityMapper';
import {
  NotificationDialog,
  NotificationDialogType,
} from '../components/helpers/NotificationDialog';
import countryIsoCodes from '../components/dashboard/isoCodes';
import { UserProfilePrefs } from '../../modules/Helpers/UserProfilePrefs';
import {
  isBeef,
  isMarineFish,
  isPoultryAvailableForProcessing,
} from '../helpers/animals';
import useAdobeDataLayer from '../analytics/adobeDataLayer';
import featureFlags from '../utils/featureFlags';

type DsmStyles = any;

type TargetSpeciesOption = {
  text: string;
  value: FacilitySpecies;
};

interface NotificationType {
  message: string | null;
  type: NotificationDialogType;
}

interface FacilityFormPageProps {
  // eslint-disable-next-line react/require-default-props
  location?: {
    state?: {
      formType?: FormType;
      duplicate?: boolean;
      facilityType?: FacilityType | undefined;
      animalType?: AnimalType | undefined;
      datasetParams?: {
        farmID: string;
        processID: string;
        datasetID: string;
        fromIntervention?: boolean;
        baselineID?: string;
        farmName?: string;
        processName?: string;
      };
      currentStep: number;
    };
  };
  match: {
    params: {
      customerID: string;
      facilityID: string;
    };
  };
}

const FacilityFormPage: FC<FacilityFormPageProps> = ({
  location = undefined,
  match,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { customerID, facilityID } = match.params;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const facilityType = location?.state?.facilityType;
  const animalType = location?.state?.animalType;
  const datasetParams = location?.state?.datasetParams;
  const [notification, setNotification] = useState<NotificationType | null>(
    null
  );
  const [formType, setFormType] = useState<FormType>(
    location?.state?.formType ||
      (facilityID === 'NEW' ? FormType.Add : FormType.View)
  );
  const [facility, setFacility] = useState<SlaughterhouseFacilityForm>();
  const classes: DsmStyles = processAndStageStylesV2();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const userUOM = UserProfilePrefs.getInstance().getUserUnitPrefs();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const outputMassUnit = userUOM.unitBarnOutputMass;
  const { formSubmissionEvent, formErrorsEvent } = useAdobeDataLayer();

  const methods = useForm<SlaughterhouseFacilityForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(slaughterHouseFacilityFormValidation(intl)),
    defaultValues: slaughterhouseFacilityDefaultValues,
  });

  const createDatasetURL = (): string | undefined => {
    if (!datasetParams || !customerID) {
      return undefined;
    }
    if (!datasetParams?.fromIntervention)
      return `/customers/${customerID}/farm/${datasetParams.farmID}/pp/${datasetParams.processID}/nds/${datasetParams.datasetID}`;

    return `/customers/${customerID}/farm/${datasetParams.farmID}/pp/${datasetParams.processID}/nint/${datasetParams.datasetID}`;
  };

  const navigateSaveCancel = (savedData?: Facility) => {
    const datasetURL = createDatasetURL();
    // If state for navigation exist go to dataset page, otherwise go to facilities
    if (datasetURL) {
      const currentStep = location?.state?.currentStep;

      let stateObj;
      if (savedData) {
        const newFacilityInfo = {
          id: savedData?.id,
          name: savedData?.name,
          type: savedData?.type,
        };

        stateObj = {
          formType:
            datasetParams?.datasetID === 'NEW' ? FormType.Add : FormType.Edit,
          autoRestoreDraft: true,
          currentStep,
          animalType,
          newFacilityInfo,
          datasetID: '',
          interventionStep: 0,
          farmName: datasetParams?.farmName,
          processName: datasetParams?.processName,
        };
      } else {
        stateObj = {
          formType:
            datasetParams?.datasetID === 'NEW' ? FormType.Add : FormType.Edit,
          autoRestoreDraft: true,
          currentStep,
          animalType,
          datasetID: '',
          interventionStep: 0,
          farmName: datasetParams?.farmName,
          processName: datasetParams?.processName,
        };
      }

      if (datasetParams?.fromIntervention) {
        stateObj.datasetID = datasetParams.baselineID || 'NEW';
        stateObj.interventionStep = 2;
      }

      history.push(datasetURL, stateObj);
    } else {
      history.push(`/profile/customers/${customerID}/facilities`);
    }
  };

  const closeNotificationDialog = () => {
    setNotification(null);
  };

  const saveSuccessHandler = (savedData: Facility) => {
    setNotification({
      message: intl.formatMessage({ id: 'BASELINE.SAVE.SUCCESS' }),
      type: NotificationDialogType.INFO,
    });
    navigateSaveCancel(savedData);
  };

  const saveFailedHandler = (errorMessage: string) => {
    setNotification({
      message: errorMessage,
      type: NotificationDialogType.ERROR,
    });
  };

  const onErrors = (errors: any) => {
    console.log('onErrors', errors);
    const formErrors = Object.keys(methods.formState.errors).length;
    formErrorsEvent(facility?.oldName ? 'Edit facility' : 'Add facility',
    facility?.oldName ? 'edit' : 'add', formErrors)
  };

  const onSubmit = (data: SlaughterhouseFacilityForm) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const mappedData = mapFacilityDataOut(data);
    formSubmissionEvent(
      facility?.oldName ? 'Edit facility' : 'Add facility',
      facility?.oldName ? 'edit' : 'add'
    );
    saveFacility(
      mappedData,
      customerID,
      saveSuccessHandler,
      saveFailedHandler,
      data?.id || undefined,
      facility?.oldName
    );
  };

  const loadFacility = () => {
    (
      API.graphql({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        query: getFacility,
        variables: {
          customerId: customerID,
          facilityId: facilityID,
        },
      }) as Promise<{
        data: {
          getFacility: Facility;
        };
      }>
    ).then(
      (response) => {
        const facilityFormObj = mapFacilityDataIn(response.data.getFacility);

        // if copy, change name, ID, and mode
        if (location?.state?.duplicate && facilityFormObj) {
          facilityFormObj.id = null;
          facilityFormObj.name = `${facilityFormObj.name} - COPY`;
          setFormType(FormType.Add);
        }

        setFacility(facilityFormObj);
        methods.reset(facilityFormObj);
      },
      (reason: { errors: GraphQLError[] }) => {
        console.log('err, ', reason);
        if (reason.errors.length > 0) {
          setNotification({
            message: reason.errors.at(0)?.message || 'Error',
            type: NotificationDialogType.ERROR,
          });
        }
      }
    );
  };

  const getTargetSpeciesDefaultValue = () => {
    const targetSpecies = methods.getValues('targetSpecies');

    if (targetSpecies) return targetSpecies;

    const animalType = location?.state?.animalType;
    if (!animalType) return undefined;

    if (isMarineFish(animalType)) {
      return FacilitySpecies.MarineFish;
    }
    if (isPoultryAvailableForProcessing(animalType)) {
      return FacilitySpecies.Poultry;
    }
    if (isBeef(animalType)) {
      return FacilitySpecies.Beef;
    }
    return FacilitySpecies.Pig;
  };

  useEffect(() => {
    if (facilityID && facilityID !== 'NEW' && customerID) {
      loadFacility();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const switchToEdit = () => {
    setFormType(FormType.Edit);
  };

  const getTargetSpeciesOptions = (): Array<TargetSpeciesOption> => {
    const availableOptions = [
      {
        text: intl.formatMessage({ id: `SUSTELL.FACILITIES.INFO.USAGE.ANIMAL_SYSTEM_TYPE.${FacilitySpecies.Pig}`}),
        value: FacilitySpecies.Pig,
      },
      // Disabled until Marine Fish processing is ready for production.
      // {
      //   text: intl.formatMessage({ id: `SUSTELL.FACILITIES.INFO.USAGE.ANIMAL_SYSTEM_TYPE.${FacilitySpecies.MarineFish}`}),
      //   value: FacilitySpecies.MarineFish,
      // },
      {
        text: intl.formatMessage({ id: `SUSTELL.FACILITIES.INFO.USAGE.ANIMAL_SYSTEM_TYPE.${FacilitySpecies.Poultry}`}),
        value: FacilitySpecies.Poultry,
      },
    ];

    if (featureFlags.beefProcessingV2) {
      return [
        ...availableOptions,
        {
          text: 'Beef',
          value: FacilitySpecies.Beef,
        },
      ]
    }

    return availableOptions;
  }

  return (
    <>
      <NotificationDialog
        open={!!notification}
        message={notification ? notification.message : null}
        type={notification ? notification.type : NotificationDialogType.INFO}
        closeNotificationDialog={() => closeNotificationDialog()}
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <input ref={methods.register()} type="hidden" name="id" />
          <h2 style={{ color: DSM_BLUE }}>
            {intl.formatMessage({ id: 'SUSTELL.FACILITIES.TITLE_NEW' })}
          </h2>
          <p>{intl.formatMessage({ id: 'SUSTELL.FACILITIES.DESCRIPTION' })}</p>
          {formType === FormType.View && Can('update', 'Facility') &&  !facility?.benchmark && (
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <DsmButton
                onClick={() => switchToEdit()}
                color="secondary"
                style={{ marginLeft: 'auto' }}
              >
                {intl.formatMessage({ id: 'SUSTELL.EDIT_FACILITY' })}
              </DsmButton>
            </div>
          )}
          <br />
          <br />

          <h3>{intl.formatMessage({ id: 'SUSTELL.FACILITIES.INFO.TITLE' })}</h3>
          <p>
            {intl.formatMessage({ id: 'SUSTELL.FACILITIES.INFO.DESCRIPTION' })}
          </p>
          <DsmGrid
            className={classes.dsmGridThreeColumn}
            style={{ marginTop: `var(--dsm-spacing-px-6)` }}
          >
            <ReactHookDsmInput
              name="name"
              label={intl.formatMessage({ id: 'SUSTELL.FACILITIES.INFO.NAME' })}
              placeholder={intl.formatMessage({
                id: 'SUSTELL.FACILITIES.INFO.NAME.PLACEHOLDER',
              })}
              required
              disabled={formType === FormType.View}
            />
            <ReactHookDsmSelect
              label={intl.formatMessage({
                id: 'SUSTELL.FACILITIES.INFO.USAGE',
              })}
              placeholder={intl.formatMessage({
                id: 'SUSTELL.FACILITIES.INFO.USAGE.PLACEHOLDER',
              })}
              name="type"
              required
              options={enumToOptionsArrayWithTranslatedStrings(
                FacilityType as object,
                intl,
                'SUSTELL.FACILITIES.INFO.USAGE'
              ).sort((a, b) => (a.value < b.value ? 1 : -1))}
              changeHandler={() => {}}
              disabled={formType !== FormType.Add}
              defaultValue={facilityType || methods.getValues('type')}
              adornment=" "
              tooltip={intl.formatMessage({
                id: 'SUSTELL.FACILITIES.INFO.USAGE.TT',
              })}
            />
            <ReactHookDsmSelect
              label={intl.formatMessage({ id: 'GENERAL.LOCATION' })}
              placeholder={intl.formatMessage({
                id: 'SUSTELL.FACILITIES.INFO.LOCATION.PLACEHOLDER',
              })}
              name="location"
              options={Object.entries(countryIsoCodes)
                .map(([value]) => ({
                  text: intl.formatMessage({id: `SUSTELL.GEOGRAPHY.${value.toUpperCase()}`}),
                  value,
                }))
                .sort(listEntryDataSorter)}
              changeHandler={() => {}}
              required
              disabled={formType === FormType.View}
              defaultValue={methods.getValues('location')}
            />

            <ReactHookDsmInput
              name="totalLiveweight"
              label={intl.formatMessage({
                id: 'SUSTELL.FACILITIES.INFO.TOTAL_LW',
              })}
              required
              type="number"
              adornment={intl.formatMessage(
                {
                  id: 'SUSTELL.FACILITIES.RESOURCES.UNIT_PER_YEAR_PLACEHOLDER',
                },
                { unit: outputMassUnit }
              )}
              tooltip={intl.formatMessage({
                id: 'SUSTELL.FACILITIES.INFO.TOTAL_LW.TT',
              })}
              disabled={formType === FormType.View}
            />
            <ReactHookDsmSelect
              name="targetSpecies"
              label={intl.formatMessage({
                id: 'GENERAL.ANIMAL_SYSTEM_TYPE',
              })}
              placeholder={intl.formatMessage({
                id: 'SUSTELL.FACILITIES.INFO.SPECIES.PLACEHOLDER',
              })}
              options={getTargetSpeciesOptions()}
              changeHandler={() => {}}
              required
              disabled={formType !== FormType.Add}
              defaultValue={getTargetSpeciesDefaultValue()}
            />
            <ReactHookDsmSelect
              label={intl.formatMessage({
                id: 'SUSTELL.FACILITIES.INFO.CURRENCY',
              })}
              placeholder={intl.formatMessage({
                id: 'SUSTELL.FACILITIES.INFO.CURRENCY.PLACEHOLDER',
              })}
              name="currency"
              required
              options={enumToOptionsArrayWithTranslatedStrings(
                Currency,
                intl,
                'SUSTELL.CURRENCY'
              )}
              disabled={formType === FormType.View}
              defaultValue={methods.getValues('currency')}
            />
          </DsmGrid>

          <FacilityResources formType={formType} />
          <FacilityAuxiliaryInputs formType={formType} />

          <StepperButtons
            continueLabel={intl.formatMessage({ id: 'GENERAL.SAVE' })}
            cancelHandler={() => navigateSaveCancel()}
            continueHandler={() => {
              // eslint-disable-next-line no-void
              void methods.handleSubmit(onSubmit, onErrors)();
            }}
          />
        </form>
      </FormProvider>
    </>
  );
};

export default FacilityFormPage;
