/* eslint-disable @typescript-eslint/unbound-method */
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { API } from 'aws-amplify';
// eslint-disable-next-line import/no-named-as-default
import { DsmGrid, DsmIcon } from '@dsm-dcs/design-system-react';
import { getDefaultCustomer } from '../../../graphql/queries';
import { updateUserSettings } from '../../../graphql/mutations';
import dsmTheme from '../MUITheme';
import { UserProfilePrefs } from '../Helpers/UserProfilePrefs';
import { useIsMounted } from '../Helpers/use-is-mounted';
import { useIntl } from '../../../_metronic/i18n/customUseIntl';
import { UserSettings, Customer } from '../../../graphql/types';
import useStyles from './profileStyles/myProfileStyles';
import { DsmButtonV2 } from '../../sustell_15/components/helpers/DsmButton';
import ReactHookDsmSelect from '../Helpers/ReactHookDsmSelect2';
import useAdobeDataLayer from '../../sustell_15/analytics/adobeDataLayer';


interface DefaultCustomerProps{
  customers: Customer[];
}

const DefaultCustomer: React.FC<DefaultCustomerProps> = (
  props: DefaultCustomerProps
) => {
  const intl = useIntl();
  const classes = useStyles();
  const [msg, setMsg] = useState('');
  const [cancelPressed, setCancelPressed] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isInError, setIsInError] = useState(false);
  const [dialogActive, setDialogActive] = useState(false);
  const [touched, setTouched] = useState(false);
  const isMounted = useIsMounted();
  const { formSubmissionEvent } = useAdobeDataLayer();

  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onBlur',
    defaultValues: {
      defaultCustomer: ''
    },
  });
  const history = useHistory();
  
  const loadDefaultCustomer = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    (
      API.graphql({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        query: getDefaultCustomer,
      }) as Promise<{ data: { getUserSettings: UserSettings } }>
    ).then(
      (response) => {
        setTouched(false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (response.data.getUserSettings.defaultCustomer && isMounted())
          methods.setValue('defaultCustomer', response.data.getUserSettings.defaultCustomer);
      },
      (reason: any) => {
        console.log('Error fetching default customer: ', reason);
      }
    );
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadDefaultCustomer();
    if (cancelPressed) {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (
    submitData:
      | {
          defaultCustomer?: string | undefined;
        }
      | undefined
  ) => {
    (async () => {
      formSubmissionEvent('Edit Default customer', 'edit');
      try {
        await (API.graphql({
          query: updateUserSettings,
          variables: {
            input: submitData,
            segment: 'DEFAULT_CUSTOMER', // updating database segment of user inputs
          },
        }) as Promise<{
          data: {
            updateUserSettings: UserSettings | null;
          };
        }>);
        setIsInError(false);
        setMsg(intl.formatMessage({ id: 'PROFILE.DEFAULT_CUSTOMER.PREF_SAVED' }));
        setDialogActive(true);
        // // data is saved with submitData so reset the form to clear isDirty property and prevent Prompt
        methods.reset(submitData);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('error: ', err);
        if (err.errors?.length > 0) {
          const error = err.errors[0];
          if (error) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            setMsg(error.message);
            setIsInError(true);
            setDialogActive(true);
          }
        }
      }
    })()
      .then(() => {})
      .catch(() => {});
  };

  const closeDialog = () => {
    setDialogActive(false);
    setMsg('');
    setTouched(false);
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  const cancelChanges = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (touched) {
      setUnsavedChanges(true);
    } else {
      setCancelPressed(true);
      if (history.length > 1) {
        history.goBack();
      } else {
        history.push('/');
      }
    }
  }

  const close = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push('/');
    }
  }

  const handleTouched = () => {
    if (!touched) {
      setTouched(true);
    }
  };

  return (
    <>
      <Dialog open={unsavedChanges} aria-labelledby="draft-dialog">
        <DialogTitle id="draft-dialog">
          {intl.formatMessage({ id: 'GENERAL.UNSAVED_CHANGES' })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {intl.formatMessage({ id: 'UNSAVEDSTATE.PROMPT.CONFIRM' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary">
            {intl.formatMessage({ id: 'GENERAL.YES' })}
          </Button>
          <Button
            autoFocus
            onClick={() => setUnsavedChanges(false)}
            color="default"
          >
            {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
          </Button>
        </DialogActions>
      </Dialog>
      <ThemeProvider theme={dsmTheme}>
        <Dialog
          open={dialogActive}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            {isInError && (
              <>
                <ErrorOutlineIcon fontSize="large" color="error" />
                &nbsp;&nbsp;Error
              </>
            )}
            {!isInError && (
              <>
                <CheckCircleOutlineIcon
                  fontSize="large"
                  style={{ color: 'green' }}
                />
                &nbsp;&nbsp;Success
              </>
            )}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {msg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {isInError && (
              <Button onClick={closeDialog} color="primary">
                {intl.formatMessage({ id: 'GENERAL.CLOSE' })}
              </Button>
            )}
            {!isInError && (
              <Button onClick={closeDialog} color="primary">
                {intl.formatMessage({ id: 'GENERAL.OK' })}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </ThemeProvider>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form
          className={classes.formContainer}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {/* begin::Body */}
          <div className="card-body p-0">
            <div className={classes.formSection}>
              <Typography className={classes.profileTitle}>
                {intl.formatMessage({ id: 'PROFILE.DEFAULT_CUSTOMER.TITLE' })}
              </Typography>
              <Typography className={classes.profileDescription}>
                {intl.formatMessage({ id: 'PROFILE.DEFAULT_CUSTOMER.DESCRIPTION' })}
              </Typography>
            </div>
          </div>
          <DsmGrid
            style={{
              grid: 'auto/1fr 1fr 1fr',
              padding: '0px',
              alignItems: 'start',
            }}
          >
            <ReactHookDsmSelect
              name="defaultCustomer"
              label={intl.formatMessage({ id: 'PROFILE.DEFAULT_CUSTOMER.SELECT' })}
              disabled={false}
              placeholder={intl.formatMessage({ id: 'PROFILE.DEFAULT_CUSTOMER.INPUT' })}
              options={Object.entries(props.customers).map(
                ([i, customer]) => ({
                  value: `${customer.id}`,
                  text: `${customer.name}`,
                })
              )}
              changeHandler={handleTouched}
              required
            />
          </DsmGrid>
          <div className={classes.btnDiv}>
            <DsmButtonV2
              variant="contained"
              type="button"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                cancelChanges(e);
              }}
              className={classes.cancelBtn}
              style={{
                background: 'var(--error-dsm-red, #E51F22)',
                '&:hover': {
                  background: 'var(--error-dsm-red, #E51F22)',
                },
              }}
            >
              <DsmIcon
                slot="before"
                name="general/trash-01"
                style={{ paddingRight: '5px', height: '20px', width: '20px' }}
              />
              <span className={classes.saveBtnTxt}>
                {intl.formatMessage({ id: 'GENERAL.CANCEL' })}
              </span>
            </DsmButtonV2>
            <div className="text-right">
              <DsmButtonV2
                variant="contained"
                disableElevation
                type="submit"
                disabled={
                  methods.formState.isSubmitting ||
                  (methods.formState.isDirty && !methods.formState.isValid) ||
                  !touched
                }
              >
                <span className={classes.saveBtnTxt}>
                  {intl.formatMessage({ id: 'GENERAL.SAVE' })}
                </span>
                <DsmIcon
                  slot="after"
                  name="arrows/arrow-right"
                  style={{
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    height: '25px',
                    width: '25px',
                    color: 'var(--neutrals-dsm-white, #FFF)',
                  }}
                />
              </DsmButtonV2>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default DefaultCustomer;
