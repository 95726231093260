export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      id
    }
  }
`;

export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      id
    }
  }
`;

export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id,
      enabled,
      status
    }
  }
`;

export const updateUser = /* GraphQL */ `
  mutation UpdateUser($userId: ID!, $input: UpdateUserInput!) {
    updateUser(userId: $userId, input: $input) {
      id
    }
  }
`;

export const benchmarkRequests = /* GraphQL */ `
  mutation BenchmarkRequests($customerId: ID!, $input: BenchmarkRequestInput!) {
    benchmarkRequests(customerId: $customerId, input: $input) {
      results
    }
  }
`;

export const resendActivation = /* GraphQL */ `
  mutation ResendActivation($email: String!) {
    resendActivation(email: $email) {
      id
    }
  }
`;

export const createFarm =  /* GraphQL */ `
  mutation CreateFarm($customerId: ID!, $input: CreateFarmInput!) {
    createFarm(customerId: $customerId, input: $input) {
      id
    }
  }
`;

export const updateFarm = /* GraphQL */ `
  mutation UpdateFarm($customerId: ID!, $input: UpdateFarmInput!) {
    updateFarm(customerId: $customerId, input: $input) {
      id
    }
  }
`;

export const deleteFarm = /* GraphQL */ `
  mutation DeleteFarm($customerId: ID!, $farmId: ID!) {
    deleteFarm(customerId: $customerId, farmId: $farmId) {
      id
    }
  }
`;

export const duplicateFarm = /* GraphQL */ `
  mutation DuplicateFarm($customerId: ID!, $farmId: ID!) {
    duplicateFarm(customerId: $customerId, farmId: $farmId) {
      id
    }
  }
`;

export const deleteCompoundFeedV2 = /* GraphQL */`
mutation deleteCompoundFeedV2($customerId: ID!, $feedId: ID!) {
  deleteCompoundFeedV2(customerId: $customerId, feedId: $feedId) {
    id
  }
}
`

export const createProject = /* GraphQL */ `
  mutation CreateProject($customerId: ID!, $input: CreateProjectInput!) {
    createProject(customerId: $customerId, input: $input) {
      id
    }
  }
`;

export const createFarmBaseline = /* GraphQL */ `
  mutation createFarmBaseline($customerId: ID!, $farmId: ID!, $animalSystemId: ID!, $input: CreateFarmBaselineInput!) {
    createFarmBaseline(customerId: $customerId, farmId: $farmId, animalSystemId: $animalSystemId, input: $input) {
      farmId
      name
    }
  }
`;

export const updateFarmBaseline = /* GraphQL */ `
  mutation updateFarmBaseline($customerId: ID!, $farmId: ID!, $input: UpdateFarmBaselineInput!) {
    updateFarmBaseline(customerId: $customerId, farmId: $farmId, input: $input) {
      farmId
      name
    }
  }
`;

export const copyBaselineToV2 = /* GraphQL */ `
  mutation copyBaselineToV2($input: CopyBaselineInput!) {
    copyBaselineToV2(input: $input) {
      newBaselineReference
    }
  }
`;

export const createCompoundFeedV2 = /* GraphQL */ `
  mutation createCompoundFeedV2($customerId: ID!, $input: CreateCompoundFeedInputV2!) {
    createCompoundFeedV2(customerId: $customerId, input: $input) {
      id
    }
  }
`;

export const updateCompoundFeedV2 = /* GraphQL */ `
  mutation updateCompoundFeedV2($customerId: ID!, $input: UpdateCompoundFeedInputV2!, $feedId: ID!) {
    updateCompoundFeedV2(customerId: $customerId, input: $input, feedId: $feedId) {
      id
    }
  }
`;

export const updateProject = /* GraphQL */ `
  mutation UpdateProject($customerId: ID!, $input: UpdateProjectInput!) {
    updateProject(customerId: $customerId, input: $input) {
      id
    }
  }
`;

export const deleteProject = /* GraphQL */ `
  mutation DeleteProject($customerId: ID!, $projectId: ID!) {
    deleteProject(customerId: $customerId, projectId: $projectId) {
      id
    }
  }
`;

export const createAnimalSystem = /* GraphQL */ `
  mutation CreateAnimalSystem($farmId: ID!, $input: CreateAnimalSystemInput!) {
    createAnimalSystem(farmId: $farmId, input: $input) {
      id
    }
  }
`;

export const updateAnimalSystem = /* GraphQL */ `
  mutation UpdateAnimalSystem($farmId: ID!, $input: UpdateAnimalSystemInput!) {
    updateAnimalSystem(farmId: $farmId, input: $input) {
      id
    }
  }
`;

export const deleteAnimalSystem = /* GraphQL */ `
  mutation DeleteAnimalSystem($animalSystemId: ID!) {
    deleteAnimalSystem(animalSystemId: $animalSystemId) {
      id
    }
  }
`;

export const createFacility = /* GraphQL */ `
  mutation CreateFacility($customerId: ID!, $input: CreateFacilityInput!) {
    createFacility(customerId: $customerId, input: $input) {
      id
    }
  }
`;

export const updateFacility = /* GraphQL */ `
  mutation UpdateFacility($customerId: ID!, $facilityId: ID!, $input: UpdateFacilityInput!) {
    updateFacility(customerId: $customerId, facilityId: $facilityId, input: $input) {
      id
    }
  }
`;

export const deleteFacility = /* GraphQL */ `
  mutation DeleteFacility($customerId: ID!, $facilityId: ID!) {
    deleteFacility(customerId: $customerId, facilityId: $facilityId) {
      id
    }
  }
`;

export const deleteProductionDataset = /* GraphQL */ `
  mutation DeleteProductionDataset($farmId: ID!, $name: String!) {
    deleteProductionDataset(farmId: $farmId, name: $name) {
      id
    }
  }
`;

export const deleteIntervention = /* GraphQL */ `
  mutation DeleteIntervention($customerId: ID!, $SK: String!) {
    deleteIntervention(customerId: $customerId, SK: $SK) {
      id
    }
  }
`;


export const updateProjectWithFarms = /* GraphQL */ `
  mutation UpdateProjectWithFarms($projectId: ID!, $input: UpdateProjectFarmsInput!) {
    updateProjectWithFarms(projectId:$projectId, input: $input) {
      id
      name
      updatedAt
      animalType
      description
      farms
    }
  }
`;

export const createIntervention = /* GraphQL */ `
  mutation createIntervention($customerId: ID!, $farmId: ID!, $animalSystemId: ID!, $input: CreateInterventionInput!) {
    createIntervention(customerId: $customerId, farmId: $farmId, animalSystemId: $animalSystemId, input: $input) {
      customerId
      name
      productionProcessId 
      farmId
      updatedAt
    }
  }
`;

export const updateIntervention = /* GraphQL */ `
  mutation updateIntervention($customerId: ID!, $farmId: ID!, $animalSystemId: ID!, $oldName:String!, $input: UpdateInterventionInput!) {
    updateIntervention(customerId: $customerId, farmId: $farmId, animalSystemId: $animalSystemId, oldName:$oldName, input: $input) {
      id
    }
  }
`;

export const updateUserSettings = /* GraphQL */ `
  mutation updateUserSettings($input: UpdateUserSettingsInput!, $segment: UserSettingsSegment!) {
    updateUserSettings(input: $input, segment: $segment) {
      unitFarmSize
      unitAverageAnnualTemperature
      unitOutputMass
      unitElectricityUse
      unitNaturalGasUse
      unitDieselUse
      unitHeatUse
      unitWaterUse
      unitBarnOutputMass
      unitBarnSmallQuantityUnits
      unitEnvImpactPer
      unitIngredientQuantityInputMass
      unitCompoundFeedOutputMass
      unitTransportDistanceTerrestrial
      unitTransportDistanceSea
      unitTransportDistanceInlandWaterways
      unitCompoundFeedWaterUse
      unitCompoundFeedNutritionalDataContent
      unitNutritionalGrossEnergy
      footprint
      databaseType
      numberOfLoginTimes
      sampleDataAccessPopup
    }
  }
`;

export const createNotification = /* GraphQL */ `
  mutation createNotification($input: CreateNotificationInput!) {
    createNotification(input: $input) {
      id
      title
      message
      active
      isWelcome
      startDateTime
      expireDateTime
      notificationType
      targetGroups
      attachementKeys
    }
  }
`;

export const createIncident = /* GraphQL */ `
  mutation createIncident($input: CreateIncidentInput!) {
    createIncident(input: $input) {
      incidentID
    }
  }
`;

export const updateNotification = /* GraphQL */ `
  mutation updateNotification($notificationID: ID!, $input: CreateNotificationInput!) {
    updateNotification(notificationID: $notificationID, input: $input) {
      id
      title
      message
      active
      isWelcome
      startDateTime
      expireDateTime
      notificationType
      targetGroups
      attachementKeys
    }
  }
`;

export const createCompoundFeed = /* GraphQL */ `
  mutation createCompoundFeed($customerId: ID!, $input:CreateCompoundFeedInput!) {
    createCompoundFeed(customerId: $customerId, input: $input) {
      id
    }
  }
`;

export const updateCompoundFeed = /* GraphQL */ `
  mutation updateCompoundFeed($customerId: ID!, $feedId: ID!, $input:CreateCompoundFeedInput!) {
    updateCompoundFeed(customerId: $customerId, feedId:$feedId, input: $input) {
      id
    }
  }
`;

export const deleteCompoundFeed = /* GraphQL */ `
  mutation deleteCompoundFeed($customerId: ID!, $feedId: ID!) {
    deleteCompoundFeed(customerId: $customerId, feedId:$feedId) {
      id
      status
      usageItems{
        type
        reference
        name
        farmId
      }
    }
  }
`;

export const updateFootprintTarget = /* GraphQL */ `
  mutation updateFootprintTarget($input:UpdateFootprintTargetInput!) {
    updateFootprintTarget(input: $input) {
      reference
    }
  }
`;

export const updateCustomerDashboardTargets = /* GraphQL */ `
  mutation updateCustomerDashboardTargets($input:UpdateCustomerDashboardTargetsInput!) {
    updateCustomerDashboardTargets(input: $input) {
      targets
    }
  }
`;
