import React, { useCallback, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import {
  filterStageTypesByAnimalType,
  isMarineFish,
  isShrimp,
} from '../../helpers/animals';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { processAndStageStylesV2 } from '../../../../_metronic/layout';
import { StageType } from '../../../../graphql/types';
import ReactHookDsmInput from '../../../modules/Helpers/ReactHookDsmInput2';
import ReactHookDsmSelect from '../../../modules/Helpers/ReactHookDsmSelect2';
import { forbiddenChars } from '../../helpers/validations';
import { enumToOptionsArrayWithTranslatedStrings } from '../../utils/obj-utils';
import StepperButtons from './CommonDataParts/v2.0/StepperButtons';
import { FormType } from './common';
import { isProcessingStage } from '../../helpers/facilities';
import { capitalizeFirstLetter } from '../../helpers/textHelpers';

const StageSteps = {
  BASELINE_DETAILS: 1,
  STAGE_SELECTION: 2,
  STAGE_DETAILS: 3,  
}

const StageForm = (props) => {
  const {
    handleFormSave,
    handleClose,
    animalType,
    navigateToAddNewFacility,
    facilities,
    formType = FormType.Add,
    baselineFormType = FormType.Add,
    activeStep,
    stageData,
    selectedStages,
    stageTypeCounters,
    setStageTypeCounters,
  } = props;

  const intl = useIntl();
  const classes = processAndStageStylesV2();
  const [isInError, setIsInError] = useState(false);
  const [showFacilitySelect, setShowFacilitySelect] = useState(false);
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(
      Yup.object({
        name: Yup.string()
          .required(intl.formatMessage({ id: 'VALIDATION.NAME.REQUIRED' }))
          .min(
            3,
            intl.formatMessage(
              { id: 'VALIDATION.FIELD.MIN_LENGTH' },
              { count: 3 }
            )
          )
          .max(
            256,
            intl.formatMessage(
              { id: 'VALIDATION.FIELD.MAX_LENGTH' },
              { count: 256 }
            )
          )
          // prevent use of quotation marks in the name
          .concat(
            forbiddenChars(
              intl,
              ['"', "'"],
              'VALIDATION.FIELD.FORBIDDEN_QUOTATION'
            )
          ),
        // type: Yup.string().required(intl.formatMessage({id: "VALIDATION.FIELD.REQUIRED"}) ),
        facilityId: Yup.string()
          .nullable(true)
          .when('type', {
            is: StageType.Processing,
            then: Yup.string().required(
              intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
            ),
          }),
        facilityType: Yup.string()
          .nullable(true)
          .when('type', {
            is: StageType.Processing,
            then: Yup.string().required(
              intl.formatMessage({ id: 'VALIDATION.FIELD.REQUIRED' })
            ),
          }),

        type: Yup.string().test(
          'stageTypeRequiredForPig',
          '',
          function (value) {
            if (isMarineFish(animalType)) {
              return true;
            } else if (value) {
              return true;
            }
            return false;
          }
        ),
      })
    ),
    defaultValues: {
      id: null,
      name: '',
      type: stageData.type,
      facility: null,
      facilityType: '',
      facilityId: null,
    },
  });

  const setMarineFishDefaultStageType = useCallback(() => {
    if (!isMarineFish(animalType)) return;

    if (selectedStages && !selectedStages.length) {
      setShowFacilitySelect(false);
      methods.setValue('type', StageType.Growing);
    } else {
      methods.setValue('type', StageType.Processing);
      setShowFacilitySelect(true);
    }
  }, [methods, selectedStages, animalType]);

  const setProcessingStageData = useCallback(() => {
    const isFacilityIdAlreadySet = methods.getValues('facilityId');
    const isFacilityTypeAlreadySet = methods.getValues('facilityType');

    // Facility values only render after facility options are mounted and available.
    // If they are already set, it means that the form is populated and ready to display values in the UI
    // If not the values will be set again.
    if (isFacilityTypeAlreadySet && isFacilityIdAlreadySet) return;
    // Should populate the form only once, otherwise it will keep setting values and changing user inputs.
    setShowFacilitySelect(true);
    methods.setValue('id', stageData.id);
    methods.setValue('name', stageData.name);
    methods.setValue('type', stageData.type);
    methods.setValue('facilityId', stageData.facilityId);
    methods.setValue('facilityType', stageData.facilityType);
  }, [methods, stageData]);

  useEffect(() => {
    if (stageData && stageData.facilityId) {
      setProcessingStageData();
    }
  }, [stageData, methods, setProcessingStageData]);

  useEffect(() => {
    if (animalType && stageData && !stageData.type) {
      setMarineFishDefaultStageType();
    }
  }, [stageData, animalType, setMarineFishDefaultStageType]);

  const getFacilitiesOptions = () => {
    const retVal =
      facilities?.map((f) => ({
        text: `${f.name} (${intl.formatMessage({
          id: `SUSTELL.STAGE.${f.type}`,
        })})`,
        value: f.id,
      })) || [];

    retVal.push({
      text: `\u2A01 ${intl.formatMessage({
        id: 'SUSTELL.STAGES.PROCESSING.NEW_FACILITY',
      })}`,
      value: 'ADD_NEW_FACILITY',
    });

    return retVal;
  };

  const addStageTypeCounters = (type) => {
    const countersCopy = { ...stageTypeCounters };
    countersCopy[type] += 1;
    setStageTypeCounters(countersCopy);
  };

  const onChangeSelectedFacility = (e) => {
    if (e.target?.value === 'ADD_NEW_FACILITY') {
      if (navigateToAddNewFacility) {
        navigateToAddNewFacility();
      }
    } else {
      // After facility is selected replace placeholder processing stage with concrete
      // from facility type
      const facility = facilities.find((f) => f.id === e.target?.value);
      methods.setValue('facilityType', facility.type);
    }
  };

  const onSubmit = (submitData) => {
    setIsInError(false);
    // setShowFacilitySelect(false);
    if (handleFormSave && handleFormSave(submitData, methods)) {
      addStageTypeCounters(submitData.type);
      methods.reset(); // needed to avoid prompt after save of this or master form
      handleClose(false);
    }
  };

  const onSubmitAndContinue = (submitData) => {
    setIsInError(false);
    if (handleFormSave && handleFormSave(submitData, methods)) {
      addStageTypeCounters(submitData.type);
      methods.reset(); // needed to avoid prompt after save of this or master form
      handleClose(true);
    }
  };

  const onErrors = (errors) => {
    console.log('errors', errors);
    setIsInError(true);
  };

  const isGrowingStageSelected = () =>
    selectedStages.some((stage) => stage.type === StageType.Growing);

  const filterStageFromOptions = (allOptions, stage) =>
    allOptions.filter((option) => option.value !== stage);

  const getMarineFishStageTypeOptions = (allOptions) =>
    isGrowingStageSelected()
      ? filterStageFromOptions(allOptions, StageType.Growing)
      : filterStageFromOptions(allOptions, StageType.Processing);

  const getStageTypeOptions = () => {
    const allOptions = enumToOptionsArrayWithTranslatedStrings(
      filterStageTypesByAnimalType(animalType, selectedStages?.map((stage) => stage.type)),
      intl,
      isShrimp(animalType)
        ? 'SUSTELL.SHRIMP.ENUMS.STAGE.TYPE'
        : 'SUSTELL.ENUMS.STAGE.TYPE'
    );

    if (isMarineFish(animalType)) {
      return getMarineFishStageTypeOptions(allOptions);
    }

    return allOptions;
  };

  return (
    <div style={{ marginTop: '24px' }}>
      {isInError && (
        <Grid
          container
          direction="column"
          spacing={1}
          style={{ marginBottom: 10 }}
        >
          <Grid item xs={12}>
            <Alert severity="error">
              <AlertTitle>
                {intl.formatMessage({ id: 'SUSTELL.ERROR.MANDATORY_FIELDS' })}
              </AlertTitle>
            </Alert>
          </Grid>
        </Grid>
      )}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <input ref={methods.register()} type="hidden" name="id" />
          <DsmGrid
            className={
              isShrimp(animalType)
                ? classes.dsmGridThreeColumnNoRowSpacing
                : classes.dsmGridThreeColumn
            }
            style={{ width: '100%' }}
          >
            <ReactHookDsmSelect
              marginBottom={0}
              name="type"
              label={intl.formatMessage({ id: 'SUSTELL.STAGE.TYPE' })}
              control={methods.control}
              disabled={formType === 'view'}
              options={getStageTypeOptions()}
              required
              changeHandler={(e) => {
                const formatedName = capitalizeFirstLetter(e.target?.value).replaceAll('_', ' ');
                methods.setValue(
                  'name',
                  `${formatedName} ${
                    stageTypeCounters[e.target?.value] < 9 ? '0' : ''
                  }${stageTypeCounters[e.target?.value] + 1}`
                );
                setShowFacilitySelect(isProcessingStage(e.target?.value));
              }}
            />

            <ReactHookDsmInput
              name="name"
              label={intl.formatMessage({
                id: 'SUSTELL.FIELD_STAGE.NAME',
              })}
              required
              disabled={formType === 'view'}
            />

            {showFacilitySelect && (
              <>
                <ReactHookDsmSelect
                  marginBottom={0}
                  name="facilityId"
                  label={intl.formatMessage({
                    id: 'SUSTELL.STAGE.FACILITY_NAME',
                  })}
                  control={methods.control}
                  disabled={formType === 'view'}
                  defaultValue={methods.getValues('facilityId')}
                  options={getFacilitiesOptions()}
                  adornment=" "
                  tooltip={intl.formatMessage({
                    id: 'SUSTELL.STAGE.FACILITY_NAME.TT',
                  })}
                  changeHandler={onChangeSelectedFacility}
                />
                <Controller
                  control={methods.control}
                  name="facilityType"
                  render={({ value, name }) => (
                    <input type="hidden" name={name} value={value} />
                  )}
                />
              </>
            )}
            {isShrimp(animalType) && (
              <ReactHookDsmInput
                name="pondName"
                label={intl.formatMessage({
                  id: 'SUSTELL.FIELD_STAGE.POND_NAME',
                })}
                disabled={formType === 'view'}
                adornment=" "
                tooltip={intl.formatMessage({
                  id: 'SUSTELL.FIELD_STAGE.POND_NAME.TOOLTIP',
                })}
              />
            )}
          </DsmGrid>

          <StepperButtons
            cancelHandler={() => handleClose(false)}
            localSaveHandler={methods.handleSubmit(onSubmit, onErrors)}
            localSaveLabel={intl.formatMessage({ id: 'SUSTELL.SAVE.STAGE' })}
            continueHandler={
              baselineFormType === FormType.Add
                ? methods.handleSubmit(onSubmitAndContinue, onErrors)
                : undefined
            }
            hideStandardContinue={
              baselineFormType === FormType.Edit || activeStep === StageSteps.STAGE_DETAILS
            }
          />
        </form>
      </FormProvider>
    </div>
  );
};

export default StageForm;
