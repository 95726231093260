export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: Date;
  AWSDateTime: Date;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export type AnimalDietInput = {
  knownIntake?: InputMaybe<KnownIntakeDietInput>;
  timeSpent: TimeSpent;
  unknownIntake: UnknownIntakeDietInput;
};

export type AnimalInput = {
  originId: Scalars['ID'];
  quantity: Scalars['Float'];
  shrinkingRate?: InputMaybe<Scalars['Float']>;
  transport: Array<Transport>;
};

export type AnimalProcessing = {
  facilities: Array<Facility>;
};

export enum AnimalProductivity {
  High = 'HIGH',
  Low = 'LOW'
}

export enum AnimalType {
  Calf = 'CALF',
  Cow = 'COW'
}

export type AnimalsIncoming = {
  distanceTransport?: InputMaybe<Scalars['Float']>;
  number: Scalars['Float'];
  origin: InputAnimalOrigin;
  originStageId?: InputMaybe<Scalars['ID']>;
  shrinkingRate?: InputMaybe<Scalars['Float']>;
};

export type AuxiliaryInputs = {
  chemicals?: InputMaybe<Array<Chemical>>;
  refrigerants?: InputMaybe<Array<Refrigerant>>;
  wastewaterAmount: Scalars['Float'];
};

export type Bedding = {
  amount: Scalars['Float'];
  type: BeddingType;
};

export enum BeddingType {
  Sand = 'SAND',
  SawDust = 'SAW_DUST',
  WheatStraw = 'WHEAT_STRAW',
  WoodShavings = 'WOOD_SHAVINGS'
}

export type BreedingOutput = {
  animalsToStage: Scalars['Float'];
  averageWeight: Scalars['Float'];
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  mortalities: Scalars['Float'];
  soldExternally: Scalars['Float'];
  weightAtMortality: Scalars['Float'];
};

export type BreedingStageInput = {
  animalsIncoming: Array<AnimalsIncoming>;
  averageWeight: Scalars['Float'];
};

export type CattleBreedingStage = {
  animalProductivity?: InputMaybe<AnimalProductivity>;
  beddingCalves?: InputMaybe<Array<InputMaybe<Bedding>>>;
  beddingCows?: InputMaybe<Array<InputMaybe<Bedding>>>;
  calvesBirthWeight?: InputMaybe<Scalars['Float']>;
  emissionMitigationsCalves?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  emissionMitigationsCows?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  feedingProgramCalves: FeedingProgram;
  feedingProgramCows: FeedingProgram;
  id: Scalars['ID'];
  input: BreedingStageInput;
  lengthStageCalves: Scalars['Float'];
  milkProduction: MilkProduction;
  mmsCalves?: InputMaybe<Array<InputMaybe<Mms>>>;
  mmsCows?: InputMaybe<Array<InputMaybe<Mms>>>;
  name?: InputMaybe<Scalars['String']>;
  outputCalves: BreedingOutput;
  outputCows: BreedingOutput;
  stock: Stock;
  timeSpentCalves: TimeSpent;
  timeSpentCows: TimeSpent;
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type Chemical = {
  amount: Scalars['Float'];
  type: ChemicalType;
};

export enum ChemicalType {
  HydrochloricAcid = 'HYDROCHLORIC_ACID',
  HydrogenPeroxide = 'HYDROGEN_PEROXIDE',
  PotassiumHydroxide = 'POTASSIUM_HYDROXIDE',
  SodiumHydroxide = 'SODIUM_HYDROXIDE',
  SodiumHypochlorite = 'SODIUM_HYPOCHLORITE'
}

export enum ClimateType {
  BorealDry = 'BOREAL_DRY',
  BorealMoist = 'BOREAL_MOIST',
  CoolTemperateDry = 'COOL_TEMPERATE_DRY',
  CoolTemperateMoist = 'COOL_TEMPERATE_MOIST',
  TropicalDry = 'TROPICAL_DRY',
  TropicalMoist = 'TROPICAL_MOIST',
  TropicalMontane = 'TROPICAL_MONTANE',
  TropicalWet = 'TROPICAL_WET',
  WarmTemperateDry = 'WARM_TEMPERATE_DRY',
  WarmTemperateMoist = 'WARM_TEMPERATE_MOIST'
}

export type CompoundFeed = {
  compoundFeedId: Scalars['ID'];
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  feedmillLocation: Scalars['String'];
  ingredientUses: Array<IngredientUse>;
  name: Scalars['String'];
  outboundTransport?: InputMaybe<Array<InputMaybe<Transport>>>;
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type CompoundFeedInfo = {
  ashContent?: InputMaybe<Scalars['Float']>;
  crudeProteinContent: Scalars['Float'];
  digestibleEnergyContent?: InputMaybe<Scalars['Float']>;
  dryMatterContent?: InputMaybe<Scalars['Float']>;
  grossEnergyContent?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  importedImpactId?: InputMaybe<Scalars['ID']>;
  milk?: InputMaybe<Scalars['Float']>;
  naturalDetergentFiberContent?: InputMaybe<Scalars['Float']>;
  phosphorusContent?: InputMaybe<Scalars['Float']>;
  quantity: Scalars['Float'];
  urinaryEnergyContent?: InputMaybe<Scalars['Float']>;
};

export type ContinuousGrowingStage = {
  animalProductivity?: InputMaybe<AnimalProductivity>;
  beddingCows?: InputMaybe<Array<InputMaybe<Bedding>>>;
  emissionMitigationsCows?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  feedingProgramCows: FeedingProgram;
  id: Scalars['ID'];
  input: GrowingStageInput;
  mmsCows?: InputMaybe<Array<InputMaybe<Mms>>>;
  name?: InputMaybe<Scalars['String']>;
  outputCows: GrowingOutput;
  stock: Stock;
  timeSpentCows: TimeSpent;
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type CowCalfFeedIntakeEstimation = {
  __typename?: 'CowCalfFeedIntakeEstimation';
  calvesDiet?: Maybe<KnownIntakeDiet>;
  cowsDiet?: Maybe<KnownIntakeDiet>;
};

export type CowCalfFeedIntakeEstimationInput = {
  calvesBirthWeight?: InputMaybe<Scalars['Float']>;
  calvesDiet: AnimalDietInput;
  calvesFemaleShare?: InputMaybe<Scalars['Float']>;
  calvesLeavingNumber: Scalars['Float'];
  calvesLeavingWeight: Scalars['Float'];
  calvesMaleCastratesShare?: InputMaybe<Scalars['Float']>;
  calvesMaleNonCastratesShare?: InputMaybe<Scalars['Float']>;
  calvesMatureWeight?: InputMaybe<Scalars['Float']>;
  calvesMortalities: Scalars['Float'];
  calvesPermanencePeriod: Scalars['Float'];
  cowsDiet: AnimalDietInput;
  cowsEnteringWeight: Scalars['Float'];
  cowsLeavingNumber: Scalars['Float'];
  cowsLeavingWeight: Scalars['Float'];
  cowsMatureWeight?: InputMaybe<Scalars['Float']>;
  cowsMilkFatShare: Scalars['Float'];
  cowsMortalities: Scalars['Float'];
};

export type DairyCowStage = {
  animalProductivity?: InputMaybe<AnimalProductivity>;
  beddingCows?: InputMaybe<Array<InputMaybe<Bedding>>>;
  calvesBirthWeight: Scalars['Float'];
  emissionMitigationsCows?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  feedingProgramCows: FeedingProgram;
  id: Scalars['ID'];
  input: BreedingStageInput;
  milkProduction: DairyMilkProduction;
  mmsCows?: InputMaybe<Array<InputMaybe<Mms>>>;
  name?: InputMaybe<Scalars['String']>;
  outputCalves: DairyOutputCalves;
  outputCows: BreedingOutput;
  stock: Stock;
  timeSpentCows: TimeSpent;
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type DairyMilkProduction = {
  amount: Scalars['Float'];
  fatContent: Scalars['Float'];
  proteinContent: Scalars['Float'];
};

export type DairyOutputCalves = {
  animalsToStage: Scalars['Float'];
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  soldExternally: Scalars['Float'];
};

export enum DatabaseFoundation {
  Afp6 = 'AFP6',
  Gfli = 'GFLI'
}

export type EmissionMitigation = {
  change: Scalars['Float'];
  emissionType: EmissionType;
};

export type EmissionResult = {
  __typename?: 'EmissionResult';
  annotation?: Maybe<Scalars['String']>;
  magnitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  unitType?: Maybe<Scalars['String']>;
};

export enum EmissionType {
  AmmoniaHousing = 'AMMONIA_HOUSING',
  AmmoniaStorage = 'AMMONIA_STORAGE',
  AmmoniaYard = 'AMMONIA_YARD',
  DinitrogenStorage = 'DINITROGEN_STORAGE',
  MethaneEntericFermentation = 'METHANE_ENTERIC_FERMENTATION',
  MethaneMms = 'METHANE_MMS',
  NitrogenOxidesStorage = 'NITROGEN_OXIDES_STORAGE',
  NitrousOxideDirect = 'NITROUS_OXIDE_DIRECT',
  NitrousOxideLeaching = 'NITROUS_OXIDE_LEACHING',
  NitrousOxideVolatilization = 'NITROUS_OXIDE_VOLATILIZATION',
  Nmvoc = 'NMVOC',
  Pm2_5 = 'PM2_5',
  Pm10 = 'PM10',
  Tsp = 'TSP'
}

export type EnergyCarrier = {
  amount: Scalars['Float'];
  type: EnergyCarrierType;
};

export enum EnergyCarrierType {
  Coal = 'COAL',
  Diesel = 'DIESEL',
  Electricity = 'ELECTRICITY',
  ElectricityPhotovoltaic = 'ELECTRICITY_PHOTOVOLTAIC',
  ElectricityWind = 'ELECTRICITY_WIND',
  Gasoline = 'GASOLINE',
  NaturalGas = 'NATURAL_GAS',
  PropaneOrLpg = 'PROPANE_OR_LPG'
}

export type Facility = {
  auxiliaryInputs: AuxiliaryInputs;
  energyCarriers: Array<EnergyCarrier>;
  geography: Scalars['String'];
  id: Scalars['ID'];
  inputs: FacilityInputs;
  name?: InputMaybe<Scalars['String']>;
  outputs: FacilityOutputs;
  water: Array<Water>;
};

export type FacilityInputs = {
  animalInputs: Array<AnimalInput>;
  averageWeight: Scalars['Float'];
  name?: InputMaybe<AnimalType>;
  totalLiveWeightEntering: Scalars['Float'];
};

export type FacilityOutputs = {
  cat1And2MaterialAndWaste: OutputSpecification;
  cat3HidesAndSkins: OutputSpecification;
  cat3SlaughterByProducts: OutputSpecification;
  foodGradeBones: OutputSpecification;
  foodGradeFat: OutputSpecification;
  freshMeatAndEdibleOffal: OutputSpecification;
};

export type Farm = {
  breedingStages?: InputMaybe<Array<InputMaybe<CattleBreedingStage>>>;
  climateType: ClimateType;
  continuousGrowingStages?: InputMaybe<Array<InputMaybe<ContinuousGrowingStage>>>;
  dairyStages?: InputMaybe<Array<InputMaybe<DairyCowStage>>>;
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  geography: Scalars['String'];
  growingStages?: InputMaybe<Array<InputMaybe<GrowingStage>>>;
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type FeedIntakeEstimatorInput = {
  cowCalfInputs?: InputMaybe<Array<InputMaybe<CowCalfFeedIntakeEstimationInput>>>;
  growingInputs?: InputMaybe<Array<InputMaybe<GrowingFeedIntakeEstimationInput>>>;
};

export type FeedIntakeEstimatorResult = {
  __typename?: 'FeedIntakeEstimatorResult';
  cowCalfDiets?: Maybe<Array<Maybe<CowCalfFeedIntakeEstimation>>>;
  growingDiets?: Maybe<Array<Maybe<KnownIntakeDiet>>>;
  warnings?: Maybe<Array<Maybe<Warning>>>;
};

export enum FeedStatus {
  FeedlotCorn = 'FEEDLOT_CORN',
  FeedlotOtherGrain = 'FEEDLOT_OTHER_GRAIN',
  HighForageHighQuality = 'HIGH_FORAGE_HIGH_QUALITY',
  HighForageLowQuality = 'HIGH_FORAGE_LOW_QUALITY',
  MixedRations = 'MIXED_RATIONS'
}

export type FeedingProgram = {
  compoundFeeds?: InputMaybe<Array<InputMaybe<CompoundFeedInfo>>>;
  feedStatus?: InputMaybe<FeedStatus>;
  freshGrass?: InputMaybe<Array<InputMaybe<FreshGrass>>>;
  singleIngredientUses?: InputMaybe<Array<InputMaybe<SingleIngredientUse>>>;
};

export type ForegroundTreeResult = {
  __typename?: 'ForegroundTreeResult';
  emissionResults?: Maybe<Array<Maybe<StageEmissionResult>>>;
  lci?: Maybe<Scalars['String']>;
  performanceParameters?: Maybe<SystemPerformanceParameters>;
  warnings?: Maybe<Array<Maybe<Warning>>>;
};

export type FreshGrass = {
  ashContent?: InputMaybe<Scalars['Float']>;
  crudeProteinContent: Scalars['Float'];
  digestibleEnergyContent: Scalars['Float'];
  dryMatterContent: Scalars['Float'];
  grossEnergyContent?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  naturalDetergentFiberContent?: InputMaybe<Scalars['Float']>;
  phosphorusContent?: InputMaybe<Scalars['Float']>;
  quantity: Scalars['Float'];
  urinaryEnergyContent?: InputMaybe<Scalars['Float']>;
};

export type GrowingFeedIntakeEstimationInput = {
  diet: AnimalDietInput;
  enteringWeight: Scalars['Float'];
  femaleNonPregnantShare: Scalars['Float'];
  femalePregnantShare?: InputMaybe<Scalars['Float']>;
  leavingNumber: Scalars['Float'];
  leavingWeight: Scalars['Float'];
  maleCastratesShare: Scalars['Float'];
  maleNonCastratesShare: Scalars['Float'];
  matureWeight: Scalars['Float'];
  mortalities: Scalars['Float'];
  stageDuration?: InputMaybe<Scalars['Float']>;
  timeSpentPregnantShare?: InputMaybe<Scalars['Float']>;
};

export type GrowingOutput = {
  animalsToStage: Scalars['Float'];
  averageAge: Scalars['Float'];
  averageWeight: Scalars['Float'];
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  mortalities: Scalars['Float'];
  soldExternally: Scalars['Float'];
  weightAtMortality: Scalars['Float'];
};

export enum GrowingPurpose {
  BeefProduction = 'BEEF_PRODUCTION',
  Replacement = 'REPLACEMENT'
}

export type GrowingStage = {
  animalProductivity?: InputMaybe<AnimalProductivity>;
  beddingCows?: InputMaybe<Array<InputMaybe<Bedding>>>;
  emissionMitigationsCows?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  endDate: Scalars['String'];
  energyCarriers?: InputMaybe<Array<InputMaybe<EnergyCarrier>>>;
  feedingProgramCows: FeedingProgram;
  growingPurpose: GrowingPurpose;
  id: Scalars['ID'];
  input: GrowingStageInput;
  mmsCows?: InputMaybe<Array<InputMaybe<Mms>>>;
  name?: InputMaybe<Scalars['String']>;
  outputCows: GrowingOutput;
  repetitions?: InputMaybe<Scalars['Float']>;
  startDate: Scalars['String'];
  timeSpentCows: TimeSpent;
  water?: InputMaybe<Array<InputMaybe<Water>>>;
};

export type GrowingStageInput = {
  animalType: AnimalType;
  animalsIncoming: Array<AnimalsIncoming>;
  averageAge: Scalars['Float'];
  averageWeight: Scalars['Float'];
};

export type Impact = {
  category: Scalars['String'];
  quantity: Scalars['Float'];
  unit: Scalars['String'];
};

export type ImpactAssessmentResult = {
  __typename?: 'ImpactAssessmentResult';
  category?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  unit?: Maybe<Scalars['String']>;
};

export type ImportedImpact = {
  id: Scalars['ID'];
  impacts: Array<Impact>;
  productName: Scalars['String'];
};

export type Ingredient = {
  addonType?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  origin: Scalars['String'];
  tags?: InputMaybe<Array<InputMaybe<TagInput>>>;
};

export type IngredientUse = {
  inboundTransport?: InputMaybe<Array<InputMaybe<Transport>>>;
  ingredient: Ingredient;
  quantity: Scalars['Float'];
};

export enum InputAnimalOrigin {
  BackgroundData = 'BACKGROUND_DATA',
  ProductionSystem = 'PRODUCTION_SYSTEM'
}

export type KnownIntakeDiet = {
  __typename?: 'KnownIntakeDiet';
  feeds?: Maybe<Array<KnownIntakeFeed>>;
};

export type KnownIntakeDietInput = {
  feeds?: InputMaybe<Array<KnownIntakeFeedInput>>;
};

export type KnownIntakeFeed = {
  __typename?: 'KnownIntakeFeed';
  digestibility: Scalars['Float'];
  energyContent: Scalars['Float'];
  intake: Scalars['Float'];
  milkFromMother?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type KnownIntakeFeedInput = {
  digestibility: Scalars['Float'];
  energyContent: Scalars['Float'];
  intake: Scalars['Float'];
  milkFromMother?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type Mms = {
  emissionMitigations?: InputMaybe<Array<InputMaybe<EmissionMitigation>>>;
  manurePhase?: InputMaybe<ManurePhase>;
  mmsType: ManureManagementSystemType;
  share: Scalars['Float'];
};

export type MmsEmissionResult = {
  __typename?: 'MMSEmissionResult';
  emissions?: Maybe<Array<EmissionResult>>;
  mmsType?: Maybe<Scalars['String']>;
};

export enum ManureManagementSystemType {
  AerobicForced = 'AEROBIC_FORCED',
  AerobicNatural = 'AEROBIC_NATURAL',
  AnaerobicHighLeakHighGastight = 'ANAEROBIC_HIGH_LEAK_HIGH_GASTIGHT',
  AnaerobicHighLeakLowGastight = 'ANAEROBIC_HIGH_LEAK_LOW_GASTIGHT',
  AnaerobicHighLeakOpen = 'ANAEROBIC_HIGH_LEAK_OPEN',
  AnaerobicLowLeakHighGastight = 'ANAEROBIC_LOW_LEAK_HIGH_GASTIGHT',
  AnaerobicLowLeakLowGastight = 'ANAEROBIC_LOW_LEAK_LOW_GASTIGHT',
  AnaerobicLowLeakOpen = 'ANAEROBIC_LOW_LEAK_OPEN',
  CompostingIntensiveWindrow = 'COMPOSTING_INTENSIVE_WINDROW',
  CompostingInVessel = 'COMPOSTING_IN_VESSEL',
  CompostingPassiveWindrow = 'COMPOSTING_PASSIVE_WINDROW',
  CompostingStaticPile = 'COMPOSTING_STATIC_PILE',
  DailySpread = 'DAILY_SPREAD',
  DeepBeddingActiveMixingOver_1Month = 'DEEP_BEDDING_ACTIVE_MIXING_OVER_1MONTH',
  DeepBeddingActiveMixingUnder_1Month = 'DEEP_BEDDING_ACTIVE_MIXING_UNDER_1MONTH',
  DeepBeddingNoMixingOver_1Month = 'DEEP_BEDDING_NO_MIXING_OVER_1MONTH',
  DeepBeddingNoMixingUnder_1Month = 'DEEP_BEDDING_NO_MIXING_UNDER_1MONTH',
  LiquidCover_1Month = 'LIQUID_COVER_1MONTH',
  LiquidCover_3Month = 'LIQUID_COVER_3MONTH',
  LiquidCover_4Month = 'LIQUID_COVER_4MONTH',
  LiquidCover_6Month = 'LIQUID_COVER_6MONTH',
  LiquidCover_12Month = 'LIQUID_COVER_12MONTH',
  LiquidCrust_1Month = 'LIQUID_CRUST_1MONTH',
  LiquidCrust_3Month = 'LIQUID_CRUST_3MONTH',
  LiquidCrust_4Month = 'LIQUID_CRUST_4MONTH',
  LiquidCrust_6Month = 'LIQUID_CRUST_6MONTH',
  LiquidCrust_12Month = 'LIQUID_CRUST_12MONTH',
  LiquidNoCrust_1Month = 'LIQUID_NO_CRUST_1MONTH',
  LiquidNoCrust_3Month = 'LIQUID_NO_CRUST_3MONTH',
  LiquidNoCrust_4Month = 'LIQUID_NO_CRUST_4MONTH',
  LiquidNoCrust_6Month = 'LIQUID_NO_CRUST_6MONTH',
  LiquidNoCrust_12Month = 'LIQUID_NO_CRUST_12MONTH',
  Pit_1Month = 'PIT_1MONTH',
  Pit_3Month = 'PIT_3MONTH',
  Pit_4Month = 'PIT_4MONTH',
  Pit_6Month = 'PIT_6MONTH',
  Pit_12Month = 'PIT_12MONTH',
  SolidAdditives = 'SOLID_ADDITIVES',
  SolidBulking = 'SOLID_BULKING',
  SolidCovered = 'SOLID_COVERED',
  SolidStorage = 'SOLID_STORAGE',
  UncoveredAnaerobicLagoon = 'UNCOVERED_ANAEROBIC_LAGOON'
}

export enum ManurePhase {
  LiquidSlurry = 'LIQUID_SLURRY',
  Solid = 'SOLID'
}

export type MilkProduction = {
  amount?: InputMaybe<Scalars['Float']>;
  fatContent?: InputMaybe<Scalars['Float']>;
  proteinContent?: InputMaybe<Scalars['Float']>;
};

export type OutputSpecification = {
  calculateLca?: InputMaybe<Scalars['Boolean']>;
  packaging?: InputMaybe<Packaging>;
  price?: InputMaybe<Scalars['Float']>;
  weight: Scalars['Float'];
};

export type Packaging = {
  primaryPackaging?: InputMaybe<Array<PrimaryPackaging>>;
  secondaryPackaging?: InputMaybe<Array<SecondaryPackaging>>;
  tertiaryPackaging?: InputMaybe<Array<TertiaryPackaging>>;
};

export type PerformanceParameter = {
  __typename?: 'PerformanceParameter';
  magnitude: Scalars['Float'];
  name: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
};

export type PrimaryPackaging = {
  amount: Scalars['Float'];
  type: PrimaryPackagingType;
};

export enum PrimaryPackagingType {
  AluminiumFoil = 'ALUMINIUM_FOIL',
  HdpeGranulates = 'HDPE_GRANULATES',
  LdpeGranulates = 'LDPE_GRANULATES',
  Paper = 'PAPER',
  Polyester = 'POLYESTER',
  Polypropylene = 'POLYPROPYLENE',
  Polystyrene = 'POLYSTYRENE'
}

export type ProductResultNode = {
  __typename?: 'ProductResultNode';
  aggregatedImpactAssessmentResults?: Maybe<Array<ImpactAssessmentResult>>;
  impactAssessmentResults?: Maybe<Array<ImpactAssessmentResult>>;
  productId?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  upstreamProductNodes?: Maybe<Array<ProductResultNode>>;
};

export type ProductionSystem = {
  animalProcessing?: InputMaybe<AnimalProcessing>;
  farms: Array<InputMaybe<Farm>>;
};

export type ProductionSystemInput = {
  compoundFeeds?: InputMaybe<Array<CompoundFeed>>;
  databaseFoundation: DatabaseFoundation;
  importedImpacts?: InputMaybe<Array<InputMaybe<ImportedImpact>>>;
  productionSystem: ProductionSystem;
};

export type ProductionSystemResult = {
  __typename?: 'ProductionSystemResult';
  emissionResults?: Maybe<Array<StageEmissionResult>>;
  impactResults?: Maybe<Array<ProductResultNode>>;
  performanceParameters?: Maybe<SystemPerformanceParameters>;
  warnings?: Maybe<Array<Maybe<Warning>>>;
};

export type Query = {
  __typename?: 'Query';
  getFeedIntakeEstimation?: Maybe<FeedIntakeEstimatorResult>;
  getLciResultByProductionSystemInput?: Maybe<ForegroundTreeResult>;
  getResultByProductionSystemInput?: Maybe<ProductionSystemResult>;
  getValidationErrorCodes?: Maybe<Array<Maybe<ValidationErrorCode>>>;
};


export type QueryGetFeedIntakeEstimationArgs = {
  feedIntakeEstimatorInput?: InputMaybe<FeedIntakeEstimatorInput>;
};


export type QueryGetLciResultByProductionSystemInputArgs = {
  productionSystemInput?: InputMaybe<ProductionSystemInput>;
};


export type QueryGetResultByProductionSystemInputArgs = {
  productionSystemInput?: InputMaybe<ProductionSystemInput>;
};

export type Refrigerant = {
  amount: Scalars['Float'];
  type: RefrigerantType;
};

export enum RefrigerantType {
  Ammonia = 'AMMONIA',
  CarbonDioxide = 'CARBON_DIOXIDE',
  Propane = 'PROPANE',
  RefrigerantHfc = 'REFRIGERANT_HFC'
}

export type SecondaryPackaging = {
  amount: Scalars['Float'];
  type: SecondaryPackagingType;
};

export enum SecondaryPackagingType {
  CorrugatedBox = 'CORRUGATED_BOX'
}

export type SingleIngredientUse = {
  ashContent?: InputMaybe<Scalars['Float']>;
  crudeProteinContent: Scalars['Float'];
  digestibleEnergyContent: Scalars['Float'];
  distanceTransport?: InputMaybe<Scalars['Float']>;
  dryMatterContent: Scalars['Float'];
  grossEnergyContent?: InputMaybe<Scalars['Float']>;
  importedImpactId?: InputMaybe<Scalars['ID']>;
  ingredient: Ingredient;
  milk?: InputMaybe<Scalars['Boolean']>;
  milkFromMother?: InputMaybe<Scalars['Boolean']>;
  naturalDetergentFiberContent?: InputMaybe<Scalars['Float']>;
  phosphorusContent?: InputMaybe<Scalars['Float']>;
  quantity: Scalars['Float'];
  silage: Scalars['Boolean'];
  urinaryEnergyContent?: InputMaybe<Scalars['Float']>;
};

export type StageEmissionResult = {
  __typename?: 'StageEmissionResult';
  emissions?: Maybe<Array<EmissionResult>>;
  mmsEmissionResults?: Maybe<Array<MmsEmissionResult>>;
  stageId?: Maybe<Scalars['String']>;
  stageName?: Maybe<Scalars['String']>;
};

export type StagePerformanceParameters = {
  __typename?: 'StagePerformanceParameters';
  stageId: Scalars['String'];
  stageName?: Maybe<Scalars['String']>;
  stageParameters?: Maybe<Array<Maybe<PerformanceParameter>>>;
};

export type Stock = {
  averageWeightCowsEnd?: InputMaybe<Scalars['Float']>;
  averageWeightCowsStart: Scalars['Float'];
  cowsPresentAtEnd: Scalars['Float'];
  cowsPresentAtStart: Scalars['Float'];
};

export type SystemPerformanceParameters = {
  __typename?: 'SystemPerformanceParameters';
  stages?: Maybe<Array<Maybe<StagePerformanceParameters>>>;
  systemParameters?: Maybe<Array<Maybe<PerformanceParameter>>>;
};

export type Tag = {
  __typename?: 'Tag';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type TagInput = {
  name: Scalars['String'];
  value: Scalars['String'];
};

export type TertiaryPackaging = {
  amount: Scalars['Float'];
  type: TertiaryPackagingType;
};

export enum TertiaryPackagingType {
  Pallet = 'PALLET',
  PlasticFilm = 'PLASTIC_FILM'
}

export type TimeSpent = {
  timeInFeedlot: Scalars['Float'];
  timeInHousing: Scalars['Float'];
  timeInHousingTied?: InputMaybe<Scalars['Float']>;
  timeOnLargeGrazingAreas: Scalars['Float'];
  timeOnSmallGrazingAreas: Scalars['Float'];
};

export type Transport = {
  distance: Scalars['Float'];
  mode: TransportMode;
};

export enum TransportMode {
  InlandShip = 'INLAND_SHIP',
  SeaShip = 'SEA_SHIP',
  Train = 'TRAIN',
  Truck = 'TRUCK'
}

export type UnknownIntakeDietInput = {
  feeds?: InputMaybe<Array<UnknownIntakeFeedInput>>;
};

export type UnknownIntakeFeedInput = {
  digestibility: Scalars['Float'];
  energyContent: Scalars['Float'];
  milkFromMother?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  share: Scalars['Float'];
};

export type ValidationErrorCode = {
  __typename?: 'ValidationErrorCode';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type Warning = {
  __typename?: 'Warning';
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type Water = {
  amount: Scalars['Float'];
  type: WaterType;
};

export enum WaterType {
  Water = 'WATER'
}
