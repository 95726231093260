import { FC } from 'react';

import { processAndStageStylesV2 } from '../../../../../../_metronic/layout';
import { CSSClassesList } from '../../../../helpers/helperTypes';
import { DialogContainer } from '../DialogContainer2';
import { useIntl } from '../../../../../../_metronic/i18n/customUseIntl';
import { DsmGrid } from '@dsm-dcs/design-system-react';

type DefinitionsDialogProps = {
  open: boolean;
  prefix: string;
  hasReference?: boolean;
  itemHeader: string;
  items: string[];
  handleClose: () => void;
};

// a static dialog loading and showing descirption text
const DefinitionsDialog: FC<DefinitionsDialogProps> = ({
  open=false,
  prefix,
  hasReference=false,
  itemHeader,
  items,
  handleClose
}) => {

  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const formTitle = intl.formatMessage({id: `${prefix}.TITLE`});
  const formRefs = hasReference ? intl.formatMessage({id: `${prefix}.REFS`}) : '';

  return (
    <DialogContainer
      formVisible = { open }
      variant = 'descriptionbox'
      handleClose = { handleClose }
      withIcon={false}
      formTitle = { formTitle }
      introText = { formRefs }
    > 
      {<DsmGrid className={classes.dsmGridTwoColumn_2_3_NoRowSpacingWithLines} style={{overflow: 'unset'}}>
        <div><h6>{ intl.formatMessage({id: `${itemHeader}`}) }</h6></div>
        <div><h6>{ intl.formatMessage({id: "GENERAL.DESCRIPTION"}) }</h6></div>
      </DsmGrid>}
      {<DsmGrid className={classes.dsmGridTwoColumn_2_3_NoRowSpacingWithLines} style={{overflow: 'unset'}}>
        {
          items.map( item => 
            <>
              <div><strong>{ intl.formatMessage({id: `${prefix}.${item}.TITLE`},{br: <br/>}) }</strong></div>
              <div>{ intl.formatMessage({id: `${prefix}.${item}.DESCRIPTION`},{br: <br/>})  }</div>
            </>
          )
        }
      </DsmGrid>}
    </DialogContainer>
	);
};

export default DefinitionsDialog;