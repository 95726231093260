import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import {
  DsmButton,
  DsmEmptyState,
  DsmGrid,
  DsmIcon,
} from '@dsm-dcs/design-system-react';
import { useSubheader } from '../../../../_metronic/layout';
import { TextBox } from '../../components/pageparts/TextBox';
import {
  DSM_DARK_GREY,
  processAndStageStylesV2,
} from '../../../../_metronic/layout/_core/MaterialThemeProvider';
import DatasetListTableV2 from '../../components/FarmFlow/DatasetListTableV2';
import { fetchProductionProcessBaselines } from '../../apicalls/farmFunctions';
import { organiseBaselines } from '../../../modules/Helpers/organise-baselines';
import {
  deleteBaseline,
  deleteInterventionDataset,
} from '../../apicalls/farmFunctions';
import DeleteConfirmationDialog from '../../../sustell_15/components/helpers/DeleteConfirmationDialog';
import WarningDialog from '../../../sustell_15/components/helpers/WarningDialog';
import { useIntl } from '../../../../_metronic/i18n/customUseIntl';
import { FormType, DatasetType, isReadOnlyAnimalSystem } from '../../components/FarmFlow/common';
import useAdobeDataLayer from '../../analytics/adobeDataLayer';
import CompositeDatasetListTableV2 from '../../components/FarmFlow/CompositeDatasetListTableV2';
import Can from '../../../modules/Auth/Can';
import { isBeef, isShrimp } from '../../helpers/animals';

export const SustellProdProcessDatasetsPageV2 = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const subheader = useSubheader();
  const classes = processAndStageStylesV2();
  const { customerID, farmID, processID } = props.match.params;
  const farmName = props?.location?.state?.farmName || 'Farm';

  const [prodProcess, setProdProcess] = useState({});
  const [activeBaseline, setActiveBaseline] = useState(null);
  const [archivedBaselines, setArchivedBaselines] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [farmCountry, setFarmCountry] = useState('');
  const [selectedFarmName, setSelectedFarmName] = useState(farmName);
  const [benchmark, setBenchmark] = useState(false);
  const [openComingSoonDialog, setOpenComingSoonDialog] = useState(false);
  const { ctaClickEvent } = useAdobeDataLayer();

  const setProductionProcessData = (fetchedData) => {
    // fetch successeded only one matching PP has been retrieved
    const selProdProcess = fetchedData.animalSystems?.items[0] || {};

    if (selProdProcess && selProdProcess.baselines) {
      const extractedBaselines = [...selProdProcess.baselines.items];
      selProdProcess.baselines = extractedBaselines;
    }

    const [activeBaseline, archivedBaselines] =
      organiseBaselines(selProdProcess);

    setActiveBaseline(activeBaseline);
    setArchivedBaselines(archivedBaselines);
    setProdProcess(selProdProcess);
    setFarmCountry(fetchedData.country);
    setBenchmark(fetchedData.benchmark);
    setSelectedFarmName(fetchedData.name);

    subheader.setBreadcrumbs([
      {
        pathname: `/customers/${customerID}/myfarms`,
        title: intl.formatMessage({ id: 'FARMS.CARD.TITLE' }),
        state: {
          animalType: selProdProcess?.animalType,
        },
      },
      {
        pathname: `/customers/${customerID}/farm/${farmID}`,
        title: selectedFarmName,
        state: {
          animalType: selProdProcess?.animalType,
        },
      },
      {
        pathname: `/customers/${customerID}/farm/${farmID}/pp/${processID}`,
        title: selProdProcess.name,
        state: {
          animalType: selProdProcess?.animalType,
        },
      },
    ]);
  };

  const fetchProductionDatasets = async (prodProcId) => {
    fetchProductionProcessBaselines(
      farmID,
      processID,
      setProductionProcessData,
      (error) => console.log(error)
    );
  };

  useEffect(() => {
    fetchProductionDatasets(processID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processID]);

  const openBaseline = (datasetID, formType) => {
    if (formType === 'add') {
      ctaClickEvent(
        window.location.href,
        'button',
        'Create baseline',
        'Baseline interventions',
        'Production processes'
      );
    }
    if (formType === 'view') {
      ctaClickEvent(
        window.location.href,
        'link',
        'View baseline',
        'Baseline interventions',
        'Production processes'
      );
    }
    if (formType === 'edit') {
      ctaClickEvent(
        window.location.href,
        'link',
        'Edit baseline',
        'Baseline interventions',
        'Production processes'
      );
    }
    history.push(
      `/customers/${customerID}/farm/${farmID}/pp/${processID}/nds/${datasetID}`,
      {
        farmName: selectedFarmName,
        processName: prodProcess?.name || 'Process',
        animalType: prodProcess?.animalType,
        formType,
      }
    );
  };

  const openIntervention = (datasetID, formType, reference) => {
    if (formType === 'add') {
      ctaClickEvent(
        window.location.href,
        'button',
        'Create intervention',
        'Baseline interventions',
        'Production processes'
      );
    }
    if (formType === 'view') {
      ctaClickEvent(
        window.location.href,
        'link',
        'View intervention',
        'Baseline interventions',
        'Production processes'
      );
    }
    if (formType === 'edit') {
      ctaClickEvent(
        window.location.href,
        'link',
        'Edit intervention',
        'Baseline interventions',
        'Production processes'
      );
    }
    history.push(
      `/customers/${customerID}/farm/${farmID}/pp/${processID}/nint/${
        reference || 'NEW'
      }`,
      {
        farmName: selectedFarmName,
        processName: prodProcess?.name || 'Process',
        animalType: prodProcess?.animalType,
        datasetID: datasetID,
        farmCountry: farmCountry, // Needed for 3NOP
        formType,
      }
    );
  };

  const openDataset = (
    datasetType,
    baselineId,
    formType,
    interventionReference
  ) => {
    if (datasetType === DatasetType.BASELINE)
      openBaseline(baselineId, formType);
    else openIntervention(baselineId, formType, interventionReference);
  };

  const onDuplicateBaselineClick = (datasetID) => {
    ctaClickEvent(
      window.location.href,
      'link',
      `Duplicate baseline`,
      'Baseline interventions',
      'Production processes'
    );
    history.push(
      `/customers/${customerID}/farm/${farmID}/pp/${processID}/nds/NEW`,
      {
        farmName: selectedFarmName,
        processName: prodProcess?.name || 'Process',
        animalType: prodProcess?.animalType,
        formType: FormType.Add,
        duplicateDatasetID: datasetID,
      }
    );
  };

  const onDuplicateInterventionClick = (item) => {
    ctaClickEvent(
      window.location.href,
      'link',
      `Duplicate intervention`,
      'Baseline interventions',
      'Production processes'
    );
    history.push(
      `/customers/${customerID}/farm/${farmID}/pp/${processID}/nint/NEW`,
      {
        farmName: selectedFarmName,
        processName: prodProcess?.name || 'Process',
        animalType: prodProcess?.animalType,
        duplicateInterventionRef: item.reference,
        datasetID: item.baseline,
        farmCountry: farmCountry, // Needed for 3NOP
      }
    );
  };

  const duplicateDataset = (datasetType, item) => {
    // console.log("duplicateDataset", `${datasetType}`, item);
    if (datasetType === 'baseline') onDuplicateBaselineClick(item.id);
    else onDuplicateInterventionClick(item); // for intervention whole object is passed to extract baseleine refrence and intervention id
  };

  const onDeleteDatasetSuccess = (result) => {
    fetchProductionDatasets(processID);
    setItemToDelete(null);
  };

  const onDeleteDatasetError = (error) => {
    console.log(error);
  };

  const deleteItem = (type, datasetName) => {
    ctaClickEvent(
      window.location.href,
      'link',
      `Delete ${type}`,
      'Baseline interventions',
      'Production processes'
    );
    if (type === 'baseline')
      deleteBaseline(
        farmID,
        datasetName,
        onDeleteDatasetSuccess,
        onDeleteDatasetError
      );
    else if (type === 'intervention')
      deleteInterventionDataset(
        customerID,
        farmID,
        processID,
        datasetName,
        onDeleteDatasetSuccess,
        onDeleteDatasetError
      );
  };

  const getBaselineInterventions = (baselineName) => {
    const baseline = prodProcess?.baselines?.find(
      (item) => item.name === baselineName
    );
    if (baseline)
      return prodProcess?.interventions?.items
        ?.filter((item) => item.baseline === baseline.reference)
        ?.map((item) => item.name);
    return null;
  };

  const onDeleteClick = (type, name) => {
    setItemToDelete({
      itemName: name,
      type: type,
      itemType:
        type === 'baseline'
          ? intl.formatMessage({ id: 'SUSTELL.PRODUCTION_PROCESS.BASELINE' })
          : intl.formatMessage({
              id: 'SUSTELL.PRODUCTION_PROCESS.INTERVENTION',
            }),
      dependencies: type === 'baseline' ? getBaselineInterventions(name) : null,
    });
  };

  const openComingSoonModal = () => {
    setOpenComingSoonDialog(true);
  }

  const closeComingSoonModal = () => {
    setOpenComingSoonDialog(false);
  }

  return (
    <>
      <DeleteConfirmationDialog
        open={itemToDelete ? true : false} // open attribute strictly expects boolean, so null was giving a warning in runtime
        confirmHandler={() => {
          deleteItem(itemToDelete?.type, itemToDelete?.itemName);
        }}
        cancelHandler={() => setItemToDelete(null)}
        itemName={itemToDelete?.itemName}
        itemType={itemToDelete?.itemType}
        dependencies={itemToDelete?.dependencies}
      />
      <WarningDialog
        isOpen={openComingSoonDialog}
        closeModal={closeComingSoonModal}
        title={'INTERVENTIONS.COMING_SOON.DIALOG.TITLE'}
        body={'INTERVENTIONS.COMING_SOON.DIALOG.BODY'}
      />
      <TextBox
        title={prodProcess?.name}
        content={intl.formatMessage(
          { id: 'SUSTELL.PROCESS.DATASETS.PAGE.DESCRIPTION' },
          { br: <br /> }
        )}
      />
      <DsmGrid
        style={{
          width: '100%',
          grid: activeBaseline ? 'auto/10fr 2fr 3fr' : 'auto/10fr 3fr',
          padding: '16px',
          marginTop: '24px',
          border: '1px solid var(--dsm-color-neutral)',
          borderBottom: '0px',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
        }}
      >
        <Box style={{ alignSelf: 'start' }}>
          <Typography variant="h6" className={classes.datasetsTableIntroTitle}>
            {intl.formatMessage({ id: 'SUSTELL.CURRENT.BASELINE.DATASET' })}
          </Typography>
          <Typography
            variant="body1"
            style={{ color: 'var(--dsm-color-neutral-darker)' }}
          >
            {intl.formatMessage({
              id: 'SUSTELL.CURRENT.BASELINE.DATASET.DESCRIPTION.1',
            })}
          </Typography>
          <Typography
            variant="body1"
            style={{ color: 'var(--dsm-color-neutral-darker)' }}
          >
            {intl.formatMessage({
              id: 'SUSTELL.CURRENT.BASELINE.DATASET.DESCRIPTION.2',
            })}
          </Typography>
        </Box>
        {Can('update', 'Baseline') && !benchmark && !isReadOnlyAnimalSystem(prodProcess.animalType) && activeBaseline && (
          <Box display="ruby">
            <DsmButton
              style={{ alignSelf: 'center', marginBottom: '8px' }}
              onClick={(e) => openBaseline(activeBaseline.id, 'edit')}
              variant="secondary"
              size="small"
            >
              <DsmIcon slot="before" name="general/edit-01" />
              {intl.formatMessage({ id: 'SUSTELL.EDIT_BASELINE' })}
            </DsmButton>
            <Typography
              style={{
                color: DSM_DARK_GREY,
                fontSize: '10px',
                textAlign: 'center',
                lineHeight: '16px',
              }}
            >
              {intl.formatMessage({
                id: 'SUSTELL.CURRENT.BASELINE.DATASET.EDIT.HINT',
              })}
            </Typography>
          </Box>
        )}
        {Can('create', 'Baseline') && !benchmark && !isReadOnlyAnimalSystem(prodProcess.animalType) &&(
          <Box display="ruby">
            <DsmButton
              style={{ alignSelf: 'center', marginBottom: '8px' }}
              onClick={(e) => openBaseline('NEW', 'add')}
              variant="secondary"
              size="small"
            >
              <DsmIcon slot="before" name="general/plus" />
              {intl.formatMessage({ id: 'SUSTELL.ADD_BASELINE' })}
            </DsmButton>
            <Typography
              style={{
                color: DSM_DARK_GREY,
                fontSize: '10px',
                textAlign: 'center',
                lineHeight: '16px',
              }}
            >
              {intl.formatMessage({
                id: 'SUSTELL.CURRENT.BASELINE.DATASET.ADD.HINT',
              })}
            </Typography>
          </Box>
        )}
      </DsmGrid>
      {!activeBaseline && (
        <DsmEmptyState
          icon="general/search-md"
          header={intl.formatMessage({ id: 'GENERAL.NO_DATA' })}
          description={intl.formatMessage({
            id: 'SUSTELL.PROCESS.CREATE.BASELINE',
          })}
          style={{
            paddingTop: ' var(--dsm-spacing-px-16',
            paddingBottom: ' var(--dsm-spacing-px-16',
            border: '1px solid var(--dsm-color-neutral)',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
          }}
        />
      )}
      {activeBaseline && (
        <DatasetListTableV2
          datasets={activeBaseline ? [activeBaseline] : null}
          customerID={customerID}
          farmID={farmID}
          benchmark={benchmark}
          processID={prodProcess?.id}
          farmName={selectedFarmName}
          processName={prodProcess?.name}
          animalType={prodProcess.animalType}
          openDataset={openBaseline}
          deleteHandler={(itemName) => onDeleteClick('baseline', itemName)}
          duplicateDataset={(item) => onDuplicateBaselineClick(item.id)}
        />
      )}
      <DsmGrid
        style={{
          width: '100%',
          grid: 'auto/1fr auto',
          padding: '16px',
          marginTop: '24px',
          border: '1px solid var(--dsm-color-neutral)',
          borderBottom: '0px',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
        }}
      >
        <Box style={{ alignSelf: 'start' }}>
          <Typography variant="h6" className={classes.datasetsTableIntroTitle}>
            {intl.formatMessage({
              id: 'SUSTELL.CURRENT.BASELINE.MODEL.INTERVENTION',
            })}
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginTop: '10px',
              color: 'var(--dsm-color-neutral-darker',
            }}
          >
            {intl.formatMessage(
              {
                id: 'SUSTELL.CURRENT.BASELINE.INTERVENTION.DESCRIPTION',
              },
              {
                br: <br />,
              }
            )}
          </Typography>
        </Box>
        {Can('create', 'Intervention') && !benchmark && !isReadOnlyAnimalSystem(prodProcess.animalType) && (
          <DsmButton
            style={{ alignSelf: 'end' }}
            onClick={(e) =>
              (isShrimp(prodProcess.animalType) || isBeef(prodProcess.animalType)) ? 
              openComingSoonModal() : 
              openIntervention(activeBaseline?.id, FormType.Add, 'NEW')
            }
            variant="secondary"
            size="small"
          >
            <DsmIcon slot="before" name="general/plus" />
            {intl.formatMessage({ id: 'SUSTELL.ADD_INTERVENTION' })}
          </DsmButton>
        )}
      </DsmGrid>
      {(!activeBaseline?.interventions ||
        activeBaseline.interventions.length == 0) && (
        <DsmEmptyState
          icon="general/search-md"
          header={intl.formatMessage({ id: 'GENERAL.NO_DATA' })}
          description={intl.formatMessage({
            id: 'SUSTELL.PROCESS.CREATE.INTERVENTION',
          })}
          style={{
            paddingTop: ' var(--dsm-spacing-px-16',
            paddingBottom: ' var(--dsm-spacing-px-16',
            border: '1px solid var(--dsm-color-neutral)',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
          }}
        />
      )}
      {activeBaseline?.interventions &&
        activeBaseline?.interventions.length > 0 && (
          <DatasetListTableV2
            datasets={activeBaseline?.interventions}
            customerID={customerID}
            variant="intervention"
            farmID={farmID}
            processID={prodProcess?.id}
            farmName={selectedFarmName}
            processName={prodProcess?.name}
            animalType={prodProcess.animalType}
            openDataset={openIntervention}
            deleteHandler={(itemName) =>
              onDeleteClick('intervention', itemName)
            }
            duplicateDataset={(item) => onDuplicateInterventionClick(item)}
            activeBaselineYear={activeBaseline.year}
          />
        )}
      <DsmGrid
        style={{
          width: '100%',
          grid: 'auto/1fr',
          padding: '16px',
          marginTop: '24px',
          border: '1px solid var(--dsm-color-neutral)',
          borderBottom: '0px',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
        }}
      >
        <Box style={{ alignSelf: 'start' }}>
          <Typography variant="h6" className={classes.datasetsTableIntroTitle}>
            {intl.formatMessage({ id: 'SUSTELL.BASELINE.ARCHIVE' })}
          </Typography>
          <Typography
            variant="body1"
            style={{
              marginTop: '10px',
              color: 'var(--dsm-color-neutral-darker',
            }}
          >
            {intl.formatMessage({ id: 'SUSTELL.BASELINE.ARCHIVE.DESCRITPION' })}
          </Typography>
        </Box>
      </DsmGrid>
      {archivedBaselines && archivedBaselines.length > 0 && (
        <CompositeDatasetListTableV2
          datasets={archivedBaselines}
          customerID={customerID}
          variant="baseline"
          farmID={farmID}
          processID={prodProcess?.id}
          farmName={selectedFarmName}
          processName={prodProcess?.name}
          animalType={prodProcess.animalType}
          archive
          openDataset={openDataset}
          deleteDataset={onDeleteClick}
          duplicateDataset={duplicateDataset}
        />
      )}
      {(!archivedBaselines || archivedBaselines.length === 0) && (
        <DsmEmptyState
          icon="general/search-md"
          header={intl.formatMessage({ id: 'GENERAL.NO_DATA' })}
          style={{
            paddingTop: ' var(--dsm-spacing-px-16',
            paddingBottom: ' var(--dsm-spacing-px-16',
            border: '1px solid var(--dsm-color-neutral)',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
          }}
        />
      )}
    </>
  );
};

export default SustellProdProcessDatasetsPageV2;
