import { FC } from "react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { TargetAnimal } from "../../../../models/Baseline/BeefBaseline";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";

type BeefTabLabelComponentProps = {
  targetAnimal: TargetAnimal;
  isTabError: boolean;
};

const BeefTabLabelComponent: FC<BeefTabLabelComponentProps> = ({
  targetAnimal,
  isTabError,
}) => {
  const intl = useIntl();

  const renderErrorIcon = () => (
    <div
      style={{
        borderRadius: "100%",
        backgroundColor: "var(--dsm-color-error-lightest)",
        marginLeft: "var(--dsm-spacing-px-1)",
        width: "var(--dsm-spacing-px-6)",
      }}
    >
      <ErrorOutlineIcon
        fontSize="small"
        color="error"
        style={{
          alignSelf: "center",
        }}
      />
    </div>
  );

  const tabTitle =
    targetAnimal === TargetAnimal.COWS
      ? intl.formatMessage({
          id: `SUSTELL.STAGE.BEEF.STEPS.COWS`,
        })
      : intl.formatMessage({
          id: `SUSTELL.STAGE.BEEF.STEPS.CALVES`,
        });

  if (isTabError) {
    return (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <span>{tabTitle}</span> {renderErrorIcon()}
      </div>
    );
  }

  return <span>{tabTitle}</span>;
};

export default BeefTabLabelComponent;