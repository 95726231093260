import { FC, useCallback, useState, useEffect } from 'react';
import { DsmGrid } from '@dsm-dcs/design-system-react';
import { useFormContext } from 'react-hook-form';
import { FormType } from '../common';
import { processAndStageStylesV2 } from '../../../../../_metronic/layout';
import { useIntl } from '../../../../../_metronic/i18n/customUseIntl';
import { PoultryBaseline } from '../../../models/Baseline/PoultryBaseline';
import { DatabaseFoundation } from '../../../../../graphql/generated/blonk/pigs';
import { UserProfilePrefs } from '../../../../modules/Helpers/UserProfilePrefs';
import { CompoundFeedNameDatabase, Origin, SingleIngredient } from '../Baseline/types';
import { Maybe } from '../../../../../graphql/types';
import { BaselineFeed } from '../../../models/Baseline';
import { CSSClassesList } from '../../../helpers/helperTypes';
import SustellRationFeeds from '../../../../modules/Farms/Baseline/SustellRationFeeds_v2';
import SustellSingleIngredientFeed from '../../../../modules/Farms/Baseline/SustellSingleIngredientFeeds_v2';

interface FeedsFormCommonDataPartProps {
  formType: FormType;
  formVisible: boolean | undefined;
  itemIndex: number;
  fieldItemPrefix: string;
  compoundFeeds: Maybe<Array<CompoundFeedNameDatabase>>;
  singleIngredients: Maybe<Array<SingleIngredient>>;
  origins: Maybe<Array<Origin>>;
  keyCompoundFeeds: string;
  keySingleIngredients: string;
  showSilageCheck?: boolean;
  isBeef?: boolean;
}

const FeedsFormCommonDataPart: FC<FeedsFormCommonDataPartProps> = ({
  formType,
  formVisible,
  itemIndex,
  fieldItemPrefix,
  compoundFeeds,
  singleIngredients,
  origins,
  keyCompoundFeeds,
  keySingleIngredients,
  showSilageCheck = false,
  isBeef = false,
}) => {
  const intl = useIntl();
  const feedItem = { feedType: '', kgPerAnimal: '' };
  const classes = processAndStageStylesV2() as CSSClassesList;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userProfile = UserProfilePrefs.getInstance();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const precision = userProfile.getUnitBarnOutputMassPrecision();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getValues, setValue, trigger } = useFormContext<PoultryBaseline>();

  const baselineCompoundFeeds = getValues(`stages.${itemIndex}.stageData.${fieldItemPrefix}.compoundFeeds`) || [];
  const baselineSingleIngredients = getValues(`stages.${itemIndex}.stageData.${fieldItemPrefix}.singleIngredients`) || [];
  const calculateInitialDatabaseValue = () => {
    let initValue: DatabaseFoundation = getValues(`info.databaseFoundation`);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    if (!initValue) initValue =  userProfile.getUserDatabasePrefs().databaseType;
    return initValue;
  }
  const baselineDatabaseFoundation: DatabaseFoundation = calculateInitialDatabaseValue();
  
  const [compoundFeedData, setCompoundFeedData] = useState(baselineCompoundFeeds && (baselineCompoundFeeds as BaselineFeed[])?.length > 0 ? baselineCompoundFeeds : [{ ...feedItem }]);
  const [singleIngredientsData, setSingleIngredientsData] = useState(baselineSingleIngredients && (baselineSingleIngredients as BaselineFeed[])?.length > 0 ? baselineSingleIngredients : [{ ...feedItem }]);

  const getCompoundFeedsList = (databaseOption: DatabaseFoundation) => compoundFeeds?.filter((feed: CompoundFeedNameDatabase) => feed.databaseFoundation === databaseOption);

  const updateTotalFeedIntake = useCallback(() => {
    const cfs: BaselineFeed[] = getValues(`stages.${itemIndex}.stageData.${fieldItemPrefix}.compoundFeeds`);
    let totalIntake = 0;
    if (cfs) {
      totalIntake += cfs.reduce((acc, item) => (acc + (item.feedType && !Number.isNaN(item.kgPerAnimal) ? Number(item.kgPerAnimal) : 0)), 0);
    }
    setValue(`${fieldItemPrefix}.totalFeedIntake`, totalIntake !== 0 ? totalIntake.toFixed(precision as number | undefined) : '');
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    if (formVisible) trigger(`${fieldItemPrefix}.totalFeedIntake`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemIndex, fieldItemPrefix, setValue, getValues, precision, formVisible]);

  useEffect(() => {
    setCompoundFeedData(baselineCompoundFeeds || []);
    setSingleIngredientsData(baselineSingleIngredients || []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (<div>
    <DsmGrid className={classes.dsmGridTwoColumn2To3}>
      <SustellRationFeeds
        key={keyCompoundFeeds}
        label={intl.formatMessage({ id: 'SUSTELL.STAGE.FEED.COMPOUND_FEED' })}
        availableFeedItems={getCompoundFeedsList(baselineDatabaseFoundation)}
        fieldItemPrefix={`stages.${itemIndex}.stageData.${fieldItemPrefix}`}
        subFieldName="compoundFeeds"
        updateTotalFeedIntake={updateTotalFeedIntake}
        tooltip={intl.formatMessage({id: 'BASELINE.FORM.BARN.RATION.COMPOUND_FEEDS.TOOLTIP'})}
        formState={formType}
        compoundFeed
        feedData={compoundFeedData}
        setFeedData={setCompoundFeedData}
        isBeef={isBeef}
      />
      <SustellSingleIngredientFeed
        key={keySingleIngredients}
        label={intl.formatMessage({id: 'SUSTELL.STAGE.FEED.SINGLE_INGREDIENT'})}
        barnItemIndex={itemIndex}
        availableFeedItems={singleIngredients}
        availableOrigins={origins}
        fieldItemPrefix={`stages.${itemIndex}.stageData.${fieldItemPrefix}`}
        subFieldName="singleIngredients"
        updateTotalFeedIntake={updateTotalFeedIntake}
        tooltip={
          <div key="feeds-form-dialog-singleIngredients">
            {intl.formatMessage({ id: 'BASELINE.FORM.BARN.RATION.SINGLE_INGREDIENTS.TOOLTIP' }, { br: <br /> })}
          </div>
        }
        formState={formType}
        singleIngredients={singleIngredients}
        setFeedData={setSingleIngredientsData}
        showSilageCheck={showSilageCheck}
        isBeef={isBeef}
      />
    </DsmGrid>
  </div>);
}

export default FeedsFormCommonDataPart;
