import { CSSProperties, FC } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { DsmGrid } from "@dsm-dcs/design-system-react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useIntl } from "../../../../../../_metronic/i18n/customUseIntl";
import {
  DSM_DARK_GREY,
  DSM_MAIN_SELECTED_COLOR,
  processAndStageStylesV2,
} from "../../../../../../_metronic/layout";
import { CSSClassesList } from "../../../../helpers/helperTypes";
import ReactHookDsmInput from "../../../../../modules/Helpers/ReactHookDsmInput2";
import { TargetAnimal } from "../../../../models/Baseline/BeefBaseline";
import { resolvePath } from "../../../../../modules/Helpers/resolvePathFn";

type BeefHousingComponentProps = {
  stageIndex: number;
  target?: TargetAnimal;
  isViewMode: boolean;
};

const BeefHousingComponent: FC<BeefHousingComponentProps> = ({
  stageIndex,
  target,
  isViewMode,
}) => {
  const formContext = useFormContext();
  const intl = useIntl();
  const classes = processAndStageStylesV2() as CSSClassesList;
  const baseItemPrefix = `stages.${stageIndex}.stageData.housing`;
  const fieldItemPrefix = target
    ? `${baseItemPrefix}.${target}`
    : baseItemPrefix;
  const boldTextStyle = {
    fontWeight: "bold",
    fontSize: "18px",
    color: "black",
    margin: "0 5px 0 0",
    padding: 0,
  };

  const timeInHousingWatch = useWatch({
    name: `${fieldItemPrefix}.timeInHousing`,
  });
  const timeInFeedlotWatch = useWatch({
    name: `${fieldItemPrefix}.timeInFeedlot`,
  });
  const timeGrazingLargeAreasWatch = useWatch({
    name: `${fieldItemPrefix}.timeGrazingLargeAreas`,
  });
  const timeGrazingPasturesWatch = useWatch({
    name: `${fieldItemPrefix}.timeGrazingPastures`,
  });

  const getTimeInHousingValue = (): number =>
    timeInHousingWatch ? Number(timeInHousingWatch) : 0;

  const getTimeInFeedlotValue = (): number =>
    timeInFeedlotWatch ? Number(timeInFeedlotWatch) : 0;

  const getTimeGrazingLargeAreasValue = (): number =>
    timeGrazingLargeAreasWatch ? Number(timeGrazingLargeAreasWatch) : 0;

  const getTimeGrazingPasturesValue = (): number =>
    timeGrazingPasturesWatch ? Number(timeGrazingPasturesWatch) : 0;

  const handleHousingTime = async () => {
    if (
      !timeInHousingWatch &&
      timeInFeedlotWatch &&
      timeGrazingLargeAreasWatch &&
      timeGrazingPasturesWatch
    ) {
      let value =
        getTimeInFeedlotValue() +
        getTimeGrazingLargeAreasValue() +
        getTimeGrazingPasturesValue();

      value = 100 - value;

      formContext.setValue(
        `${fieldItemPrefix}.timeInHousing`,
        value > 0 ? value : 0
      );
    }

    if (
      timeInHousingWatch &&
      !timeInFeedlotWatch &&
      timeGrazingLargeAreasWatch &&
      timeGrazingPasturesWatch
    ) {
      let value =
        getTimeInHousingValue() +
        getTimeGrazingLargeAreasValue() +
        getTimeGrazingPasturesValue();

      value = 100 - value;

      formContext.setValue(
        `${fieldItemPrefix}.timeInFeedlot`,
        value > 0 ? value : 0
      );
    }

    if (
      timeInHousingWatch &&
      timeInFeedlotWatch &&
      !timeGrazingLargeAreasWatch &&
      timeGrazingPasturesWatch
    ) {
      let value =
        getTimeInHousingValue() +
        getTimeInFeedlotValue() +
        getTimeGrazingPasturesValue();

      value = 100 - value;

      formContext.setValue(
        `${fieldItemPrefix}.timeGrazingLargeAreas`,
        value > 0 ? value : 0
      );
    }

    if (
      timeInHousingWatch &&
      timeInFeedlotWatch &&
      timeGrazingLargeAreasWatch &&
      !timeGrazingPasturesWatch
    ) {
      let value =
        getTimeInHousingValue() +
        getTimeInFeedlotValue() +
        getTimeGrazingLargeAreasValue();

      value = 100 - value;

      formContext.setValue(
        `${fieldItemPrefix}.timeGrazingPastures`,
        value > 0 ? value : 0
      );
    }

    const total =
      getTimeInHousingValue() +
      getTimeInFeedlotValue() +
      getTimeGrazingLargeAreasValue() +
      getTimeGrazingPasturesValue();

    if (total === 100) {
      if (!timeInHousingWatch)
        formContext.setValue(`${fieldItemPrefix}.timeInHousing`, 0);
      if (!timeInFeedlotWatch)
        formContext.setValue(`${fieldItemPrefix}.timeInFeedlot`, 0);
      if (!timeGrazingLargeAreasWatch)
        formContext.setValue(`${fieldItemPrefix}.timeGrazingLargeAreas`, 0);
      if (!timeGrazingPasturesWatch)
        formContext.setValue(`${fieldItemPrefix}.timeGrazingPastures`, 0);
    }

    await formContext.trigger(`${fieldItemPrefix}.timeInHousing`);
    await formContext.trigger(`${fieldItemPrefix}.timeInFeedlot`);
    await formContext.trigger(`${fieldItemPrefix}.timeGrazingLargeAreas`);
    await formContext.trigger(`${fieldItemPrefix}.timeGrazingPastures`);
  };

  const renderAdditionText = () => <span style={boldTextStyle}>+</span>;

  const isTimeSpentError = () =>
    Boolean(
      resolvePath(formContext.errors, `${fieldItemPrefix}.timeInHousing`, null)
    );

  const getTotalTimeSpentText = () => {
    const totalTimeSpentTextStyle = isTimeSpentError()
      ? { color: "var(--dsm-color-error)", fontWeight: "500" }
      : ({ color: "var(--dsm-color-basic-black)" } as CSSProperties);
    const totalTimeSpentText = intl.formatMessage({
      id: "SUSTELL.STAGE.BEEF.MMS.TOTAL_TIME_SPENT",
    });
    const errorHelperText = intl.formatMessage({
      id: "SUSTELL.STAGE.BEEF.HOUSING.TIME_SUM_MUST_BE_100",
    });
    const totalPercentageText = "= 100%";

    const mountedTextElement = (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span
          style={{
            fontWeight: "bold",
            color: "var(--dsm-color-basic-black)",
            fontSize: "18px",
          }}
        >
          {totalPercentageText}
        </span>
        <span
          style={{
            ...totalTimeSpentTextStyle,
            marginLeft: "var(--dsm-spacing-px-1)",
          }}
        >
          ({totalTimeSpentText})
        </span>
      </div>
    );

    if (isTimeSpentError()) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "15px",
          }}
        >
          <div style={{ display: "flex" }}>
            {mountedTextElement}
            <ErrorOutlineIcon
              fontSize="small"
              color="error"
              style={{
                marginLeft: "var(--dsm-spacing-px-2)",
                alignSelf: "center",
              }}
            />
          </div>
          <span style={{ color: "var(--dsm-color-error)", fontSize: "10px" }}>
            {errorHelperText}
          </span>
        </div>
      );
    }

    return mountedTextElement;
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <DsmGrid
        className={classes.additionalEntriesBeefMMSBlockHolder}
        style={{ borderBottom: "0", backgroundColor: DSM_MAIN_SELECTED_COLOR }}
      >
        <div
          className={classes.beefMMSFieldContainer}
          style={{ paddingLeft: "10px" }}
        >
          <ReactHookDsmInput
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.BEEF.HOUSING.TIME_SPENT_IN_HOUSING",
            })}
            name={`${fieldItemPrefix}.timeInHousing`}
            adornment="%"
            type="number"
            disabled={isViewMode}
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.BEEF.HOUSING.TIME_SPENT_IN_HOUSING.TOOLTIP",
            })}
            defaultValue={
              formContext.getValues(
                `${fieldItemPrefix}.timeInHousing`
              ) as string
            }
            showHelperText={false}
            required
            focusLostHandler={handleHousingTime}
          />
        </div>
      </DsmGrid>

      <DsmGrid className={classes.additionalEntriesBeefMMSBlockHolder}>
        <div className={classes.beefMMSFieldContainer}>
          {renderAdditionText()}
          <ReactHookDsmInput
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.BEEF.HOUSING.TIME_SPENT_IN_FEEDLOT",
            })}
            name={`${fieldItemPrefix}.timeInFeedlot`}
            adornment="%"
            type="number"
            disabled={isViewMode}
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.BEEF.HOUSING.TIME_SPENT_IN_FEEDLOT.TOOLTIP",
            })}
            defaultValue={
              formContext.getValues(
                `${fieldItemPrefix}.timeInFeedlot`
              ) as string
            }
            showHelperText={false}
            required
            focusLostHandler={handleHousingTime}
          />
        </div>

        <div className={classes.beefMMSFieldContainer}>
          {renderAdditionText()}
          <ReactHookDsmInput
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.BEEF.HOUSING.TIME_SPENT_GRAZING_LARGE_AREAS",
            })}
            name={`${fieldItemPrefix}.timeGrazingLargeAreas`}
            adornment="%"
            type="number"
            disabled={isViewMode}
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.BEEF.HOUSING.TIME_SPENT_GRAZING_LARGE_AREAS.TOOLTIP",
            })}
            defaultValue={
              formContext.getValues(
                `${fieldItemPrefix}.timeGrazingLargeAreas`
              ) as string
            }
            showHelperText={false}
            required
            focusLostHandler={handleHousingTime}
          />
        </div>

        <div className={classes.beefMMSFieldContainer}>
          {renderAdditionText()}
          <ReactHookDsmInput
            label={intl.formatMessage({
              id: "SUSTELL.STAGE.BEEF.HOUSING.TIME_SPENT_GRAZING_PASTURES",
            })}
            name={`${fieldItemPrefix}.timeGrazingPastures`}
            adornment="%"
            type="number"
            disabled={isViewMode}
            tooltip={intl.formatMessage({
              id: "SUSTELL.STAGE.BEEF.HOUSING.TIME_SPENT_GRAZING_PASTURES.TOOLTIP",
            })}
            defaultValue={
              formContext.getValues(
                `${fieldItemPrefix}.timeGrazingPastures`
              ) as string
            }
            showHelperText={false}
            required
            focusLostHandler={handleHousingTime}
          />
        </div>
        <hr
          style={{
            borderWidth: "1px",
            borderColor: DSM_DARK_GREY,
            width: "100%",
            margin: 0,
            padding: 0,
          }}
        />
        {getTotalTimeSpentText()}
      </DsmGrid>
    </div>
  );
};

BeefHousingComponent.defaultProps = {
  target: undefined,
};

export default BeefHousingComponent;